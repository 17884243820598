import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import baseUrl from "../../../baseUrl";
import Loader from "../../../DefaultComponent/Loader";
import Modal from 'react-bootstrap/Modal';
import { useGetAllSuperAdminMenuQuery } from "../../../redux/superadminApiSlice";
import Edit from "../../../assets/images/Edit.svg";
import AddMenu from "./AddMenu";
import MenuPermissions from "./MenuPermissions";

const MenuList = () => {
    const [page, setPage] = useState(1);
    const [menuItem, setMenuItem] = useState('');
    const [menuList, setMenuList] = useState([]);
    const [show, setShow] = useState({ edit: false, permissions: false, modalName: "" });
    const containerRef = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const { data: menu, isLoading, isFetching, isSuccess } = useGetAllSuperAdminMenuQuery(page);
    const handleShow = (payload) => {
        setShow({ ...payload });
    }
    const handleClose = () => {
        setShow({ edit: false, permissions: false, modalName: "" });
    }
    useEffect(() => {
        if (isSuccess) {
            setMenuList((prev) => {
                const existingIds = prev?.map((item) => item.menu_id);
                const newMenu = menu?.data.filter(
                    (item) => !existingIds.includes(item.menu_id)
                );
                return [...prev, ...newMenu];
            });
            setHasMore(page < menu.totalPage);
        }
    }, [menu, isSuccess, page]);

    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    const handleScroll = () => {
        const container = containerRef.current;
        if (
            container.scrollTop + container.clientHeight >=
            container.scrollHeight - 100 &&
            !isFetching &&
            hasMore
        ) {
            setPage((prev) => prev + 1);
        }
    };

    const debouncedScroll = debounce(handleScroll, 100);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', debouncedScroll);
            return () => container.removeEventListener('scroll', debouncedScroll);
        }
    }, [debouncedScroll]);
    console.log(menuList);
    
    return (
        <>
            <div className="container-xl">
                <div className="row" >
                    <div className="app-card-body d-flex justify-content-between px-5">
                        <div className="payment-heading">
                            <h5>Menu</h5>
                        </div>
                        <button
                            className="btn btn-primary purp border border-1 text-black"
                            onClick={() => handleShow({ edit: true, permissions: false, modalName: "Add" })}
                        >
                            <i className="fa-solid fa-plus"></i> Add Menu
                        </button>
                    </div>
                </div>
                <div className="date-picker-container overflow-x-auto scroll-tbl ">
                    <Table>
                        <thead>
                            <tr>
                                <th>Menu Heading</th>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Icon</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className="scroll-trigger" ref={containerRef}>
                            {menuList?.length > 0 && menuList?.map((item) => {
                                return (
                                    <tr key={item.menu_id} className="table-hover cursor-pointer">
                                        <td>{item.menu_heading}</td>
                                        <td>{item.name}</td>
                                        <td>{item.slug}</td>
                                        <td>
                                            <img src={`${baseUrl}${item.icon}`} alt="" className="mx-2" />
                                            <img src={`${baseUrl}${item.icon_highlight}`} alt="" />
                                        </td>
                                        <td>
                                            <div className="button-hover">
                                                <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                    <img
                                                        src={Edit}
                                                        alt="Edit"
                                                        onClick={() => { handleShow({ edit: true, permissions: false, modalName: "Edit" }); setMenuItem(item) }}
                                                    />
                                                </div>
                                                <div className="tooltip-toggle" aria-label="Permissions" tabIndex="0">
                                                    <i className="fa-solid fa-lock cursor-pointer mx-2 " onClick={() => { handleShow({ edit: false, permissions: true, modalName: "Permissions" }) }}></i>
                                                </div>
                                                {/* <div className="tooltip-toggle" aria-label="permissions" tabIndex="0">
                                                    <img
                                                        style={{ marginLeft: "4px" }}
                                                        src={permissions}
                                                        alt="permissions"
                                                        onClick={() => handleShow({ edit: false, permissions: true, modalName: "permissions" })}
                                                    />
                                                </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                   
                </div>
                {/* edit menu */}
                <Modal show={show.edit} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{show.modalName} Menu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddMenu menuItem={show.modalName === 'Edit' ? menuItem : ''} action={show.modalName} handleClose={handleClose} />
                    </Modal.Body>

                </Modal>
                {/* permissions menu */}
                <Modal show={show.permissions} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>permissions Menu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MenuPermissions menuItem={menuItem} action={show.modalName} handleClose={handleClose} />
                    </Modal.Body>
                </Modal>

            </div>
            <Loader loading={isLoading || isFetching} />
        </>
    )
}

export default MenuList