// superadminApiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { toast } from 'react-toastify';
import { method } from 'lodash';
import END_POINTS from '../api/endPoints';

const getFingerPrint = async () => {
    try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        return result.visitorId;
    } catch (error) {
        console.log(error)
    }
}

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
        const deviceId = await getFingerPrint();
        const localTkn = localStorage.getItem("refreshToken");
        const sessionTkn = sessionStorage.getItem("refreshToken");
        const refToken = JSON.parse(localTkn ? localTkn : sessionTkn);
        if (refToken) {
            headers.set('Authorization', `${refToken}`);
        }
        if (deviceId) {
            headers.set('X-Device-Fingerprint', deviceId);
        }
        return headers;
    },
});


const showError = (error) => {
    if (error?.data?.message &&
        ["Unauthorized", "Forbidden"].some(status => error.data.message.includes(status))) {
        toast.error(error.data.message || "Something went wrong!")
        localStorage.removeItem("refreshToken");
        setTimeout(() => {
            window.location.replace("/super-admin-login");
        }, 3000)
    }
    else if (error?.data?.message) {
        toast.error(error.data.message || "Something went wrong!")

    }
}
export const superadminApiSlice = createApi({
    reducerPath: 'superadminMenu',
    baseQuery,
    tagTypes: ['Menu', 'Client', 'Role', 'Sidebar', 'Client-Profile', 'Client-Merchant','Transaction'],
    endpoints: (builder) => ({
        // Profile call
        getProfile: builder.query({
            query: () => END_POINTS.GET_PROFILE,
            transformErrorResponse: (error) => { showError(error) },
        }),
        // dashboard analytics
        getDashobardAnalytics: builder.query({
            query: ({ startDate, endDate, graphFilter }) => ({
                url: END_POINTS.DASHBOARD_ANALYTICS,
                method: 'GET',
                params: graphFilter === 'allTime'
                    ? { graphFilter }
                    : { startDate, endDate, graphFilter }
            }),
            transformErrorResponse: (error) => { showError(error) },
        }),
        getSidebar: builder.query({
            query: () => END_POINTS.SIDEBAR,
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Sidebar'],
        }),

        getAllSuperAdminMenu: builder.query({
            query: (page = 1) => `${END_POINTS.MENU_LIST}?page=${page}`,
            transformResponse: (response) => {
                return {
                    data: response?.data?.menus,
                    page: response?.data?.page,
                    totalPage: response?.data?.totalPage
                }
            },
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Menu'],
        }),

        // fetch entire menu list
        getAllMenuList: builder.query({
            query: () => END_POINTS.PARENT_MENU,
            transformResponse: (response) => {
                return response?.data?.map(item => ({ value: item?.menu_id, label: item.name }))
            },
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Menu'],
        }),

        updateMenuItem: builder.mutation({
            query: ({ formData, id }) => ({
                url: `${END_POINTS.UPDATE_MENU}/${id}`,
                method: 'PATCH',
                body: formData,
            }),

            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Menu', 'Sidebar'],
        }),

        addMenuItem: builder.mutation({
            query: (formData) => ({
                url: END_POINTS.ADD_MENU,
                method: 'POST',
                body: formData,
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Menu'],
        }),

        deleteMenu: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/super-admin/role/delete-menu?id=${id}`,
                method: 'DELETE',
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Menu'],
        }),

        // fetch roles
        getRoles: builder.query({
            query: () => END_POINTS.ROLE_LIST,
            transformResponse: (response) => {
                return response?.data?.map(item => ({ value: item?.id, label: item.name }))
            },
            transformErrorResponse: (error) => { showError(error) },
        }),
        // menu created role for
        // getRoles: builder.query({
        //     query: (id) => `/api/v1/super-admin/menu/role-list?menu_created_role_for=${id}`,
        //     transformResponse: (response) => {
        //         return response?.data?.map(item => ({ value: item?.id, label: item.name }))
        //     },
        //     transformErrorResponse: (error) => { showError(error) },
        // }),
        // roles 
        // fetch all roles 
        getRolesList: builder.query({
            query: (page = 1) => `${END_POINTS.ROLE_LIST_ALL}?perPage=${20}&page=${page}&isAll=${true}`,
            transformResponse: (response) => {
                return response?.data
            },
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Role'],
        }),

        //get role by id
        getRoleById: builder.query({
            query: ({ id }) => `${END_POINTS.GET_ROLE_BY_ID}/${id}`,
            transformErrorResponse: (error) => { showError(error) },
        }),

        // add role
        addRole: builder.mutation({
            query: (body) => ({
                url: END_POINTS.CREATE_ROLE,
                method: 'POST',
                body: body,
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Role'],
        }),

        //update role
        updateRole: builder.mutation({
            query: (body) => ({
                url: END_POINTS.UPDATE_ROLE,
                method: 'PATCH',
                body: body,
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Role'],
        }),

        // get client list
        getClientList: builder.query({
            query: ({ page = 1, search = '', perPage }) => ({
                url: END_POINTS.CLIENT_LIST,
                params: { page, search, perPage },
            }),
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Client'],
        }),

        // ADD client
        addClient: builder.mutation({
            query: (formData) => ({
                url: END_POINTS.ADD_CLIENT,
                method: 'POST',
                body: formData,
            }),
            transformErrorResponse: (error) => { return error?.data },
            invalidatesTags: (result, error) => {
                if (error) return []
                return ['Client']
            }
        }),

        // UPDATE client
        updateClient: builder.mutation({
            query: ({ formData, id }) => ({
                url: `${END_POINTS.UPDATE_CLIENT}/${id}`,
                method: 'PATCH',
                body: formData,
            }),
            transformErrorResponse: (error) => { return error?.data },
            invalidatesTags: (result, error) => {
                if (error) return []
                return ['Client']
            }

        }),

        // DELETE client
        deleteClient: builder.mutation({
            query: (clientId) => ({
                url: `${END_POINTS.DELETE_CLIENT}/${clientId}`,
                method: "DELETE",
            }),
            // transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: (result, error) => {
                if (error) return []
                return ['Client']
            }
        }),

        // get client detail by client id
        getClientProfileByClientId: builder.query({
            query: (id) => `${END_POINTS.GET_CLIENT_BY_ID}/${id}`,
            transformResponse: (response) => { return response?.data },
            providesTags: ['Client-Profile']
        }),

        // ADD skyfi credentials to client
        addSkyFiCredentials: builder.mutation({
            query: ({ clientId, body }) => ({
                url: `${END_POINTS.ADD_SKYFI_CREDENTIALS_TO_CLIENT}/${clientId}`,
                method: "POST",
                body,
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Client-Profile'],
        }),

        // menu permissions
        menuPermission: builder.mutation({
            query: (body) => ({
                url: END_POINTS.ADD_MENU_PERMISSION,
                method: "POST",
                body,
            }),
            transformErrorResponse: (error) => { showError(error) },

        }),

        // customer list
        getCustomerList: builder.query({
            query: ({ page = 1, search = '' }) => ({
                url: END_POINTS.CUSTOMER_LIST,
                params: { page, search },
            }),
            transformResponse: (response) => {
                return {
                    data: response?.data?.allList,
                    page: response?.data?.page,
                    totalPage: response?.data?.totalPage
                }
            },
            transformErrorResponse: (error) => { showError(error) },
        }),
        //GET MERCHANT LIST BY CLIENT ID
        getMerchantListByClientId: builder.query({
            query: ({ page = 1, search = '', clientId }) => ({
                url: `${END_POINTS.GET_MERCHANT_LIST_BY_CLIENT_ID}/${clientId}`,
                params: { page, search },
            }),
            transformResponse: (response) => {
                return {
                    data: response?.data?.allVerifiedMerchant,
                    page: response?.data?.page,
                    totalPage: response?.data?.totalPage
                }
            },
            providesTags: ['Client-Merchant'],
            transformErrorResponse: (error) => { showError(error) },
        }),
        approveMerchantById: builder.query({
            query: (id) => `${END_POINTS.APPROVE_MERCHANT_BY_ID}/${id}`,
            invalidatesTags: ['Client-Merchant']
        }),
        // TRANSACTIONS list
        getTransactionList: builder.query({
            query: ({ page = 1, search = '', endDate, startDate }) => ({
                url: END_POINTS.TRANSACTION_LIST,
                params: { page, search, endDate ,startDate},
            }),
            transformResponse: (response) => {
                return {
                    data: response?.data?.allTransaction,
                    page: response?.data?.page,
                    totalPage: response?.data?.totalPage
                }
            },
            providesTags: ['Transaction'],
            transformErrorResponse: (error) => { showError(error) },
        }),
        
    }),

});

export const {
    useGetDashobardAnalyticsQuery,
    useGetProfileQuery,
    useLazyGetSidebarQuery: useLazyGetSidebarQuerySuperAdmin,
    useLazyGetClientListQuery,
    useGetClientListQuery,
    useGetSidebarQuery: useGetSidebarQuerySuperAdmin,
    useGetAllSuperAdminMenuQuery,
    useUpdateMenuItemMutation,
    useAddMenuItemMutation,
    useDeleteMenuMutation,
    useGetRolesQuery,
    useGetAllMenuListQuery,
    useGetRolesListQuery,
    useAddRoleMutation,
    useUpdateRoleMutation,
    useLazyGetRoleByIdQuery,
    useMenuPermissionMutation,
    useGetCustomerListQuery,
    useAddClientMutation,
    useUpdateClientMutation,
    useGetClientProfileByClientIdQuery,
    useAddSkyFiCredentialsMutation,
    useGetMerchantListByClientIdQuery,
    useLazyApproveMerchantByIdQuery,
    useDeleteClientMutation,
    useGetTransactionListQuery,
} = superadminApiSlice;
