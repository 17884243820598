import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
    geocodeByPlaceId,
} from "react-places-autocomplete";
import { bank_account_type_Array, business_structure_option_Array, IndustryData, processing_environment_options_Array, title_option_Array } from './IndustryData';
import Select from 'react-select';
import BackBtn from '../../DefaultComponent/BackBtn';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { CreateSubmerchant } from '../../api/SubmerchantAPI';
import Loader from '../../DefaultComponent/Loader';
import SearchSelect from '../../SuperAdmin/DefaultComponents/SearchSelect';




const SelectBox = ({ divclass, labelclass, label, options, name, value, onChange, error }) => {
    return (
        <div className={divclass}>
            <label
                className={labelclass}
                style={{ display: "block" }}
            >
                {label}
            </label>
            <select
                className="custom-date-picker"
                name={name}
                value={value}
                onChange={onChange}
                style={{ color: value ? "#212529" : "#999" }}
            >
                {
                    options?.map((item) => {
                        return (
                            <option value={item.value} style={{ color: item?.color }} >
                                {item.name}
                            </option>
                        )
                    })
                }
            </select>
            {error && (
                <span className="text-danger fs-12">
                    {error}
                </span>
            )}
        </div>
    )
}
const InputBox = ({ divclass, labelclass, label, inputtype, placeholder, name, value, onChange, error, maxLength, min, minLength, max }) => {
    return (
        <div className={divclass}>
            <label
                className={labelclass}
                style={{ display: "block" }}
            >
                {label}
            </label>
            <input
                className="custom-date-picker"
                type={inputtype}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                minLength={minLength}
                maxLength={maxLength}
                min={min}
                max={max}
            />
            {error && (
                <span className="text-danger fs-12">
                    {error}
                </span>
            )}
        </div>
    )
}

const initialOwnerValues = {
    // phone: '1231231231',
    // first_name: 'June',
    // last_name: 'Summers',
    // date_of_birth: '2023-04-04',
    // email: 'june1@mailinator.com',
    // address: '12315 West McFarlane Road',
    // city: 'Airway Heights',
    // state: 'WA',
    // country: 'US',
    // zip: '99001',
    // b_title: 'CEO',
    // ssn: '121231231',
    // ownership_percent: '100',
    // license_number: '1321231231231',
    phone: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    b_title: '',
    ssn: '',
    ownership_percent: '',
    license_number: '',
    isPrimary: false,
    error: {},
}
const initialOwnerValues2 = {
    // phone: '1231231231',
    // first_name: 'Summer',
    // last_name: 'june',
    // date_of_birth: '2023-04-04',
    // email: 'summer1@mailinator.com',
    // address: '12315 West McFarlane Road',
    // city: 'Airway Heights',
    // state: 'WA',
    // country: 'US',
    // zip: '99001',
    // b_title: 'CEO',
    // ssn: '121231231',
    // ownership_percent: '100',
    // license_number: '1321231231231',
    // isPrimary: false,
    phone: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    b_title: '',
    ssn: '',
    ownership_percent: '',
    license_number: '',
    isPrimary: false,
    error: {},
}

const initialSubmerchantValues = {
    // business_name: 'Fast Wheels',
    // business_structure: 'LLC',
    // business_address: '12315 W McFarlane Rd',
    // business_state: 'WA',
    // business_city: 'Airway Heights',
    // business_country: 'US',
    // business_zip: '99001',
    // ein: '123123123',
    // processing_environment: 'fuel',
    // website: 'http://google.com',
    // business_industry: '7512',
    // legal_productssold: '1500',
    // established_date: '2024-09-17',
    // avgTicket: '25000',
    // annualCCSales: '60000',
    // legal_b2b: '50',
    // legal_b2c: '50',
    // legal_cp: '48',
    // legal_keyed: '52',
    // legal_cnpphoneemail: '60',
    // legal_cnpinternet: '40',
    // legal_percentdeliveredsameday: '25',
    // percentdelievered7days: '25',
    // percentdelivered14days: '25',
    // legal_percentdeliveredover30days: '25',
    // routing_number: '121231231',
    // bank_name: 'Family Bank',
    // account_number: '1234567899',
    // account_method: '8',
    // password: 'Pine@123',
    // cnf_password: 'Pine@123',
    business_name: '',
    business_structure: '',
    business_address: '',
    business_state: '',
    business_city: '',
    business_country: '',
    business_zip: '',
    ein: '',
    processing_environment: '',
    website: '',
    business_industry: '',
    legal_productssold: '',
    established_date: '',
    avgTicket: '',
    annualCCSales: '',
    legal_b2b: '',
    legal_b2c: '',
    legal_cp: '',
    legal_keyed: '',
    legal_cnpphoneemail: '',
    legal_cnpinternet: '',
    legal_percentdeliveredsameday: '',
    percentdelievered7days: '',
    percentdelivered14days: '',
    legal_percentdeliveredover30days: '',
    routing_number: '',
    bank_name: '',
    account_number: '',
    account_method: '',
    password: '',
    cnf_password: '',
    errors: {}
}

const AddSubMerchant = () => {
    const navigate = useNavigate()
    const phoneNum = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    const websiteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+)(\.[a-zA-Z]{2,})+$/;
    const einRegex = /^\d{9}$/;
    const numberRegex = /^\d+(\.\d+)?$/;
    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [personalAddress, setPersonalAddress] = useState([])
    const [businessAddress, setBusinessAddress] = useState([])
    const [bot_key, setBot_key] = useState('')
    const [state, setState] = useState({
        loading: false
    }
    )
    const [ownersdata, setOwnersData] = useState([initialOwnerValues, initialOwnerValues2]);
    const [submerchantdata, setSubmerchantdata] = useState(initialSubmerchantValues)
    const addOwner = () => {
        setOwnersData([
            ...ownersdata,
            initialOwnerValues
        ]);
    };
    const removeOwner = (index) => {
        const newOwners = ownersdata.filter((_, i) => i !== index);
        setOwnersData(newOwners);
    };
    const handleownerChange = (index, e) => {
        const { name, value } = e.target;
        const newOwners = [...ownersdata];
        newOwners[index] = { ...newOwners[index], [name]: value };
        newOwners.forEach((owner, index) => {
            newOwners[index] = { ...owner, error: {} }
        })
        setSubmerchantdata({
            ...submerchantdata,
            errors: {}
        });
        setOwnersData(newOwners);
    };
    const handleRestChange = (e) => {
        const { name, value } = e.target;
        setSubmerchantdata({
            ...submerchantdata,
            [name]: value,
            errors: {}
        });
        const newOwners = [...ownersdata];
        newOwners.forEach((owner, index) => {
            newOwners[index] = { ...owner, error: {} }
        })
        setOwnersData(newOwners);
    };
    const handleDOBChange = (date, index) => {
        // console.log(date, index);
        const newOwners = [...ownersdata];
        newOwners[index] = { ...newOwners[index], date_of_birth: date ? date.toISOString().split('T')[0] : '' };
        newOwners.forEach((owner, index) => {
            newOwners[index] = { ...owner, error: {} }
        })
        setOwnersData(newOwners);
    };
    const handleEDChange = (date) => {
        let newdata = { ...submerchantdata, established_date: date ? date.toISOString().split('T')[0] : '', errors: {} };
        setSubmerchantdata(newdata);
    };
    const handlePhoneNumber = (num) => {
        let no = num.replace(/\D/g, "");
        return phoneNum.test(no);
    };
    // const validateOwner = () => {
    //     let isValid = true;
    //     const newOwners = [...ownersdata];

    //     const sumOwnershipPercentValues = () => {
    //         return newOwners.reduce((sum, item) => sum + parseInt(item.ownership_percent), 0);
    //     };


    //     newOwners.forEach((owner, index) => {
    //         let errors = {};

    //         // Check if required fields are filled
    //         if (!owner?.first_name) {
    //             errors.first_name = "First Name is required";
    //             isValid = false;
    //         }
    //         if (!owner?.last_name) {
    //             errors.last_name = "Last Name is required";
    //             isValid = false;
    //         }
    //         if (!owner?.phone) {
    //             errors.phone = "Phone number is required";
    //             isValid = false;
    //         } else if (!handlePhoneNumber(owner?.phone)) {
    //             errors.phone = "Phone number must be a valid number";
    //         }
    //         if (!owner?.email) {
    //             errors.email = "Email is required";
    //             isValid = false;
    //         } else if (!regEmail.test(owner?.email)) {
    //             errors.email = "Invalid Email";
    //             isValid = false;
    //         }
    //         if (!owner?.date_of_birth) {
    //             errors.date_of_birth = "Date of Birth is required";
    //             isValid = false;
    //         }
    //         if (!owner?.ssn) {
    //             errors.ssn = "Social Security Number is required";
    //             isValid = false;
    //         } else if (!ssnRegex.test(owner?.ssn)) {
    //             errors.ssn = "Invalid Social Security Number";
    //             isValid = false;
    //         }
    //         if (!owner?.ownership_percent) {
    //             errors.ownership_percent = "Ownership Percent is required";
    //             isValid = false;
    //         } else if (owner?.ownership_percent < 25) {
    //             errors.ownership_percent = "Ownership percent must be at least 25";
    //             isValid = false;
    //         } else if (sumOwnershipPercentValues() !== 100) {
    //             errors.ownership_percent = "Ownership percent cannot be more than 100";
    //             isValid = false;
    //         }
    //         if (!owner?.b_title) {
    //             errors.b_title = "Position/Title is required";
    //             isValid = false;
    //         }
    //         if (!owner?.address) {
    //             errors.address = "Address is required";
    //             isValid = false;
    //         }
    //         if (!owner?.city) {
    //             errors.city = "City is required";
    //             isValid = false;
    //         }
    //         if (!owner?.state) {
    //             errors.state = "State is required";
    //             isValid = false;
    //         }
    //         if (!owner?.country) {
    //             errors.country = "Country is required";
    //             isValid = false;
    //         }
    //         if (!owner?.zip) {
    //             errors.zip = "Zip Code is required";
    //             isValid = false;
    //         }
    //         if (!owner?.license_number) {
    //             errors.license_number = "License Number is required";
    //             isValid = false;
    //         }

    //         console.log(errors);

    //         // Update error state for each owner
    //         newOwners[index] = { ...owner, error: errors };
    //     });

    //     setOwnersData(newOwners);
    //     return isValid;
    // };

    const validateOwner = () => {
        const newOwners = [...ownersdata];
        let isValid = true;

        // Sum of ownership percentages across all owners
        const sumOwnershipPercentValues = newOwners.reduce((sum, item) => sum + parseInt(item.ownership_percent, 10), 0);

        // Helper functions to validate individual fields
        const validatePhone = (phone) => handlePhoneNumber(phone);
        const validateEmail = (email) => regEmail.test(email);
        const validateSSN = (ssn) => ssnRegex.test(ssn);

        // Define the required fields with custom error messages and validation functions
        const requiredFields = [
            { field: 'first_name', errorMessage: "First Name is required" },
            { field: 'last_name', errorMessage: "Last Name is required" },
            { field: 'phone', errorMessage: "Phone number is required", validation: validatePhone, validationMessage: "Phone number must be a valid number" },
            { field: 'email', errorMessage: "Email is required", validation: validateEmail, validationMessage: "Invalid Email" },
            { field: 'date_of_birth', errorMessage: "Date of Birth is required" },
            { field: 'ssn', errorMessage: "SSN is required", validation: validateSSN, validationMessage: "Invalid Social Security Number" },
            { field: 'b_title', errorMessage: "Position/Title is required" },
            { field: 'address', errorMessage: "Address is required" },
            { field: 'city', errorMessage: "City is required" },
            { field: 'state', errorMessage: "State is required" },
            { field: 'country', errorMessage: "Country is required" },
            { field: 'zip', errorMessage: "Zip Code is required" },
            { field: 'license_number', errorMessage: "License Number is required" }
        ];

        // Validate each owner
        newOwners.forEach((owner, index) => {
            let errors = {};

            // Loop through all the required fields and check for validation
            requiredFields.forEach(({ field, validation, errorMessage, validationMessage }) => {
                const fieldValue = owner[field];

                // First check for the "required" condition
                if (!fieldValue) {
                    errors[field] = errorMessage; // Use the requiredMessage or default to errorMessage
                    isValid = false;
                } else if (validation && !validation(fieldValue)) {
                    // Apply custom validation if a validation function is provided
                    errors[field] = validationMessage;
                    isValid = false;
                }
            });

            // Special case: ownership percent validation
            const ownershipPercent = owner?.ownership_percent;
            if (!ownershipPercent) {
                errors.ownership_percent = "Ownership Percent is required";
                isValid = false;
            } else if (ownershipPercent < 25) {
                errors.ownership_percent = "Ownership percent must be at least 25";
                isValid = false;
            } else if (ownershipPercent > 100) {
                errors.ownership_percent = "Ownership percent must not exceed 100";
                isValid = false;
            }

            // Ownership sum validation (once per validation run)
            if (sumOwnershipPercentValues !== 100) {
                errors.ownership_percent = "Total Ownership percent must equal 100";
                isValid = false;
            }

            // Update the owner object with the errors
            newOwners[index] = { ...owner, error: errors };
        });

        // Update the state with the new owners data containing errors
        setOwnersData(newOwners);
        return isValid;
    };





    const checkPassword = (pass, cnf_pass) => {
        if (isEqual(pass, cnf_pass)) {
            return true;
        }
        return false;
    };
    // const validateRest = () => {
    //     let isValid = true;
    //     let errors = {};
    //     console.log(errors, "errors");
    //     if (!submerchantdata?.business_name) {
    //         errors.business_name = "Business Name is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_structure) {
    //         errors.business_structure = "Company Structure is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.ein) {
    //         errors.ein = "EIN or TAX ID is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_address) {
    //         errors.business_address = "Business Address is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_city) {
    //         errors.business_city = "Business City is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_country) {
    //         errors.business_country = "Business Country is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_state) {
    //         errors.business_state = "Business State is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_zip) {
    //         errors.business_zip = "Business Zip Code is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.business_industry) {
    //         errors.business_industry = "Industry is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.processing_environment) {
    //         errors.processing_environment = "Processing Environment is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.website) {
    //         errors.website = "Website is required";
    //         isValid = false;
    //     } else if (!websiteRegex.test(submerchantdata?.website)) {
    //         errors.website = "Invalid URL";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.established_date) {
    //         errors.established_date = "Established Date is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_productssold) {
    //         errors.legal_productssold = "Product Sold is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.avgTicket) {
    //         errors.avgTicket = "Average Ticket is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.annualCCSales) {
    //         errors.annualCCSales = "Annual Credit Card Sales is required";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_b2c) {
    //         errors.legal_b2c = "B2C Transactions is required";
    //         isValid = false;
    //     } else if ((+submerchantdata?.legal_b2b + +submerchantdata?.legal_b2c) !== 100) {
    //         errors.legal_b2c = "B2B and B2C should total 100%";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_b2b) {
    //         errors.legal_b2b = "B2C Transactions is required";
    //         isValid = false;
    //     } else if ((+submerchantdata?.legal_b2b + +submerchantdata?.legal_b2c) !== 100) {
    //         errors.legal_b2b = "B2B and B2C should total 100%";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_cp) {
    //         errors.legal_cp = "Card Present is required";
    //         isValid = false;
    //     } else if ((+submerchantdata?.legal_cp + +submerchantdata?.legal_keyed) !== 100) {
    //         errors.legal_cp = "Card Present and Card Not Present should total 100%";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_keyed) {
    //         errors.legal_keyed = "Card Not Present is required";
    //         isValid = false;
    //     } else if ((+submerchantdata?.legal_cp + +submerchantdata?.legal_keyed) !== 100) {
    //         errors.legal_keyed = "Card Present and Card Not Present should total 100%";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_cnpphoneemail) {
    //         errors.legal_cnpphoneemail = "Manual Entry via Phone/Email is required";
    //         isValid = false;
    //     } else if ((+submerchantdata?.legal_cnpphoneemail + +submerchantdata?.legal_cnpinternet) !== 100) {
    //         errors.legal_cnpphoneemail = "Manual Entry via Phone/Email, internet should total 100%";
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_cnpinternet) {
    //         errors.legal_cnpinternet = "Manual Entry via Phone/Email is required";
    //         isValid = false;
    //     } else if ((+submerchantdata?.legal_cnpphoneemail + +submerchantdata?.legal_cnpinternet) !== 100) {
    //         errors.legal_cnpinternet = "Manual Entry via Phone/Email, internet should total 100%";
    //         isValid = false;
    //     }

    //     if (!submerchantdata?.legal_percentdeliveredsameday) {
    //         errors.legal_percentdeliveredsameday = "Same-Day Delivery is required"
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.percentdelievered7days) {
    //         errors.percentdelievered7days = "Deferred Delivery > 7 is required"
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.percentdelivered14days) {
    //         errors.percentdelivered14days = "Deferred Delivery > 14 is required"
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.legal_percentdeliveredover30days) {
    //         errors.legal_percentdeliveredover30days = "Deferred Delivery > 30 Days is required"
    //         isValid = false;
    //     }

    //     if (submerchantdata?.legal_percentdeliveredsameday && submerchantdata?.percentdelievered7days && submerchantdata?.percentdelivered14days && submerchantdata?.legal_percentdeliveredover30days && (+submerchantdata?.legal_percentdeliveredsameday + +submerchantdata?.percentdelievered7days + +submerchantdata?.percentdelivered14days + +submerchantdata?.legal_percentdeliveredover30days) !== 100) {
    //         errors.posttrantion = "Deferred Delivery greater then 7, 14, 30 Days should total 100%,"
    //         isValid = false;
    //     }

    //     if (!submerchantdata?.routing_number) {
    //         errors.routing_number = "Routing number is required"
    //         isValid = false;
    //     } else if (!numberRegex.test(submerchantdata?.routing_number)) {
    //         errors.routing_number = "Routing number must be a number"
    //         isValid = false;
    //     }

    //     if (!submerchantdata?.account_number) {
    //         errors.account_number = "Account number is required"
    //         isValid = false;
    //     } else if (!(submerchantdata?.account_number?.length >= 4 && submerchantdata?.account_number?.length <= 17)) {
    //         errors.account_number = "Should be between 4 and 17 digits and contain numbers only"
    //         isValid = false;
    //     } else if (!numberRegex.test(submerchantdata?.account_number)) {
    //         errors.account_number = "Should contain numbers only"
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.bank_name) {
    //         errors.bank_name = "Bank Name is required"
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.account_method) {
    //         errors.account_method = "Account type is required"
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.password) {
    //         errors.password = "Password is required"
    //         isValid = false;
    //     }
    //     if (submerchantdata?.password.length < 8) {
    //         errors.password = "Password must be at least 8 characters long."
    //         isValid = false;
    //     }

    //     // Check if password contains at least one lowercase letter
    //     if (!/[a-z]/.test(submerchantdata?.password)) {
    //         errors.password = "Password must contain at least one lowercase letter."
    //         isValid = false;
    //     }

    //     // Check if password contains at least one uppercase letter
    //     if (!/[A-Z]/.test(submerchantdata?.password)) {
    //         errors.password = "Password must contain at least one uppercase letter."
    //         isValid = false;
    //     }

    //     // Check if password contains at least one digit
    //     if (!/\d/.test(submerchantdata?.password)) {
    //         errors.password = "Password must contain at least one number."
    //         isValid = false;
    //     }

    //     // Check if password contains at least one special character
    //     if (!/[!@#$%^&*(),.?":{}|<>]/.test(submerchantdata?.password)) {
    //         errors.password = "Password must contain at least one special character."
    //         isValid = false;
    //     }
    //     if (!submerchantdata?.cnf_password) {
    //         errors.cnf_password = "Confirm Password is required"
    //         isValid = false;
    //     } else if (!(checkPassword(submerchantdata?.password, submerchantdata?.cnf_password))) {
    //         errors.cnf_password = "Confirm password and Password must be same"
    //         isValid = false;
    //     }

    //     setSubmerchantdata({
    //         ...submerchantdata,
    //         errors: errors
    //     });
    //     return isValid;
    // }

    const validateRest = () => {
        let isValid = true;
        let errors = {};

        // Helper functions for custom validations
        const validateWebsite = (website) => websiteRegex.test(website);
        const validateEin = (ein) => einRegex.test(ein);
        const validateRoutingNumber = (routingNumber) => numberRegex.test(routingNumber);
        const validateAccountNumber = (accountNumber) => accountNumber.length >= 4 && accountNumber.length <= 17 && numberRegex.test(accountNumber);
        const validatePasswordLength = (password) => password.length >= 8;
        const validatePasswordLowercase = (password) => /[a-z]/.test(password);
        const validatePasswordUppercase = (password) => /[A-Z]/.test(password);
        const validatePasswordDigit = (password) => /\d/.test(password);
        const validatePasswordSpecialChar = (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const validateRange = (value) => value >= 0 && value <= 100;
        const validateMinimum = (value) => value >= 0;

        // Required fields with error messages and validation rules
        const requiredFields = [
            { field: 'business_name', errorMessage: "Business Name is required" },
            { field: 'business_structure', errorMessage: "Company Structure is required" },
            { field: 'ein', errorMessage: "EIN or TAX ID is required", validation: validateEin, validationMessage: "Invalid EIN" },
            { field: 'business_address', errorMessage: "Business Address is required" },
            { field: 'business_city', errorMessage: "Business City is required" },
            { field: 'business_country', errorMessage: "Business Country is required" },
            { field: 'business_state', errorMessage: "Business State is required" },
            { field: 'business_zip', errorMessage: "Business Zip Code is required" },
            { field: 'business_industry', errorMessage: "Industry is required" },
            { field: 'processing_environment', errorMessage: "Processing Environment is required" },
            { field: 'website', errorMessage: "Website is required", validation: validateWebsite, validationMessage: "Invalid URL" },
            { field: 'established_date', errorMessage: "Established Date is required" },
            { field: 'legal_productssold', errorMessage: "Product Sold is required" },
            { field: 'avgTicket', errorMessage: "Average Ticket is required", validation: validateMinimum, validationMessage: "Average Ticket must be above 0" },
            { field: 'annualCCSales', errorMessage: "Annual Credit Card Sales is required", validation: validateMinimum, validationMessage: "Annual Credit Card Sales must be above 0" },
            { field: 'legal_b2c', errorMessage: "B2C Transactions is required", validation: validateRange, validationMessage: "B2C Transactions must be between 0 and 100." },
            { field: 'legal_b2b', errorMessage: "B2B Transactions is required", validation: validateRange, validationMessage: "B2B Transactions must be between 0 and 100." },
            { field: 'legal_cp', errorMessage: "Card Present is required", validation: validateRange, validationMessage: "Card Present must be between 0 and 100." },
            { field: 'legal_keyed', errorMessage: "Card Not Present is required", validation: validateRange, validationMessage: "Card Not Present must be between 0 and 100." },
            { field: 'legal_cnpphoneemail', errorMessage: "Manual Entry via Phone/Email is required", validation: validateRange, validationMessage: "Manual Entry via Phone/Email must be between 0 and 100." },
            { field: 'legal_cnpinternet', errorMessage: "Manual Entry via Internet is required", validation: validateRange, validationMessage: "Manual Entry via Internet must be between 0 and 100." },
            { field: 'legal_percentdeliveredsameday', errorMessage: "Same-Day Delivery is required", validation: validateRange, validationMessage: "Same-Day Delivery must be between 0 and 100." },
            { field: 'percentdelievered7days', errorMessage: "Deferred Delivery > 7 Days is required", validation: validateRange, validationMessage: "Deferred Delivery > 7 Days must be between 0 and 100." },
            { field: 'percentdelivered14days', errorMessage: "Deferred Delivery > 14 Days  is required", validation: validateRange, validationMessage: "Deferred Delivery > 14 Days must be between 0 and 100." },
            { field: 'legal_percentdeliveredover30days', errorMessage: "Deferred Delivery > 30 Days is required", validation: validateRange, validationMessage: "Deferred Delivery > 30 Days must be between 0 and 100." },
            { field: 'routing_number', errorMessage: "Routing number is required", validation: validateRoutingNumber, validationMessage: "Routing number must be a number" },
            { field: 'account_number', errorMessage: "Account number is required", validation: validateAccountNumber, validationMessage: "Should be between 4 and 17 digits and contain numbers only" },
            { field: 'bank_name', errorMessage: "Bank Name is required" },
            { field: 'account_method', errorMessage: "Account type is required" },
            { field: 'password', errorMessage: "Password is required" },
            { field: 'cnf_password', errorMessage: "Confirm Password is required" }
        ];

        // Validate each field in submerchantdata
        requiredFields.forEach(({ field, errorMessage, validation, validationMessage }) => {
            const fieldValue = submerchantdata[field];

            // Check for required fields
            if (!fieldValue) {
                errors[field] = errorMessage;
                isValid = false;
            } else if (validation && !validation(fieldValue)) {
                // Custom validation
                errors[field] = validationMessage;
                isValid = false;
            }
        });

        // Password field validation (multi-step)
        const password = submerchantdata?.password;

        if (password) {
            if (!validatePasswordLength(password)) {
                errors.password = "Password must be at least 8 characters long.";
                isValid = false;
            }
            if (!validatePasswordLowercase(password)) {
                errors.password = "Password must contain at least one lowercase letter.";
                isValid = false;
            }
            if (!validatePasswordUppercase(password)) {
                errors.password = "Password must contain at least one uppercase letter.";
                isValid = false;
            }
            if (!validatePasswordDigit(password)) {
                errors.password = "Password must contain at least one number.";
                isValid = false;
            }
            if (!validatePasswordSpecialChar(password)) {
                errors.password = "Password must contain at least one special character.";
                isValid = false;
            }
        }

        // Confirm Password validation (check if it matches)
        const cnfPassword = submerchantdata?.cnf_password;
        if (cnfPassword && cnfPassword !== password) {
            errors.cnf_password = "Confirm password and Password must be the same";
            isValid = false;
        }

        // Special case for fields that require a sum validation
        if (+submerchantdata?.legal_b2b + +submerchantdata?.legal_b2c !== 100) {
            errors.legal_b2b = "B2B and B2C should total 100%";
            errors.legal_b2c = "B2B and B2C should total 100%";
            isValid = false;
        }

        if (+submerchantdata?.legal_cp + +submerchantdata?.legal_keyed !== 100) {
            errors.legal_cp = "Card Present and Card Not Present should total 100%";
            errors.legal_keyed = "Card Present and Card Not Present should total 100%";
            isValid = false;
        }

        if (+submerchantdata?.legal_cnpphoneemail + +submerchantdata?.legal_cnpinternet !== 100) {
            errors.legal_cnpphoneemail = "Manual Entry via Phone/Email, internet should total 100%";
            errors.legal_cnpinternet = "Manual Entry via Phone/Email, internet should total 100%";
            isValid = false;
        }

        if (submerchantdata?.legal_percentdeliveredsameday && submerchantdata?.percentdelievered7days && submerchantdata?.percentdelivered14days && submerchantdata?.legal_percentdeliveredover30days && (+submerchantdata?.legal_percentdeliveredsameday + +submerchantdata?.percentdelievered7days + +submerchantdata?.percentdelivered14days + +submerchantdata?.legal_percentdeliveredover30days) !== 100) {
            errors.posttrantion = "Deferred Delivery greater than 7, 14, 30 Days should total 100%";
            isValid = false;
        }

        // Update the submerchant data with the errors
        setSubmerchantdata({
            ...submerchantdata,
            errors: errors
        });

        return isValid;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        let restvalid = validateRest();
        let oenwervalid = validateOwner();
        if (oenwervalid && restvalid) {
            let ownerdatafinal = []
            ownersdata.map((item, index) => {
                let ow = item
                delete ow['error']
                if (index === 0) {
                    ow = { ...ow, isPrimary: true, ssn: getCleanSSN(ow.ssn), phone: getCleanPhoneNumber(ow.phone), date_of_birth: moment(ow.date_of_birth).format('YYYY-MM-DD') }
                } else {
                    ow = { ...ow, isPrimary: false, ssn: getCleanSSN(ow.ssn), phone: getCleanPhoneNumber(ow.phone), date_of_birth: moment(ow.date_of_birth).format('YYYY-MM-DD') }
                }
                ownerdatafinal.push(ow)
            })

            let submerchantdataFinal = submerchantdata;
            submerchantdataFinal = {
                ...submerchantdataFinal,
                established_date: moment(submerchantdataFinal.established_date).format("YYYY-MM-DD")
            }
            delete submerchantdataFinal['cnf_password']
            delete submerchantdataFinal['errors']

            let finaldata = {
                OwnerInfo: [...ownerdatafinal],
                ...submerchantdataFinal
            }
            try {
                setState((prev) => ({ ...prev, loading: true }));
                const token = localStorage.getItem("token");
                let tokn = JSON.parse(token)
                let obj = { ...finaldata, bot_key }
                const response = await CreateSubmerchant(tokn, obj)
                setState((prev) => ({ ...prev, loading: false }));
                if (response?.status) {
                    toast.success(response?.data?.message || "Sub-Merchant Added successfully!");
                    setSubmerchantdata(initialSubmerchantValues)
                    navigate("/sub-merchant");
                }
            } catch (error) {
                setState((prev) => ({ ...prev, loading: false }));
                console.error("Error:", error);
                if (error.response && error.response.data) {
                    console.log("Error response data:", error.response.data);
                    if (error.response.data.errors) {
                        const errorMessages = Object.values(error.response.data.errors).join(
                            ", "
                        );
                        toast.error(errorMessages);
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error("An unexpected error occurred. Please try again later.");
                    }
                }
            }

        } else {
            toast.error("Please fill out all required fields.")
        }
    };
    const formattedDate = (data) => {
        if (!data) {
            return "";
        } else {
            const momentDate = moment(data);
            if (momentDate.isValid()) {
                return momentDate.format("YYYY-MM-DD");
            } else {
                return "Invalid date";
            }
        }
    };
    const formatSSN = (numericValue) => {
        const cleaned = ('' + numericValue).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match) {
            return `${match[1] + '-' + match[2] + '-' + match[3]}`;
        }
        return numericValue;
    };
    const getCleanSSN = (formattedValue) => {
        return formattedValue.replace(/\D/g, '');
    };
    const formatPhoneNumber = (numericValue) => {
        const cleaned = ('' + numericValue).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${'(' + match[1] + ') ' + match[2] + '-' + match[3]}`;
        }
        return numericValue;
    };
    const getCleanPhoneNumber = (formattedValue) => {
        return formattedValue.replace(/\D/g, '');
    };
    const handlePersonlAddresschange = (address, index) => {
        let newaddress = [...personalAddress]
        newaddress[index] = address
        setPersonalAddress(newaddress)
    }
    const handleBusinessAddresschange = (address) => {
        setBusinessAddress(address)
    }
    const handlepersonalAddressSelect = async (address, placeID, index) => {
        let newaddress = [...ownersdata]
        let streetNumber = ''
        let city = ''
        let state = ''
        let country = ''
        let zipcode = ''

        //for displaying address
        let newaddress1 = [...personalAddress]
        newaddress1[index] = address?.split(",")[0]
        setPersonalAddress(newaddress1)

        try {

            const result = await geocodeByPlaceId(placeID)
            const { address_components, formatted_address } = result[0];
            address_components.forEach((component) => {
                const { types, long_name, short_name } = component;
                if (types.includes("street_number")) {
                    streetNumber = short_name;
                }
                if (types.includes("locality")) {
                    city = short_name;
                } else if (types.includes("administrative_area_level_1")) {
                    state = short_name;
                } else if (types.includes("country")) {
                    country = short_name;
                } else if (types.includes("postal_code")) {
                    zipcode = long_name;
                } else if (types.includes("route")) {
                    console.log("route");

                }
            });

            newaddress[index] = {
                ...newaddress[index],
                address: address?.split(",")[0],
                city: city,
                state: state,
                country: country,
                zip: zipcode,
                error: {}
            }
            setOwnersData(newaddress)


        } catch (error) {
            console.log(error, "Address Select error");

        }

    }
    const handleBusinessAddressSelect = async (address, placeID) => {
        let streetNumber = ''
        let city = ''
        let state = ''
        let country = ''
        let zipcode = ''

        //for displaying address
        setBusinessAddress(address?.split(",")[0])

        try {

            const result = await geocodeByPlaceId(placeID)
            const { address_components, formatted_address } = result[0];
            address_components.forEach((component) => {
                const { types, long_name, short_name } = component;
                if (types.includes("street_number")) {
                    streetNumber = short_name;
                }
                if (types.includes("locality")) {
                    city = short_name;
                } else if (types.includes("administrative_area_level_1")) {
                    state = short_name;
                } else if (types.includes("country")) {
                    country = short_name;
                } else if (types.includes("postal_code")) {
                    zipcode = long_name;
                } else if (types.includes("route")) {
                    console.log("route");

                }
            });

            let newaddress = {
                ...submerchantdata,
                business_address: address?.split(",")[0],
                business_city: city,
                business_state: state,
                business_country: country,
                business_zip: zipcode,
                errors: {}
            }

            setSubmerchantdata(newaddress)


        } catch (error) {
            console.log(error, "Address Select error");

        }

    }

    // const handleOwnerBlur = (e) => {
    //     const { name } = e.target;
    //     const newOwners = [...ownersdata];
    //     let touchedobj = {}
    //     newOwners?.forEach((owner, index) => {
    //         touchedobj[name] = true;
    //         newOwners[index] = { ...owner, touched: touchedobj }
    //     })
    //     setOwnersData(newOwners)
    //     let check = validateOwner();
    // };

    return (
        <>
            <Loader loading={state?.loading} />
            <div className="pb-2">
                <BackBtn />
            </div>
            <div className="container">
                <h1 className="app-page-title text-center">Add Sub-Merchant</h1>
                <form onSubmit={(e) => { handleSubmit(e) }}>
                    {/* *********Personal Details**********   */}
                    <div className='d-flex mt-2 mb-4 justify-content-between align-items-center' >
                        <h4 >Owner Info</h4>
                        <div>
                            <button
                                disabled={ownersdata?.length === 4} className='second-blk-btn'
                                onClick={(e) => {
                                    e.preventDefault();
                                    addOwner();
                                }}
                            >
                                Add Owner +1
                            </button>
                        </div>
                    </div>
                    {
                        ownersdata?.length > 0 && ownersdata?.map((owner, index) => {
                            return (
                                <div className='py-4' style={{ borderBottom: '1px solid #35294d40' }} key={index}>
                                    <div className='row my-0'>
                                        <div className='py-2 d-flex justify-content-between' >
                                            <div>
                                                <h5 style={{ textTransform: 'capitalize' }} >{owner?.first_name || `Owner ${index + 1}`}</h5>
                                                <p>{owner?.b_title || "CEO"} <span> ~ Ownership {owner?.ownership_percent || 50}% </span> </p>
                                            </div>
                                            {
                                                ownersdata?.length > 1 && (
                                                    <div>
                                                        <button onClick={() => { removeOwner(index) }} className='idle-blk-btn' >
                                                            Delete
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='row my-0'>
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle mt-0"
                                            label="First Name*" inputtype="text" placeholder="First Name"
                                            name='first_name'
                                            value={owner?.first_name} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.first_name}
                                        />
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle mt-0"
                                            label="Last Name*" inputtype="text" placeholder="Last Name"
                                            name='last_name'
                                            value={owner?.last_name} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.last_name}
                                        />

                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle mt-0"
                                            label="Phone*" inputtype="text" placeholder="Phone"
                                            name='phone'
                                            value={formatPhoneNumber(owner?.phone)} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.phone}
                                            maxLength="10"
                                        />

                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Email*" inputtype="email" placeholder="Email"
                                            name='email'
                                            value={owner?.email} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.email}
                                        />
                                        <div className="col-md-4">
                                            <label
                                                className="labelStyle"
                                                style={{ display: "block" }}
                                            >
                                                Date of Birth*
                                            </label>
                                            <DatePicker
                                                name="dob"
                                                selected={formattedDate(owner?.date_of_birth)}
                                                onChange={(e) => { handleDOBChange(e, index) }}
                                                className="custom-datepicker"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="YYYY-MM-DD"
                                            />
                                            <br></br>
                                            {owner?.error?.date_of_birth && (
                                                <span className="text-danger fs-12">
                                                    {owner?.error?.date_of_birth}
                                                </span>
                                            )}
                                        </div>
                                        <SelectBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Position/Title*" options={title_option_Array}
                                            name="b_title" value={owner?.b_title}
                                            onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.b_title}
                                        />
                                        <div className="col-md-8">
                                            <label
                                                className="labelStyle"
                                                style={{ display: "block" }}
                                            >
                                                Personal Address*
                                            </label>
                                            <PlacesAutocomplete
                                                value={personalAddress?.length > 0 ? personalAddress[index] : ''}
                                                onChange={(text) => {
                                                    handlePersonlAddresschange(text, index);
                                                }}
                                                onSelect={(address, placeID) => { handlepersonalAddressSelect(address, placeID, index) }}
                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        <input
                                                            className="custom-date-picker"
                                                            {...getInputProps({
                                                                placeholder: "Search owner address",
                                                            })}

                                                        />
                                                        <div>
                                                            {loading ? <div>Loading...</div> : null}
                                                            {suggestions.map((suggestion) => {
                                                                const style = {
                                                                    backgroundColor: suggestion.active
                                                                        ? "#41b6e6"
                                                                        : "#fff",
                                                                    border: suggestion.active
                                                                        ? "1px solid #41b6e6"
                                                                        : "1px solid #ccc",
                                                                    width: "auto",
                                                                    marginLeft: "10px", // Add margin right
                                                                    marginTop: "5px",
                                                                };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            style,
                                                                        })}
                                                                    >
                                                                        {suggestion.description}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                            {owner?.error?.address && (
                                                <span className="text-danger fs-12">
                                                    {owner?.error?.address}
                                                </span>
                                            )}
                                        </div>
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="City*" inputtype="text" placeholder="City"
                                            name='city'
                                            value={owner?.city} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.city}
                                        />
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="State*" inputtype="text" placeholder="State"
                                            name='state'
                                            value={owner?.state} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.state}
                                        />
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Country*" inputtype="text" placeholder="Country"
                                            name='country'
                                            value={owner?.country} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.country}
                                            maxLength={2}
                                        />
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Zip Code*" inputtype="text" placeholder="Zip Code"
                                            name='zip'
                                            value={owner?.zip} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.zip}
                                        />
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Social Security Number (SSN)*" inputtype="text" placeholder="Social Security Number (SSN)"
                                            name='ssn'
                                            value={formatSSN(owner?.ssn)} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.ssn}
                                        />

                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Ownership Percent*" inputtype="text" placeholder="Ownership Percent"
                                            name='ownership_percent'
                                            value={owner?.ownership_percent} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.ownership_percent}
                                        />
                                        <InputBox
                                            divclass="col-md-4" labelclass="labelStyle"
                                            label="Drivers License Number*" inputtype="text" placeholder="Drivers License Number"
                                            name='license_number'
                                            value={owner?.license_number} onChange={(e) => { handleownerChange(index, e) }}
                                            error={owner?.error?.license_number}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* *********Business Details**********   */}
                    <div className='d-flex my-3 justify-content-between align-items-center' >
                        <h4 >Business Details</h4>
                    </div>
                    <div className='row my-0 pb-4' style={{ borderBottom: '1px solid #35294d40' }}>
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle mt-0"
                            label="Business name*" inputtype="text" placeholder="Business name"
                            name='business_name'
                            value={submerchantdata?.business_name} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.business_name}
                        />
                        <SelectBox divclass="col-md-4" labelclass="labelStyle mt-0" label="Company Structure" options={business_structure_option_Array} name="business_structure" value={submerchantdata?.business_structure} onChange={(e) => { handleRestChange(e) }} error={submerchantdata?.errors?.business_structure} />

                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle mt-0"
                            label=" EIN or TAX ID*" inputtype="text" placeholder=" EIN or TAX ID"
                            name='ein'
                            maxLength={9}
                            value={submerchantdata?.ein} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.ein}
                        />
                        <div className="col-md-8">
                            <label
                                className="labelStyle"
                                style={{ display: "block" }}
                            >
                                Business Address*
                            </label>
                            <PlacesAutocomplete
                                value={businessAddress}
                                onChange={(text) => {
                                    handleBusinessAddresschange(text);
                                }}
                                onSelect={(address, placeID) => { handleBusinessAddressSelect(address, placeID) }}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div>
                                        <input
                                            className="custom-date-picker"
                                            {...getInputProps({
                                                placeholder: "Search Business address",
                                            })}

                                        />
                                        <div>
                                            {loading ? <div>Loading...</div> : null}
                                            {suggestions.map((suggestion) => {
                                                const style = {
                                                    backgroundColor: suggestion.active
                                                        ? "#41b6e6"
                                                        : "#fff",
                                                    border: suggestion.active
                                                        ? "1px solid #41b6e6"
                                                        : "1px solid #ccc",
                                                    width: "auto",
                                                    marginLeft: "10px", // Add margin right
                                                    marginTop: "5px",
                                                };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            style,
                                                        })}
                                                    >
                                                        {suggestion.description}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {submerchantdata?.errors?.business_address && (
                                <span className="text-danger fs-12">
                                    {submerchantdata?.errors?.business_address}
                                </span>
                            )}
                        </div>
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label=" Business City*" inputtype="text" placeholder=" Business City"
                            name='business_city'
                            value={submerchantdata?.business_city} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.business_city}
                        />
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label="Business State*" inputtype="text" placeholder="Business State"
                            name='business_state'
                            value={submerchantdata?.business_state} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.business_state}
                        />
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label="Business Country*" inputtype="text" placeholder="Business Country"
                            name='business_country'
                            value={submerchantdata?.business_country} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.business_country}
                        />
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label="Business Zip code*" inputtype="text" placeholder="Business Zip code"
                            name='business_zip'
                            value={submerchantdata?.business_zip} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.business_zip}
                        />
                        <SelectBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label="Processing environment*" options={processing_environment_options_Array}
                            name="processing_environment"
                            value={submerchantdata?.processing_environment}
                            onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.processing_environment}
                        />
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label="Website*" inputtype="text" placeholder="Website"
                            name='website'
                            value={submerchantdata?.website} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.website}
                        />

                        <div className="col-md-4">
                            <label
                                className="labelStyle"
                                style={{ display: "block" }}
                            >
                                Established Date*
                            </label>
                            <DatePicker
                                name="dob"
                                selected={formattedDate(submerchantdata?.established_date)}
                                onChange={(e) => { handleEDChange(e) }}
                                className="custom-datepicker"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="YYYY-MM-DD"
                            />
                            <br></br>
                            {submerchantdata?.errors?.established_date && (
                                <span className="text-danger fs-12">
                                    {submerchantdata?.errors?.established_date}
                                </span>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label
                                className="labelStyle"
                                style={{ display: "block" }}
                            >
                                Industry*
                            </label>
                            <SearchSelect
                                isClearable={true}
                                placeholder="Select Industry"
                                isSearchable={true}
                                value={
                                    IndustryData.find(option => option.value === submerchantdata?.business_industry) || null
                                }
                                onChange={(data) => {
                                    handleRestChange({ target: { name: 'business_industry', value: data?.value } });
                                }}
                                options={IndustryData}
                            />
                            {/* <Select
                                isClearable={true}
                                placeholder="Select Industry"
                                isSearchable={true}
                                value={
                                    IndustryData.find(option => option.value === submerchantdata?.business_industry) || null
                                }
                                onChange={(data) => {
                                    handleRestChange({ target: { name: 'business_industry', value: data?.value } });
                                }}
                                options={IndustryData}
                            /> */}
                            {submerchantdata?.errors?.business_industry && (
                                <span className="text-danger fs-12">
                                    {submerchantdata?.errors?.business_industry}
                                </span>
                            )}
                        </div>
                        <InputBox
                            divclass="col-md-4" labelclass="labelStyle"
                            label="Products Sold*" inputtype="text" placeholder="Products Sold"
                            name='legal_productssold'
                            value={submerchantdata?.legal_productssold} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_productssold}
                        />
                    </div>
                    {/* *********Processing information**********   */}
                    <div className='d-flex my-3 justify-content-between align-items-center' >
                        <h4 >Processing information</h4>
                    </div>

                    <div className="row my-0 pb-4" style={{ borderBottom: '1px solid #35294d40' }}>
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle mt-0"
                            label="Average Ticket*" inputtype="number" placeholder="($)"
                            name='avgTicket'
                            value={submerchantdata?.avgTicket} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.avgTicket}
                            min={0}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle mt-0"
                            label="Annual Credit Card Sales*" inputtype="number" placeholder="($)"
                            name='annualCCSales'
                            value={submerchantdata?.annualCCSales} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.annualCCSales}
                            min={0}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="B2C Transactions*" inputtype="number" placeholder="a. Cumulative to 100%"
                            name='legal_b2c'
                            value={submerchantdata?.legal_b2c} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_b2c}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="B2B Transactions*" inputtype="number" placeholder="a. Cumulative to 100%"
                            name='legal_b2b'
                            min={0}
                            max={100}
                            value={submerchantdata?.legal_b2b} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_b2b}
                        />

                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="Card Present % *" inputtype="number" placeholder="b. Cumulative to 100%"
                            name='legal_cp'
                            value={submerchantdata?.legal_cp} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_cp}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="Card Not Present % *" inputtype="number" placeholder="b. Cumulative to 100%"
                            name='legal_keyed'
                            value={submerchantdata?.legal_keyed} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_keyed}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="Manual Entry via Phone/Email*" inputtype="number" placeholder="c. Cumulative to 100%"
                            name='legal_cnpphoneemail'
                            value={submerchantdata?.legal_cnpphoneemail} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_cnpphoneemail}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="Manual Entry via Internet*" inputtype="number" placeholder="c. Cumulative to 100%"
                            name='legal_cnpinternet'
                            value={submerchantdata?.legal_cnpinternet} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_cnpinternet}
                        />
                    </div>

                    {/* *********Post-Transaction Info**********   */}
                    <div className='d-flex my-3 justify-content-between align-items-center' >
                        <h4 >Post-Transaction Info</h4>
                    </div>
                    <div className="row my-0 pb-4" style={{ borderBottom: '1px solid #35294d40' }}>
                        <InputBox
                            divclass="col-md-3" labelclass="labelStyle mt-0"
                            label="Same-Day Delivery*" inputtype="number" placeholder="d. Cumulative to 100%"
                            name='legal_percentdeliveredsameday'
                            value={submerchantdata?.legal_percentdeliveredsameday} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_percentdeliveredsameday}
                        />
                        <InputBox
                            divclass="col-md-3" labelclass="labelStyle mt-0"
                            label={`Deferred Delivery > 7 Days*`} inputtype="number" placeholder="d. Cumulative to 100%"
                            name='percentdelievered7days'
                            value={submerchantdata?.percentdelievered7days} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.percentdelievered7days}
                        />
                        <InputBox
                            divclass="col-md-3" labelclass="labelStyle mt-0"
                            label={`Deferred Delivery > 14 Days*`} inputtype="number" placeholder="d. Cumulative to 100%"
                            name='percentdelivered14days'
                            value={submerchantdata?.percentdelivered14days} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.percentdelivered14days}
                        />
                        <InputBox
                            divclass="col-md-3" labelclass="labelStyle mt-0"
                            label={`Deferred Delivery > 30 Days*`} inputtype="number" placeholder="d. Cumulative to 100%"
                            name='legal_percentdeliveredover30days'
                            value={submerchantdata?.legal_percentdeliveredover30days} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.legal_percentdeliveredover30days}
                        />

                        {
                            submerchantdata?.errors?.posttrantion && (
                                <span className="text-danger fs-12">
                                    {submerchantdata?.errors?.posttrantion}
                                </span>
                            )
                        }

                    </div>

                    {/* *********Banking information**********   */}
                    <div className='d-flex my-3 justify-content-between align-items-center' >
                        <h4 >Banking information</h4>
                    </div>
                    <div className="row my-0 pb-4" style={{ borderBottom: '1px solid #35294d40' }}>
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle mt-0"
                            label="Routing number*" inputtype="text" placeholder="Routing number"
                            name='routing_number'
                            value={submerchantdata?.routing_number} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.routing_number}
                            minLength={9}
                            maxLength={9}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle mt-0"
                            label="Bank Name*" inputtype="text" placeholder="Bank Name"
                            name='bank_name'
                            value={submerchantdata?.bank_name} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.bank_name}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="Account Number*" inputtype="text" placeholder="Account Number"
                            name='account_number'
                            value={submerchantdata?.account_number} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.account_number}
                            maxLength={17}
                        />
                        <SelectBox
                            divclass="col-md-6" labelclass="labelStyle"
                            label="Account Type*" options={bank_account_type_Array}
                            name="account_method"
                            value={submerchantdata?.account_method}
                            onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.account_method}
                        />
                    </div>

                    {/* *********password**********   */}
                    <div className='d-flex my-3 justify-content-between align-items-center' >
                        <h4 >Password</h4>
                    </div>
                    <div className="row my-0 pb-4">
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle mt-0"
                            label="Password*" inputtype="text" placeholder="Password"
                            name='password'
                            value={submerchantdata?.password} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.password}
                        />
                        <InputBox
                            divclass="col-md-6" labelclass="labelStyle mt-0"
                            label="Confirm Password*" inputtype="password" placeholder="Confirm Password"
                            name='cnf_password'
                            value={submerchantdata?.cnf_password} onChange={(e) => { handleRestChange(e) }}
                            error={submerchantdata?.errors?.cnf_password}
                        />
                        <input
                            name='bot_key'
                            value={bot_key}
                            onChange={(e) => { setBot_key(e.target.value) }}
                            style={{ display: 'none' }}
                        />
                    </div>

                    <div className='my-2 w-100 text-center'>
                        <button type='submit' className='idle-green-btn' onClick={(e) => { handleSubmit(e) }} >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddSubMerchant
