
const superAdminVersion = "/api/v1/super-admin";
const merchantVersion = "/api/v1/merchant";
const adminVersion = "/api/v1/admin";
const version = "/api/v1";
const END_POINTS = {
    // Admin
    GET_ALL_MERCHANT: `${adminVersion}/merchant/get-all-merchant`,
    DASHBOARD_ANALYTICS_ADMIN: `${adminVersion}/dashboard/analytics`,
    GET_PROFILE_ADMIN: `${adminVersion}/auth/get-profile`,
    GET_MENU_ADMIN: `${adminVersion}/auth/get-all-menu`,
    CREATE_PUBLIC_KEY: `${version}/order/generate-hash`,
    UPDATE_WEBHOOK_ADMIN: `${adminVersion}/auth/update-webhook`,

    // Super Admin
    // ============================SUPER ADMIN========================================
    DASHBOARD_ANALYTICS: `${superAdminVersion}/dashboard/analytics`,
    GET_PROFILE: `${superAdminVersion}/auth/get-profile`,
    SIDEBAR: `${superAdminVersion}/auth/get-all-menu`,
    MENU_LIST: `${superAdminVersion}/menu/all-menu-list`,
    PARENT_MENU: `${superAdminVersion}/menu/get-parent-menu`,
    UPDATE_MENU: `${superAdminVersion}/menu/update-menu`,
    ADD_MENU: `${superAdminVersion}/menu/add-menu`,
    ROLE_LIST: `${superAdminVersion}/menu/role-list`,
    ROLE_LIST_ALL: `${superAdminVersion}/role/get-all`,
    GET_ROLE_BY_ID: `${superAdminVersion}/role/get-by-id`,
    CREATE_ROLE: `${superAdminVersion}/role/create`,
    UPDATE_ROLE: `${superAdminVersion}/role/update`,
    CLIENT_LIST: `${superAdminVersion}/client`,
    ADD_MENU_PERMISSION: `${superAdminVersion}/menu-permission/add`,
    CUSTOMER_LIST: `${superAdminVersion}/customer/customer-list`,
    ADD_MENU: `${superAdminVersion}/menu/add-menu`,
    UPDATE_MENU: `${superAdminVersion}/update-menu`,
    ADD_CLIENT: `${superAdminVersion}/client/onboarding`,
    GET_MERCHANT_LIST_BY_CLIENT_ID: `${superAdminVersion}/merchant/merchant-list`,
    UPDATE_CLIENT: `${superAdminVersion}/client`,
    DELETE_CLIENT: `${superAdminVersion}/client/deletion`,
    GET_CLIENT_BY_ID: `${superAdminVersion}/client/get-client-profile`,
    APPROVE_MERCHANT_BY_ID: `${superAdminVersion}/merchant/approve-merchant`,
    ADD_SKYFI_CREDENTIALS_TO_CLIENT: `${superAdminVersion}/client/add-skyfi-credentials`,
    // Super admin transaction
    TRANSACTION_LIST: `${superAdminVersion}/transaction/list`,
    
    // ================================MERCHANT=========================================
     //merchant 
    GET_SUB_MERCHANT_LIST: `${merchantVersion}/account/sub-merchant/get-all`,
    GET_VIEW_SUB_MERCHANT_DETAILS: `${merchantVersion}/account/sub-merchant/view-sub-merchant-detail/`,
    CREATE_SUB_MERCHANT: `${merchantVersion}/account/sub-merchant/create`,
    UPDATE_SUB_MERCHANT_BUSINESS: `${merchantVersion}/account/sub-merchant/business-detail-update/`,
    GET_SUB_MERCHANT_OWNER: `${merchantVersion}/account/sub-merchant/owner-list/`,
    UPDATE_SUB_MERCHANT_OWNER: `${merchantVersion}/account/sub-merchant/update-owner`,
    GET_MERCHANT_STAFF: `${merchantVersion}/account/employee/get-all`,
    GET_MERCHANT_STAFF_ROLES: `${merchantVersion}/account/role/get-all`,
    DELETE_MERCHANT_STAFF_BY_ID: `${merchantVersion}/account/employee/delete`,
    CREATE_MERCHANT_STAFF: `${merchantVersion}/account/employee/create`,
    GET_MERCHANT_STAFF_ID: `${merchantVersion}/account/employee/get-by-id`,
    UPDATE_MERCHANT_STAFF: `${merchantVersion}/account/employee/update`,
    MERCHNAT_DASHBOARD_ANALYTICS: `${merchantVersion}/account/dashboard/analytics`,
    //Widget
    //==============================WIDGET================================================
    CREATE_WIDGET_ORDER: '/create-widget-order',
}

export default END_POINTS;