import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import baseUrl from "../../../baseUrl";
import { useToken } from "../../../hooks/useToken";
import DatePicker from "react-datepicker";
import PaginationPg from "../../../DefaultComponent/PaginationPg";
import { Table } from "react-bootstrap";
import BreadCrumbs from "../../../components/Breadcrumbs";
import VisaLogo from "../../../DefaultComponent/VisaLogo";
import MastercardLogo from "../../../DefaultComponent/MastercardLogo";
import { useSelector } from "react-redux";

const CustomerDetails = () => {
  const token = useToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const { deviceId } = useSelector(state => state.deviceId);

  // const location = useLocation();
  // const { data } = location.state;
  // const customerId = data.CustomerId;
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [transactionData, setTransactionData] = useState([]);
  const [page, setPage] = useState(1);
  const [analytics, setAnalytics] = useState({});
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);

  const [customerDetail, setCustomerDetail] = useState({});
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${month}-${day}-${year}`.replace(" ", ", ");
  };

  const handleViewDetails = (item) => {
    navigate(`/transactions/${item}`);
  };

  // const toggleDropdown = (pos_guid) => {
  //   setShowDropdown((prevShowDropdown) => ({
  //     ...prevShowDropdown,
  //     [pos_guid]: !prevShowDropdown[pos_guid],
  //   }));
  // };
  // const fetchData = async (
  //   newPage,
  //   newPerPage,
  //   startDate,
  //   endDate,
  //   searchKeyword,
  //   paymntStatus
  // ) => {
  //   setPage(newPage);
  //   setPerPage(newPerPage);
  //   setLoading(true);
  //   try {
  //     const requestBody = {
  //       page: newPage,
  //       perPage: newPerPage,
  //     };

  //     if (startDate && endDate) {
  //       requestBody.startDate = formatDate(startDate);
  //       requestBody.endDate = formatDate(endDate);
  //     }

  //     if (searchKeyword) {
  //       requestBody.searchKeyword = searchKeyword;
  //     }

  //     if (paymntStatus) {
  //       requestBody.paymentStatus = paymntStatus;
  //     }

  //     const response = await axios.post(
  //       `${baseUrl}/api/v1/admin/transaction`,
  //       requestBody,
  //       {
  //         headers: {
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     console.log("Fetched data:", response?.data?.data?.analytics);
  //     const formattedTransactions = response.data?.data?.allTransaction.map(
  //       (item) => ({
  //         ...item,
  //         Amount: `$${parseFloat(item?.Amount).toLocaleString("en-US", {
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2,
  //         })}`,
  //         Net: `$${parseFloat(item?.Net).toLocaleString("en-US", {
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2,
  //         })}`,
  //         Date: formatDate(item.Date),
  //       })
  //     );
  //     setTotalPage(response.data?.data?.totalPage);
  //     setLoading(false);
  //     setTransactionData(formattedTransactions || []);
  //     setAnalytics(response.data.data.analytics);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error fetching transaction data:", error);
  //   }
  // };
  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "orange";
      case 2:
        return "green";
      case 5:
        return "blue";
      default:
        return "default";
    }
  };
  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Success";
      case 5:
        return "Refund";
      default:
        return "";
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/admin/admin-customer/get-customer-detail/${id}`,
        {
          headers: {
            Authorization: token,
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
      setCustomerDetail(response.data);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  const fetchTransactionData = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/admin/transaction`,
        {
          customerId: id,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
    
      setTransactionData(response?.data?.data?.allTransaction);
    
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchTransactionData();
  }, [id]);

  const handlePageChange = (pageNumber) => {
    setTransactionData([]);
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    fetchData(1, perPage, startDate, endDate);
  };
  // console.log("customerDetail", customerDetail);
  // console.log("transactionData", transactionData);
  const breadcrumbItems = [
    { label: "Dashboard", href: "/", active: false },
    { label: "Customers", href: "/customers", active: false },
    { label: "Customer-details", href: "/customer-details", active: true },
  ];

  return (
    <>
      <div className="container-xl">
        <BreadCrumbs items={breadcrumbItems} />

        <div className="row">
          <div className="col-12 col-md-4">
            <h1 className="app-page-title">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-credit-card"
                viewBox="0 0 16 16"
              >
                <path d="M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm8 0a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V2z" />
              </svg>{" "}
              Customer
            </h1>
          </div>
        </div>
        <div className="app-card app-card-orders-table shadow-sm mb-5">
          <div className="app-card-body">
            <div className="app-card app-card-orders-table shadow-sm mb-5">
              <div className="app-card-body">
                <div className="payment-heading">
                  <h5>Customer Details</h5>
                </div>
                <hr />
              </div>
              <div className="payment-details overflow-x-auto">
                <table
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 10px",
                  }}
                >
                  <tbody>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        Name :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.first_name}{" "}
                        {customerDetail?.data?.last_name}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        Phone No :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.customer_phone}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        Email :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.customer_email}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        Address :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.customer_address}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        City :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.city}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        Country :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.country}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f9f9f9", padding: "15px" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          paddingRight: "20px",
                        }}
                      >
                        Business Name :
                      </th>
                      <td style={{ fontSize: "16px", paddingLeft: "20px" }}>
                        {customerDetail?.data?.merchantDetails?.bussiness_name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="app-card app-card-orders-table ">
              <div className="row d-flex justify-content-end">
                <div className="col-12 col-sm-5 col-md-4 col-lg-3 ">
                  <DatePicker
                    className="date-picker cursor-pointer"
                    selected={startDate}
                    onChange={handleStartDate}
                    placeholderText="Select start date"
                  />
                </div>
                <div className="col-12 col-sm-5 col-md-4 col-lg-3 mt-2 mt-sm-0">
                  <DatePicker
                    className="date-picker cursor-pointer"
                    selected={endDate}
                    onChange={handleEndDate}
                    placeholderText="Select end date"
                  />
                </div>
              </div>
              <div className="app-card-body overflow-auto">
                <div className="sm-table-responsive md-none">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Created Date</th>
                        <th>Merchant Name</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Last 4</th>
                        <th>Brand</th>
                        <th>Status</th>
                        <th>Net</th>
                        <th>Fee Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData && transactionData.length > 0 ? (
                        transactionData.map((transaction) => (
                          <tr key={transaction.id}>
                            <td>{formatDate(transaction.Date)}</td>
                            <td>
                              {transaction.merchantDetails?.businessName
                                ? transaction.merchantDetails?.businessName
                                : "Merchant Name"}
                            </td>
                            <td style={{ color: "#383838", fontWeight: 700 }}>
                              {typeof transaction.Amount !== "string"
                                ? `$${parseFloat(
                                    transaction.Amount
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : `${transaction.Amount.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}`}
                            </td>
                            <td>{transaction.Type}</td>
                            <td>
                              <span>****{transaction.lastFourDigits}</span>
                            </td>
                            <td>
                              {transaction.cardType === "VISA" ? (
                                <VisaLogo />
                              ) : transaction.cardType === "MASTERCARD" ? (
                                <MastercardLogo />
                              ) : (
                                <div style={{ marginLeft: "12px" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 28 28"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M5.754 5a3.75 3.75 0 0 0-3.75 3.75v.75H26v-.75A3.75 3.75 0 0 0 22.25 5H5.755Zm-3.75 14.25V11H26v8.25A3.75 3.75 0 0 1 22.25 23H5.755a3.75 3.75 0 0 1-3.75-3.75ZM18.25 16.5a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5h-3.5Z"
                                    />
                                  </svg>
                                </div>
                              )}
                            </td>

                            <td>
                              <span
                                style={{
                                  backgroundColor: getStatusColor(
                                    transaction.is_status
                                  ),
                                  color: "white",
                                }}
                                className={`status-box ${getStatusLabel(
                                  transaction.is_status
                                )}`}
                              >
                                {getStatusLabel(transaction.is_status)}
                              </span>
                            </td>
                            <td>
                              {typeof transaction.Net !== "string"
                                ? `$${parseFloat(
                                    transaction.Net
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : `${transaction.Net.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}
                            </td>
                            <td>
                              {typeof transaction.fee_amount !== "string"
                                ? `$${parseFloat(
                                    transaction.fee_amount
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : `${transaction.fee_amount.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}`}
                            </td>
                            <td>
                              <div className="action-column">
                                <i
                                  className="fa-solid fa-eye cursor-pointer"
                                  onClick={() => handleViewDetails(transaction.id)}
                                ></i>
                                {/* <span
                                className="three-dots"
                                onClick={(event) =>
                                  toggleDropdown(transaction.pos_guid)
                                }
                              >
                                ...
                              </span>
                              {showDropdown[transaction.pos_guid] && (
                                <div className="action-dropdown">
                                  <div>
                                    <button
                                      style={{
                                        border: "none",
                                        color: "green",
                                        background: "none",
                                      }}
                                      onClick={() =>
                                        handleViewDetails(transaction.pos_guid)
                                      }
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              )} */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10" style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="pb-2 d-flex justify-content-center">
                <PaginationPg
                  totalPages={totalPage}
                  onPageChange={handlePageChange}
                  current={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
