const SardineService = {
    initialize: (sessionKey) => {
      const isSandbox = process.env.REACT_APP_SARDINE_ENV == "false" ? false: true;
      const sardineHost = isSandbox ? "api.sandbox.sardine.ai" : "api.sardine.ai";
      const loader = document.createElement("script");
      loader.type = "text/javascript";
      loader.async = true;
      loader.src = `https://${sardineHost}/assets/loader.min.js`;
      loader.onload = () => {
        window._Sardine.createContext({
          clientId: process.env.REACT_APP_CLIENT_KEY,
          sessionKey: sessionKey,
          flow: "login",
          environment: isSandbox ? "sandbox" : "production",
          parentElement: document.body,
          onDeviceResponse: (data) => {
           
          },
        });
      };
      document.body.appendChild(loader);
    },
    updateConfig: (newConfig) => {
      if (window._Sardine && window._Sardine.updateConfig) {
        window._Sardine.updateConfig(newConfig);
      }
    },
  };
  export default SardineService;