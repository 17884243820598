import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGetCustomerListQuery } from '../../../../redux/superadminApiSlice';
import Loader from '../../../DefaultComponents/Loader';
import { debounce } from 'lodash';
import { Table } from "react-bootstrap";

const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    
    // API call with RTK Query
    const { data: customerList, isLoading, isFetching } = useGetCustomerListQuery({
        page,
        search: searchValue,
    }, {
        skip: !hasMore && page !== 1
    });

    // Last element ref for intersection observer
    const lastCustomerRef = useCallback((node) => {
        if (isLoading || isFetching || !hasMore) return; // Don't observe if loading or no more data
        if (observer.current) observer.current.disconnect();
        
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading && !isFetching) {
                setPage(prevPage => prevPage + 1);
            }
        }, {
            root: null,
            rootMargin: '20px', // Load a bit before reaching the end
            threshold: 0.1 // Trigger when even 10% of the element is visible
        });

        if (node) observer.current.observe(node);
    }, [isLoading, isFetching, hasMore]);

    // Cleanup observer on component unmount
    useEffect(() => {
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);

    // Update customers when new data is fetched
    useEffect(() => {
        if (customerList?.data) {
            if (page === 1) {
                setCustomers(customerList.data);
            } else {
                setCustomers(prev => {
                    // More strict duplicate checking
                    const existingIds = new Set(prev.map(item => item.customer_id));
                    const newCustomers = customerList.data.filter(
                        newItem => !existingIds.has(newItem.customer_id)
                    );
                    return [...prev, ...newCustomers];
                });
            }
            // Only set hasMore if we received less data than expected
            setHasMore(customerList?.totalPage > page);
        }
    }, [customerList?.data, page]);

    // Create a memoized debounced search function
    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchValue(value);
            setPage(1);
            setCustomers([]);
            setHasMore(true);
        }, 500),
        []
    );

    // Handle input change immediately
    const handleSearchInput = (e) => {
        const value = e.target.value;
        setSearchInput(value); // Update input immediately
        debouncedSearch(value); // Debounce the actual search
    };

    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-12 col-md-4">
                    <p className="heading">Clients Customers</p>
                </div>
                <div className="col-12 col-md-8">
                    <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
                        <div className="search-box">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7372_11113)">
                                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7372_11113">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchInput}
                                value={searchInput}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-card-body">
                <div className="date-picker-container overflow-x-auto scroll-tbl">
                    <Table className='table'>
                        <thead>
                            <tr>
                                <th>Client Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Business Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers?.map((item, index) => (
                                <tr
                                    key={item.customer_id}
                                    ref={index === customers.length - 1 ? lastCustomerRef : null}
                                    className="table-hover cursor-pointer"
                                >
                                    <td>{`${item?.first_name} ${item?.last_name}`}</td>
                                    <td>{item?.Email}</td>
                                    <td>{item?.Phone}</td>
                                    <td>{item?.Merchant_detail?.bussiness_name}</td>
                                </tr>
                            ))}
                            {!isLoading && customers.length === 0 && (
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        No customers available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

            <Loader loading={isLoading || isFetching} />
        </div>
    );
};

export default Customers;
