import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Layout/Login.jsx";
import Register from "./Layout/Register.jsx";
import "./assets/css/portal.css";
import AdminLayout from "./components/AdminLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Protected from "./components/Protected";
import ForgotManagerPassword from "./Layout/ForgotManagerPassword.jsx";
import ForgotPasswordEmailSend from "./Layout/ForgotPasswordEmailSend.jsx";
import OTPverification from "./Layout/OTPverification.jsx";
import EmailVerification from "./Layout/EmailVerification.jsx";
import DocsLayout from "./documentation/DocsLayout.jsx";
import Toaster from "./Layout/Toaster.jsx";
import LoginScreen from "./SuperAdmin/Layout/LoginScreen.js"
import OTPVerification from "./SuperAdmin/Layout/OTPVerification.jsx"
import SuperAdminLayout from "./SuperAdmin/components/SuperAdminLayout.js";
import SuperAdminProtected from "./SuperAdmin/components/SuperAdminProtected.jsx";
import SuperForgotManagerPassword from "./SuperAdmin/Layout/SuperForgotManagerPassword.jsx";
import SuperForgotPasswordEmailSend from "./SuperAdmin/Layout/SuperForgotPasswordEmailSend.jsx";
import { CheckDomain } from "./hooks/CheckDomain.js";
import fractallogo from "./assets/images/favicon/logo512.png"
import Commandalkonlogo from "./assets/images/favicon/mlogo512.png"
import { useEffect } from "react";
import useDeviceFingerprint from "./hooks/useDeviceFingerprint.jsx";
import { useDispatch } from "react-redux";
import { deviceIdAction } from "./redux/deviceIdSlice.js";


function App() {
const dispatch=useDispatch()
  let isConditionMet = CheckDomain()
  const { fingerprint }=useDeviceFingerprint()
 
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.rel = 'icon';

    if (isConditionMet) {
      link.href = Commandalkonlogo;
      document.title = 'Command Alkon';
    } else {
      link.href = fractallogo;
      document.title = 'Fractal Portal';
    }

    document.head.appendChild(link);

    // Cleanup function to reset the favicon if needed
    return () => {
      document.head.removeChild(link);
    };
  }, [isConditionMet]);

  useEffect(()=>{
if(fingerprint){
  dispatch(deviceIdAction.getDeviceId(fingerprint))
}
  },[fingerprint])


  return (
    <>
      <div className="purp" style={{ height: "100vh" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Protected Component={AdminLayout} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPasswordEmailSend />} />
            <Route path="/reset-password/:id" element={<ForgotManagerPassword />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/otp" element={<OTPverification />} />

            <Route path="/docs/*" element={<Protected Component={DocsLayout} />} />
            
            <Route path="/admin/*" element={<SuperAdminProtected Component={SuperAdminLayout} />} />
            <Route path="/super-admin-login" element={<LoginScreen />} />
            <Route path="/super-admin-otp" element={<OTPVerification />} />
            <Route path="/super-forgot-password" element={<SuperForgotPasswordEmailSend />} />
            <Route path="/super-reset-password/:id" element={<SuperForgotManagerPassword />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} autoClose={3000} />
        <Toaster />
      </div>
    </>
  );
}

export default App;
