

export const CreateRecordTransaction = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={20}
            height={20}
            x={0}
            y={0}
            viewBox="0 0 24 24"
            style={{
                enableBackground: "new 0 0 512 512",
            }}
            xmlSpace="preserve"
        >
            <g>
                <path
                    d="M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1zm5 12h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2z"
                    data-name="Layer 2"
                    fill="#35254d"
                    opacity={1}
                    data-original="#35254D"
                />
            </g>
        </svg>
    )
}


export const RefundIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={20}
            height={20}
            x={0}
            y={0}
            viewBox="0 0 512 512"
            style={{
                enableBackground: "new 0 0 512 512",
            }}
            xmlSpace="preserve"
        >
            <g>
                <path
                    d="M426.416 110.28c-36.933-38.204-84.733-62.497-136.609-69.887l14.786-14.786c5.858-5.858 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.213 0L245.393 42.38c-5.858 5.857-5.858 15.355 0 21.213l37.986 37.986c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.465 10.606-4.394c5.858-5.857 5.858-15.355 0-21.213l-8.495-8.495c41.213 8.065 79.012 28.499 108.749 59.259 37.505 38.796 58.16 89.888 58.16 143.863C463.007 389.137 370.143 482 256 482S48.993 389.137 48.993 274.994c0-43.555 13.367-85.219 38.657-120.487 24.765-34.537 58.905-60.308 98.732-74.526 7.802-2.786 11.868-11.368 9.083-19.171-2.785-7.802-11.369-11.868-19.171-9.083-45.603 16.281-84.686 45.777-113.025 85.298-28.966 40.396-44.276 88.105-44.276 137.97 0 63.307 24.653 122.824 69.418 167.589C133.176 487.346 192.693 512 256 512s122.824-24.653 167.589-69.417c44.765-44.765 69.418-104.282 69.418-167.589 0-61.798-23.65-120.295-66.591-164.714z"
                    fill="#35254D"
                    opacity={1}
                    data-original="#35254D"
                />
                <path
                    d="M101.884 274.994c0 84.979 69.136 154.115 154.116 154.115 84.979 0 154.115-69.136 154.115-154.115S340.979 120.877 256 120.877c-84.981 0-154.116 69.137-154.116 154.117zm163.324 13.351c-21.592-7.633-34.102-13.649-43.178-20.768-11.17-8.761-16.283-23.892-13.345-39.487 3.188-16.918 14.986-30.386 30.791-35.147.219-.066.434-.119.652-.183v-6.04c0-8.284 6.716-15 15-15s15 6.716 15 15v5.085c10.473 2.453 17.783 7.24 20.738 9.449 6.635 4.961 7.993 14.36 3.033 20.996-4.961 6.636-14.358 7.992-20.996 3.033-3.176-2.374-11.942-7.482-24.774-3.616-7.581 2.284-9.542 9.745-9.963 11.978-.831 4.41.124 8.561 2.377 10.327 7.847 6.153 21.925 11.586 34.662 16.088 23.3 8.236 36.256 29.821 32.24 53.71-1.972 11.729-7.872 22.612-16.616 30.646-6.013 5.524-13.02 9.452-20.701 11.668v7.182c0 8.284-6.716 15-15 15s-15-6.716-15-15v-5.707c-9.979-1.173-18.306-4.246-29.505-11.572-6.933-4.535-8.876-13.832-4.341-20.765 4.535-6.934 13.833-8.876 20.765-4.341 10.383 6.793 14.309 7.417 27.981 7.322 13.461-.09 21.271-10.123 22.832-19.407.764-4.534 1.057-15.605-12.652-20.451z"
                    fill="#35254D"
                    opacity={1}
                    data-original="#35254D"
                />
            </g>
        </svg>
    )
}
export const VoidIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={20}
            height={20}
            x={0}
            y={0}
            viewBox="0 0 24 24"
            style={{
                enableBackground: "new 0 0 20 20",
            }}
            xmlSpace="preserve"
        >
            <g>
                <path
                    d="M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1zm4.242 13.829a1 1 0 1 1-1.414 1.414L12 13.414l-2.828 2.829a1 1 0 0 1-1.414-1.414L10.586 12 7.758 9.171a1 1 0 1 1 1.414-1.414L12 10.586l2.828-2.829a1 1 0 1 1 1.414 1.414L13.414 12z"
                    data-name="Layer 2"
                    fill="#35254D"
                    opacity={1}
                    data-original="#35254D"
                />
            </g>
        </svg>
    )
}
