import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import baseUrl from '../baseUrl';
import axios from 'axios';
import Loader from '../DefaultComponent/Loader';
import { toast } from 'react-toastify';
import { useToken } from '../hooks/useToken';
import { useDispatch, useSelector } from 'react-redux'

const ExportCSV = ({ searchKey, dataLength,id }) => {
    
    const token = useToken();
    // console.log("sdfsdfsdf==============idddddddddd",id);
    
    const { deviceId } = useSelector(state => state.deviceId);
    const { startDate,endDate}=useSelector(state=>state.dates);
    function convertMili(da) {
        const date = new Date(da);
        return date.getTime();
    }
//     const initialStartDate = new Date();
//     const startOfDay = new Date(initialStartDate.getFullYear(), initialStartDate.getMonth(), initialStartDate.getDate());
//     const [startDate, setStartDate] = useState(startOfDay);
//     const [endDate, setEndDate] = useState(null);
    const [loading,setLoading]=useState(false);
    const handleExportCsv = async () => {
        if (!startDate || !endDate ) {
            toast.error("Start and End date required");
            return;
        }
        if(dataLength ==0){
            return
        }
        try {
           
            setLoading(true);
            console.log("startDate", startDate, "endDate", endDate);
            
            let data = {
                "paymentStatus": 2,
                "isAdminExport": true,
                startDate:  convertMili(startDate)       ,
                endDate:convertMili(endDate),
                search:searchKey,
                merchantId:id
            }
            
            const resp = await axios.post(`${baseUrl}/api/v1/admin/transaction`, data, {
                headers: {
                    Authorization: token,
                    'X-Device-Fingerprint': deviceId,
                },
            }
            );
            setLoading(false);
            if (resp?.status == 200) {
                const link = document.createElement('a');
                link.href = `${resp?.data?.data}`;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }
// useEffect(()=>{
//     if( endDate){
// // setEndDate(null);
//         handleExportCsv();
       
//     }

// },[endDate])
//     const isSelectable = (date) => {
//         if (!startDate) {
//             return true;
//         }
//         const diffFromStart = (date - startDate) / (1000 * 60 * 60 * 24);
//         return diffFromStart >= -maxRangeDays && diffFromStart <= maxRangeDays;
//     };
//     const maxRangeDays = 30;
//     const handleDateChange = ([start, end]) => {
//         setStartDate(start);
//         setEndDate(end);
//     }
    return (
        <>
          
            {/* <ReactDatePicker
                className="cursor-pointer"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={new Date()}
                filterDate={isSelectable}
                withPortal
                customInput={
                    <div className="cursor-pointer">
                     
                        <button className="btn app-btn purp border border-1 mx-1">Export CSV</button>











                        
                    </div>
                }
            />
            // */}

            <button className="btn app-btn purp border border-1 mx-1 export-csv-btn" onClick={handleExportCsv}>Export CSV</button>
            <Loader loading={loading} /> 

        </>
    )
}

export default ExportCSV