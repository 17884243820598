import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../pages/Merchant/Tabs/MerchantOnboard.css";
import axios from "axios";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import baseUrl from "../../baseUrl";
// import PaginationPg from "../../DefaultComponent/PaginationPg";
import Loader from "../../DefaultComponent/Loader";
import { useToken } from "../../hooks/useToken";
// import DownloadMerchantCSV from "../Form/DownloadMerchantCSV";
import debounce from "lodash/debounce";
import View from "../../assets/images/View.svg";
import Edit from "../../assets/images/Edit.svg";
import Transaction from "../../assets/images/Transaction.svg";
import Upload from "../../assets/images/Download.svg";
import { isEmpty } from "lodash";
import { useGetSidebarQuery } from "../../redux/apiSlice";
import AddSubMerchant from "./AddSubMerchant";
import { GetSubmerchantList } from "../../api/SubmerchantAPI";
import UploadDocument from "./UploadDocument";



const SubmerchantList = () => {
    const { data: userData, data: menu, isLoading, isError } = useGetSidebarQuery();
    const { role_id, staff_roll_type } = userData?.data;
    const token = useToken();
    const navigate = useNavigate();
    const [sub_merchantList, setSubMerchantList] = useState([]);
    const [filterDays, setFilterDays] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        searchValue: "",
        totalPage: null,
        loading: false,
        upload: false,
        row: {},
    });
    const loader = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const [show, setShow] = useState(true);
    const [noDataMessage, setNoDataMessage] = useState(false);
    const [fetMoreData, setFetchMoreData] = useState(0);

    const handleMerchantDetail = (item) => {
        navigate(`/sub-merchant/view/${item?.guid}`);
    };

    const handleEdit = (row) => {
        navigate(`/sub-merchant/edit/${row.guid}`);
    };

    const handleTransaction = (item) => {
        navigate(`/sub-merchant/transactions/${item?.guid}`);
    };

    const handleUpload = (row) => {
        handleState("upload", true);
        handleState("row", row);
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        return `${month}.${day}.${year}`;
    };

    const fetchMerchants = async (newPage, newPerPage, searchValue) => {
        setShow(true)
        setState((prev) => ({ ...prev, loading: true }));
        // function convertMili(da) {
        //   const date = new Date(da);
        //   return date.getTime();
        // }
        try {
            const params = {
                page: newPage,
            };

            if (newPerPage) {
                params.perPage = newPerPage;
            }

            if (searchValue) {
                params.search = searchValue;
            }

            const data = await GetSubmerchantList(token, params)
            console.log("data 123", data);

            if (searchValue?.trim() || newPage == 1) {
                setSubMerchantList([]);
            }
            setState((prev) => ({ ...prev, loading: false }));
            const result = data?.data?.data;


            if (result?.length === 0 && data?.data?.totalCount) {
                setHasMore(false);
                setNoDataMessage(true);
                setFetchMoreData(0);
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
                setSubMerchantList([]);
                return false;
            }

            if (data?.data?.totalPage == newPage) {
                setHasMore(false)
                setFetchMoreData(0);
            }
            else {
                setHasMore(true);
                setFetchMoreData(fetMoreData + 1);

            }
            if (data?.result) {
                setSubMerchantList((prev) => [...prev, ...result]);
                setNoDataMessage(false);
                setState((prev) => ({
                    ...prev,
                    // page: response?.data?.page + 1,
                    page: state.page + 1,
                }));
            }
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            if (error?.response?.data?.message.includes("Unauthorized")) {
                navigate("/login");
                localStorage.removeItem("token");
            }
            toast.error(error?.response?.data?.message);
            console.error(error);
        }
    };

    useEffect(() => {
        if (isEmpty(state.searchValue) && hasMore) {
            const options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0,
            };

            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    fetchMerchants(state.page, state.perPage, state.searchValue);
                }
            }, options);

            if (loader.current) {
                observer.observe(loader.current);
            }

            return () => {
                if (loader.current) {
                    observer.unobserve(loader.current);
                }
            };
        }
    }, [state.page, fetMoreData]);

    useMemo(() => (
        fetchMerchants(state.page, state.perPage, state.searchValue)
    ), []);

    const handleSearchChange = (e) => {
        const searchData = e.target.value;
        setState((prev) => ({ ...prev, searchValue: searchData, page: 1, totalPage: null }));
        handleSearch(e.target.value);
    };

    const handlePerPageChange = (e) => {
        setSubMerchantList([]);
        const PageValue = e.target.value;
        setState((prev) => ({ ...prev, perPage: PageValue, totalPage: null }));
        fetchMerchants(state.page, PageValue, state.searchValue);
    };

    const handleState = (item, key) => {
        setState((prev) => ({
            ...prev,
            [item]: key,
        }));
    };

    // search debouncing
    const handleSearch = useCallback(
        debounce((searchQuery) => {
            fetchMerchants(state.page, state.perPage, searchQuery);
            setSubMerchantList([]);
        }, 700),
        []
    );

    // export csv function
    function convertMili(da) {
        const date = new Date(da);
        return date.getTime();
    }

    // const exportCsv = async () => {
    //     if (!sub_merchantList && sub_merchantList?.length < 1) { return }
    //     try {
    //         setState((pre) => ({ ...pre, loading: true }));
    //         // setLoading(true);

    //         const resp = await axios.get(`${baseUrl}/api/v1/admin/merchant/get-all-merchant?isMerchantExport=true&search=${state.searchValue}`, {
    //             headers: {
    //                 Authorization: token,
    //             },
    //         }
    //         );
    //         setState((pre) => ({ ...pre, loading: false }));
    //         // setLoading(false);
    //         if (resp?.status == 200) {
    //             const link = document.createElement('a');
    //             link.href = `${resp?.data?.data}`;
    //             link.target = '_blank';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //         // console.log("milil dates=======", startdate, enddate);

    //     } catch (error) {
    //         setState((pre) => ({ ...pre, loading: false }));
    //         toast.error(error?.response?.data?.message);
    //         console.log(error);
    //     }
    // }
    // console.log("============sub_merchantList", sub_merchantList);

    return (
        <>
            <div className="container-xl">
                {/* <BreadCrumbs items={breadcrumbItems} /> */}

                <div className="row">
                    <div className="col-12 col-md-4">
                        <p className="heading">Sub-Merchants</p>
                    </div>
                    <div className="col-12  col-md-8">
                        <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
                            <div className="" >

                                {/* {((role_id == "132" && staff_roll_type == "277") || (role_id == "223")) && <button className="btn app-btn purp border border-1 mx-1 export-csv-btn" onClick={exportCsv} >Export CSV</button>} */}
                                {/* <button onClick={handleShow} className="btn app-btn purp border border-1 mx-1">Export CSV</button> */}
                            </div>
                            <div className="search-box">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_7372_11113)">
                                        <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7372_11113">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <input
                                    className="searchbox"
                                    type="text"
                                    placeholder="Search"
                                    name="search"
                                    onChange={handleSearchChange}
                                    value={state.searchValue}
                                />

                            </div>
                            <button
                                className="second-blk-btn py-1"
                                onClick={() => navigate("/sub-merchant/add")}
                            >
                                Add sub-merchant +
                            </button>
                            {/* <div className=""><button>Add Sub-Merchant</button></div> */}
                        </div>
                    </div>
                </div>

                <div className="app-card-body overflow-auto">
                    <div className="sm-table-responsive md-none">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th align="right">Sub-Merchant Name</th>
                                    <th align="right">Join Date</th>
                                    <th align="right">State</th>
                                    <th align="right">Status</th>
                                    <th align="right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sub_merchantList && sub_merchantList?.length > 0 ? (
                                    sub_merchantList?.map((item) => (
                                        <tr key={item?.guid} className="table-hover cursor-pointer"
                                        // onClick={() => handleRow(item, "view")}
                                        >
                                            <td>{`${item?.first_name} ${item?.last_name}`}</td>
                                            <td>{formatDate(item?.join_date)}</td>
                                            {/* <td>{`${item.first_name} ${item.last_name}`}</td> */}
                                            <td>{item?.state}</td>
                                            <td>
                                                {item?.isApproved ? (

                                                    <div className=" d-flex "> <span className="active-btn">Approved</span></div>
                                                ) : (
                                                    <div className="d-flex"><span className="active-btn">Not Approved</span></div>

                                                )}
                                            </td>
                                            <td align="left" style={{ width: "14%" }}>
                                                <div className="button-hover">
                                                    <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="" data-original-title="Disabled tooltip">
                                                        <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                                                            <img
                                                                src={View}
                                                                onClick={() => handleMerchantDetail(item)}
                                                            />
                                                        </div>
                                                    </span>
                                                    <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                        <img
                                                            src={Edit}
                                                            alt="Edit"
                                                            style={{ marginLeft: "6px" }}
                                                            onClick={() => handleEdit(item)}
                                                        />
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Transactions" tabIndex="0">
                                                        <img

                                                            src={Transaction}
                                                            alt="Transactions"
                                                            style={{ marginLeft: "6px" }}
                                                            onClick={() => handleTransaction(item)}
                                                        />
                                                    </div>

                                                    <div className="tooltip-toggle" aria-label="Upload" tabIndex="0">
                                                        <img
                                                            src={Upload}
                                                            alt="upload"
                                                            style={{ marginLeft: "6px" }}
                                                            onClick={() => handleUpload(item)}
                                                        /></div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={10} style={{ textAlign: "center" }}>
                                            No sub-merchants available
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="py-2">


                    {state.loading && <Loader loading={state.loading} />}

                    {sub_merchantList.length > 9 && <div ref={loader} style={{ height: "20px" }} />}
                </div>
            </div>

            <Modal
                className="upload-documents"
                show={state.upload}
                onHide={() => handleState("upload", false)}
            >
                <Modal.Header className="upload-doc" closeButton>
                    <Modal.Title>Upload Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadDocument handleState={handleState} state={state} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubmerchantList;




