
import React, { useEffect, useRef, useState } from 'react'
import logo from "../../assets/images/logo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import "./Sidebar.css"
import { Modal } from "react-bootstrap";
import emptyImg from "../../assets/images/Empty_Image.svg";
import { isEmpty } from 'lodash';
import baseUrl from '../../baseUrl';
import axios from 'axios';
import { useRefToken } from '../hooks/useToken';
import { toast } from 'react-toastify';
import { Transactionicon } from '../../DefaultComponent/Svgicons';
import { useGetProfileQuery, useGetSidebarQuerySuperAdmin } from '../../redux/superadminApiSlice';
import Loader from "../DefaultComponents/Loader";
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const { deviceId } = useSelector(state => state.deviceId);
    const { data: userData, isLoading, isError } = useGetSidebarQuerySuperAdmin();
    const { data: profileData, } = useGetProfileQuery();
    let role_id = userData?.data?.staff_roll_type
    const refToken = useRefToken();
    const navigate = useNavigate();
    const location = useLocation()
    const path = userData?.data?.allowedPath;
    const currentslug = location?.pathname?.replace("/admin/", "")
    const [activeLink, setActiveLink] = useState(currentslug || "dashboard");
    const [openSubmenu, setOpenSubmenu] = useState(null);
    const [menuItems, setMenuItems] = useState([])
    const buttonRef = useRef(null);
    const [isLogout, setIsLogout] = useState(false);
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoadinglogout, setIsloadinglogout] = useState(false)

    // Handlers to toggle dropdown visibility
    const handleClick = () => {
        const appSidepanel = document.querySelector(".app-sidepanel");
        if (appSidepanel) {
            appSidepanel.style.left = "0";
        }
    };
    const closeClick = () => {
        const appSidepanel = document.querySelector(".app-sidepanel");
        if (appSidepanel) {
            appSidepanel.style.left = "-400px";
            const style = document.createElement("style");
            style.textContent = `
      @media (min-width: 1199px) {
        .app-sidepanel {
          left: 0px !important;
        }
      }
    `;
            document.head.appendChild(style);
        }
    };

    const handleLogoutAPI = async (e) => {
        setIsloadinglogout(true);
        try {
            const response = await axios.post(`${baseUrl}/api/v1/super-admin/auth/log-out`, null, {
                headers: {
                    Authorization: refToken,
                    'X-Device-Fingerprint': deviceId,
                },
            });
            console.log(response, "logout");
            if (response?.status == 200) {
                setActive("/super-admin-login");
                localStorage.removeItem("refreshToken");
                setIsloadinglogout(false);
                toast.success(response?.data?.message || "You’ve been logged out successfully")
                navigate("/super-admin-login");
            }
        } catch (error) {
            console.log(error, "logout Error");
            setIsloadinglogout(false);
            toast.error(error?.response?.data?.message || "Something went wrong !!")
        }
    }

    const handleProfileDropDown = (value) => {
        setActive(value);
        setIsOpen(!isOpen);
    };
    const setActive = (path) => {
        setActiveLink(path);
    };

    const toggleSubmenu = (slug) => {
        setOpenSubmenu(openSubmenu === slug ? null : slug);
        setActive(slug);
    };

    // const fetchClientData = async () => {
    //     try {
    //         setState((prev) => ({ ...prev, loading: true }));
    //         const response = await axios.get(`${baseUrl}/api/v1/super-admin/auth/get-profile`, {
    //             headers: {
    //                 Authorization: refToken,
    //                 'X-Device-Fingerprint': deviceId,
    //             },
    //         });
    //         setState((prev) => ({ ...prev, loading: false }));
    //         const responseData = response?.data;
    //         // console.log("responseData", responseData);
    //         if (responseData?.result) {
    //             // setData(responseData.data);
    //             setState((prev) => ({ ...prev, accData: responseData?.data }));
    //         }
    //     } catch (error) {
    //         setState((prev) => ({ ...prev, loading: false }));
    //         toast.error(error?.response?.data?.message || "Failed to fetch profile data");
    //         console.log("error",error)
    //         if (error?.response?.data?.message &&
    //             ["Unauthorized", "Forbidden"].some(status => error.response.data.message.includes(status))) {
            
    //             navigate("/super-admin-login");
    //             // localStorage.clear();
    //             localStorage.removeItem("refreshToken");
    //         }
    //         console.error("Fetch client data error:", error);
    //     }
    // };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            buttonRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (userData) {

            setMenuItems(userData?.data?.menuData)
        }
        if (path && path?.length > 0) {
            setActive(path[0]);

        }
    }, [userData]);

    return (
        <>
            {isLoadinglogout && <Loader loading={isLoadinglogout} />}
            <div className="app-header-inner container-xl purp">
                <div className="app-header-content  px-md-2 px-lg-2">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <Link
                                // class="sidepanel"
                                className="sidepanel-toggler d-inline-block d-xl-none"
                                onClick={handleClick}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={30}
                                    height={30}
                                    viewBox="0 0 30 30"
                                    role="img"
                                >
                                    <title>Menu</title>
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeMiterlimit={10}
                                        strokeWidth={2}
                                        d="M4 7h22M4 15h22M4 23h22"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="app-sidepanel"
                className="app-sidepanel "
                style={{ zIndex: "9" }}
            >
                <div id="sidepanel-drop" className="sidepanel-drop" />
                <div className="sidepanel-inner d-flex flex-column purp">
                    {/* <button onClick={sortObjects}>Sort Objects</button> */}

                    {/* </div> */}
                    <Link
                        id="sidepanel-close"
                        className="sidepanel-close"
                        onClick={closeClick}
                    >
                        ×
                    </Link>
                    <div className="app-branding">
                        <div className="app-logo desk-logo">
                            <img className="logo-icon me-2" src={logo} alt="" />
                            {/* <span className="logo-text">FRACTAL</span> */}
                        </div>
                        <div className="tab-logo"><svg width="44" height="46" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.5001 14.0535V17.922C43.5001 18.0438 43.398 18.1454 43.2754 18.1454H30.4775C31.9075 16.7239 33.3272 15.3125 34.7571 13.891C34.798 13.8504 34.8593 13.8301 34.9103 13.8301H43.2754C43.398 13.8301 43.5001 13.9316 43.5001 14.0535Z" fill="#35254D" />
                            <path d="M30.4678 27.8616V23.7595C30.4678 23.6377 30.5699 23.5361 30.6925 23.5361H34.2775C34.4716 23.5361 34.5737 23.7798 34.4307 23.9118C33.1131 25.2318 31.7956 26.5518 30.4678 27.8616Z" fill="#35254D" />
                            <path d="M30.7022 23.0087H38.9346C39.0571 23.0087 39.1593 22.9071 39.1593 22.7853V18.9167C39.1593 18.7949 39.0571 18.6934 38.9346 18.6934H30.7022C30.5797 18.6934 30.4775 18.7949 30.4775 18.9167V22.7853C30.4775 22.9071 30.5797 23.0087 30.7022 23.0087Z" fill="#35254D" />
                            <path d="M24.9314 0.0308478L28.3019 1.97019C28.4041 2.03112 28.4449 2.16311 28.3836 2.2748L21.9796 13.2915C21.4587 11.3522 20.9378 9.42297 20.4169 7.48362C20.3965 7.4227 20.4067 7.36178 20.4373 7.31101L24.6147 0.112077C24.6862 0.000386907 24.8292 -0.030074 24.9314 0.0308478Z" fill="#35254D" />
                            <path d="M30.4466 18.1446L26.882 16.0936C26.7798 16.0326 26.739 15.9006 26.8003 15.7889L28.5877 12.7022C28.6898 12.5296 28.9452 12.5601 28.9962 12.753C29.4763 14.5604 29.9666 16.3576 30.4466 18.1446Z" fill="#35254D" />
                            <path d="M26.3314 15.5249L30.4476 8.43762C30.5089 8.33608 30.468 8.19393 30.3659 8.13301L26.9953 6.19366C26.8932 6.13274 26.7502 6.17336 26.6889 6.27489L22.5727 13.3621C22.5115 13.4637 22.5523 13.6058 22.6544 13.6667L26.025 15.6061C26.1374 15.667 26.2701 15.6264 26.3314 15.5249Z" fill="#35254D" />
                            <path d="M3.45161 8.97578L6.82216 7.03644C6.9243 6.97551 7.06729 7.01613 7.12857 7.11767L13.5326 18.1344C11.5818 17.6165 9.64117 17.0987 7.69033 16.5809C7.62905 16.5707 7.58819 16.5301 7.55755 16.4793L3.3699 9.28039C3.30862 9.1687 3.33926 9.0367 3.45161 8.97578Z" fill="#35254D" />
                            <path d="M21.9896 13.2915L18.425 15.3324C18.3229 15.3933 18.1799 15.3527 18.1186 15.2512L16.3312 12.1746C16.2291 12.002 16.3925 11.799 16.5865 11.8497C18.3842 12.3269 20.192 12.8042 21.9896 13.2915Z" fill="#35254D" />
                            <path d="M17.6497 15.5155L13.5336 8.42821C13.4723 8.32668 13.3395 8.28606 13.2271 8.34698L9.8566 10.2863C9.75446 10.3473 9.7136 10.4792 9.77489 10.5909L13.891 17.6782C13.9523 17.7797 14.0851 17.8203 14.1975 17.7594L17.568 15.8201C17.6701 15.7591 17.711 15.6271 17.6497 15.5155Z" fill="#35254D" />
                            <path d="M0.5 31.9435V28.0749C0.5 27.9531 0.602138 27.8516 0.724703 27.8516H13.5226C12.0927 29.2731 10.6729 30.6844 9.24301 32.1059C9.20215 32.1466 9.14087 32.1669 9.0898 32.1669H0.724703C0.602138 32.1669 0.5 32.0653 0.5 31.9435Z" fill="#35254D" />
                            <path d="M13.5319 18.1348V22.2368C13.5319 22.3587 13.4298 22.4602 13.3072 22.4602H9.72216C9.5281 22.4602 9.42596 22.2165 9.56895 22.0845C10.8865 20.7646 12.2041 19.4547 13.5319 18.1348Z" fill="#35254D" />
                            <path d="M13.2969 22.9883H5.06455C4.94198 22.9883 4.83984 23.0898 4.83984 23.2117V27.0802C4.83984 27.202 4.94198 27.3036 5.06455 27.3036H13.2969C13.4194 27.3036 13.5216 27.202 13.5216 27.0802V23.2117C13.5216 23.0898 13.4194 22.9883 13.2969 22.9883Z" fill="#35254D" />
                            <path d="M19.0387 45.9657L15.6681 44.0264C15.566 43.9655 15.5251 43.8335 15.5864 43.7218L21.9905 32.7051C22.5114 34.6444 23.0323 36.5736 23.5532 38.513C23.5736 38.5739 23.5634 38.6348 23.5327 38.6856L19.3553 45.8845C19.2736 45.9962 19.1408 46.0267 19.0387 45.9657Z" fill="#35254D" />
                            <path d="M13.5225 27.8516L17.0871 29.9026C17.1892 29.9635 17.2301 30.0955 17.1688 30.2072L15.3814 33.2939C15.2792 33.4665 15.0239 33.4361 14.9728 33.2431C14.4826 31.4358 14.0025 29.6386 13.5225 27.8516Z" fill="#35254D" />
                            <path d="M17.6391 30.4712L13.5229 37.5584C13.4617 37.66 13.5025 37.8021 13.6046 37.863L16.9752 39.8024C17.0773 39.8633 17.2203 39.8227 17.2816 39.7212L21.3978 32.6339C21.4591 32.5324 21.4182 32.3902 21.3161 32.3293L17.9455 30.39C17.8332 30.329 17.7004 30.3696 17.6391 30.4712Z" fill="#35254D" />
                            <path d="M40.5175 37.0209L37.147 38.9602C37.0448 39.0212 36.9019 38.9805 36.8406 38.879L30.4365 27.8623C32.3874 28.3801 34.328 28.898 36.2788 29.4158C36.3401 29.426 36.381 29.4666 36.4116 29.5174L40.589 36.7163C40.6605 36.828 40.6299 36.96 40.5175 37.0209Z" fill="#35254D" />
                            <path d="M21.9805 32.7056L25.5451 30.6546C25.6472 30.5937 25.7902 30.6343 25.8515 30.7358L27.6389 33.8225C27.741 33.9951 27.5776 34.1982 27.3836 34.1475C25.5859 33.6702 23.7781 33.193 21.9805 32.7056Z" fill="#35254D" />
                            <path d="M26.3218 30.4816L30.4379 37.5688C30.4992 37.6703 30.632 37.711 30.7443 37.65L34.1149 35.7107C34.217 35.6498 34.2579 35.5178 34.1966 35.4061L30.0804 28.3188C30.0192 28.2173 29.8864 28.1767 29.774 28.2376L26.4035 30.177C26.3013 30.2379 26.2605 30.3699 26.3218 30.4816Z" fill="#35254D" />
                        </svg>
                        </div>
                    </div>
                    <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
                        <ul
                            className="app-menu list-unstyled accordion"
                            id="menu-accordion"
                        >
                            {[...menuItems]
                                .sort((a, b) => a.order_id - b.order_id)
                                ?.map((item, ind) => {
                                    const menuWithSub = item.Submenu;
                                   
                                    
                                    return isEmpty(menuWithSub) ? (
                                        <li
                                            className="nav-item purp"
                                            style={{ marginBottom: "7.5px" }}
                                            key={item.order_id}
                                        >
                                            <Link
                                                to={`/admin${item.slug}`}
                                                // to={item?.slug}
                                                className={`nav-link ${activeLink === item.slug ? "active" : ""
                                                    }`}
                                                onClick={() => setActive(item.slug)}
                                            >
                                                <span className="nav-icon">
                                                    {/* {item?.icon} */}
                                                    
                                                    <img
                                                        src={`${baseUrl}${activeLink === item.slug
                                                            ? item.icon_highlight
                                                            : item.icon
                                                            }`}
                                                        alt=""
                                                    />
                                                </span>
                                                <small
                                                    className="navhead"
                                                >
                                                    {item?.menu_heading || item?.name}
                                                </small>
                                            </Link>
                                        </li>
                                    ) : (
                                        <li
                                            className="nav-item has-submenu purp"
                                            key={item.order_id}
                                        >
                                            <Link
                                                className="nav-link submenu-toggle"
                                                onClick={() => toggleSubmenu(item?.slug)}
                                                aria-expanded={openSubmenu === item?.slug}
                                            >
                                                <span className="nav-icon">
                                                        <img
                                                            src={`${baseUrl}${activeLink === item.slug
                                                                ? item.icon_highlight
                                                                : item.icon
                                                                }`}
                                                            alt=""
                                                        />
                                                </span>
                                                <small
                                                    style={{
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        lineHeight: "15px",
                                                    }}
                                                >
                                                    {item.name}
                                                </small>
                                                <span className="submenu-arrow">
                                                    <svg
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 16 16"
                                                        className="bi bi-chevron-down"
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                                        />
                                                    </svg>
                                                </span>
                                            </Link>

                                            <div
                                                id={`submenu-${ind}`}
                                                className={`collapse submenu ${openSubmenu === item.slug ? "show" : ""
                                                    } submenu-${ind} purp`}
                                                data-bs-parent="#menu-accordion"
                                            >
                                                <ul className="submenu-list list-unstyled">
                                                    {menuWithSub?.map((sub) => (
                                                        <li className="submenu-item" key={sub.order_id}>
                                                            <Link
                                                                to={`/admin${sub.slug}`}
                                                                className="nav-link submenu-link"
                                                                onClick={() => setActive(sub.slug)}
                                                            >
                                                                {sub.name}
                                                                <i className={`${sub?.icon} ms-2`}></i>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    );
                                })}

                        </ul>
                    </nav>

                    <div className="app-sidepanel-footer purp">
                        <nav
                            className="app-nav app-nav-footer purp"
                            style={{ marginBottom: "10px", paddingTop: "10px" }}
                        >
                            <ul className="app-menu footer-menu list-unstyled">
                                <li className=" purp" style={{ marginBottom: "0px" }}>
                                    <div className={`ms-4 pe-5  `} >
                                        {isOpen && (
                                            <div className="border rounded-4 p-3" ref={dropdownRef} >
                                                <ul className="">
                                                    {(role_id === 133 || role_id === 280) &&
                                                        (
                                                            <li className="py-1 cursor-pointer my-1 user-link">
                                                                <Link to={"/admin/profile"}>

                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                                                        <g clipPath="url(#clip0_7336_22400)">
                                                                            <path d="M1.12046 16.0001C0.960869 15.9998 0.803192 15.9654 0.657934 15.8993C0.512676 15.8332 0.383174 15.7369 0.278055 15.6168C0.172936 15.4967 0.094616 15.3556 0.0483122 15.2029C0.00200851 15.0502 -0.0112152 14.8893 0.00952234 14.7311L0.410772 11.6648C0.447437 11.3873 0.57438 11.1296 0.772022 10.9314L11.3436 0.35983C11.5745 0.129556 11.8873 0.000244141 12.2134 0.000244141C12.5395 0.000244141 12.8524 0.129556 13.0833 0.35983L15.6401 2.91671C15.8704 3.14763 15.9997 3.46043 15.9997 3.78655C15.9997 4.11266 15.8704 4.42547 15.6401 4.65639L5.06858 15.228C4.87042 15.4257 4.61267 15.5526 4.33515 15.5892L1.2689 15.9905C1.21968 15.9969 1.1701 16.0002 1.12046 16.0001ZM1.94702 11.9661L1.63296 14.367L4.0339 14.053L14.3001 3.78671L12.2133 1.69983L1.94702 11.9661Z" fill="#35254D" />
                                                                            <path d="M12.7145 7.25883C12.612 7.25913 12.5104 7.23919 12.4156 7.20015C12.3208 7.16111 12.2346 7.10374 12.162 7.03133L8.9689 3.8382C8.82237 3.6915 8.74012 3.4926 8.74023 3.28526C8.74035 3.07791 8.82283 2.87911 8.96953 2.73258C9.11623 2.58604 9.31513 2.50379 9.52247 2.50391C9.72982 2.50402 9.92862 2.5865 10.0752 2.7332L13.267 5.92508C13.3763 6.03434 13.4507 6.17356 13.4809 6.32512C13.5111 6.47669 13.4956 6.63379 13.4364 6.77657C13.3773 6.91934 13.2771 7.04137 13.1486 7.12721C13.0201 7.21305 12.8691 7.25885 12.7145 7.25883Z" fill="#35254D" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_7336_22400">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    {/* Edit Profile */}
                                                                    Profile
                                                                </Link>
                                                            </li>
                                                        )}
                                                    <li className="py-1 cursor-pointer my-1 user-link" onClick={() => { setIsLogout(true); handleProfileDropDown("profile") }}>
                                                        <Link>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                                                <g clipPath="url(#clip0_7336_22424)">
                                                                    <path d="M9.61586 10.8586C9.41695 10.8586 9.22618 10.9376 9.08553 11.0782C8.94488 11.2189 8.86586 11.4097 8.86586 11.6086V13.4358C8.86561 13.7166 8.75396 13.9859 8.55541 14.1845C8.35686 14.3831 8.08763 14.4948 7.8068 14.4951H2.56492C2.28409 14.4948 2.01486 14.3831 1.81631 14.1845C1.61776 13.9859 1.50611 13.7166 1.50586 13.4358V2.5645C1.50611 2.28367 1.61776 2.0144 1.81631 1.8158C2.01486 1.61719 2.28409 1.50546 2.56492 1.50513H7.8068C8.08763 1.50546 8.35686 1.61719 8.55541 1.8158C8.75396 2.0144 8.86561 2.28367 8.86586 2.5645V4.14388C8.86586 4.34279 8.94488 4.53355 9.08553 4.67421C9.22618 4.81486 9.41695 4.89388 9.61586 4.89388C9.81477 4.89388 10.0055 4.81486 10.1462 4.67421C10.2868 4.53355 10.3659 4.34279 10.3659 4.14388V2.5645C10.365 1.88602 10.0952 1.23556 9.61543 0.755774C9.1357 0.275986 8.48528 0.00603661 7.8068 0.00512695H2.56492C1.88644 0.00603661 1.23601 0.275986 0.756286 0.755774C0.276556 1.23556 0.00668618 1.88602 0.00585938 2.5645V13.4358C0.00668618 14.1142 0.276556 14.7647 0.756286 15.2445C1.23601 15.7243 1.88644 15.9942 2.56492 15.9951H7.8068C8.48528 15.9942 9.1357 15.7243 9.61543 15.2445C10.0952 14.7647 10.365 14.1142 10.3659 13.4358V11.6086C10.3659 11.4097 10.2868 11.2189 10.1462 11.0782C10.0055 10.9376 9.81477 10.8586 9.61586 10.8586Z" fill="#35254D" />
                                                                    <path d="M15.7751 7.45417L13.106 4.78698C12.9672 4.64818 12.7794 4.56935 12.5831 4.56742C12.3868 4.56549 12.1976 4.64062 12.056 4.77667C11.7529 5.06823 11.7601 5.56198 12.0576 5.85948L13.4473 7.25011H6.94727C6.74835 7.25011 6.55759 7.32913 6.41694 7.46978C6.27628 7.61043 6.19727 7.8012 6.19727 8.00011C6.19727 8.19902 6.27628 8.38979 6.41694 8.53044C6.55759 8.67109 6.74835 8.75011 6.94727 8.75011H13.4473L12.0441 10.1532C11.9035 10.2939 11.8244 10.4847 11.8244 10.6836C11.8243 10.8825 11.9033 11.0733 12.044 11.214C12.1846 11.3547 12.3754 11.4338 12.5743 11.4338C12.7733 11.4338 12.9641 11.3548 13.1048 11.2142L15.7751 8.54511C15.8468 8.47353 15.9037 8.38851 15.9425 8.29491C15.9814 8.20131 16.0013 8.10097 16.0013 7.99964C16.0013 7.89831 15.9814 7.79797 15.9425 7.70437C15.9037 7.61077 15.8468 7.52575 15.7751 7.45417Z" fill="#35254D" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_7336_22424">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Logout
                                                        </Link>

                                                    </li>
                                                </ul>

                                            </div>


                                        )}
                                        <button className=" py-2 px-2  rounded-pill user-shadow cursor-pointer truncate max-w-[150]"
                                            ref={buttonRef}
                                            onClick={() => handleProfileDropDown("profile")}
                                        >
                                            <img src={emptyImg} alt="" className="ms-2" />
                                            <span className="nav-link-text ms-2 " style={{ textTransform: 'capitalize' }}>
                                                {profileData?.data?.first_name} {profileData?.data?.last_name}
                                            </span>
                                        </button>
                                    </div>
                                </li>


                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <Modal
                size="sm"
                className="delete-employee"
                style={{ width: "100%" }}
                show={isLogout}
                onHide={() => setIsLogout(false)}
            >
                <Modal.Header className="log-main" closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h5 className="text-center">Are you sure you want to Log out?</h5>

                        <div className="row pt-3">
                            <div className="col-6">
                                <button
                                    className="cancel-btn"
                                    onClick={() => setIsLogout(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6">
                                {/* <Link
                                    to={'/super-admin-login'}
                                    onClick={handleLogoutAPI}
                                > */}
                                <button className="add-btn" onClick={handleLogoutAPI}>
                                    Log Out
                                </button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Sidebar
