import React from 'react'


export const GetStatusLabelGlobal = ({ status, styles }) => {
    switch (status) {
        case 1:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px" }} className="fas">
                        &#xf110;
                    </i>
                    Pending
                </div>
            );
        case 2:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px", color: 'green' }} className="fas fa-check"></i>
                    Success
                </div>
            );
        case 5:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px", color: '#8161C6' }} className="fas fa-redo-alt"></i>
                    Refunded
                </div>
            );
        case 3:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px", color: 'red' }} className="far fa-times-circle"></i>
                    Declined
                </div>
            );
        case 4:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px", color: '#BE7D4D' }} className="fas fa-pause"></i>
                    On-hold
                </div>
            );
        case 6:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px", color: '#9E3636' }} className="fas fa-circle-minus"></i>
                    Voided
                </div>
            );
        case 7:
            return (
                <div className="paymentcard-status" style={styles}>
                    <i style={{ fontSize: "12px", color: 'green' }} className="fa-solid fa-check"></i>
                    Settled
                </div>
            );
        case 8:
            return (
                <div className="paymentcard-status" style={styles} >
                    <i style={{ fontSize: "12px" }} className="fas">
                        &#xf110;
                    </i>
                    Authorized
                </div>
            );
        case 9:
            return (
                <div className="paymentcard-status" style={styles} >
                    {/* <i style={{ fontSize: "12px" }} className="fas">
                        &#xf110;
                    </i> */}
                    ACH (Incoming)
                </div>
            );
        case 10:
            return (
                <div className="paymentcard-status" style={styles} >
                    {/* <i style={{ fontSize: "12px" }} className="fas">
                        &#xf110;
                    </i> */}
                    ACH (Outgoing)
                </div>
            );
        default:
            return "";
    }
};
