import React, { useState } from 'react'
import { useToken } from '../../../hooks/useToken';
import { toast } from 'react-toastify';
import { forgotPasswordStaffMerchant } from '../../../api/Merchant';
import { isEmpty, isEqual } from 'lodash';

const UpdatePasswordStaffMerchant = ({ handleState, setState, state, setHasMore }) => {
    const token = useToken();
    const [data, setData] = useState({});
    const [error, setError] = useState({});

    const passwordStrengthCheck = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        return strongPasswordRegex.test(password);
    };

    const Validation = () => {
        let error = {};

        // Check if password field is empty
        if (!requiredValue(data.password)) {
            error.password = "Password field is required";
        }
        // Validate password strength
        else if (!passwordStrengthCheck(data.password)) {
            error.password = "Password must be at least 8 characters long, contain upper and lower case letters, a number, and a special character.";
        }

        // Check if confirm password field is empty
        if (!requiredValue(data.cnf_password)) {
            error.cnf_password = "Confirm password field is required";
        }
        // Check if passwords match
        else if (!checkPassword(data.password, data.cnf_password)) {
            error.cnf_password = "Both passwords must match";
        }

        return Object.keys(error).length > 0 ? error : false;
    };

    const checkPassword = (pass, cnf_pass) => isEqual(pass, cnf_pass);

    const requiredValue = (value) => !isEmpty(value);

    const handleChange = (e) => {
        let newData = { ...data };
        const { name, value } = e.target;
        newData[name] = value?.trim(); // Ensure no leading/trailing spaces
        setData(newData);
    };

    const handleData = async (e) => {
        e.preventDefault();
        setError({});
        const errors = Validation();
        if (!errors) {
            setState((prev) => ({
                ...prev,
                loading: true,
            }));
            try {
                const response = await forgotPasswordStaffMerchant(
                    token,
                    data,
                    state.pass.id
                );
                toast.success(response.message);
                handleState("edit", false);
                setHasMore(true);
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
            } catch (error) {
                console.log("error", error);
                // dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message || 'Error resetting password', open: true }));
                toast.error(error?.response?.data?.message || 'Error resetting password');
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
            }
        } else {
            setError(errors);
        }
    };

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <label className="labelStyle" style={{ display: "block", marginTop: "2px" }}>
                            Password*
                        </label>
                        <input
                            className="custom-date-picker"
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                        />
                        {error.password && (
                            <span className="error" style={{ color: "red" }}>
                                {error.password}
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label className="labelStyle" style={{ display: "block", marginTop: "2px" }}>
                            Confirm Password*
                        </label>
                        <input
                            className="custom-date-picker"
                            type="password"
                            name="cnf_password"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                        />
                        {error.cnf_password && (
                            <span className="error" style={{ color: "red" }}>
                                {error.cnf_password}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <input type="text" name="bot_key" onChange={handleChange} style={{ display: "none !important" }} hidden />
            <div className="row pt-1">
                <div className="col-6">
                    <button
                        className="cancel-btn"
                        disabled={state.loading}
                        onClick={() => {
                            handleState("pass", false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
                <div className="col-6">
                    <button className="add-btn" onClick={handleData}>
                        Update
                    </button>
                </div>
            </div>
        </>
    )
}

export default UpdatePasswordStaffMerchant
