import React, { useState } from "react";
import ProfileView from "../Tabs/ProfileView.js";

const Profile = () => {
  const [state, setState] = useState({
    key: false,
    keys: false,
  });
  
  return (
    <div className="container-xl">
      <div className="d-flex justify-content-between align-items-start mb-3"></div>
      <div className="tab-content" id="orders-table-tab-content">
        <ProfileView title="Profile" state={state} setState={setState}/>
      </div>
    </div>
  );
};

export default Profile;
