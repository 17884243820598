
export const DocsjsonData = [
    {
        "title": 'Merchant Onboarding',
        "api": [
            {
                "id": 1,
                "label": "Full Merchant Submit",
                "method": "POST",
                "slug": "full-merchant-submit",
                "url": "/docs/api/full-merchant-submit",
                "headerData": {
                    "heading": 'Merchant Onboarding',
                    "subHeading": 'Full Merchant Submit',
                    "method": 'POST',
                    "api": ['onboarding'],
                },
                "body": {
                    "OwnerInfo": {
                        "first_name": "MJ",
                        "last_name": "Mann",
                        "email": "slabreadymixx@mix.com",
                        "ssn": "555667777",
                        "phone": "1234567895",
                        "date_of_birth": "1998-01-17",
                        "address": "333 West Vine Street",
                        "city": "Lexington",
                        "state": "Kentucky",
                        "zip": "40507",
                        "country": "US",
                        "b_dl_number": "123123123",
                        "b_title": "Owner",
                        "ownership_percent": "100"
                    },
                    "BusinessInfo": {
                        "business_name": "Slab Ready Mix",
                        "dba": "Slab Ready Mix",
                        "business_phone": "8599779166",
                        "business_email": "slabreadymixx@mix.com",
                        "ein": "123456789",
                        "ownershiptype": "LLC",
                        "established_date": "2024-01-01",
                        "business_address": "333 West Vine Street",
                        "business_city": "Lexington",
                        "business_state": "Kentucky",
                        "business_zip": "40517",
                        "business_country": "US",
                        "business_industry": "7311",
                        "website": "https://testwebsite.com/"
                    },
                    "ProcessingInfo": {
                        "processing_environment": "Ecommerce",
                        "avgTicket": "50",
                        "annualCCSales": "500000",
                        "legal_b2c": "50",
                        "legal_b2b": "50",
                        "legal_cnpinternet": "50",
                        "legal_cnpphoneemail": "50",
                        "legal_percentdeliveredover30days": "50",
                        "legal_percentdeliveredsameday": "50",
                        "percentdelievered7days": "50",
                        "percentdelivered14days": "50",
                        "legal_cp": "50",
                        "legal_productssold": "Goods"
                    },
                    "BankingInfo": {
                        "account_number": "091000019",
                        "routing_number": "00212369",
                        "bank_name": "Truist"
                    }
                },
                "head": {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Basic Auth"
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '201',
                        "content":
                        {
                            "result": true,
                            "message": "Merchant onboarding successfully",
                            "data": {
                                "email": "slabreadymix@mix.com",
                                "businessname": "Slab Ready Mix",
                                "merchant_key": "{{merchant_key}}",
                                "public_key": "{{public_key}}"
                            }
                        },
                    }
                ]


            },
            {
                "id": 2,
                "label": "Hosted Onboarding",
                "method": "POST",
                "slug": "hosted-onboarding",
                "url": "/docs/api/hosted-onboarding",

            },
            {
                "id": 3,
                "label": "Get Merchants by Client",
                "method": "GET",
                "slug": "get-merchant-by-client",
                "url": "/docs/api/get-merchant-by-client",
                "headerData": {
                    "heading": 'Merchant Onboarding',
                    "subHeading": 'Get Merchants by Client',
                    "method": 'GET',
                    "api": ['merchant'],
                },
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {},
                "tabsdata": [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "Merchants fetched successfully",
                            "data": [
                                {
                                    "merchant_id": "{{merchant_id}}",
                                    "businessname": "Rogue 6 Ready Mix",
                                    "first_name": "JP",
                                    "last_name": "Gound",
                                    "email": "jpdemo@fractal.com",
                                    "website": "https://testwebsite.com/",
                                    "join_date": "2024-06-05T13:44:25.000Z",
                                    "phonenumber": "1234567895",
                                    "public_data": {
                                        "public_key": "{{public_key}}"
                                    }
                                },
                                {
                                    "merchant_id": "{{merchant_id}}",
                                    "businessname": "Celtic Ready Mix",
                                    "first_name": "Drew",
                                    "last_name": "Rice",
                                    "email": "droooobie@fractall.com",
                                    "website": "https://testwebsite.com/",
                                    "join_date": "2024-07-22T19:18:36.000Z",
                                    "phonenumber": "1234567895",
                                    "public_data": {
                                        "public_key": "{{public_key}}"
                                    }
                                },
                                {
                                    "merchant_id": "{{merchant_id}}",
                                    "businessname": "Slab Ready Mix",
                                    "first_name": "MJ",
                                    "last_name": "Mann",
                                    "email": "slabreadymix@fractall.com",
                                    "website": "https://testwebsite.com/",
                                    "join_date": "2024-08-20T18:44:35.000Z",
                                    "phonenumber": "1234567895",
                                    "public_data": {
                                        "public_key": "{{public_key}}"
                                    }
                                }
                            ]
                        },
                    }
                ]
            },
            {
                "id": 4,
                "label": "Add Logo",
                "method": "PATCH",
                "slug": "merchant-logo-upload",
                "url": "/docs/api/merchant-logo-upload",
                "headerData": {
                    "heading": 'Merchant Onboarding',
                    "subHeading": 'Add Merachant Logo',
                    "method": 'PATCH',
                    "api": ['merchant', 'merchant-logo-upload'],
                    "params": 'merhcant_id'
                },
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {},
                "tabsdata": [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "status": true,
                            "message": "Merchant logo uploaded successfully."
                        },
                    }
                ]
            },
        ]
    },
    {
        "title": "Payment Collection Methods",
        "api": [
            {
                "id": 5,
                "label": "Create Payment Link",
                "method": "POST",
                "slug": "create-link",
                "url": "/docs/api/create-link",
                "body": {
                    "amount": 4.54,
                    "merchant_key": "{{merchant_key}}",
                    "order_id": "22111fdz"
                },
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "headerData": {
                    "heading": 'Payment Collection Methods',
                    "subHeading": 'Create Link',
                    "method": 'POST',
                    "api": ['pay-link'],
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '201',
                        "content":
                        {
                            "message": "Payment link created successfully.",
                            "data": {
                                "link_id": "ord_8d42138017",
                                "order_id": "22111fdz",
                                "payment_link": "{{payment_link}}"
                            }
                        },
                    }
                ]
            },
            {
                "id": 6,
                "label": "QR Payment Link",
                "method": "POST",
                "slug": "payment-link",
                "url": "/docs/api/payment-link",
                "body": {
                    "amount": "2.00",
                    "merchant_key": "{{merchant_key}}",
                    "order_id": "24400"
                },
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "headerData": {
                    "heading": 'Payment Collection Methods',
                    "subHeading": 'Payment Request (QR)',
                    "method": 'POST',
                    "api": ['order', "api-qr_code_link"],
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '201',
                        "content":
                        {
                            "message": "QR code URL has been created",
                            "data": {
                                "qr_code_link": "{{qr_code_link}}",
                                "sales_id": "c9d48e46-edbf-11ee-b78a-02fabd890500",
                                "order_id": "24400"
                            }
                        },
                    }
                ]
            }],
        "sub": [
            {
                "subtitle": "Requests",
                "subapi": [
                    {
                        "id": 7,
                        "label": "Payment Request",
                        "method": "POST",
                        "slug": "payment-request",
                        "url": "/docs/api/payment-request",
                        "body": {
                            "amount": "4.00",
                            "phone_number": "6063318626",
                            "merchant_key": "{{merchant_key}}",
                            "order_id": "5465",
                            "preauth": false
                        },
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "headerData": {
                            "heading": 'Payment Collection Methods',
                            "subHeading": 'Payment Request',
                            "method": 'POST',
                            "api": ['requests'],
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "message": "Text request has been sent",
                                    "data": {
                                        "link_id": "ord_aa0ef50184",
                                        "order_id": "5465",
                                        "link": "{{payment_link}}"
                                    }
                                },
                            }
                        ]
                    },
                    {
                        "id": 8,
                        "label": "Payment Request Customer",
                        "method": "POST",
                        "slug": "PaymentRequestCustomer",
                        "url": "/docs/api/PaymentRequestCustomer",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "amount": 4.11,
                            "order_id": "512345",
                            "merchant_key": "{{merchant_key}}",
                            "preauth": false
                        },
                        "headerData": {
                            "heading": 'Payment Requests',
                            "subHeading": 'Payment Request Customer',
                            "method": 'POST',
                            "api": ['requests'],
                            "params": 'customer_id'
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "message": "Text request has been sent",
                                    "data": {
                                        "link_id": "ord_8dd71cadec",
                                        "order_id": "512345",
                                        "link": "{{payment_link}}"
                                    }
                                },

                            }
                        ],
                    },
                    {
                        "id": 9,
                        "label": "Authorization Request Customer",
                        "method": "POST",
                        "slug": "AuthorizationRequestCustomer",
                        "url": "/docs/api/AuthorizationRequestCustomer",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "amount": "4.00",
                            "order_id": "54514",
                            "merchant_key": "{{merchant_key}}",
                            "preauth": true
                        },
                        "headerData": {
                            "heading": 'Authorization Requests',
                            "subHeading": 'Authorization Request Customer',
                            "method": 'POST',
                            "api": ['requests'],
                            "params": 'customer_id',
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "message": "Text request has been sent",
                                    "data": {
                                        "link_id": "ord_8dd71cadec",
                                        "order_id": "512345",
                                        "link": "{{payment_link}}"
                                    }
                                },

                            }
                        ]

                    },
                    {
                        "id": 10,
                        "label": "Request Card With Customer",
                        "method": "POST",
                        "slug": "RequestCardCustomer",
                        "url": "/docs/api/RequestCardCustomer",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic {{token}}"
                        },
                        "body": {
                            "isRequestCard": true,
                            "merchant_key": "{{merchant_key}}"
                        },
                        "headerData": {
                            "heading": 'Card Requests',
                            "subHeading": 'Request Card Customer',
                            "method": 'POST',
                            "api": ['requests'],
                            "params": 'customer_id'
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "result": true,
                                    "message": "Card request sent successfully"
                                },

                            }
                        ]
                    }
                ]
            },
            {
                "subtitle": "Preauthorizations",
                "subapi": [
                    {
                        "id": 11,
                        "label": "Pre-Authorization with Token",
                        "method": "POST",
                        "slug": "PreauthWithToken",
                        "url": "/docs/api/PreauthWithToken",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "Token": "{{token}}",
                            "amount": 1.50,
                            "merchant_key": "{{merchant_key}}"
                        },
                        "headerData": {
                            "heading": 'Preauthorization',
                            "subHeading": 'Preauth With Token',
                            "method": 'POST',
                            "api": ['preauth'],
                            "params": ''
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "result": true,
                                    "message": "Authorization Approved",
                                    "data": {
                                        "transaction_id": "08a55e95-fa71-4763-945c-6291ece41648"
                                    }
                                },

                            }
                        ]

                    },
                    {
                        "id": 12,
                        "label": "Increment Pre-Authorization",
                        "method": "POST",
                        "slug": "IncrementPreAuth",
                        "url": "/docs/api/IncrementPreAuth",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "transaction_id": "4074476d-15c2-4e5f-be5d-74309af7b6a7",
                            "last_authorized_amount": "4.80",
                            "new_authorized_amount": "4.90",
                            "order_id": "655541a",
                            "merchant_key": "{{merchant_key}}"
                        }
                        ,
                        "headerData": {
                            "heading": 'Preauthorization',
                            "subHeading": 'Increment PreAuth',
                            "method": 'POST',
                            "api": ['preauth', 'update'],
                            // "params": 'transaction_id'
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "result": true,
                                    "message": "Amount Updated Successfully",
                                    "data": {
                                        "transaction_id": "4074476d-15c2-4e5f-be5d-74309af7b6a7",
                                        "order_id": "fffddd"
                                    }
                                },
                            }
                        ]
                    },
                    {
                        "id": 13,
                        "label": "Capture Pre-Authorization",
                        "method": "POST",
                        "slug": "CapturePreAuthorization",
                        "url": "/docs/api/CapturePreAuthorization",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "transaction_id": "563aef93-8e4a-4ea9-91a1-6b7b494d91ed",
                            "merchant_key": "{{merchant_key}}"
                        },
                        "headerData": {
                            "heading": 'Preauthorization',
                            "subHeading": 'Capture PreAuthorization',
                            "method": 'POST',
                            "api": ['preauth', 'capture'],
                            // "params": 'transaction_id'
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "message": "Payment captured successfully.",
                                    "data": {
                                        "transaction_id": "f669a7b2-947e-4f06-864d-b396f8478f2d",
                                        "order_id": "fffddd"
                                    }
                                },
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        "title": "Customers",
        "api": [
            {
                "id": 14,
                "label": "Create Customer",
                "method": "POST",
                "slug": "create-customer",
                "url": "/docs/api/create-customer",
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {
                    "first_name": "John",
                    "last_name": "Doe",
                    "email": "john@fractal.com",
                    "phone_number": "5134140000",
                    "merchant_key": "{{merchant_key}}"
                },
                "headerData": {
                    "heading": 'Customers',
                    "subHeading": 'Create Customer',
                    "method": 'POST',
                    "api": ['customers', 'add'],
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "data": {
                                "customer_id": "f1070284-5fda-11ef-bae5-0ef0812e6179",
                                "customerEmail": "john@fractal.com",
                                "customerPhone": "5134140000",
                                "firstName": "John",
                                "lastName": "Doe",
                            },
                            "message": "Customer added successfully",
                            "result": true
                        }
                    }
                ]
            },
            {
                "id": 15,
                "label": "Get Customer",
                "method": "GET",
                "slug": "get-customer",
                "url": "/docs/api/get-customer",
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {
                    "merchant_key": "(merchant_key)"
                },
                "headerData": {
                    "heading": 'Customers',
                    "subHeading": 'Get Customer',
                    "method": 'GET',
                    "api": ['customers'],
                    "params": 'customer_id'
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "Customer details",
                            "data": {
                                "customer_email": "john@fractal.com",
                                "customer_phone": "5134140000",
                                "customer_id": "f1070284-5fda-11ef-bae5-0ef0812e6179"
                            }
                        }
                    }
                ]

            },
            {
                "id": 16,
                "label": "Get Customers",
                "method": "GET",
                "slug": "get-customers",
                "url": "/docs/api/get-customers",
                "headerData": {
                    "heading": 'Customers',
                    "subHeading": 'Get Customer',
                    "method": 'GET',
                    "api": ['customers'],
                },
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "All customer list",
                            "data": {
                                "perPage": 20,
                                "page": 1,
                                "totalPage": 1,
                                "totalCount": 2,
                                "allList": [
                                    {
                                        "customer_id": "f1070284-5fda-11ef-bae5-0ef0812e6179",
                                        "first_name": "John",
                                        "last_name": "Woe",
                                        "Email": "john@fractal.com",
                                        "Phone": "5134140000",
                                        "create_date": "2024-08-21T16:32:22.000Z"
                                    },
                                    {
                                        "customer_id": "0c5f67e2-42c5-11ef-af4e-0ef0812e6179",
                                        "first_name": "John F",
                                        "last_name": "Doe",
                                        "Email": "mike@james.com",
                                        "Phone": "4548591474",
                                        "create_date": "2024-07-15T16:12:35.000Z"
                                    }
                                ]
                            }
                        }

                    }
                ]
            },
            {
                "id": 17,
                "label": "Request Card",
                "method": "POST",
                "slug": "request-card",
                "url": "/docs/api/request-card",
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {
                    "merchant_key": "(merchant_key)"
                },
                "headerData": {
                    "heading": 'Customers',
                    "subHeading": 'Request Card',
                    "method": 'POST',
                    "api": ['customers', 'request-card'],
                    "params": 'customer_id'
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content": {
                            "result": true,
                            "message": "Request sent successfully"
                        }

                    }
                ]

            }

        ],
        "sub": [
            {
                "subtitle": "Charging Customer",
                "subapi": [
                    {
                        "id": 18,
                        "label": "Charge Customer",
                        "method": "POST",
                        "slug": "charge-customer",
                        "url": "/docs/api/charge-customer",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "amount": 1.00,
                            "merchant_key": "{{merchant_key}}"
                        },
                        "headerData": {
                            "heading": 'Customers',
                            "subHeading": 'Charge Customer',
                            "method": 'POST',
                            "api": ['customers', 'charge'],
                            "params": 'customer_id'
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "result": true,
                                    "message": "Payment captured successfully.",
                                    "transaction_id": "0e99eb7c-5a45-11ef-af4e-0ef0812e6179"
                                }
                            }
                        ]
                    },
                    {
                        "id": 19,
                        "label": "Charge Customer PreAuth",
                        "method": "POST",
                        "slug": "charge-customer-preauth",
                        "url": "/docs/api/charge-customer-preauth",
                        "head": {
                            "Content-Type": "application/json",
                            "Authorization": "Basic Auth"
                        },
                        "body": {
                            "amount": 1.00,
                            "preAuth": true,
                            "merchant_key": "{{merchant_key}}",
                            "order_id": "5465446"
                        },
                        "headerData": {
                            "heading": 'Customers',
                            "subHeading": 'Charge Customer (PreAuth)',
                            "method": 'POST',
                            "api": ['customers', 'charge'],
                            "params": 'customer_id'
                        },
                        'tabsdata': [
                            {
                                "id": 1,
                                "label": '200',
                                "content":
                                {
                                    "result": true,
                                    "message": "Authorization Approved",
                                    "data": {
                                        "transaction_id": "68e93a56-fa9c-4196-8ef6-b13d731f1bef"
                                    }
                                }
                            }
                        ]
                    }
                ]
            },

        ]
    },
    {
        "title": 'Transactions',
        "api": [
            {
                "id": 20,
                "label": "Get Transactions",
                "method": "GET",
                "slug": "get-transactions",
                "url": "/docs/api/get-transactions",
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {
                    "merchant_key": "{{merchant_key}}",
                    "from_date": "2024-01-15",
                    "to_date": "2024-07-18",
                    "pageNumber": 0,
                    "limit": "20"
                },
                "headerData": {
                    "heading": 'Transactions',
                    "subHeading": 'Get Transactions',
                    "method": 'GET',
                    "api": ['merchant', 'transactions'],
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "Merchant's Transactions",
                            "total": 18,
                            "data": [
                                {
                                    "transaction_id": "294ac4cb-42fc-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T22:47:06.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "y1sskla5N",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "f83920db-42fb-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T22:45:44.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "9nYTCO3",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "ee8a4828-42fb-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T22:45:27.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "ZZ072OJeLk",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "e6bbd595-42fb-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T22:45:14.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "IEWrahzKAB",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "b330e46b-42fb-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T22:43:48.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "jPXI7gxQ",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "cec8b3b1-42c7-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T16:32:20.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "ZvaoUl4Lyq",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "664f064a-42c7-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T16:29:25.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "i6a7il0",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "057629e6-42c6-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T16:19:33.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "0N1BuUwq",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "e95bd207-42c5-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-15T16:18:46.000Z",
                                    "amount": "1.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 0.97,
                                    "other_details": {
                                        "auth_code": "k7NiHFSOQF",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "82b654dc-3fa0-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-11T16:13:29.000Z",
                                    "amount": "4.55",
                                    "fee_percentage": "0.1365000",
                                    "net_amount": 4.41,
                                    "other_details": {
                                        "auth_code": "8eD7NXQ0",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "ffd2c834-3e1c-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-09T17:59:34.000Z",
                                    "amount": "8.00",
                                    "fee_percentage": "0.2400000",
                                    "net_amount": 7.76,
                                    "other_details": {
                                        "auth_code": "M2bxfXS",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "e656c18b-3e0b-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-09T15:57:10.000Z",
                                    "amount": "3.00",
                                    "fee_percentage": "0.0900000",
                                    "net_amount": 2.91,
                                    "other_details": {
                                        "auth_code": "WxxkG24J",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "8d1d35a9-3e0b-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-09T15:54:40.000Z",
                                    "amount": "4.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 4,
                                    "other_details": {
                                        "auth_code": "7caxq68qT",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "f1e946cc-3e07-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-09T15:28:51.000Z",
                                    "amount": "7.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 7,
                                    "other_details": {
                                        "auth_code": "zGlAoC3Zk",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "72ea4c0e-3d4d-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-08T17:13:52.000Z",
                                    "amount": "6.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 2.82,
                                    "other_details": {
                                        "auth_code": "cTbiOd2dwY",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "cf54dd13-3d4c-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-08T17:09:17.000Z",
                                    "amount": "2.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": -1.06,
                                    "other_details": {
                                        "auth_code": "C1AQg5S",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "e1d611ae-3d44-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-08T16:12:32.000Z",
                                    "amount": "3.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 2.91,
                                    "other_details": {
                                        "auth_code": "00tCCRUMEB",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                },
                                {
                                    "transaction_id": "2582623a-3d44-11ef-af4e-0ef0812e6179",
                                    "created_date": "2024-07-08T16:07:16.000Z",
                                    "amount": "8.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 7.76,
                                    "other_details": {
                                        "auth_code": "M5a0IKoB",
                                        "last_four": "0043",
                                        "card_brand": "VISA"
                                    }
                                }
                            ]
                        },

                    }
                ]
            },
            {
                "id": 21,
                "label": "Get Transaction",
                "method": "GET",
                "slug": "get-transaction",
                "url": "/docs/api/get-transaction",
                "head": {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Auth"
                },
                "body": {
                    // "merchant_key": "(merchant_key)"
                },
                "headerData": {
                    "heading": 'Transactions',
                    "subHeading": 'Get Transaction',
                    "method": 'GET',
                    "api": ['merchant', 'transactions'],
                    "params": 'transaction_id'
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "Transaction fetched successfully",
                            "data": [
                                {
                                    "transaction_id": "f0478a5f-fb1f-11ee-b78a-02fabd890500",
                                    "transaction_date": "2024-04-15T12:01:48.000Z",
                                    "amount": "3000.00",
                                    "fee_percentage": "0.0000000",
                                    "net_amount": 2920,
                                    "other_details": {
                                        "authcode": "VDhdH5BWz",
                                        "lastFourDigits": "0060",
                                        "cardType": "M/C"
                                    }
                                }
                            ]
                        }
                    }
                ]
            },
            {
                "id": 22,
                "label": "Return Sale",
                "method": "POST",
                "slug": "return-sale",
                "url": "/docs/api/return-sale",
                "head": {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Basic Auth"
                }
                ,
                "body": {
                    "merchant_key": "{{merchant_key}}",
                    "transaction_id": "{{transaction_id}}",
                    "amount": "8.00"
                }
                ,
                "headerData": {
                    "heading": 'Order',
                    "subHeading": 'Sales Return',
                    "method": 'POST',
                    "api": ['order', 'sales-return'],
                    "params": 'transaction_id'
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "Transaction returned successfully"
                        }
                    }
                ]
            },
            {
                "id": 23,
                "label": "Void Sale",
                "method": "POST",
                "slug": "void-sale",
                "url": "/docs/api/void-sale",
                "head": {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Basic Auth"
                },
                "body": {
                    "merchant_key": "{{merchant_key}}",
                    "transaction_id": "{{transaction_id}}"
                },
                "headerData": {
                    "heading": 'Order',
                    "subHeading": 'Sales Void',
                    "method": 'POST',
                    "api": ['order', 'sales-void'],
                    "params": 'transaction_id'
                },
                'tabsdata': [
                    {
                        "id": 1,
                        "label": '200',
                        "content":
                        {
                            "result": true,
                            "message": "Transaction voided successfully"
                        },

                    }
                ]
            },
        ]
    },
    // {
    //     "title": 'Client',
    //     "api": [
    //         {
    //             "label": "Get Client List",
    //             "method": "GET",
    //             "slug": "get-client-list",
    //             "url": "/docs/api/get-client-list",
    //             "head": {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic keyinfo=="
    //             },
    //             "body": {

    //             },
    //             "headerData": {
    //                 "heading": 'Client',
    //                 "subHeading": 'Get Client List',
    //                 "method": 'GET',
    //                 "api": ['client'],
    //             },
    //             "backNavigate": {
    //                 // "title": "Payment Link",
    //                 // "url": "/docs/api/payment-link"
    //             },
    //             "forwardNavigate": {
    //                 // "title": "Get Transaction",
    //                 // "url": "/docs/api/get-transaction"
    //             },
    //             'tabsdata': [
    //                 {
    //                     "id": 1,
    //                     "label": '200',
    //                     "content": JSON.stringify(
    //                         {
    //                             "result": true,
    //                             "message": "Client details fetch successfully",
    //                             "data": [
    //                                 {
    //                                     "id": "0fd4877a-eff0-11ee-b78a-02fabd890500",
    //                                     "first_name": "Easter",
    //                                     "last_name": "Kilback",
    //                                     "email": "Xavier16@hotmail.com",
    //                                     "phonenumber": "2663819464",
    //                                     "businessname": "pinesucceednew"
    //                                 },
    //                                 {
    //                                     "id": "1d770a88-eff0-11ee-b78a-02fabd890500",
    //                                     "first_name": "Easter",
    //                                     "last_name": "Kilback",
    //                                     "email": "Xavier16@hotmail.com",
    //                                     "phonenumber": "2663819464",
    //                                     "businessname": "pinesucceednew"
    //                                 }
    //                             ]
    //                         },
    //                         null,
    //                         2
    //                     ),
    //                 }
    //             ]
    //         },
    //         {
    //             "label": "Generte Client API Keys",
    //             "method": "POST",
    //             "slug": "generateapikeys",
    //             "url": "/docs/api/generateapikeys",
    //             "head": {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic keyinfo=="
    //             },
    //             "body": {
    //                 "client_id": "{{client_id}}"
    //             },
    //             "headerData": {
    //                 "heading": 'Client',
    //                 "subHeading": 'Generte Client API Keys',
    //                 "method": 'POST',
    //                 "api": ['client', "generateAPIKeys"],
    //             },
    //             "backNavigate": {
    //                 // "title": "Payment Link",
    //                 // "url": "/docs/api/payment-link"
    //             },
    //             "forwardNavigate": {
    //                 // "title": "Get Transaction",
    //                 // "url": "/docs/api/get-transaction"
    //             },
    //             'tabsdata': [
    //                 {
    //                     "id": 1,
    //                     "label": '200',
    //                     "content": JSON.stringify(
    //                         {
    //                             "result": true,
    //                             "message": "API Keys generated successfully",
    //                             "data": {
    //                                 "h_guid": [
    //                                     1
    //                                 ],
    //                                 "api_key": "((api_key))"
    //                             }
    //                         },
    //                         null,
    //                         2
    //                     ),
    //                 }
    //             ]
    //         },
    //         {
    //             "label": "Activate Client",
    //             "method": "PUT",
    //             "slug": "client-activation",
    //             "url": "/docs/api/client-activation",
    //             "head": {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic keyinfo=="
    //             },
    //             "body": {
    //                 "client_id": "{{client_id}}",
    //                 "is_verify": 1
    //             },
    //             "headerData": {
    //                 "heading": 'Client',
    //                 "subHeading": 'Activate Client',
    //                 "method": 'PUT',
    //                 "api": ['client', "activation"],
    //             },
    //             "backNavigate": {
    //                 // "title": "Payment Link",
    //                 // "url": "/docs/api/payment-link"
    //             },
    //             "forwardNavigate": {
    //                 // "title": "Get Transaction",
    //                 // "url": "/docs/api/get-transaction"
    //             },
    //             'tabsdata': [
    //                 {
    //                     "id": 1,
    //                     "label": '200',
    //                     "content": JSON.stringify(
    //                         {
    //                             "result": true,
    //                             "message": "Client activation status updated successfully"
    //                         },
    //                         null,
    //                         2
    //                     ),
    //                 }
    //             ]
    //         },
    //         {
    //             "label": "Delete Client",
    //             "method": "DELETE",
    //             "slug": "client-deletion",
    //             "url": "/docs/api/client-deletion",
    //             "head": {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic keyinfo=="
    //             },
    //             "body": {
    //                 "client_id": "{{client_id}}",
    //                 "delete": 0
    //             },
    //             "headerData": {
    //                 "heading": 'Client',
    //                 "subHeading": 'Delete Client',
    //                 "method": 'DELETE',
    //                 "api": ['client', "deletion"],
    //             },
    //             "backNavigate": {
    //                 // "title": "Payment Link",
    //                 // "url": "/docs/api/payment-link"
    //             },
    //             "forwardNavigate": {
    //                 // "title": "Get Transaction",
    //                 // "url": "/docs/api/get-transaction"
    //             },
    //             'tabsdata': [
    //                 {
    //                     "id": 1,
    //                     "label": '200',
    //                     "content": JSON.stringify(
    //                         {
    //                             "result": true,
    //                             "message": "Client deleted successfully"
    //                         },
    //                         null,
    //                         2
    //                     ),
    //                 }
    //             ]
    //         },
    //         {
    //             "label": "Client Info",
    //             "method": "GET",
    //             "slug": "client-info",
    //             "url": "/docs/api/client-info",
    //             "head": {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic keyinfo=="
    //             },
    //             "body": {

    //             },
    //             "headerData": {
    //                 "heading": 'Client',
    //                 "subHeading": 'Client Info',
    //                 "method": 'GET',
    //                 "api": ['client', "info"],
    //             },
    //             "backNavigate": {
    //                 // "title": "Payment Link",
    //                 // "url": "/docs/api/payment-link"
    //             },
    //             "forwardNavigate": {
    //                 // "title": "Get Transaction",
    //                 // "url": "/docs/api/get-transaction"
    //             },
    //             'tabsdata': [
    //                 {
    //                     "id": 1,
    //                     "label": '200',
    //                     "content": JSON.stringify(
    //                         {
    //                             "result": true,
    //                             "message": "client authenticated successfully"
    //                         },
    //                         null,
    //                         2
    //                     ),
    //                 }
    //             ]
    //         },
    //         {
    //             "label": "Client Merchant Transaction ",
    //             "method": "GET",
    //             "slug": "client-merchantTransactionss",
    //             "url": "/docs/api/client-merchantTransactionss",
    //             "head": {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic keyinfo=="
    //             },
    //             "body": {

    //             },
    //             "headerData": {
    //                 "heading": 'Client',
    //                 "subHeading": 'Client Merchant Transaction',
    //                 "method": 'GET',
    //                 "api": ['client', "merchantTransactionss"],
    //             },
    //             "backNavigate": {
    //                 // "title": "Payment Link",
    //                 // "url": "/docs/api/payment-link"
    //             },
    //             "forwardNavigate": {
    //                 // "title": "Get Transaction",
    //                 // "url": "/docs/api/get-transaction"
    //             },
    //             'tabsdata': [
    //                 {
    //                     "id": 1,
    //                     "label": '200',
    //                     "content": JSON.stringify(
    //                         {
    //                             "result": true,
    //                             "message": "client authenticated successfully"
    //                         },
    //                         null,
    //                         2
    //                     ),
    //                 }
    //             ]
    //         },
    //     ]
    // }
] 