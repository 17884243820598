import React, { useEffect, useState } from 'react';
import { useAddMenuItemMutation, useGetAllMenuListQuery,  useUpdateMenuItemMutation } from '../../../redux/superadminApiSlice';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Loader from '../../../DefaultComponent/Loader';
import baseUrl from '../../../baseUrl';

const AddMenu = ({ menuItem, action, handleClose }) => {
  const [addMenuTrigger, { data, isLoading, isError }] = useAddMenuItemMutation();
  let { data: menu, isFetching: roleMenuLoad, isError: roleMenuError } = useGetAllMenuListQuery();
  const [updateMenuTrigger, { data: updateResp, isLoading: updateLoading }] = useUpdateMenuItemMutation();
console.log("menuuuuu",menu);

  const [menuData, setMenuData] = useState({
    name: '',
    menu_heading: '',
    slug: '',
    icon: '',
    icon_highlight: '',
    parent_id: ''
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (name, value) => {
    setMenuData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

    
    // filed validations
    const validateFields = () => {
      const errors = {};
      if (!menuData.name.trim()) errors.name = 'Name is required';
      if (!menuData.menu_heading.trim()) errors.menu_heading = 'Menu heading is required';
    if (!menuData.slug.trim()) errors.slug = 'Slug is required';
    if (!menuData.icon && action === "Add" && !menuData.parent_id) errors.icon = 'Icon is required';
    if (!menuData.icon_highlight && action === "Add" && !menuData.parent_id) errors.icon_highlight = 'Highlighted icon is required';
    return errors;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Update errors state
      return;
    }
    
    try {
      const formData = new FormData();
   
      formData.append('name', menuData.name);
      formData.append('menu_heading', menuData.menu_heading);
      formData.append('slug', menuData.slug);
      formData.append('icon', menuData.icon);
      formData.append('icon_highlight', menuData.icon_highlight);
      if (action === "Add") {
        console.log("formdata", menuData);
    let updateResponse= await addMenuTrigger(formData).unwrap();
        if (updateResponse?.result) {
          toast.success(updateResponse?.message);
          handleClose()
        }
      }
      else {
        let updateResponse = await updateMenuTrigger({ formData: formData, id: menuItem?.menu_id }).unwrap();
        
        if (updateResponse?.result) {
          toast.success(updateResponse?.message);
          handleClose()
        }
        console.log("updatemenu", updateResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log("default vad", menuItem);

  useEffect(() => {
    if (menuItem && action === 'Edit') {
      setMenuData((prev) => ({
        ...prev,
        name: menuItem?.name || '',
        menu_heading: menuItem?.menu_heading || '',
        slug: menuItem?.slug || '',
      }));
    }
    else{
      setMenuData({
        name: '',
        menu_heading: '',
        slug: '',
        icon: '',
        icon_highlight: '',
        role_id: '',
        parent_id: ''
      });
    }
  }, [menuItem?.menu_id]);

  useEffect(() => {
    // get parent menu
    let findParentMenu = menu?.find(item => item.value === menuItem?.parent_id)
    setMenuData((prev) => ({
      ...prev,
      parent_id: findParentMenu || '',
    }));
  }, [menu])
  console.log("mernu dataaaa",menuData);
  
  return (
    <div className="container-xl">
      <form>
        <div className="row">
          <div className="col-6">
            <label>Name</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Name"
              name="name"
              value={menuData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              required
            />
            {formErrors.name && <span className="text-danger fs-12">{formErrors.name}</span>}
          </div>
          <div className="col-6">
            <label>Menu Heading</label>
            <input
              className="custom-date-picker"
              type="text"
              name="menu_heading"
              placeholder="Menu Heading"
              value={menuData.menu_heading}
              onChange={(e) => handleChange('menu_heading', e.target.value)}
              required
            />
            {formErrors.menu_heading && <span className="text-danger fs-12">{formErrors.menu_heading}</span>}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label>Slug</label>
            <input
              className="custom-date-picker"
              type="text"
              name="slug"
              value={menuData.slug}
              placeholder="Slug"
              onChange={(e) => action === 'Add' ? handleChange('slug', e.target.value) : null}
              disabled={action === 'Edit'}
              required
            />
            {formErrors.slug && <span className="text-danger fs-12">{formErrors.slug}</span>}
          </div>
        
         
          <div className="col-6">
            <label>Parent Menu</label>
            <Select className=''
              placeholder="Menu"
              value={menuData.parent_id}
              isDisabled={roleMenuLoad}
              options={menu}
              isClearable
              isLoading={roleMenuLoad}
              onChange={(e) => { handleChange('parent_id', e) }} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label>Icon</label>
            {menuData?.icon ?
              <img src={URL.createObjectURL(menuData?.icon)} alt="" style={{ maxWidth: "40px" }} /> :
              <img src={`${baseUrl}${menuItem?.icon}`} alt="" />}

            <input
              type="file"
              name="icon"
              id="icon"
              onChange={(e) => handleChange('icon', e.target.files[0])}
            />
            {formErrors.icon && <span className="text-danger fs-12">{formErrors.icon}</span>}
          </div>
          <div className="col-6">
            <label>Highlighted Icon</label>
          
            {menuData?.icon_highlight ?
              <img src={URL.createObjectURL(menuData?.icon_highlight)} alt="" style={{ maxWidth: "40px" }} /> :
              <img src={`${baseUrl}${menuItem?.icon_highlight}`} alt="" />
              }

            <input
              type="file"
              name="icon_highlight"
              id="icon_highlight"
              onChange={(e) => handleChange('icon_highlight', e.target.files[0])}
            />
            {formErrors.icon_highlight && <span className="text-danger fs-12">{formErrors.icon_highlight}</span>}
          </div>
        </div>
        <div className="col-6">
          <button className="idle-green-btn w-100" onClick={handleSubmit}>
            {action === 'Add' ? 'Add' : 'Update'} Menu
          </button>
        </div>
      </form>
      <Loader loading={updateLoading || isLoading} />

    </div>
  );
};

export default AddMenu;
