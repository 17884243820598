import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useToken } from '../../../hooks/useToken';
import { Dropdown } from "react-bootstrap";
import { fetchStaffMerchantDetail, getStaffMerchantRole, updateStaffMerchant } from '../../../api/Merchant';
import { toast } from 'react-toastify';
import Input from 'react-phone-number-input/input'
import { useGetMerchantStaffByIdQuery, useUpdateMerchantStaffMutation } from '../../../redux/merchantApiSlice';
import Loader from '../../../SuperAdmin/DefaultComponents/Loader';

const EditStaffMerchant = ({ setStaffMerchant, handleState, setState, state, setHasMore, staffMerchant, role }) => {
    const token = useToken();
    const [showRole, setShowRole] = useState(null)
    const { data: Merchantstaffdata, isLoading, isError } = useGetMerchantStaffByIdQuery({ id: state?.update?.id })
    const [UpdateMerchantStaffTrigger, { data: UpdateMerchantStaffData, isLoading: updateStaffloading, isError: updatestaffLoading }] = useUpdateMerchantStaffMutation()
    const [data, setData] = useState({
        first_name: "",
        email: "",
        role_id: "",
        phonenumber: "",
        bot_key: "",
    });
    const [error, setError] = useState({});
    // const [role, setRole] = useState([]);



    const Validation = () => {
        let error = {};
        if (!requiredValue(data.first_name)) {
            error.first_name = "first name is required";
        }
        if (!requiredValue(data?.role_id)) {
            error.role_id = "Please select a role";
        }
        if (!requiredValue(data?.phonenumber)) {
            error.phonenumber = "Please enter a valid phone number";
        }
        if (Object.keys(error).length < 1) {
            return false;
        }
        return error;
    };

    const handleChange = (name, value) => {
        let newData = { ...data };
        if (name === "first_name") {
            newData[name] = value;
        } else {
            newData[name] = value?.trim();
        }
        setData(newData);
    };

    const handleData = async (e) => {
        e.preventDefault();
        setError({});
        let errors = Validation();
        if (!errors) {
            setState((prev) => ({
                ...prev,
                loading: true,
            }));
            let name = data?.first_name?.split(" ");
            let firstName = name[0]
            let lastName = name?.slice(1)?.join(" ")
            let finalData = { ...data, phonenumber: (data?.phonenumber.toString()).slice(2), first_name: firstName, last_name: lastName }

            finalData = {
                ...finalData,
                employeeId: state.update.id
            }

            try {

                const { data:response } = await UpdateMerchantStaffTrigger(finalData);

                if (response?.result) {
                    toast.success(`You have updated ${data?.first_name}`)
                    handleState("edit", false);
                    // setHasMore(true);
                    setState((prev) => ({
                        ...prev,
                        loading: false,
                    }));
                }


                // if (!state?.filterParam || state?.filterParam == response?.data?.role_id) {
                //     let newData = staffMerchant?.map((item) => {
                //         if (item?.id === response?.data?.id) {
                //             return { ...item, ...response?.data };
                //         }
                //         return item;
                //     });
                //     setStaffMerchant(newData)
                // } else {
                //     let newData = staffMerchant?.filter((item) => item?.id != response?.data?.id);
                //     setStaffMerchant(newData)
                // }
                // dispatch(tokenAction.showToaster({ status: true, message: `You have updated ${data?.first_name}`, open: true }));

            } catch (error) {
                if (typeof error?.response?.data?.message == "object") {
                    // dispatch(tokenAction.showToaster({ status: false, message: Object.values(error?.response?.data?.message)[0], open: true }));
                    toast.error(Object.values(error?.response?.data?.message)[0])
                } else {
                    // dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message, open: true }));
                    toast.error(error?.response?.data?.message);
                }
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
            }
        } else {
            setError(errors);
        }
    };

    const requiredValue = (value) => {
        if (!value) {
            return false;
        }
        return true;
    };

    // const fetchRoles = async () => {
    //     setState((prev) => ({
    //         ...prev,
    //         loading: true,
    //     }));
    //     const param = {
    //         isAll: true,
    //     };
    //     try {
    //         const response = await getStaffMerchantRole(token, param);
    //         setRole(response?.allList);
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     } catch (error) {
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     }
    // };

    // const fetchData = async () => {
    //     try {
    //         let resp = await fetchStaffMerchantDetail(token, state?.update?.id)
    //         if (resp?.result == true) {
    //             setData((prev) => ({
    //                 ...prev,
    //                 first_name: `${resp?.data?.first_name} ${resp?.data?.last_name}`,
    //                 email: resp?.data?.email,
    //                 phonenumber: `+1${resp?.data?.phonenumber}`,
    //                 role_id: resp?.data?.role_id?.toString(),
    //             }));
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    useEffect(() => {
        if (Merchantstaffdata)
            setData({
                ...data,
                first_name: `${Merchantstaffdata?.data?.first_name} ${Merchantstaffdata?.data?.last_name}`,
                email: Merchantstaffdata?.data?.email,
                phonenumber: `+1${Merchantstaffdata?.data?.phonenumber}`,
                role_id: Merchantstaffdata?.data?.role_id?.toString(),
            })
        handleUserRole(Merchantstaffdata?.data?.role_id)
        // fetchData();
        // fetchRoles();
    }, [Merchantstaffdata]);

    const handleSelectRole = (roleId, roleName) => {
        let newData = { ...data };
        newData["role_id"] = roleId;
        setData(newData);
        setShowRole(roleName);
        setError(error?.role_id == "");
    }

    // useEffect(() => {
    //     if (role) {
    //         handleUserRole();
    //     }
    // }, [role])

    function handleUserRole(role_id) {
        if (role?.length > 0) {
            let urole = role?.find((item) => item?.role_id == role_id);
            setShowRole(urole?.role_name);
        }
    }
    return (
        <>
            <div className="container-xl">
                <div className="row">
                    <div className="col-12">
                        <label className="">FIRST AND LAST NAME</label>
                        <input
                            className="custom-date-picker"
                            type="text"
                            value={data.first_name?.trim()}
                            placeholder="Enter first and last name"
                            name="first_name"
                            onChange={(e) => handleChange("first_name", e.target.value)}

                            required
                        />
                        {error.first_name && (
                            <span className="error text-danger fs-12">
                                {error.first_name}
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="">EMAIL ADDRESS</label>
                        <input
                            className="custom-date-picker"
                            type="text"
                            name="email"
                            placeholder="Enter email address"
                            value={data.email}

                            // onChange={(e) => handleChange("email", e)}

                            inputMode="numeric"
                            required
                            readOnly
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-6">
                        <label className="">Role Type</label>
                        {/* <select
              className="custom-date-picker"
              name="role_id"
              onChange={handleChange}
              value={data.role_id}
            >
              <option value="">Role Type</option>
              {role &&
                role?.map((itm) => (
                  <option key={itm.role_id} value={itm.role_id}>
                    {itm.role_name}
                  </option>
                ))}
            </select> */}
                        <div className="d-flex add-emp">

                            <Dropdown>
                                <Dropdown.Toggle style={{ textTransform: 'capitalize' }} variant="success" id="dropdown-basic-1">
                                    {showRole ?? "Select Role"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {role?.map((itm, ind) => (

                                        <Dropdown.Item style={{ textTransform: 'capitalize' }} href="javascript:void(0)" onClick={() => handleSelectRole(itm.role_id, itm?.role_name)} key={ind}>{itm.role_name}
                                            {/* <span>Placeholder text</span> */}
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {error.role_id && (
                            <span className="error text-danger fs-12">{error.role_id}</span>
                        )}
                    </div>
                    <div className="col-6">
                        <label className="">PHONE</label>
                        {/* <input
              className="custom-date-picker"
              type="text"
              maxLength="10"
              value={data.phonenumber}
              name="phonenumber"
              placeholder="Phone"
              onChange={handleChange}
              required
              /> */}
                        <Input
                            maxLength="14"
                            className="custom-date-picker"
                            placeholder="Phone"
                            country="US"
                            value={data?.phonenumber}
                            onChange={(e) => handleChange("phonenumber", e)}
                        // onChange={handleChange}
                        />
                        {error.phonenumber && (
                            <span className="error text-danger fs-12">
                                {error.phonenumber}
                            </span>
                        )}
                    </div>
                </div>
                <input type="text" name="bot_key" onChange={(e) => handleChange("bot_key", e.target.value)} style={{ display: "none !important" }} hidden />
                <div className="row">
                    <div className="col-6">
                        <button
                            className="cancel-btn "
                            onClick={() => {
                                handleState("edit", false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        <button className="add-btn" onClick={handleData}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <Loader loading={isLoading || updateStaffloading} />
        </>
    )
}

export default EditStaffMerchant
