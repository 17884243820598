import { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles for the date picker
import "../Tabs/MerchantOnboard.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import baseUrl from "../../../baseUrl";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import Loader from "../../../DefaultComponent/Loader";
import { useToken } from "../../../hooks/useToken";
import BreadCrumbs from "../../../components/Breadcrumbs";
import BackBtn from "../../../DefaultComponent/BackBtn";
import { dataIndustries } from "./Industries";
import { useSelector } from "react-redux";

const EditMerchant = () => {
  const { deviceId } = useSelector(state => state.deviceId);

  // var merchant_id = "";
  const token = useToken();
  const [ids, setId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location?.state?.data, "statessssssssss");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [website, setUrl] = useState("https://www.abc.com");

  const [errors, setErrors] = useState({});
  const [showResultModal, setShowResultModal] = useState(false);

  const [per_address, setPer_address] = useState(
    "4069 Lake Drive Southeast Grand Rapids MI USA"
  );
  const [per_city, setPer_city] = useState("Grand Rapids");
  const [per_state, setPer_state] = useState("Michigan");
  const [businessCountry, setBusinessCountry] = useState("US");
  const [per_zip, setPer_zip] = useState("49546");
  // const [established_date, setEstablished_date] = useState("49546");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    date_of_birth: "",
    established_date: "",
    country: "",
    address: "",
    per_city: "",
    per_state: "",
    zip: "",
    business_country: "",
    business_industry: "",
    business_name: "",
    business_structure: "",
    website: "",
    processing_environment: "",
    avgTicket: "",
    annualCCSales: "",
    b_title: "CEO",
    business_structure: "",
    dba: "",
    ein: "",
    MerchantApplicationId: "",
    ssn: "",
    // routing_number: "",
    // account_number: "",
    // bank_name: "Test Bank",
    ownership_percent: "",

    ownershiptype: "CORPORATION",
    // business_structure: '',
    legal_b2c: "",
    legal_b2b: "",
    legal_cnpinternet: "",
    legal_cnpphoneemail: "",
    legal_percentdeliveredover30days: "",
    legal_percentdeliveredsameday: "",
    percentdelievered7days: "",
    percentdelivered14days: "",
    legal_cp: "",
    legal_productssold: "",
    file: null,
    // Estore_Slug: "",
    business_email: "",
    // account_type: "",
    account_manager_name: "",
    commission_type: "",
    personal_email: "",

    Industry_name: "",
  });
  const [bot, setBot] = useState("");
  const handleBotChange = (event) => {
    setBot(event.target.value);
  }

  useEffect(() => {
    const handleEdit = async (row) => {
      try {
        const { data } = await axios.get(
          `${baseUrl}/api/v1/admin/merchant/show/${location?.state?.data}`,
          {
            headers: {
              Authorization: token,
              'X-Device-Fingerprint': deviceId,

            },
          }
        );

        setFormData({

          merchant_id: data.data?.merchantData?.id,
          ownership_percent: data.data?.merchantData?.ownership_percent,
          first_name: data.data?.merchantData?.first_name,
          last_name: data?.data?.merchantData?.last_name,

          phone: data.data?.merchantData?.phone_no,

          business_name: data.data?.merchantData?.business_name,
          business_email: data.data?.merchantData?.business_email,

          website: data.data?.merchantData?.website,
          annualCCSales: data.data?.merchantData?.annualCCSales,
          legal_b2b: data.data?.merchantData?.legal_b2b,
          legal_b2c: data.data?.merchantData?.legal_b2c,
          avgTicket: data.data?.merchantData?.avgTicket,
          business_structure: data.data?.merchantData?.business_structure,
          processing_environment: data.data?.merchantData?.processing_environment,
          legal_productssold: data.data?.merchantData?.legal_productssold,
          date_of_birth: data.data?.merchantData?.date_of_birth,
          established_date: data.data?.merchantData?.established_date,
          address: data.data?.merchantData?.per_address,
          city: data.data?.merchantData?.per_city,
          state: data.data?.merchantData?.per_state,
          zip: data.data?.merchantData?.per_zip,
          country: data.data?.merchantData?.country,
          ein: data.data?.merchantData?.ein,
          ssn: data.data?.merchantData?.ssn,
          email: data.data?.merchantData?.personal_email,
          b_title: data.data?.merchantData?.b_title,
          business_industry: data.data?.merchantData?.Industry,

        });
      } catch (error) {
        console.error("Error:", error);
      }
    };
    handleEdit();
  }, []);
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // fetchIndustries();
    setIndustries(dataIndustries);
  }, []);

  const validateRoutingNumber = (routingNumber) => {
    const digits = routingNumber.replace(/\D+/g, '').split('').map(Number);
    if (digits.length !== 9) return false;

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += digits[i] * (i % 2 === 0 ? 3 : 7);
    }

    return sum % 10 === 0;
  };


  const validateAccountNumber = (accountNumber) => {
    const digits = accountNumber.replace(/\D+/g, '').split('').map(Number);
    if (digits.length < 4 || digits.length > 17) return false;
    return true;
  };

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {
        types: ["address"],
      }
    );
    autocomplete.addListener("place_changed", fillInAddress);
  }, []);

  const fillInAddress = () => {
    const place = document.getElementById("autocomplete").getPlace();
    const addressComponents = place.address_components;
    let selectedCity = "";
    let selectedState = "";

    addressComponents.forEach((component) => {
      if (component.types.includes("locality")) {
        selectedCity = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        selectedState = component.short_name;
      }
    });

    setFormData({
      ...formData,
      state: selectedState,
      city: selectedCity,
      business_country:
        place.address_components.find((comp) => comp.types.includes("country"))
          ?.short_name || "",
      zip:
        place.address_components.find((comp) =>
          comp.types.includes("postal_code")
        )?.short_name || "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    if (formData.first_name == "") {
      errors.first_name = "First name is required";
    }
    if (formData.last_name == "") {
      errors.last_name = "Last name is required";
    }
    // if (formData.pin == "") {
    //   errors.pin = "Pin is required";
    // } else if (!isValidPin(formData.pin)) {
    //   errors.pin = "Please enter a valid pin";
    // }
    if (formData.business_name == "") {
      errors.business_name = "Business name is required";
    }

    if (formData.personal_email == "") {
      errors.personal_email = "Email is required";
    }

    // business_email
    if (formData.website !== "" && !isValidUrl(formData.website)) {
      errors.website = "Please enter a valid website URL";
    }
    if (formData.business_email == "") {
      errors.business_email = "Business email is required";
    } else if (!isValidEmail(formData.business_email)) {
      errors.business_email = "Please enter a valid email";
    }
    if (
      formData.account_manager_name !== "" &&
      !isValidUrl(formData.account_manager_name)
    ) {
      errors.account_manager_name = "Please enter a account manager";
    }

    if (formData.per_address == "") {
      errors.per_address = "Business address is required";
    }
    if (formData.per_city == "") {
      errors.per_city = "City is required";
    }
    if (formData.per_state == "") {
      errors.per_state = "State is required";
    }
    if (formData?.business_country == "") {
      errors.business_country = "Country is required";
    }
    if (formData.mid && formData.mid == "") {
      errors.mid = "Merchant Application Id is required";
    }

    if (formData.commission_type == "") {
      errors.commission_type = "Commission type is required";
    }
    if (formData.cms_acct == "") {
      errors.cms_acct = "Commission account is required";
    }

    if (formData.user_name == "") {
      errors.user_name = "User name is required";
    }
    if (formData.ssn == "") {
      errors.ssn = "Security social number must be 9 characters";
    }

    if (formData.ein == "") {
      errors.ein = "Employee identification number must be 9 characters";
    }
    // if (formData.bank_name == "") {
    //   errors.bank_name = "Bank name is required";
    // }
    // if (formData.established_date == "") {
    //   errors.established_date = "Bank name is required";
    // }
    // if (formData.estore_slug == "") {
    //   errors.estore_slug = "Estore Slug is required";
    // }
    if (formData.date_of_birth == "") {
      errors.date_of_birth = "Date of birth is required";
    } else if (!isValidDate(formData.date_of_birth)) {
      errors.date_of_birth = "Please enter a valid date of birth (YYYY-MM-DD)";
    }
    if (formData.per_zip == "") {
      errors.per_zip = "Zip code is required";
    } else if (!isValidZipCode(formData.per_zip)) {
      errors.per_zip = "Please enter a valid zip code";
    }
    // if (formData.routing_number == "") {
    //   errors.routing_number = "Routing number is required";
    // } else if (!isValidRoutingNumber(formData.routing_number)) {
    //   errors.routing_number = "Please enter a valid routing number";
    // }

    // if (formData.account_number == "") {
    //   errors.account_number = "Account number is required";
    // } else if (!isValidAccountNumber(formData.account_number)) {
    //   errors.account_number = "Please enter a valid account number";
    // }

    setErrors(errors);
    return Object.keys(errors).length == 0;
    //  return Object.keys(errors).length == 0;
  };
  const isValidRoutingNumber = (routing_number) => {
    return true;
  };
  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };
  const isValidPin = (pin) => {
    return true;
  };

  const isValidEmail = (pin) => {
    return true;
  };

  const isValidUrl = (url) => {
    return true;
  };
  const isValidAccountNumber = (account_number) => {
    return true;
  };
  const isValidZipCode = (zipCode) => {
    return true;
  };

  const formatPhoneNumber = (numericValue) => {
    const cleaned = ("" + numericValue).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return numericValue;
  };

  const handleEstablishedDateChange = (date) => {
    setFormData({ ...formData, established_date: date });
  };
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    // console.log("handlePhoneChange :: name => %s ::: value => ", name, value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: value.replace(/[^0-9]/g, ""),
    }));
  };

  const formattedDate = (data) => {
    if (!data) {
      return "";
    } else {
      const momentDate = moment(data);

      if (momentDate.isValid()) {
        return momentDate.format("YYYY-MM-DD");
      } else {
        return "Invalid date";
      }
    }
  };


  const handleDOBChange = (inputDate) => {
    if (inputDate) {
      const formattedDate = moment(inputDate).format("YYYY-MM-DD");
      setDateOfBirth(formattedDate);
      setFormData((prevState) => ({ ...prevState, date_of_birth: formattedDate }));
    }
  };

  const handleIndustryChange = (e) => {
    const { name, value } = e.target;

    setSelectedIndustry(e.target.value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };


  const handleBusinessSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const { address_components } = results[0];
      let streetNumber = "";

      // Initialize variables to hold address components
      let selectedAddress = {
        per_address: address,
        per_city: "",
        per_state: "",
        business_country: "",
        per_zip: "",
      };

      address_components.forEach((component) => {
        const { types, long_name, short_name } = component;
        if (types.includes("street_number")) {
          streetNumber = short_name;
        }
        if (types.includes("locality")) {
          selectedAddress.per_city = short_name;
        } else if (types.includes("administrative_area_level_1")) {
          selectedAddress.per_state = short_name;
        } else if (types.includes("country")) {
          selectedAddress.business_country = short_name;
        } else if (types.includes("postal_code")) {
          selectedAddress.per_zip = long_name;
        } else if (types.includes("route")) {
          selectedAddress.per_address =
            short_name;
        }
      });

      setPer_address(address);
      setPer_city(selectedAddress.per_city);
      setPer_state(selectedAddress.per_state);
      setBusinessCountry(selectedAddress.business_country);
      setPer_zip(selectedAddress.per_zip);

      setFormData((prevFormData) => ({
        ...prevFormData,
        address: selectedAddress.per_address,
        city: selectedAddress.per_city,
        business_state: selectedAddress.business_state,
        business_country: selectedAddress.business_country,
        zip: selectedAddress.per_zip,
      }));
      // Fetch coordinates if needed
      const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    if (!validateForm()) {
      setButtonLoading(false);
      toast.error("Please fill out all required fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/admin/merchant/update`,
        {...formData , bot_key: bot},
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/JSON",
            'X-Device-Fingerprint': deviceId,

          },
        }
      );

      if (response.status === 200) {
        toast.success("Merchant added successfully!");
      } else {
        toast.error(response.data.message);
      }
      setButtonLoading(false);
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data) {
        console.log("Error response data:", error.response.data);
        if (error.response.data.errors) {
          const errorMessages = Object.values(error.response.data.errors).join(
            ", "
          );
          toast.error(errorMessages);
        } else if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred. Please try again later.");
        }
      }
      setButtonLoading(false);
    }
  };



  const handleUrlChange = (event) => {
    const newValue = event.target.value;

    if (newValue) {
      if (newValue == "https:/") {
        setUrl("https://");
      } else {
        if (newValue.startsWith("https://")) {
          setUrl(newValue);
        } else {
          setUrl("https://" + newValue);
        }
      }
    }
  };

  const handleUrlInputClick = () => {
    if (!website.startsWith("https://")) {
      setUrl("https://" + website);
    }
  };

  return (
    <>
      <div className="pb-2">
        <BackBtn />
      </div>
      <div className="container-xl">
        <h1 className="app-page-title">Edit Merchant</h1>
        <div className="app-card app-card-orders-table shadow-sm px-4 py-4">
          <h1 className="app-page-title">Basic Details</h1>
          <div className="step-content">
            <>
              <div className="step-pane active" id="step-1">
                <div className="container all-steps">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        First Name*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                      {errors.first_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.first_name}
                        </span>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Last Name*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        placeholder="Last Name"
                        onChange={handleChange}
                        required
                      />
                      {errors.last_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.last_name}
                        </span>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Phone Number*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formatPhoneNumber(formData.phone)}
                        onChange={handlePhoneChange}
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength="13"
                        required
                      />
                      {errors.phone && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.phone}
                        </span>
                      )}
                    </div>

                    {/* <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Password</label>
                                        <input className='custom-date-picker' type="password" name="password" placeholder='Password' onChange={handleChange} required />
                                        {errors.password && <span className="error" style={{ color: 'red' }}>{errors.password}</span>}
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Confirm Password</label>
                                        <input className='custom-date-picker' type="password" name="password" placeholder=' Confirm Password' onChange={handleChange} required />
                                        {errors.password && <span className="error" style={{ color: 'red' }}>{errors.password}</span>}
                                    </div>  */}

                    {/* <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Pin</label>
                                        <input className='custom-date-picker' type="text" name="pin" placeholder='Pin' onChange={handleChange} required />
                                        {errors.pin && <span className="error" style={{ color: 'red' }}>{errors.pin}</span>}

                                    </div>
                                    <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Mid</label>
                                        <input className='custom-date-picker' type="text" name="mid" placeholder='Mid' onChange={handleChange} required value={formData.MerchantApplicationId} />
                                        {errors.MerchantApplicationId && <span className='error' style={{ color: 'red' }}>{errors.MerchantApplicationId}</span>}

                                    </div> */}
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Ein
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="ein"
                        maxLength={9}
                        placeholder="EIN"
                        onChange={handleChange}
                        required
                        value={formData.ein}
                      />
                      {errors.ein && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.ein}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        SSN
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="ssn"
                        maxLength={9}
                        placeholder="SSN"
                        onChange={handleChange}
                        required
                        value={formData.ssn}
                      />
                      {errors.ssn && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.ssn}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Commision Type
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="commission_type"
                        placeholder="Commision Type"
                        onChange={handleChange}
                        required
                      />
                      {errors.commission_type && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.commission_type}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Commision Account
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="cms_acct"
                        placeholder="Commision Account"
                        onChange={handleChange}
                        required
                      />
                      {errors.cms_acct && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.cms_acct}
                        </span>
                      )}
                    </div>
                    {/* 
                                    <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>User-Name</label>
                                        <input className='custom-date-picker' name="user_name" type="text" placeholder='User Name'  onChange={handleChange} required />
                                        {errors.user_name && <span className='error' style={{ color: 'red' }}>{errors.user_name}</span>}

                                    </div> */}
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Email
                      </label>
                      <input
                        className="custom-date-picker"
                        name="email"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                        required
                        value={formData.email}
                      />
                      {errors.personal_email && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.personal_email}
                        </span>
                      )}
                    </div>

                    {/* <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Store-Slug</label>
                                        <input className='custom-date-picker' type="text" placeholder='Store-Slug' name="store_slug" onChange={handleChange} required />
                                        {errors.store_slug && <span className='error' style={{ color: 'red' }}>{errors.store_slug}</span>}

                                    </div>
                                    <div className='col-md-4'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Estore-Slug</label>
                                        <input className='custom-date-picker' type="text" placeholder='Estore-Slug' name="Estore_Slug" onChange={handleChange} required />
                                        {errors.Estore_Slug && <span className='error' style={{ color: 'red' }}>{errors.Estore_Slug}</span>}

                                    </div> */}
                    {/* <div className='col-md-12'>
                                        <label className='labelStyle' style={{ display: 'block' }}>Account Manager</label>
                                        <input className='custom-date-picker' type="text" placeholder='Account Manager' name="account_manager_name" value={formData.account_manager_name} onChange={handleChange} required />
                                        {errors.account_manager_name && <span className='error' style={{ color: 'red' }}>{errors.account_manager_name}</span>}

                                    </div> */}

                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Date Of Birth*
                      </label>
                      <DatePicker
                        name="dob"
                        selected={formattedDate(formData.date_of_birth)}
                        onChange={handleDOBChange}
                        className="custom-datepicker"

                        dateFormat="yyyy-MM-dd"
                        placeholderText="YYYY-MM-DD"
                      />


                      {errors.date_of_birth && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.date_of_birth}
                        </span>
                      )}
                    </div>

                    <div className="heading-container">
                      <div className="row">
                        <div className="heading-text">
                          <h4 className="section-title">Other Details</h4>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Business name
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_name"
                            value={formData.business_name}
                            placeholder="Business name"
                            onChange={handleChange}
                            required
                          />
                          {errors.business_name && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_name}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="labelStyle">Business Email</label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_email"
                            value={formData.business_email}
                            placeholder="Business email"
                            onChange={handleChange}
                          />
                          {errors.business_email && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_email}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            htmlFor="industrySelect"
                            style={{
                              display: "block",
                              width: "11.3vw",
                            }}
                          >
                            Industry
                          </label>
                          <select
                            className="custom-date-picker"
                            id="industrySelect"
                            onChange={handleIndustryChange}
                            defaultValue=""
                            value={formData.business_industry}
                            name="business_industry"
                          >
                            <option value="">Select Industry</option>
                            {industries.map((industry) => (
                              <option
                                value={industry.value}
                                key={industry.value}
                              >
                                {industry.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Website
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="website"
                            value={website}
                            placeholder="Website link"
                            onChange={handleUrlChange}
                            onClick={handleUrlInputClick}
                            required
                          />
                          {errors.website && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.website}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Ownership Percent (%)
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="ownership_percent"
                            value={formData.ownership_percent}
                            placeholder="Ownership Percent"
                            onChange={handleChange}

                            required
                          />
                          {errors.ownership_percent && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.ownership_percent}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="labelStyle">Position/Title</label>
                          <select
                            className="custom-date-picker"
                            name="b_title"
                            value={formData.b_title}
                            onChange={handleChange}
                            placeholder="Title"
                          >
                            <option value>Title</option>
                            <option value="CEO">CEO</option>
                            <option value="CFO">CFO </option>
                            <option value="COO">COO</option>
                            <option value="President">President</option>
                            <option value="Secretary">Secretary </option>
                            <option value="Treasurer">Treasurer</option>
                            <option value="Vice President">
                              Vice President
                            </option>
                          </select>
                        </div>

                        <div className="col-md-12">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Business Address
                          </label>
                          <PlacesAutocomplete
                            value={per_address}
                            onChange={(text) => {
                              setPer_address(text);
                            }}
                            onSelect={handleBusinessSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  className="custom-date-picker"
                                  {...getInputProps({
                                    placeholder: "Search owner address",
                                  })}
                                  required
                                />
                                <div>
                                  {loading ? <div>Loading...</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                      border: suggestion.active
                                        ? "1px solid #41b6e6"
                                        : "1px solid #ccc",
                                      width: "410px",
                                      marginLeft: "10px", // Add margin right
                                      marginTop: "5px",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {errors.address && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.address}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            City
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            placeholder="City"
                            name="per_city"
                            value={per_city}
                            onChange={handleChange}
                          />
                          {errors.per_city && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.per_city}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            State
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="per_state"
                            value={per_state}
                            placeholder="State"
                            onChange={handleChange}
                          />
                          {errors.per_state && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.per_state}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Country
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_country"
                            value={businessCountry}
                            placeholder="Country"
                            onChange={handleChange}
                          />

                          {errors.business_country && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_country}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Zip Code
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            placeholder="Zip Code"
                            name="per_zip"
                            value={per_zip}
                            onChange={handleChange}
                          />
                          {errors.per_zip && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.per_zip}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Established Date
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            selected={formattedDate(formData.established_date)}
                            placeholder="Established Date"
                            name="established_date"
                            value={formData.established_date}
                            onChange={handleEstablishedDateChange}
                          />
                          {errors.established_date && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.established_date}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="labelStyle" style={{ display: "block" }}>
                            Company Structure
                          </label>
                          <select
                            className="custom-date-picker"
                            name="business_structure"
                            value={formData.business_structure}
                            onChange={handleChange}
                            placeholder="Select Company Structure"
                          >
                            <option value="">Select Company Structure</option>
                            <option value="Sole Prop">Sole Prop</option>
                            <option value="LLC">LLC</option>
                            <option value="Private Corporation">Private Corporation</option>
                            <option value="Public Corporation">Public Corporation</option>
                            <option value="Non profit">Non Profit</option>
                            <option value="limited liability partnership">Limited  Liability Partnership</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label className='labelStyle' >Processing Environment
                          </label>
                          <select
                            className='custom-date-picker'
                            name="processing_environment"
                            value={formData.processing_environment}
                            onChange={handleChange}
                            placeholder='Select Processing Environment'
                          >
                            <option value="">Select Processing Environment</option>
                            <option value="cardPresent">Card Present</option>
                            <option value="ecommerce">E-Commerce</option>
                            <option value="fuel">Fuel</option>
                            <option value="moto">Mail Order/Telephone Order</option>
                            <option value="restaurant">Restaurant</option>
                            <option value="serviceStation">Service Station</option>
                            <option value="supermarket">Supermarket</option>

                          </select>
                        </div>

                        <div className="col-md-6">
                          <label className="labelStyle" style={{ display: "block" }}>
                            Products Sold
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="legal_productssold"
                            value={formData.legal_productssold}
                            placeholder="Products Sold"
                            onChange={handleChange}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="container all-steps">
                <div className="row">
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Average Ticket
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      name="avgTicket"
                      value={formData.avgTicket}
                      placeholder="($)"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Annual Credit Card Sales
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      name="annualCCSales"
                      value={formData.annualCCSales}
                      placeholder="($)"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      B2C Transactions
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="a. Cumulative to 100%"
                      name="legal_b2c"
                      value={formData.legal_b2c}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      B2B Transactions
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="a. Cumulative to 100%"
                      name="legal_b2b"
                      value={formData.legal_b2b}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Card Present
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="b. Cumulative to 100%"
                      name="legal_cp"
                      value={formData.legal_cp}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Manual Entry via Phone/Email
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="b. Cumulative to 100%"
                      name="legal_cnpphoneemail"
                      value={formData.legal_cnpphoneemail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Manual Entry via Internet
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="b. Cumulative to 100%"
                      name="legal_cnpinternet"
                      value={formData.legal_cnpinternet}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Same-Day Delivery
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="legal_percentdeliveredsameday"
                      value={formData.legal_percentdeliveredsameday}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      className="labelStyle"
                      style={{ display: "block" }}
                      name="legal_percentdeliveredover30days"
                    >
                      Deferred Delivery {">"} 7 Days
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="percentdelievered7days"
                      value={formData.percentdelievered7days}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Deferred Delivery {">"} 14 Days
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="percentdelivered14days"
                      value={formData.percentdelivered14days}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Deferred Delivery {">"} 30 Days
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="legal_percentdeliveredover30days"
                      value={formData.legal_percentdeliveredover30days}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Routing number*
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="Routing number"
                      name="routing_number"
                      value={formData.routing_number}
                      onChange={(e) => {
                        const routingNumber = e.target.value;
                        if (!validateRoutingNumber(routingNumber)) {
                          errors.routing_number = "Routing Number is not a valid US Bank ABA Number";
                        } else {
                          delete errors.routing_number;
                        }
                        handleChange(e);
                      }}
                      required
                    />

                    {errors.routing_number && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.routing_number}
                      </span>
                    )}
                  </div> */}
                  {/* <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Bank Name
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="Bank name"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={handleChange}
                    />
                    {errors.bank_name && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.bank_name}
                      </span>
                    )}
                  </div> */}

                  {/* <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Account Number*
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="Account Number"
                      name="account_number"
                      value={formData.account_number}
                      onChange={(e) => {
                        const accountNumber = e.target.value;
                        if (!validateAccountNumber(accountNumber)) {
                          errors.account_number = "Account Number is not valid";
                        } else {
                          delete errors.account_number;
                        }
                        handleChange(e);
                      }}
                      required
                    />

                    {errors.account_number && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.account_number}
                      </span>
                    )}
                  </div> */}
                  {/* <div className="col-md-4">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Account Type
                    </label>
                    <select
                      className="custom-select"
                      name="account_type"
                      value={formData.account_type}
                      onChange={handleChange}
                      placeholder="Select Bank Account Type"
                    >
                      <option value="">Select Bank Account Type</option>
                      <option value="8">Personal checking account</option>
                      <option value="9">Personal saving account</option>
                      <option value="10">Corporate checking account</option>
                      <option value="11">Corporate saving account</option>
                    </select>
                  </div> */}
                  {/* <div className='row d-flex  justify-content-center align-align-items-center my-4'>
                                <DragNDrop onFilesSelected={setFiles} width=" auto" height='auto' />
                                </div> */}
                  <input type="text" name="bot_key" onChange={handleBotChange} style={{ display: "none !important" }} hidden />
                  <div className="row">
                    <div className="col  d-flex justify-content-center ">
                      <div className="row-md-5">

                        <button onClick={handleSubmit} className="btn dark-purp">
                          {buttonLoading ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            'Submit'
                          )}
                        </button>
                      </div>

                    </div>
                  </div>
                  <Modal
                    show={showResultModal}
                    onHide={() => setShowResultModal(false)}
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body style={{ textAlign: "center" }}>
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="59"
                          height="59"
                          rx="29.5"
                          stroke="#31B379"
                        />
                        <g clipPath="url(#clip0_2659_5018)">
                          <path
                            d="M41.1778 22.248C40.7483 21.8184 40.0518 21.8184 39.6222 22.248L26.4435 35.4268L21.3778 30.3611C20.9483 29.9315 20.2518 29.9316 19.8222 30.3611C19.3926 30.7907 19.3926 31.4871 19.8222 31.9167L25.6657 37.7601C26.0951 38.1897 26.7921 38.1894 27.2213 37.7601L41.1778 23.8036C41.6074 23.3741 41.6074 22.6776 41.1778 22.248Z"
                            fill="#31B379"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2659_5018">
                            <rect
                              width="22"
                              height="22"
                              fill="white"
                              transform="translate(19.5 19.0039)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <h2>
                        Merchant details updated <br /> successfully
                      </h2>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <Loader loading={loading} />
    </>
  );
};

export default EditMerchant;
