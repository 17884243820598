import React from 'react'
import { useGetSidebarQuery } from '../../redux/apiSlice';
import CustomerDev from '../../DefaultComponent/CustomerDev';
import CustomerProd from '../../DefaultComponent/CustomerProd';

const CustomersList = () => {
    const env = process.env.REACT_APP_ENV || 'development';
    const { data: userData, isLoading, isError } = useGetSidebarQuery();
    const fractalpayClientKey = userData?.data?.publickey || null;

    return (
        <div className='container-xl'>
            <div className="row">
                <div className="col-lg-1">
                    <h4 className="app-page-title">Customer</h4>
                </div>
                <div className="col-lg-11 d-md-flex justify-content-end">
                    <div className="d-flex justify-content-start align-items-start gap-2 flex-wrap ">
                        {env === 'development' ?
                            (<CustomerDev componentType='addCustomer' fractalpayClientKey={fractalpayClientKey} />) :
                            (<CustomerProd componentType='addCustomer' fractalpayClientKey={fractalpayClientKey} />)
                        }
                    </div>
                </div>
            </div>
            {env === 'development' ?
                (<CustomerDev componentType='customerList' fractalpayClientKey={fractalpayClientKey} />) :
                (<CustomerProd componentType='customerList' fractalpayClientKey={fractalpayClientKey} />)
            }
        </div>
    )
}

export default CustomersList
