import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import Dashboard from "../pages/Dashboard/Page/Dashboard";
import MerchantOnboard from "../pages/Merchant/Tabs/MerchantOnboard";
import ForgotManagerPassword from "../Layout/ForgotManagerPassword";
import Footer from "./Footer";
import AllVerifiedMerchants from "../pages/Merchant/Page/AllVerifiedMerchants";
import EditMerchant from "../pages/Merchant/Form/EditMerchant";
import CustomerTable from "../pages/Customer/Page/CustomerTable";
import WidgetDocs from "../pages/Docs/WidgetDocs";
import Payments from "../pages/Payments/Page/Payments";
import MerchantDetail from "../pages/Merchant/Details/MerchantDetail";
import Profile from "../pages/Profile/Page/Profile";
import ActiveLog from "../pages/Logs/Page/ActiveLog";
import ApiKeys from "../pages/Docs/ApiKeys";
import Employee from "../pages/Employee/Page/Employee.main";
import CustomerDetails from "../pages/Customer/Details/CustomerDetails";
import PaymentDetails from "../pages/Payments/Detail/PaymentDetails";
import Permissions from "../pages/Permissions/Page/Permissions";
import Roles from "../pages/Roles/Page/Roles";
import AccountManager from "../pages/Account Manager/Page/AccountManager";
import EditManager from "../pages/Account Manager/Form/EditManager";
import AddManager from "../pages/Account Manager/Form/AddManager";
import "../pages/Docs/Docs.css";
import WebhookUrl from "../pages/Docs/WebhookUrl";

const ClientRoutes = () => {

    return (
        <div className=" ">
            <Sidebar />
            <div className="app-wrapper">
                <div className="app-content ">
                    <div className="container-xl">
                        <div className="white-box-main">
                            <div className="" id="style-4">
                                <Routes>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="/admin-manager" element={<AccountManager />} />
                                    <Route
                                        path="/merchant-onboarding"
                                        element={<MerchantOnboard />}
                                    />
                                    <Route path="/edit-manager" element={<EditManager />} />
                                    <Route path="/edit-merchant" element={<EditMerchant />} />
                                    <Route path="/add-manager" element={<AddManager />} />
                                    <Route
                                        path="/forgot-password"
                                        element={<ForgotManagerPassword />} //device id not added
                                    />
                                    <Route path="/merchants" element={<AllVerifiedMerchants />} />
                                    <Route path="/customers" element={<CustomerTable />} />
                                    {/* <Route path="/roles" element={<Roles />} /> */}
                                    <Route path="/activeLog" element={<ActiveLog />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="/employee" element={<Employee />} />
                                    <Route path="/transactions" element={<Payments />} />
                                    <Route
                                        path="/transactions/:id"
                                        element={<PaymentDetails />}
                                    />
                                    <Route path="/permissions/:role" element={<Permissions />} />
                                    <Route path="/api-keys" element={<ApiKeys />} />
                                    <Route path="/api-keys/webhook-url" element={<WebhookUrl />} />
                                    <Route
                                        path="/merchants/:id/:flag"
                                        element={<MerchantDetail />}
                                    />
                                    <Route
                                        path="/customers/:id"
                                        element={<CustomerDetails />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ClientRoutes;
