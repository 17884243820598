import axios from "axios";
import baseUrl from "../../baseUrl";
import { store } from '../../redux/store';

const getTokenFromState = () => {
    const state = store.getState();
    return state.deviceId.deviceId;
};
const getclientlist = `${baseUrl}/api/v1/super-admin/client/client-list`
const getmerchantlist = `${baseUrl}/api/v1/super-admin/merchant/merchant_list`
const getpaymentvia = `${baseUrl}/api/v1/super-admin/payment-via/get-list`
const gettypeofterminal = `${baseUrl}/api/v1/super-admin/terminal/get-list`
const getpaymenttype = `${baseUrl}/api/v1/super-admin/payment-type/get-list`
const getcardtype = `${baseUrl}/api/v1/super-admin/payment-type/card-type`
const createrecord = `${baseUrl}/api/v1/super-admin/transaction/create-transaction`
const getstatuslist = `${baseUrl}/api/v1/super-admin/transaction/get-status-list`

export const GetClientList = async (token) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        getclientlist,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response.data.data;
};

export const GetMerchantList = async (token, id) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        `${getmerchantlist}/${id}`,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response.data.data;
};

export const GetPaymentvia = async (token) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        getpaymentvia,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response?.data?.data;
};

export const GetTypeofTerminal = async (token) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        gettypeofterminal,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response?.data?.data;
};

export const GetPaymenttype = async (token) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        getpaymenttype,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response?.data?.data;
};

export const GetCardtype = async (token) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        getcardtype,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response?.data?.data;
};

export const GetStausList = async (token) => {
    let deviceId = getTokenFromState()

    const response = await axios.get(
        getstatuslist,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response?.data?.data;
};

export const CreateTransactionRecord = async (token, body) => {
    let deviceId = getTokenFromState()

    const response = await axios.post(
        createrecord, body,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );

    if (!response?.data?.result) {
        throw new Error(response?.data?.message);
    }
    return response?.data;
};