import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../../baseUrl";
import { useSelector } from "react-redux";

const AddManager = () => {
  const navigate = useNavigate();
  const { deviceId } = useSelector(state => state.deviceId);

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    cnf_password: "",
  });
  const [fieldErrors, setFieldErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    cnf_password: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    if (fieldErrors[name]) {
      setFieldErrors({ ...fieldErrors, [name]: false });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formIsValid = true;
      const updatedFieldErrors = { ...fieldErrors };

      Object.keys(userData).forEach((key) => {
        if (!userData[key].trim()) {
          updatedFieldErrors[key] = true;
          formIsValid = false;
        } else {
          updatedFieldErrors[key] = false;
        }
      });

      if (!formIsValid) {
        setFieldErrors(updatedFieldErrors);
        toast.error("All Fields Required");
        return;
      }
      // if(!userData.first_name.trim()||!userData.last_name.trim()||!userData.email.trim()||!userData.password.trim()||!userData.cnf_password){
      //    toast.error('All Fields Required');
      //    return;
      // }
      if (userData.cnf_password != userData.password) {
        toast.error("Password Not Matched");
        return;
      }
      const user = localStorage.getItem("token");
      const token = JSON.parse(user);
      const { data } = await axios.post(
        `${baseUrl}/api/v1/admin/admin-manager/add`,
        userData,
        {
          headers: {
            Authorization: token,
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
      if (data.result == true) {
        toast.success(`${data?.message}`);
        navigate("/admin-manager");
      }

  
    } catch (error) {
      toast.error(error?.response.data.message);

      console.log(error?.response.data.message);
    }
  };

  return (
    <div className="container-xl">
      <h1 className="app-page-title">Add member</h1>

      <div className="">
        <form>
          <div className="row">
            <div className=" mb-3 col-md-6 ">
              <label className="sr-only" htmlFor="signin-email">
                First Name
              </label>
              <input
                id="first_name"
                name="first_name"
                type="text"
                className="form-control border-2 border "
                placeholder="First name "
                value={userData.first_name}
                onChange={handleChange}
              />
              {fieldErrors.first_name && (
                <small className="text-danger">First name is required</small>
              )}
            </div>
            <div className=" mb-3 col-md-6 ">
              <label className="sr-only" htmlFor="last_name">
                Last Name
              </label>
              <input
                id="last_name"
                name="last_name"
                type="text"
                className="form-control border-2 border "
                placeholder="Last Name "
                value={userData.last_name}
                onChange={handleChange}
              />
              {fieldErrors.last_name && (
                <small className="text-danger">Last name is required</small>
              )}
            </div>
          </div>
          <div className="row">
            <div className=" mb-3 col-md-6 ">
              <label className="sr-only" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="form-control border-2 border "
                placeholder="Email "
                value={userData.email}
                onChange={handleChange}
              />
              {fieldErrors.email && (
                <small className="text-danger">Email is required</small>
              )}
            </div>
            <div className=" mb-3 col-md-6 ">
              <label className="sr-only" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                className="form-control border-2 border "
                placeholder="Password "
                value={userData.password}
                onChange={handleChange}
              />
              {fieldErrors.password && (
                <small className="text-danger">Password is required</small>
              )}
            </div>
          </div>
          <div className="row">
            <div className=" mb-3 col-md-6 ">
              <label className="sr-only" htmlFor="cnf_password">
                Confirm Password
              </label>
              <input
                id="cnf_password"
                name="cnf_password"
                type="password"
                className="form-control border-2 border "
                placeholder="Confirm Password "
                value={userData.cnf_password}
                onChange={handleChange}
              />
              {fieldErrors.cnf_password && (
                <small className="text-danger">
                  Confirm Password is required
                </small>
              )}
            </div>
          </div>
          <button className="btn app-btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddManager;
