export const CheckDomain = () => {
    let domain = window?.location?.origin
    let origindomain = process.env.REACT_APP_ORIGIN_URL
    // console.log("Current Domain :",domain,"Env Domain : ",origindomain, domain === origindomain, "dom");

    if (domain === origindomain) {
        return true
    } else {
        return false
    }
}