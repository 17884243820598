import React, { useCallback, useEffect, useRef, useState } from 'react'
import Loader from '../../../DefaultComponents/Loader';
import axios from 'axios';
import baseUrl from '../../../../baseUrl';
import { Modal, Table } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaginationPg from "../../../DefaultComponents/PaginationPg";
import { debounce } from 'lodash';
import { useRefToken } from '../../../hooks/useToken';
import View from "../../../../assets/images/View.svg";
import Fiserv from "../../../../assets/images/fiserv.svg";
import FisercDoc from "../../../../assets/images/fiservdoc.svg";
import Edit from "../../../../assets/images/Edit.svg";
import Transaction from "../../../../assets/images/Transaction.svg";
import Download from "../../../../assets/images/Download.svg";
import DownloadSuperMerchantCSV from '../Form/DownloadSuperMerchantCSV';
import { useGetMerchantListByClientIdQuery, useGetSidebarQuerySuperAdmin, useLazyApproveMerchantByIdQuery } from '../../../../redux/superadminApiSlice';
import { useSelector } from 'react-redux';

const ClientMerchant = () => {
    const { deviceId } = useSelector(state => state.deviceId);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const refToken = useRefToken();
    const { clientId } = useParams();
    const [clientMerchantList, setClientMerchantList] = useState([]);
    const observer = useRef();
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [state, setState] = useState({
        searchInput: "",
        download: false,
        loading: false,
        row: {},
    });
    const { data: merchantList, isLoading: merchantListLoading, isError: merchantListError, isFetching: merchantListFetching } = useGetMerchantListByClientIdQuery({ page: page, search: searchValue, clientId: clientId }, {
        skip: !hasMore && page !== 1
    });
    const { data: userData, isLoading, isError } = useGetSidebarQuerySuperAdmin();
    const [approveMerchantTrigger, { isFetching: approveMerchantFetching }] = useLazyApproveMerchantByIdQuery();
    let role_id = userData?.data?.staff_roll_type;

    // Last element ref for intersection observer
    const lastCustomerRef = useCallback((node) => {
        if (merchantListLoading || merchantListFetching || !hasMore) return; // Don't observe if loading or no more data
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading && !merchantListFetching && !merchantListLoading) {
                setPage(page + 1);
            }
        }, {
            root: null,
            rootMargin: '20px', // Load a bit before reaching the end
            threshold: 0.1 // Trigger when even 10% of the element is visible
        });

        if (node) observer.current.observe(node);
    }, [merchantListLoading, merchantListFetching, hasMore, page]);

    // Cleanup observer on component unmount
    useEffect(() => {
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);
    // Update client merchant list when new data is fetched
    useEffect(() => {
        if (merchantList?.data) {
            if (page === 1) {
                setClientMerchantList(merchantList.data);
            } else {
                setClientMerchantList(prev => {
                    // More strict duplicate checking
                    const existingIds = new Set(prev.map(item => item.id));
                    const newMerchants = merchantList.data.filter(
                        newItem => !existingIds.has(newItem.id)
                    );
                    return [...prev, ...newMerchants];
                });
            }
            // Only set hasMore if we received less data than expected
            setHasMore(merchantList?.totalPage >= page);

        }
    }, [merchantList?.data, page]);


    const handleTransactions = (id) => {
        navigate(`/admin/clients/${clientId}/${id}/transactions`);
    }

    const verifiedMerchant = async (item) => {
        if (item.isApproved) {
            toast.error("Merchant is already verified.");
        } else {
            try {
                const response = await approveMerchantTrigger(item?.encencodeId).unwrap();
                if (response.status === 200) {
                    toast.success("Merchant has been verified successfully.");
                } else {
                    toast.error("Merchant is not valid. Please try again.");
                }
            } catch (error) {
                console.log("error", error);
                if (error.response && error.response.data) {
                    if (error.response.data.errors) {
                        const errorMessages = Object.values(error.response.data.errors).join(
                            ", "
                        );
                        toast.error(errorMessages);
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error("An unexpected error occurred. Please try again later.");
                    }
                } else {
                    toast.error("An unexpected error occurred. Please try again later.");
                }
            }
        }
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return `${month}.${day}.${year}`;
    };

    const handleState = (item, value) => {
        setState((prev) => ({
            ...prev,
            [item]: value,
        }));
    };

    const handleMerchantDetail = (id) => {
        navigate(`/admin/clients/${clientId}/${id}`);
    };

    const handleDownload = (row) => {
        handleState("download", true);
        handleState("row", row);
    }

    const handleEdit = (id) => {
        navigate(`/admin/clients/${clientId}/${id}/edit`);
    }

    const handleFiserv = async (event, id) => {
        event.stopPropagation();
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const response = await axios.get(
                `${baseUrl}/api/v1/super-admin/merchant/create-pdf/${id}`,
                {
                    headers: {
                        Authorization: refToken,
                        'X-Device-Fingerprint': deviceId,

                    },
                }
            );

            if (response?.data) {
                toast.success(response?.data?.message)
                setState((prev) => ({ ...prev, loading: false }));
            }

        }
        catch (error) {
            toast.error(error?.response?.data?.message)
            console.log("Error:", error);
            setState((prev) => ({ ...prev, loading: false }));
        }
    }
    const handleFiservSubmit = async (event, guid) => {
        event.stopPropagation();
        if (!guid) {
            toast.error("GUID is missing or invalid.");
            return;
        }
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const response = await axios.get(
                `${baseUrl}/Submit_complete_mpa/${guid}`,
                {
                    headers: {
                        Authorization: refToken,
                        'X-Device-Fingerprint': deviceId,
                    },
                }
            );
            console.log("response", response);

            if (response?.data?.result) {
                toast.success(response?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred.");
            console.error("Error in handleFiservSubmit:", error);
        } finally {
            setState((prev) => ({ ...prev, loading: false }));
        }
    };


    let str = '';
    const handleRow = (item, value, e) => {
        e.stopPropagation();
        str = value;
        console.log(str, "str");
        if (str == "download") {
            handleDownload(item)
        } else if (str == "view") {
            handleMerchantDetail(item.id)
        } else if (str == "edit") {
            handleEdit(item.id)
        } else if (str == "merchant") {
            verifiedMerchant(item)
        } else if (str == "transaction") {
            handleTransactions(item.guid)
        }
    }

    const GetOnboardingstatus = (status) => {
        let Status = status?.replace(/_/g, ' ') || null
        return Status
        // switch (status) {
        //     case "pending_signatures":
        //         return "Pending Signature"
        //     case "pending_approval":
        //         return "Pending Approval"
        //     case "approved":
        //         return "Approved"
        //     case "active":
        //         return "Active"
        //     case "boarded":
        //         return "Boarded"
        //     default:
        //         return null
        // }
    }


    // Create a memoized debounced search function
    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchValue(value);
            setPage(1);
            setClientMerchantList([]);
            setHasMore(true);
        }, 500),
        []
    );

    // Handle input change immediately
    const handleSearchInput = (e) => {
        const value = e.target.value;
        setState((prev) => ({ ...prev, searchInput: value })); // Update input immediately
        debouncedSearch(value); // Debounce the actual search
    };

    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-12 col-md-4">
                    <p className="heading">Client Merchants</p>
                </div>
                <div className="col-12  col-md-8">
                    <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
                        <div className="search-box">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7372_11113)">
                                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7372_11113">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchInput}
                                value={state.searchInput}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="app-card-body  overflow-x-auto">
                <div className="date-picker-container overflow-x-auto scroll-tbl">
                    <Table className="table">
                        <thead>
                            <tr>
                                <th align="right">Join Date</th>
                                <th align="right">Merchant Name</th>
                                <th align="right">Email</th>
                                <th align="right">Phone</th>
                                {/* <th align="right">Status</th> */}
                                <th align="right">Onboarding Status</th>
                                <th align="right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientMerchantList?.length > 0 && (
                                clientMerchantList?.map((item, index) => (
                                    <tr
                                        key={item.guid}
                                        className="table-hover cursor-pointer"
                                        ref={index === clientMerchantList.length - 1 ? lastCustomerRef : null}
                                        onClick={(role_id == 133 || role_id === 280 || role_id === 281 || role_id === 282) ? (e) => handleRow(item, "view", e) : null}
                                    >
                                        <td>{formatDate(item.join_date)}</td>
                                        {/* <td>{`${item.first_name} ${item.last_name}`}</td> */}
                                        <td>{item.businessname}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phonenumber}</td>
                                        {/* <td>
                                            {item.isApproved ? (
                                                <div className="active-btn">Active</div>
                                            ) : (
                                                <div className="active-btn">Inactive</div>
                                            )}
                                        </td> */}
                                        <td>
                                            {
                                                item?.internal_status && (
                                                    <div className="active-btn" style={{ textTransform: 'capitalize' }} >{GetOnboardingstatus(item?.internal_status)}</div>
                                                )
                                            }
                                        </td>
                                        <td align='start' style={{ width: '24%' }}>
                                            <div className='button-hover'>
                                                {
                                                    (role_id === 133 || role_id === 280 || role_id === 281 || role_id === 282 || role_id === 283 || role_id === 308) && (
                                                        <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                                                            <img
                                                                src={View}
                                                                alt="view"
                                                                onClick={(e) => handleRow(item, "view", e)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (role_id === 133 || role_id === 280 || role_id === 281) && (
                                                        <>
                                                            <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                                <img
                                                                    src={Edit}
                                                                    alt="Edit"
                                                                    style={{ marginLeft: "6px" }}
                                                                    onClick={(e) => handleRow(item, "edit", e)}
                                                                />
                                                            </div>
                                                            <div className="tooltip-toggle" aria-label="Upload" tabIndex="0">
                                                                <img
                                                                    src={Download}
                                                                    alt="Download"
                                                                    style={{ marginLeft: "6px" }}
                                                                    onClick={(e) => handleRow(item, "download", e)}
                                                                />
                                                            </div>
                                                            <div className="tooltip-toggle" aria-label="Verify" tabIndex="0">
                                                                <i
                                                                    className="fas fa-user-check"
                                                                    style={{ marginLeft: "6px" }}
                                                                    onClick={(e) => handleRow(item, "merchant", e)}
                                                                ></i>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (role_id === 133 || role_id === 280 || role_id === 281) && (
                                                        <>
                                                            <div className="tooltip-toggle" aria-label="Resend MPA Email" tabIndex="0">
                                                                <img
                                                                    src={FisercDoc}
                                                                    alt="Fiserv"
                                                                    onClick={(e) => handleFiserv(e, item.id)}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                 }
                                                {
                                                    (role_id === 133 || role_id === 280 || role_id === 281) && item?.internal_status === "approved" && (
                                                        <div className="tooltip-toggle" aria-label="Fiserv Submit" tabIndex="0">
                                                            <img
                                                                src={Fiserv}
                                                                alt="Fiserv Submit"
                                                                onClick={(e) => handleFiservSubmit(e, item.guid)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <div className="tooltip-toggle" aria-label="Transactions" tabIndex="0">
                                                    <img
                                                        src={Transaction}
                                                        alt="Transaction"
                                                        style={{ marginLeft: "6px" }}
                                                        onClick={(e) => handleRow(item, "transaction", e)}
                                                    />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                ))
                            )}
                            {!isLoading && clientMerchantList.length === 0 && (
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        No merchants available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

            <Loader loading={merchantListLoading || merchantListFetching || approveMerchantFetching || state?.loading} />
            <Modal
                className="upload-documents"
                show={state.download}
                onHide={() => handleState("download", false)}
            >
                <Modal.Header className="upload-doc" closeButton>
                    <Modal.Title>Upload Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DownloadSuperMerchantCSV handleState={handleState} state={state} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ClientMerchant
