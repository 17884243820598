import React, { useEffect, useState } from "react";
import { useRefToken } from "../../../hooks/useToken";
import { useDispatch } from "react-redux";
import { tokenAction } from "../../../../redux/tokenSlice";
import DragNDrop from "../../../../components/DragNDrop";
import { useUpdateClientMutation } from "../../../../redux/superadminApiSlice";

const EditClient = (props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { handleState, setState, state, fetchClients } = props;
  const phoneNum =
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  const token = useRefToken();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phonenumber: "",
    webhook_url: "",
    business_name: "",
    bot_key: "",
  });
  const [error, setError] = useState({});
  const [updateClientTrigger,{isFetching,isLoading,error:isError}] = useUpdateClientMutation()
  const Validation = () => {
    let error = {};
    if (!requiredValue(data.first_name)) {
      error.first_name = "first name is required";
    }
    if (!requiredValue(data.last_name)) {
      error.last_name = "last name is required";
    }
    if (!requiredValue(data.business_name)) {
      error.business_name = "business name is required";
    }
    if (!requiredValue(data?.phonenumber)) {
      error.phonenumber = "phone number is required";
    } else if (!handlePhoneNumber(data?.phonenumber)) {
      error.phonenumber = "phone number must be number";
    }
    if (Object.keys(error).length < 1) {
      return false;
    }
    return error;
  };

  const handlePhoneNumber = (num) => {
    let no = num.replace(/\D/g, "");
    return phoneNum.test(no);
  };

  const handleChange = (e) => {
    let newData = { ...data };
    const { name, value } = e.target;
    newData[name] = value?.trim();
    setData(newData);
  };
  const handleData = async (e) => {
    e.preventDefault();
    setError({});
    let errors = Validation();
    if (!errors) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const formData = new FormData();
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("phonenumber", data.phonenumber);
        formData.append("business_name", data.business_name);
        formData.append("webhook_url", data.webhook_url);
        formData.append("bot_key", data.bot_key || "");
        files.forEach((fileData) => {
          formData.append("business_logo", fileData);
        });
        const response = await updateClientTrigger({formData, id:state.row.id}).unwrap();
        
       console.log("response",response);
       dispatch(tokenAction.showToaster({ status: true, message: response?.message, open: true }));
       handleState("edit", false);
       setState((prev) => ({
         ...prev,
         loading: false,
        }));
      } catch (error) {
        console.log("error",error);
        dispatch(tokenAction.showToaster({ status: false, message: (error?.message) ? error?.message : "Something went wrong", open: true }));
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      setError(errors);
    }
  };

  const requiredValue = (value) => {
    if (!value) {
      return false;
    }
    return true;
  };


  useEffect(() => {
    let itm = state.row;
    setData({
      first_name: itm.first_name,
      last_name: itm.last_name,
      phonenumber: itm.phonenumber,
      webhook_url: itm.webhooks?.webhook_url,
      business_name: itm.businessname,
    })
  }, [state.row]);

  const formatPhoneNumber = (numericValue) => {
    const cleaned = ("" + numericValue).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return numericValue;
  };


  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-6">
            <label className="">FIRST NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Enter first name"
              name="first_name"
              value={data.first_name}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.first_name && (
              <span className="error text-danger fs-12">
                {error.first_name}
              </span>
            )}
          </div>
          <div className="col-6">
            <label className="">LAST NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Enter last name"
              name="last_name"
              value={data.last_name}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.last_name && (
              <span className="error text-danger fs-12">
                {error.last_name}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label className="">PHONE</label>
            <input
              className="custom-date-picker"
              type="text"
              maxLength="10"
              value={formatPhoneNumber(data.phonenumber)}
              name="phonenumber"
              placeholder="Phone"
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.phonenumber && (
              <span className="error text-danger fs-12">
                {error.phonenumber}
              </span>
            )}
          </div>
          <div className="col-6">
            <label className="">BUSINESS NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              value={data.business_name}
              name="usiness_name"
              placeholder="Business name"
              onChange={handleChange}
              autoComplete="off"
            />
            {error.business_name && (
              <span className="error text-danger fs-12">
                {error.business_name}
              </span>
            )}
          </div>
        </div>
        <input type='text' id='bot_key' autoComplete="off" name='bot_key' value={data?.bot_key} onChange={handleChange} style={{ display: 'none' }} />
        <div className="row">
          <div className="col-12">
            <label className="">webhook_url</label>
            <input
              className="custom-date-picker"
              type="text"
              value={data.webhook_url}
              name="webhook_url"
              placeholder="Webhook url"
              onChange={handleChange}
            />
            {error.webhook_url && (
              <span className="error text-danger fs-12">
                {error.webhook_url}
              </span>
            )}
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center my-4">
          <DragNDrop
            onFilesSelected={setFiles}
            width=" auto"
            height="auto"
          />
        </div>
        <div className="row">
          <div className="col-6">
            <button
              className="cancel-btn "
              onClick={() => {
                handleState("edit", false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button className="add-btn" onClick={handleData}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditClient;
