import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { DocsjsonData } from '../DocsjsonData'
import DefaultLayout from '../DefaultLayout'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import OnboardingSession from '../main/OnboardingSession';
import { toast } from 'react-toastify';

const DynamicApidata = () => {
    const params = useParams()
    const { slug } = params || {}
    const [APIData, setAPIData] = useState()
    const [nextAPI, setNextAPIData] = useState()
    const [previousAPI, setPreviousAPIData] = useState()
    const [selectedTab, setSelectedTab] = useState();
    const [isLoading, setIsLoading] = useState(true)


    const FindApiBySlug = (slug) => {

        for (const section of DocsjsonData) {
            const api = section.api.find(item => item.slug === slug);
            if (api) {
                return api;
            }
            if (section.sub) {
                for (const subSection of section.sub) {
                    const subApi = subSection.subapi.find(item => item.slug === slug);
                    if (subApi) {
                        return subApi;
                    }
                }
            }
        }

        return null;
    };


    const FindApiByid = (id) => {
        for (const section of DocsjsonData) {
            const api = section.api.find(item => item.id === id);
            if (api) {
                return api;
            }
            if (section.sub) {
                for (const subSection of section.sub) {
                    const subApi = subSection.subapi.find(item => item.id === id);
                    if (subApi) {
                        return subApi;
                    }
                }
            }
        }
        return null;
    };


    useEffect(() => {
        setIsLoading(true)
        if (slug) {
            let Apidata = FindApiBySlug(slug)
            let Previous = FindApiByid(Apidata?.id - 1)
            let Next = FindApiByid(Apidata?.id + 1)
            setAPIData(Apidata)
            setNextAPIData({
                "title": Next?.label,
                "url": Next?.url
            })
            setPreviousAPIData({
                "title": Previous?.label,
                "url": Previous?.url
            })
            setSelectedTab(Apidata?.tabsdata && Apidata?.tabsdata?.length > 0 ? Apidata?.tabsdata[0]?.id : null)
            setIsLoading(false)
        }
    }, [slug])



    const Tab = ({ tab, isSelected, onClick }) => {
        return (
            <button
                className={`group flex items-center relative px-2 pt-2.5 pb-2 text-gray-400 outline-none whitespace-nowrap font-medium ${isSelected ? 'text-primary-light' : 'text-gray-500'}`}
                style={{
                    background: 'none',
                    border: 0,
                    borderBottom: isSelected ? '2px #A66FF8 solid' : 'none',
                }}
                role="tab"
                onClick={onClick}
            >
                <div className="px-2 rounded-md">
                    <div className="z-10">{tab.label}</div>
                </div>
                <div className="absolute inset-0 border-b pointer-events-none border-primary-light" />
            </button>
        );
    };

    const TabPanel = ({ tab, isSelected }) => {
        if (!isSelected) return null;
        return (
            <div
                className="flex-none text-gray-50 p-5 min-w-full text-sm overflow-x-auto scrollbar-thumb-white/25 dark:scrollbar-thumb-white/10 w-full scrollbar-track-transparent scrollbar-thin scrollbar-thumb-rounded text-xs leading-[1.35rem]"
                role="tabpanel"
                aria-labelledby={`headlessui-tabs-tab-${tab.id}`}
                style={{ fontVariantLigatures: 'none' }}
            >
                <SyntaxHighlighter language="json" style={coldarkDark}>
                    {JSON.stringify(tab.content, null, 2)}
                </SyntaxHighlighter>
            </div>
        );
    };

    const handleCopy = () => {
        console.log(selectedTab, "selectedTab", APIData);

        let content = APIData?.tabsdata?.find(tab => tab.id === selectedTab)
        content = JSON.stringify(content?.content)


        navigator.clipboard.writeText(content).then(() => {
            toast.success("Copied !!")
        }).catch((err) => {
            console.error('Failed to copy content: ', err);
            toast.error("Something went wrong !!")
        });
    }


    return (
        <>
            {
                slug === "hosted-onboarding" ? (
                    <OnboardingSession backNavigate={previousAPI} forwardNavigate={nextAPI} />
                ) : (

                    !APIData && !isLoading ? (
                        <>
                            <div
                                className="relative grow overflow-hidden mx-auto lg:-ml-12 lg:pl-[23.7rem]"
                                id="content-area"
                            >
                                <div className="section404" style={{ width: '100%', height: '100%', padding: '1rem' }}>
                                    <div className="in404">

                                        <h1 className="error404">404</h1>
                                        <div className="page404">Ooops!!! The page you are looking for is not found</div>
                                        <Link className="back-home" to="/docs/api/full-merchant-submit">
                                            Back to home
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <DefaultLayout headerData={APIData?.headerData} head={APIData?.head} body={APIData?.body} backNavigate={previousAPI} forwardNavigate={nextAPI} handleActiveTab={APIData?.handleActiveTab} />

                            <div className="hidden xl:flex self-start sticky h-[calc(100vh-8rem-1rem)] top-32" id="content-side-layout">
                                <div className="w-[28rem] gap-6 grid grid-rows-[repeat(auto-fit,minmax(0,min-content))] grid-rows">
                                    <div className="not-prose relative overflow-hidden rounded-xl dark:ring-1 dark:ring-gray-800/50"
                                        style={{ backgroundColor: '#111b27' }}
                                    >
                                        {/* Tabs Header */}
                                        <div style={{ backgroundColor: '#111b27' }} className="flex h-10 text-xs leading-6 border-b bg-black/40 rounded-t-xl border-gray-900/80" role="tablist" aria-orientation="horizontal">
                                            <div className="flex overflow-x-auto">
                                                {APIData?.tabsdata && APIData?.tabsdata?.length > 0 && APIData?.tabsdata.map((tab) => (
                                                    <Tab
                                                        key={tab.id}
                                                        tab={tab}
                                                        isSelected={tab.id === selectedTab}
                                                        onClick={() => setSelectedTab(tab.id)}
                                                    />
                                                ))}
                                            </div>
                                            <div className="flex-auto flex justify-end items-center pr-4 rounded-tr">
                                                <div className="group z-10 relative" onClick={handleCopy}>
                                                    <button className="h-7 w-7 flex items-center justify-center rounded-md" style={{
                                                        background: 'none',
                                                        border: 0
                                                    }}>
                                                        <svg
                                                            className="fill-gray-700 group-hover:fill-gray-400"
                                                            width={16}
                                                            height={16}
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                                                        </svg>
                                                    </button>
                                                    <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                                                        Copy
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Tab Panels */}
                                        <div className="flex max-h-[calc(100%-40px)]">
                                            {APIData?.tabsdata && APIData?.tabsdata?.length > 0 && APIData?.tabsdata.map((tab) => (
                                                <TabPanel
                                                    key={tab.id}
                                                    tab={tab}
                                                    isSelected={tab.id === selectedTab}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    )


                )

            }
        </>

    )
}

export default DynamicApidata