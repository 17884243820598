import axios from "axios";
import baseUrl from "../baseUrl";
import {store} from '../redux/store';

const getTokenFromState = () => {
  const state = store.getState(); 
  return state.deviceId.deviceId; 
};

export const createPublicKey = async (token, body) => {
    let deviceId=  getTokenFromState()

  const response = await axios.post(
    `${baseUrl}/api/v1/order/generate-hash`,
    body,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const getActiveLog = async (token, params) => {
  let deviceId = getTokenFromState()
  
  const response = await axios.get(`${baseUrl}/api/v1/admin/log/get-allLog`, {
    headers: {
      Authorization: token,
      'X-Device-Fingerprint': deviceId,
      
    },
    params,
  });
  return response.data;
};

export const checkRoutesPermission = async (token, id) => {
  let deviceId = getTokenFromState()
  const response = await axios.get(`${baseUrl}/api/v1/admin/auth/check-access`, {
    headers: {
      Authorization: token,
      menu_id: id,
      'X-Device-Fingerprint': deviceId,

    },
  });
  return response?.data;
};
