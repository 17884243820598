export const IndustryData = [
    {

        value: "7623",
        label: "A/C Refrigeration Repair",
    },
    {

        value: "8931",
        label: "Accounting/Bookkeeping Services",
    },
    {

        value: "7311",
        label: "Advertising Services",
    },
    {

        value: "0763",
        label: "Agricultural Cooperative",
    },
    {

        value: "4511",
        label: "Airlines Air Carriers",
    },
    {

        value: "4582",
        label: "Airports Flying Fields",
    },
    {

        value: "4119",
        label: "Ambulance Services",
    },
    {

        value: "7996",
        label: "Amusement Parks/Carnivals",
    },
    {

        value: "5937",
        label: "Antique Reproductions",
    },
    {

        value: "5932",
        label: "Antique Shops",
    },
    {

        value: "7998",
        label: "Aquariums",
    },
    {

        value: "8911",
        label: "Architectural/Surveying Services",
    },
    {

        value: "5971",
        label: "Art Dealers and Galleries",
    },
    {

        value: "5970",
        label: "Artists Supply and Craft Shops",
    },
    {

        value: "7531",
        label: "Auto Body Repair Shops",
    },
    {

        value: "7535",
        label: "Auto Paint Shops",
    },
    {

        value: "7538",
        label: "Auto Service Shops",
    },
    {

        value: "5531",
        label: "Auto and Home Supply Stores",
    },
    {

        value: "6011",
        label: "Automated Cash Disburse",
    },
    {

        value: "5542",
        label: "Automated Fuel Dispensers",
    },
    {

        value: "8675",
        label: "Automobile Associations",
    },
    {

        value: "5533",
        label: "Automotive Parts and Accessories Stores",
    },
    {

        value: "5532",
        label: "Automotive Tire Stores",
    },
    {

        value: "9223",
        label: "Bail and Bond Payments (payment to the surety for the bond not the actual bond paid to the government agency)",
    },
    {

        value: "5462",
        label: "Bakeries",
    },
    {

        value: "7929",
        label: "Bands Orchestras",
    },
    {

        value: "7230",
        label: "Barber and Beauty Shops",
    },
    {

        value: "7995",
        label: "Betting/Casino Gambling",
    },
    {

        value: "5940",
        label: "Bicycle Shops",
    },
    {

        value: "7932",
        label: "Billiard/Pool Establishments",
    },
    {

        value: "5551",
        label: "Boat Dealers",
    },
    {

        value: "4457",
        label: "Boat Rentals and Leases",
    },
    {

        value: "5942",
        label: "Book Stores",
    },
    {

        value: "5192",
        label: "Books Periodicals and Newspapers",
    },
    {

        value: "7933",
        label: "Bowling Alleys",
    },
    {

        value: "4131",
        label: "Bus Lines",
    },
    {

        value: "8244",
        label: "Business/Secretarial Schools",
    },
    {

        value: "7278",
        label: "Buying/Shopping Services",
    },
    {

        value: "4899",
        label: "Cable Satellite and Other Pay Television and Radio",
    },
    {

        value: "5946",
        label: "Camera and Photographic Supply Stores",
    },
    {

        value: "5441",
        label: "Candy Nut and Confectionery Stores",
    },
    {

        value: "7512",
        label: "Car Rental Agencies",
    },
    {

        value: "7542",
        label: "Car Washes",
    },
    {

        value: "5511",
        label: "Car and Truck Dealers (New &amp; Used) Sales Service Repairs Parts and Leasing",
    },
    {

        value: "5521",
        label: "Car and Truck Dealers (Used Only) Sales Service Repairs Parts and Leasing",
    },
    {

        value: "1750",
        label: "Carpentry Services",
    },
    {

        value: "7217",
        label: "Carpet/Upholstery Cleaning",
    },
    {

        value: "5811",
        label: "Caterers",
    },
    {

        value: "8398",
        label: "Charitable and Social Service Organizations - Fundraising",
    },
    {

        value: "5169",
        label: "Chemicals and Allied Products (Not Elsewhere Classified)",
    },
    {

        value: "5641",
        label: "Chidrens and Infants Wear Stores",
    },
    {

        value: "8351",
        label: "Child Care Services",
    },
    {

        value: "8049",
        label: "Chiropodists Podiatrists",
    },
    {

        value: "8041",
        label: "Chiropractors",
    },
    {

        value: "5993",
        label: "Cigar Stores and Stands",
    },
    {

        value: "8641",
        label: "Civic Social Fraternal Associations",
    },
    {

        value: "7349",
        label: "Cleaning and Maintenance",
    },
    {

        value: "7296",
        label: "Clothing Rental",
    },
    {

        value: "8220",
        label: "Colleges Universities",
    },
    {

        value: "5046",
        label: "Commercial Equipment (Not Elsewhere Classified)",
    },
    {

        value: "5139",
        label: "Commercial Footwear",
    },
    {

        value: "7333",
        label: "Commercial Photography Art and Graphics",
    },
    {

        value: "4111",
        label: "Commuter Transport Ferries",
    },
    {

        value: "4816",
        label: "Computer Network Services",
    },
    {

        value: "7372",
        label: "Computer Programming",
    },
    {

        value: "7379",
        label: "Computer Repair",
    },
    {

        value: "5734",
        label: "Computer Software Stores",
    },
    {

        value: "5045",
        label: "Computers Peripherals and Software",
    },
    {

        value: "1771",
        label: "Concrete Work Services",
    },
    {

        value: "5039",
        label: "Construction Materials (Not Elsewhere Classified)",
    },
    {

        value: "7392",
        label: "Consulting Public Relations",
    },
    {

        value: "8241",
        label: "Correspondence Schools",
    },
    {

        value: "5977",
        label: "Cosmetic Stores",
    },
    {

        value: "7277",
        label: "Counseling Services",
    },
    {

        value: "7997",
        label: "Country Clubs",
    },
    {

        value: "4215",
        label: "Courier Services",
    },
    {

        value: "9211",
        label: "Court Costs Including Alimony and Child Support - Courts of Law",
    },
    {

        value: "7321",
        label: "Credit Reporting Agencies",
    },
    {

        value: "4411",
        label: "Cruise Lines",
    },
    {

        value: "5451",
        label: "Dairy Products Stores",
    },
    {

        value: "7911",
        label: "Dance Hall Studios Schools",
    },
    {

        value: "7273",
        label: "Dating/Escort Services",
    },
    {

        value: "8021",
        label: "Dentists Orthodontists",
    },
    {

        value: "5311",
        label: "Department Stores",
    },
    {

        value: "7393",
        label: "Detective Agencies",
    },
    {

        value: "5964",
        label: "Direct Marketing - Catalog Merchant",
    },
    {

        value: "5965",
        label: "Direct Marketing - Combination Catalog and Retail Merchant",
    },
    {

        value: "5967",
        label: "Direct Marketing - Inbound Telemarketing",
    },
    {

        value: "5960",
        label: "Direct Marketing - Insurance Services",
    },
    {

        value: "5969",
        label: "Direct Marketing - Other",
    },
    {

        value: "5966",
        label: "Direct Marketing - Outbound Telemarketing",
    },
    {

        value: "5968",
        label: "Direct Marketing - Subscription",
    },
    {

        value: "5962",
        label: "Direct Marketing - Travel",
    },
    {

        value: "5310",
        label: "Discount Stores",
    },
    {

        value: "8011",
        label: "Doctors",
    },
    {

        value: "5963",
        label: "Door-To-Door Sales",
    },
    {

        value: "5714",
        label: "Drapery Window Covering and Upholstery Stores",
    },
    {

        value: "5813",
        label: "Drinking Places",
    },
    {

        value: "5912",
        label: "Drug Stores and Pharmacies",
    },
    {

        value: "5122",
        label: "Drugs Drug Proprietaries and Druggist Sundries",
    },
    {

        value: "7216",
        label: "Dry Cleaners",
    },
    {

        value: "5099",
        label: "Durable Goods (Not Elsewhere Classified)",
    },
    {

        value: "5309",
        label: "Duty Free Stores",
    },
    {

        value: "5812",
        label: "Eating Places Restaurants",
    },
    {

        value: "8299",
        label: "Educational Services",
    },
    {

        value: "5997",
        label: "Electric Razor Stores",
    },
    {

        value: "5065",
        label: "Electrical Parts and Equipment",
    },
    {

        value: "1731",
        label: "Electrical Services",
    },
    {

        value: "7622",
        label: "Electronics Repair Shops",
    },
    {

        value: "5732",
        label: "Electronics Stores",
    },
    {

        value: "8211",
        label: "Elementary Secondary Schools",
    },
    {

        value: "7361",
        label: "Employment/Temp Agencies",
    },
    {

        value: "7394",
        label: "Equipment Rental",
    },
    {

        value: "7342",
        label: "Exterminating Services",
    },
    {

        value: "5651",
        label: "Family Clothing Stores",
    },
    {

        value: "5814",
        label: "Fast Food Restaurants",
    },
    {

        value: "6012",
        label: "Financial Institutions",
    },
    {

        value: "9222",
        label: "Fines - Government Administrative Entities",
    },
    {

        value: "5718",
        label: "Fireplace Fireplace Screens and Accessories Stores",
    },
    {

        value: "5713",
        label: "Floor Covering Stores",
    },
    {

        value: "5992",
        label: "Florists",
    },
    {

        value: "5193",
        label: "Florists Supplies Nursery Stock and Flowers",
    },
    {

        value: "5422",
        label: "Freezer and Locker Meat Provisioners",
    },
    {

        value: "5983",
        label: "Fuel Dealers (Non Automotive)",
    },
    {

        value: "7261",
        label: "Funeral Services Crematories",
    },
    {

        value: "7641",
        label: "Furniture Repair Refinishing",
    },
    {

        value: "5712",
        label: "Furniture Home Furnishings and Equipment Stores Except Appliances",
    },
    {

        value: "5681",
        label: "Furriers and Fur Shops",
    },
    {

        value: "1520",
        label: "General Services",
    },
    {

        value: "5947",
        label: "Gift Card Novelty and Souvenir Shops",
    },
    {

        value: "5231",
        label: "Glass Paint and Wallpaper Stores",
    },
    {

        value: "5950",
        label: "Glassware Crystal Stores",
    },
    {

        value: "7992",
        label: "Golf Courses - Public",
    },
    {

        value: "9399",
        label: "Government Services (Not Elsewhere Classified)",
    },
    {

        value: "5411",
        label: "Grocery Stores Supermarkets",
    },
    {

        value: "5251",
        label: "Hardware Stores",
    },
    {

        value: "5072",
        label: "Hardware Equipment and Supplies",
    },
    {

        value: "7298",
        label: "Health and Beauty Spas",
    },
    {

        value: "5975",
        label: "Hearing Aids Sales and Supplies",
    },
    {

        value: "1711",
        label: "Heating Plumbing A/C",
    },
    {

        value: "5945",
        label: "Hobby Toy and Game Shops",
    },
    {

        value: "5200",
        label: "Home Supply Warehouse Stores",
    },
    {

        value: "8062",
        label: "Hospitals",
    },
    {

        value: "7011",
        label: "Hotels Motels and Resorts",
    },
    {

        value: "5722",
        label: "Household Appliance Stores",
    },
    {

        value: "5085",
        label: "Industrial Supplies (Not Elsewhere Classified)",
    },
    {

        value: "7375",
        label: "Information Retrieval Services",
    },
    {

        value: "6399",
        label: "Insurance - Default",
    },
    {

        value: "6300",
        label: "Insurance Underwriting Premiums",
    },
    {

        value: "9950",
        label: "Intra-Company Purchases",
    },
    {

        value: "5944",
        label: "Jewelry Stores Watches Clocks and Silverware Stores",
    },
    {

        value: "0780",
        label: "Landscaping Services",
    },
    {

        value: "7211",
        label: "Laundries",
    },
    {

        value: "7210",
        label: "Laundry Cleaning Services",
    },
    {

        value: "8111",
        label: "Legal Services Attorneys",
    },
    {

        value: "5948",
        label: "Luggage and Leather Goods Stores",
    },
    {

        value: "5211",
        label: "Lumber Building Materials Stores",
    },
    {

        value: "6010",
        label: "Manual Cash Disburse",
    },
    {

        value: "4468",
        label: "Marinas Service and Supplies",
    },
    {

        value: "1740",
        label: "Masonry Stonework and Plaster",
    },
    {

        value: "7297",
        label: "Massage Parlors",
    },
    {

        value: "8099",
        label: "Medical Services",
    },
    {

        value: "8071",
        label: "Medical and Dental Labs",
    },
    {

        value: "5047",
        label: "Medical Dental Ophthalmic and Hospital Equipment and Supplies",
    },
    {

        value: "8699",
        label: "Membership Organizations",
    },
    {

        value: "5611",
        label: "Mens and Boys Clothing and Accessories Stores",
    },
    {

        value: "5691",
        label: "Mens Womens Clothing Stores",
    },
    {

        value: "5051",
        label: "Metal Service Centers",
    },
    {

        value: "5699",
        label: "Miscellaneous Apparel and Accessory Shops",
    },
    {

        value: "5599",
        label: "Miscellaneous Auto Dealers",
    },
    {

        value: "7399",
        label: "Miscellaneous Business Services",
    },
    {

        value: "5499",
        label: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
    },
    {

        value: "5399",
        label: "Miscellaneous General Merchandise",
    },
    {

        value: "7299",
        label: "Miscellaneous General Services",
    },
    {

        value: "5719",
        label: "Miscellaneous Home Furnishing Specialty Stores",
    },
    {

        value: "2741",
        label: "Miscellaneous Publishing and Printing",
    },
    {

        value: "7999",
        label: "Miscellaneous Recreation Services",
    },
    {

        value: "7699",
        label: "Miscellaneous Repair Shops",
    },
    {

        value: "5999",
        label: "Miscellaneous Specialty Retail",
    },
    {

        value: "5271",
        label: "Mobile Home Dealers",
    },
    {

        value: "7832",
        label: "Motion Picture Theaters",
    },
    {

        value: "4214",
        label: "Motor Freight Carriers and Trucking - Local and Long Distance Moving and Storage Companies and Local Delivery Services",
    },
    {

        value: "5592",
        label: "Motor Homes Dealers",
    },
    {

        value: "5013",
        label: "Motor Vehicle Supplies and New Parts",
    },
    {

        value: "5571",
        label: "Motorcycle Shops and Dealers",
    },
    {

        value: "5561",
        label: "Motorcycle Shops Dealers",
    },
    {

        value: "5733",
        label: "Music Stores-Musical Instruments Pianos and Sheet Music",
    },
    {

        value: "5994",
        label: "News Dealers and Newsstands",
    },
    {

        value: "6051",
        label: "Non-FI Money Orders",
    },
    {

        value: "6540",
        label: "Non-FI Stored Value Card Purchase/Load",
    },
    {

        value: "5199",
        label: "Nondurable Goods (Not Elsewhere Classified)",
    },
    {

        value: "5261",
        label: "Nurseries Lawn and Garden Supply Stores",
    },
    {

        value: "8050",
        label: "Nursing/Personal Care",
    },
    {

        value: "5021",
        label: "Office and Commercial Furniture",
    },
    {

        value: "8043",
        label: "Opticians Eyeglasses",
    },
    {

        value: "8042",
        label: "Optometrists Ophthalmologist",
    },
    {

        value: "5976",
        label: "Orthopedic Goods - Prosthetic Devices",
    },
    {

        value: "8031",
        label: "Osteopaths",
    },
    {

        value: "5921",
        label: "Package Stores-Beer Wine and Liquor",
    },
    {

        value: "5198",
        label: "Paints Varnishes and Supplies",
    },
    {

        value: "7523",
        label: "Parking Lots Garages",
    },
    {

        value: "4112",
        label: "Passenger Railways",
    },
    {

        value: "5933",
        label: "Pawn Shops",
    },
    {

        value: "5995",
        label: "Pet Shops Pet Food and Supplies",
    },
    {

        value: "5172",
        label: "Petroleum and Petroleum Products",
    },
    {

        value: "7395",
        label: "Photo Developing",
    },
    {

        value: "7221",
        label: "Photographic Studios",
    },
    {

        value: "5044",
        label: "Photographic Photocopy Microfilm Equipment and Supplies",
    },
    {

        value: "7829",
        label: "Picture/Video Production",
    },
    {

        value: "5131",
        label: "Piece Goods Notions and Other Dry Goods",
    },
    {

        value: "5074",
        label: "Plumbing Heating Equipment and Supplies",
    },
    {

        value: "8651",
        label: "Political Organizations",
    },
    {

        value: "9402",
        label: "Postal Services - Government Only",
    },
    {

        value: "5094",
        label: "Precious Stones and Metals Watches and Jewelry",
    },
    {

        value: "8999",
        label: "Professional Services",
    },
    {

        value: "4225",
        label: "Public Warehousing and Storage - Farm Products Refrigerated Goods Household Goods and Storage",
    },
    {

        value: "7338",
        label: "Quick Copy Repro and Blueprint",
    },
    {

        value: "4011",
        label: "Railroads",
    },
    {

        value: "6513",
        label: "Real Estate Agents and Managers - Rentals",
    },
    {

        value: "5735",
        label: "Record Stores",
    },
    {

        value: "7519",
        label: "Recreational Vehicle Rentals",
    },
    {

        value: "5973",
        label: "Religious Goods Stores",
    },
    {

        value: "8661",
        label: "Religious Organizations",
    },
    {

        value: "1761",
        label: "Roofing/Siding Sheet Metal",
    },
    {

        value: "7339",
        label: "Secretarial Support Services",
    },
    {

        value: "6211",
        label: "Security Brokers/Dealers",
    },
    {

        value: "5541",
        label: "Service Stations",
    },
    {

        value: "5949",
        label: "Sewing Needlework Fabric and Piece Goods Stores",
    },
    {

        value: "7251",
        label: "Shoe Repair/Hat Cleaning",
    },
    {

        value: "5661",
        label: "Shoe Stores",
    },
    {

        value: "7629",
        label: "Small Appliance Repair",
    },
    {

        value: "5598",
        label: "Snowmobile Dealers",
    },
    {

        value: "1799",
        label: "Special Trade Services",
    },
    {

        value: "2842",
        label: "Specialty Cleaning",
    },
    {

        value: "5941",
        label: "Sporting Goods Stores",
    },
    {

        value: "7032",
        label: "Sporting/Recreation Camps",
    },
    {

        value: "7941",
        label: "Sports Clubs/Fields",
    },
    {

        value: "5655",
        label: "Sports and Riding Apparel Stores",
    },
    {

        value: "5972",
        label: "Stamp and Coin Stores",
    },
    {

        value: "5111",
        label: "Stationary Office Supplies Printing and Writing Paper",
    },
    {

        value: "5943",
        label: "Stationery Stores Office and School Supply Stores",
    },
    {

        value: "5996",
        label: "Swimming Pools Sales",
    },
    {

        value: "4723",
        label: "TUI Travel - Germany",
    },
    {

        value: "5697",
        label: "Tailors Alterations",
    },
    {

        value: "9311",
        label: "Tax Payments - Government Agencies",
    },
    {

        value: "7276",
        label: "Tax Preparation Services",
    },
    {

        value: "4121",
        label: "Taxicabs/Limousines",
    },
    {

        value: "4812",
        label: "Telecommunication Equipment and Telephone Sales",
    },
    {

        value: "4814",
        label: "Telecommunication Services",
    },
    {

        value: "4821",
        label: "Telegraph Services",
    },
    {

        value: "5998",
        label: "Tent and Awning Shops",
    },
    {

        value: "8734",
        label: "Testing Laboratories",
    },
    {

        value: "7922",
        label: "Theatrical Ticket Agencies",
    },
    {

        value: "7012",
        label: "Timeshares",
    },
    {

        value: "7534",
        label: "Tire Retreading and Repair",
    },
    {

        value: "4784",
        label: "Tolls/Bridge Fees",
    },
    {

        value: "7991",
        label: "Tourist Attractions and Exhibits",
    },
    {

        value: "7549",
        label: "Towing Services",
    },
    {

        value: "7033",
        label: "Trailer Parks Campgrounds",
    },
    {

        value: "4789",
        label: "Transportation Services (Not Elsewhere Classified)",
    },
    {

        value: "4722",
        label: "Travel Agencies Tour Operators",
    },
    {

        value: "7511",
        label: "Truck StopIteration",
    },
    {

        value: "7513",
        label: "Truck/Utility Trailer Rentals",
    },
    {

        value: "2791",
        label: "Typesetting Plate Making and Related Services",
    },
    {

        value: "5978",
        label: "Typewriter Stores",
    },
    {

        value: "9405",
        label: "U.S. Federal Government Agencies or Departments",
    },
    {

        value: "5137",
        label: "Uniforms Commercial Clothing",
    },
    {

        value: "5931",
        label: "Used Merchandise and Secondhand Stores",
    },
    {

        value: "4900",
        label: "Utilities",
    },
    {

        value: "5331",
        label: "Variety Stores",
    },
    {

        value: "0742",
        label: "Veterinary Services",
    },
    {

        value: "7993",
        label: "Video Amusement Game Supplies",
    },
    {

        value: "7994",
        label: "Video Game Arcades",
    },
    {

        value: "7841",
        label: "Video Tape Rental Stores",
    },
    {

        value: "8249",
        label: "Vocational/Trade Schools",
    },
    {

        value: "7631",
        label: "Watch/Jewelry Repair",
    },
    {

        value: "7692",
        label: "Welding Repair",
    },
    {

        value: "5300",
        label: "Wholesale Clubs",
    },
    {

        value: "5698",
        label: "Wig and Toupee Stores",
    },
    {

        value: "4829",
        label: "Wires Money Orders",
    },
    {

        value: "5631",
        label: "Womens Accessory and Specialty Shops",
    },
    {

        value: "5621",
        label: "Womens Ready-To-Wear Stores",
    },
    {

        value: "5935",
        label: "Wrecking and Salvage Yards",
    },
];

export const business_structure_option_Array = [
    { name: 'Select Company Structure', value: '', color: '#999' },
    { name: 'Sole Prop', value: 'Sole Prop', color: '#212529' },
    { name: 'LLC', value: 'LLC', color: '#212529' },
    { name: 'Private Corporation', value: 'Private Corporation', color: '#212529' },
    { name: 'Public Corporation', value: 'Public Corporation', color: '#212529' },
    { name: 'Non Profit', value: 'Non profit', color: '#212529' },
    { name: 'Limited Liability Partnership', value: 'limited liability partnership', color: '#212529' }
];

export const title_option_Array = [
    { name: 'Select title', value: '', color: '#999' },
    { name: 'CEO', value: 'CEO', color: '#212529' },
    { name: 'CFO', value: 'CFO', color: '#212529' },
    { name: 'COO', value: 'COO', color: '#212529' },
    { name: 'President', value: 'President', color: '#212529' },
    { name: 'Secretary', value: 'Secretary', color: '#212529' },
    { name: 'Treasurer', value: 'Treasurer', color: '#212529' },
    { name: 'Vice President', value: 'Vice President', color: '#212529' }
];

export const processing_environment_options_Array = [
    { name: 'Select Processing Environment', value: '', color: '#999' },
    { name: 'Card Present', value: 'cardPresent', color: '#212529' },
    { name: 'E-Commerce', value: 'ecommerce', color: '#212529' },
    { name: 'Fuel', value: 'fuel', color: '#212529' },
    { name: 'Mail Order/Telephone Order', value: 'moto', color: '#212529' },
    { name: 'Restaurant', value: 'restaurant', color: '#212529' },
    { name: 'Service Station', value: 'serviceStation', color: '#212529' },
    { name: 'Supermarket', value: 'supermarket', color: '#212529' }
];


export const bank_account_type_Array = [
    { name: 'Select Bank Account Type', value: '', color: '#999' },
    { name: 'Personal checking account', value: '8', color: '#212529' },
    { name: 'Personal saving account', value: '9', color: '#212529' },
    { name: 'Corporate checking account', value: '10', color: '#212529' },
    { name: 'Corporate saving account', value: '11', color: '#212529' }
];
