import axios from "axios";
import baseUrl from "../../baseUrl";
import { store } from '../../redux/store';

const getTokenFromState = () => {
  const state = store.getState();
  return state.deviceId.deviceId;
};
export const getSuprAdminRolesAll = async (token) => {
  let deviceId = getTokenFromState()
  const response = await axios.get(`${baseUrl}/api/v1/super-admin/role/get-all`, {
    headers: {
      Authorization: token,
      'X-Device-Fingerprint': deviceId,
    },
    params: {
      isAll: true,
    },
  });
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const getSuperAdminEmployee = async (token, params) => {
  let deviceId = getTokenFromState()
  const response = await axios.get(`${baseUrl}/api/v1/super-admin/employee/get-all`, {
    headers: {
      Authorization: token,
      'X-Device-Fingerprint': deviceId,
    },
    params,
  });
  // if (!response.data.result) {
  //   throw new Error(response.data.message);
  // }
  return response.data;
};

export const createSuperAdminEmployee = async (token, body) => {
  let deviceId = getTokenFromState()
  const response = await axios.post(
    `${baseUrl}/api/v1/super-admin/employee/create`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const updateSuperAdminEmployee = async (token, body, id) => {
  let deviceId = getTokenFromState()
  const response = await axios.patch(
    `${baseUrl}/api/v1/super-admin/employee/update`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const forgotPasswordSuperAdminEmployee = async (token, body, id) => {
  let deviceId = getTokenFromState()
  const response = await axios.patch(
    `${baseUrl}/api/v1/super-admin/employee/update-password`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const forgotPinSuperAdminEmployee = async (token, body, id) => {
  let deviceId = getTokenFromState()
  const response = await axios.patch(
    `${baseUrl}/api/v1/super-admin/employee/set-pin`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const deleteSuperAdminEmployee = async (token, id) => {
  let deviceId = getTokenFromState()
  const response = await axios.delete(
    `${baseUrl}/api/v1/super-admin/employee/delete`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,
      },
      params: {
        employeeId: id,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};