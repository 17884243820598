import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { useRoutes, useToken } from "../hooks/useToken";
import axios from "axios";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
import { useRefToken } from "../hooks/useToken";
import baseUrl from "../../baseUrl";
import Loader from "../DefaultComponents/Loader";
import { isEmpty } from "lodash";
import { useLazyGetSidebarQuerySuperAdmin } from "../../redux/superadminApiSlice";
import { useSelector } from "react-redux";

const SuperAdminProtected = (props) => {
  const { deviceId } = useSelector(state => state.deviceId);

  let [sidebarTrigger, { data: routes, isLoading, isError, error }] = useLazyGetSidebarQuerySuperAdmin();
  // const dispatch=useDispatch()

  const refToken = useRefToken();
  const { Component } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [accessible, setAccessible] = useState(false);
  const route = routes?.data?.allowedPath;
 
  // const routes = useRoutes();

  
  // console.log(window.location.pathname)
  // if(error?.status==401){
  //   localStorage.clear();
  //   dispatch(apiSlice.util.resetApiState());
  //   toast.error("Unauthorized");
  //   navigate("/login");
  // }

  useEffect(() => {
    if (!refToken) {
      navigate("/super-admin-login");
    }
  }, [location.pathname])

  useEffect(() => {
    if (routes) {

      let slug = location?.pathname?.split("/")?.filter((item) => item != "")[1];
      let matchId = routes?.data?.allowedPath?.filter((item) => item?.slug == (slug ? `/${slug}` : '/'))[0]?.menu_id;
      // console.log(slug,matchId,"opopop");
      
      if (matchId) {
        checkPermission(matchId);
      } else {
        navigate("/admin");
        // navigate(routes?.data?.allowedPath[0]?.slug);
        if (isEmpty(routes?.data?.allowedPath[0]?.slug)) {
          localStorage.removeItem("refreshToken");
          navigate("/super-admin-login");
        }
      }
    }
    else if (refToken && !routes) {
      sidebarTrigger();
    }
    else {
      // toast.error("Unauthorized");

      localStorage.removeItem("refreshToken"); navigate("/super-admin-login")
      navigate("/super-admin-login");

    }
  }, [location.pathname])

  const checkPermission = async (matchId) => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/super-admin/auth/check-access`, {
        headers: {
          Authorization: refToken,
          menu_id: matchId,
          'X-Device-Fingerprint': deviceId,

        },
      });

      if (response?.data?.result) {
        setAccessible(response?.data?.result);
      } else {
        navigate(routes?.data?.allowedPath[0]?.slug);
      }

    } catch (error) {
      setAccessible(false);
      if (error?.response?.status == 401 || error?.response?.status == 403 ) {
        navigate("/super-admin-login");
        // localStorage.clear();
        navigate("/super-admin-login")
        toast.error(error.response.statusText)
      } else {
        toast.error(error?.message)
      }
    }
  };


  if (isLoading) {
    return <Loader loading={isLoading} />

  }
  return refToken
    && <Component />;
};

export default SuperAdminProtected;


