import React from 'react'
import { Link } from 'react-router-dom'
import AccordianData from './AccordianData'
import { isEmpty } from 'lodash'

const DefaultLayout = ({ headerData, head, body, backNavigate, forwardNavigate, handleActiveTab }) => {

    const Normalstyles = (apimethod) => {
        if (apimethod === "GET") {
            return { color: '#2AB673', backgroundColor: '#2ab67336' }
        } else if (apimethod === "DELETE") {
            return { color: '#c52626', backgroundColor: '#c5262636' }
        } else if (apimethod === "PUT") {
            return { color: '#a16207', backgroundColor: '#a1620736' }
        } else if (apimethod === "PATCH") {
            return { color: '#750080', backgroundColor: '#75008036' }
        } else {
            return { color: '#3064E3', backgroundColor: '#3064e336' }
        }
    }
    const Paramstyles = (apimethod) => {
        if (apimethod === "GET") {
            return { color: '#2AB673' }
        } else if (apimethod === "DELETE") {
            return { color: '#c52626' }
        } else if (apimethod === "PUT") {
            return { color: '#a16207' }
        } else if (apimethod === "PATCH") {
            return { color: '#750080' }
        } else {
            return { color: '#3064E3' }
        }
    }

    return (
        <div
            className="relative grow overflow-hidden mx-auto lg:-ml-12 lg:pl-[23.7rem]"
            id="content-area"
        >
            <header id="header" className="relative">
                <div className="mt-0.5 space-y-2.5">
                    <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                        {headerData?.heading}
                    </div>
                    <div className="flex items-center">
                        <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                            {headerData?.subHeading}
                        </h1>
                    </div>
                </div>
            </header>
            <div className="flex flex-col gap-8 get-merchants1">
                <div className="mt-6 flex w-full flex-col space-y-4">
                    <div className="flex w-full flex-col space-y-3 bg-background-light dark:bg-background-dark border border-gray-200/70 dark:border-white/10 rounded-xl ring-2 ring-gray-100 dark:ring-white/5 p-4">
                        <div className="flex items-center space-x-2">
                            <div className="relative flex-1 flex gap-2 min-w-0 rounded-lg items-center cursor-pointer p-1.5 border border-gray-200/70 dark:border-white/10">
                                {/* {headerData?.method === 'POST' ? (<div className="rounded-md font-bold px-1.5 py-0.5 text-sm leading-5 bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400">
                                    POST
                                </div>) : headerData?.method === "PUT" ? (
                                    <div className="rounded-md font-bold px-1.5 py-0.5 text-sm leading-5 bg-yellow-400/20 text-yellow-700 dark:bg-yellow-400/20 dark:text-yellow-400">
                                        PUT
                                    </div>
                                ) :
                                    (<div className="rounded-md font-bold px-1.5 py-0.5 text-sm leading-5 bg-green-400/20 text-green-700 dark:bg-green-400/20 dark:text-green-400">
                                        GET
                                    </div>)} */}

                                <div className="rounded-md font-bold px-1.5 py-0.5 text-sm leading-5"
                                    style={Normalstyles(headerData?.method)}
                                >
                                    {headerData?.method}
                                </div>

                                <div className="w-px h-4 bg-gray-200 dark:bg-white/10" />
                                <div className="group flex items-center flex-1 gap-1 overflow-x-auto font-mono slide-overflo">
                                    {/* <div className="absolute right-3 hidden group-hover:block">
                                      <svg
                                          className="w-4 h-4 bg-gray-400 dark:bg-white/30"
                                          style={{
                                              maskImage:
                                                  'url("https://mintlify.b-cdn.net/v6.5.1/regular/clone.svg")',
                                              maskRepeat: "no-repeat",
                                              maskPosition: "center center"
                                          }}
                                          onClick={copyTextToClipboard()}
                                      />
                                  </div> */}
                                    {headerData?.api?.map((item, ind) => (
                                        <div className='d-flex' key={ind}>
                                            <div className="text-sm text-gray-400">/</div>
                                            <div className="text-sm font-medium text-gray-800 dark:text-white min-w-max">
                                                {item}
                                            </div>
                                        </div>
                                    ))}

                                    {!isEmpty(headerData?.params) && <>
                                        <div className="text-sm text-gray-400">/</div>
                                        <div className="text-sm font-mono font-medium rounded-md px-1 border-2 min-w-max"
                                            style={Paramstyles(headerData?.method)}
                                        >
                                            {"{"}{headerData?.params}{"}"}
                                        </div>
                                        {/* {headerData?.method === 'POST' ?
                                            <div className="text-sm font-mono font-medium rounded-md px-1 border-2 min-w-max text-[#3064E3] bg-[#3064E3]/10 border-[#3064E3]/30">
                                                {"{"}{headerData?.params}{"}"}
                                            </div> :
                                            <div className="text-sm font-mono font-medium rounded-md px-1 border-2 min-w-max text-[#2AB673] bg-[#2AB673]/10 border-[#2AB673]/30">
                                                {"{"}{headerData?.params}{"}"}
                                            </div>} */}
                                    </>}
                                </div>
                            </div>
                            {/* <button className="flex items-center justify-center w-16 h-9 text-white font-medium rounded-lg mouse-pointer disabled:opacity-70 hover:opacity-80 bg-[#3064E3]">
                                  Send
                                  </button> */}
                        </div>
                        <AccordianData header={head}
                            body={body
                            }
                        />


                    </div>

                </div>

            </div>

            <div className="leading-6 mt-14">
                <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                    {!isEmpty(backNavigate) && backNavigate?.url && <Link to={backNavigate?.url}
                        className="flex items-center space-x-3 group"
                    // onClick={() => handleActiveTab(backNavigate?.url)}
                    >
                        <svg
                            viewBox="0 0 3 6"
                            className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                        >
                            <path
                                d="M3 0L0 3L3 6"
                                fill="none"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                            {backNavigate?.title}
                        </span>
                    </Link>}
                    {!isEmpty(forwardNavigate) && forwardNavigate?.url && <Link to={forwardNavigate?.url}
                        className="flex items-center ml-auto space-x-3 group"
                    // onClick={() => handleActiveTab(forwardNavigate?.url)}
                    >
                        <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                            {forwardNavigate?.title}
                        </span>
                        <svg
                            viewBox="0 0 3 6"
                            className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                        >
                            <path
                                d="M3 0L0 3L3 6"
                                fill="none"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Link>}
                </div>
                <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                    <div className="flex mb-6 space-x-6 sm:mb-0">
                        {/* <a

                            >
                                <span className="sr-only">linkedin</span>
                                <svg
                                    className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                    style={{
                                        maskImage:
                                            'url("https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg")',
                                        maskRepeat: "no-repeat",
                                        maskPosition: "center center"
                                    }}
                                />
                            </a> */}
                    </div>
                    <div className="sm:flex">
                        <a

                            rel="noreferrer"
                            className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                        >
                            Fractal Pay
                        </a>
                    </div>
                </footer>
            </div>
        </div >
    )
}

export default DefaultLayout
