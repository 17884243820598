export const PaymentSuccess = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.12669 13.9774C5.97396 14.131 5.76558 14.2167 5.54913 14.2167C5.33267 14.2167 5.1243 14.131 4.97157 13.9774L0.359012 9.36408C-0.119671 8.8854 -0.119671 8.10919 0.359012 7.6314L0.936573 7.05369C1.4154 6.57501 2.19072 6.57501 2.6694 7.05369L5.54913 9.93357L13.3306 2.15198C13.8094 1.6733 14.5855 1.6733 15.0634 2.15198L15.641 2.72969C16.1196 3.20837 16.1196 3.98444 15.641 4.46237L6.12669 13.9774Z" fill="#61C699" />
        </svg>

    )
}

export const FileIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 24 32"
        >
            <g fill="#35254D">
                <path d="M1.5 32h21c.827 0 1.5-.673 1.5-1.5v-21c0-.017-.008-.031-.009-.047c-.002-.023-.008-.043-.013-.065a.488.488 0 0 0-.09-.191c-.007-.009-.006-.02-.013-.029l-8-9c-.003-.003-.007-.003-.01-.006a.494.494 0 0 0-.223-.134c-.019-.006-.036-.008-.056-.011C15.557.012 15.53 0 15.5 0h-14C.673 0 0 .673 0 1.5v29c0 .827.673 1.5 1.5 1.5zM16 1.815L22.387 9H16.5c-.22 0-.5-.42-.5-.75V1.815zM1 1.5a.5.5 0 0 1 .5-.5H15v7.25c0 .809.655 1.75 1.5 1.75H23v20.5a.5.5 0 0 1-.5.5h-21c-.28 0-.5-.22-.5-.5v-29z" />
                <path d="M5.5 14h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0 4h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0-8h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0 0 1zm0 12h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0 4h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1z" />
            </g>
        </svg>
    )
}
export const Transactionicon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10541_18344)">
                <path d="M16 5.99462C16.0004 6.06044 15.9869 6.12568 15.9604 6.18661C15.9339 6.24754 15.8948 6.30295 15.8453 6.34968L12.6453 9.35022C12.5955 9.39657 12.5364 9.43324 12.4714 9.45812C12.4064 9.48301 12.3369 9.49562 12.2667 9.49524C12.1973 9.49604 12.1285 9.48415 12.064 9.46024C11.9658 9.4224 11.8818 9.35792 11.8229 9.27506C11.764 9.1922 11.7328 9.09474 11.7333 8.99515V7.49489H6.93333C6.79189 7.49489 6.65623 7.4422 6.55621 7.34841C6.45619 7.25463 6.4 7.12743 6.4 6.9948V4.99444C6.4 4.86181 6.45619 4.73461 6.55621 4.64083C6.65623 4.54704 6.79189 4.49435 6.93333 4.49435H11.7333V2.99409C11.7338 2.89518 11.7655 2.79862 11.8244 2.71662C11.8834 2.63461 11.967 2.57084 12.0646 2.53337C12.1622 2.4959 12.2695 2.48641 12.3729 2.50611C12.4762 2.52581 12.571 2.5738 12.6453 2.64402L15.8453 5.64456C15.9439 5.7377 15.9994 5.86343 16 5.99462ZM9.06666 8.49507H4.26667V6.9948C4.26615 6.89607 4.23446 6.79969 4.17562 6.71782C4.11678 6.63594 4.0334 6.57223 3.936 6.53472C3.83888 6.49642 3.73183 6.48596 3.62837 6.50465C3.52491 6.52334 3.42967 6.57034 3.35467 6.63973L0.154675 9.64027C0.105245 9.687 0.0661386 9.74241 0.0395975 9.80334C0.0130565 9.86427 -0.000396895 9.92952 8.91428e-06 9.99533C-0.000396895 10.0611 0.0130565 10.1264 0.0395975 10.1873C0.0661386 10.2482 0.105245 10.3037 0.154675 10.3504L3.35467 13.3509C3.40451 13.3973 3.46361 13.4339 3.52859 13.4588C3.59357 13.4837 3.66315 13.4963 3.73334 13.496C3.8033 13.4976 3.87271 13.4839 3.936 13.4559C4.0334 13.4184 4.11678 13.3547 4.17562 13.2728C4.23446 13.191 4.26615 13.0946 4.26667 12.9959V11.4956H9.06666C9.20811 11.4956 9.34377 11.4429 9.44379 11.3491C9.54381 11.2553 9.6 11.1281 9.6 10.9955V8.99515C9.6 8.86252 9.54381 8.73532 9.44379 8.64154C9.34377 8.54775 9.20811 8.49507 9.06666 8.49507Z" fill="#35254D" />
            </g>
            <defs>
                <clipPath id="clip0_10541_18344">
                    <rect width="16" height="16" fill="white" transform="translate(0 -0.00390625)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const SearchIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7372_11113)">
                <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
            </g>
            <defs>
                <clipPath id="clip0_7372_11113">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

