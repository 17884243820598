import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Modal } from "react-bootstrap";
import { useRefToken } from '../../../hooks/useToken';
import axios from 'axios';
import baseUrl from '../../../../baseUrl';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import PaginationPg from '../../../DefaultComponents/PaginationPg';
import Loader from '../../../DefaultComponents/Loader';
import CardCheck from '../../../DefaultComponents/CardCheck';
import { RefundIcon, VoidIcon } from '../../../DefaultComponents/SvgiconsSA';
// import { RefundIcon, VoidIcon } from '../../../../DefaultComponent/Svgicons';
import View from "../../../../assets/images/View.svg";
import { useGetSidebarQuerySuperAdmin } from '../../../../redux/superadminApiSlice';
import { useSelector } from 'react-redux';

const ClientTransaction = () => {
    const { deviceId } = useSelector(state => state.deviceId);
    const { clientId, merchantId } = useParams();
    // console.log("merchantId ", merchantId);
    // console.log("clientId", clientId);
    const { data: userData, isLoading, isError } = useGetSidebarQuerySuperAdmin();
    let role_id = userData?.data?.staff_roll_type
    const refToken = useRefToken();
    const navigate = useNavigate();
    const [clientTransList, setClientTransList] = useState([]);
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        totalPage: null,
        searchValue: "",
        deleteId: '',
        delete: false,
        add: false,
        edit: false,
        loading: false,
        row: {},
    });
    const fetchClientTransactions = async (
        newPage, searchValue
    ) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const params = {
                page: newPage,
            };

            // if (newPerPage) {
            //     params.perPage = newPerPage;
            // }l
            // console.log("!typeof merchantId ==", typeof merchantId == "string");

            if (searchValue) {
                params.search = searchValue;
            }
            if (clientId) {
                params.client_id = clientId;
            }
            if (typeof merchantId == "string") {
                params.merchant_id = merchantId;
            }
            // console.log("params", params);

            const { data } = await axios.get(
                `${baseUrl}/api/v1/super-admin/transaction/list`,
                {
                    headers: {
                        Authorization: refToken,
                        'X-Device-Fingerprint': deviceId,
                    },
                    params,
                }
            );
            setState((prev) => ({ ...prev, loading: false }));
            if (data?.status) {
                setClientTransList(data?.data?.allTransaction);
                setState((prev) => ({ ...prev, totalPage: data?.data?.totalPage }));
            }
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            if (error.response.data.message.includes("Unauthorized")) {
                localStorage.removeItem("refreshToken");
                navigate("/super-admin-login");
            }
            toast.error(error?.response.data.message);
            console.error(error);
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px" }} className="fas">
                            &#xf110;
                        </i>
                        Pending
                    </div>
                );
            case 2:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: 'green' }} className="fas fa-check"></i>
                        Success
                    </div>
                );
            case 5:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: '#8161C6' }} className="fas fa-redo-alt"></i>
                        Refunded
                    </div>
                );
            case 3:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: 'red' }} className="far fa-times-circle"></i>
                        Declined
                    </div>
                );
            case 4:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: '#BE7D4D' }} className="fas fa-pause"></i>
                        On-hold
                    </div>
                );
            case 6:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: '#9E3636' }} className="fas fa-circle-minus"></i>
                        Voided
                    </div>
                );
            case 7:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: 'green' }} className="fa-solid fa-check"></i>
                        Settled
                    </div>
                );
            case 8:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px" }} className="fas">
                            &#xf110;
                        </i>
                        Authorized
                    </div>
                );
            default:
                return "";
        }
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return `${month}.${day}.${year}`;
    };

    const handleState = (item, value) => {
        setState((prev) => ({
            ...prev,
            [item]: value,
        }));
    };

    const handlePageChange = (pageNo) => {
        setState((prev) => ({
            ...prev,
            page: pageNo,
        }));
        fetchClientTransactions(pageNo, state.searchValue);
        setClientTransList([]);
    }

    const handleSearchChange = (e) => {
        const searchData = e.target.value;
        setState((prev) => ({
            ...prev,
            searchValue: searchData,
        }));
        handleSearch(searchData);
    };

    const handleSearch = useCallback(
        debounce((searchQuery) => {
            setClientTransList([]);
            fetchClientTransactions(state.page, searchQuery);
        }, 700),
        []
    );


    const handleAction = async (id, amount, action) => {
        setState((prev) => ({ ...prev, loading: true }));
        let url = action === 'refund' ? `${baseUrl}/api/v1/super-admin/transaction/sales-return` : `${baseUrl}/api/v1/super-admin/transaction/sales-void`
        let obj = {
            transaction_id: id,
        }
        try {
            const response = await axios.post(url, obj, {
                headers: {
                    Authorization: refToken,
                    'X-Device-Fingerprint': deviceId,
                }
            })
            // console.log(url, id, response, "response");
            if (response?.status == 200) {
                fetchClientTransactions(state.page, state.searchValue)
            }
        } catch (error) {
            console.log(error?.response, "error");

            toast.error(error?.response?.data?.message || "Something went wrong!!")
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    const HandleRefund = (e, transaction) => {
        e.stopPropagation()
        // console.log(transaction, "Refund", transaction?.pos_guid);
        handleAction(transaction?.pos_guid, transaction?.Amount, 'refund')
    }

    const HandleVoid = (e, transaction) => {
        e.stopPropagation()
        // console.log(transaction, "Void");
        handleAction(transaction?.pos_guid, transaction?.Amount, 'void')
    }

    useEffect(() => {
        fetchClientTransactions(state.page, state.searchValue);
    }, [])

    return (
        <>
            <div className="">
                <div className="dashboard-filter payments mb-4 row"   >
                    <div className="col-lg-3">
                        <p className="heading">{typeof merchantId == "string" ? "Merchant Transactions" : "Client Transactions"}</p>
                    </div>
                    <div className="d-flex col-lg-9 justify-content-end search-dates" >

                        {/* <div className="" > */}
                        {/* {role_id == "132" && staff_roll_type == "277" && <ExportCSV />} */}
                        {/* </div> */}
                        {(role_id === 133 || role_id === 280 || role_id === 281 || role_id === 282 || role_id === 283) && (<div>
                            <button
                                className="second-blk-btn"
                                onClick={() => { navigate("/admin/create-transaction") }}
                            >
                                Create Record  <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>)}
                        <div className="search-box">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7372_11113)">
                                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7372_11113">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchChange}
                                value={state.searchValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="date-picker-container overflow-x-auto">
                <Table>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Merchant Name</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Last 4</th>
                            {/* <th>Brand</th> */}
                            <th>Status</th>
                            <th>Fee</th>
                            <th>Net</th>
                            <th style={{ width: '12%' }} >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientTransList && clientTransList.length > 0 ? (
                            clientTransList?.map((transaction) => (
                                <tr
                                    className="table-hover cursor-pointer"
                                    key={transaction?.pos_guid}
                                    onClick={() => navigate(`/admin/transactions/${transaction?.pos_guid}`)}
                                >
                                    <td>{formatDate(transaction.Date)}</td>
                                    <td>
                                        {transaction.merchantDetails?.businessName
                                            ? transaction.merchantDetails?.businessName
                                            : "Merchant Name"}
                                    </td>
                                    <td style={{ color: "#383838", fontWeight: 700 }}>
                                        {typeof transaction.Amount !== "string"
                                            ? `$${parseFloat(transaction.Amount).toLocaleString(
                                                "en-US",
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )}`
                                            : `${transaction.Amount.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                    </td>
                                    <td>{transaction.Type}</td>
                                    <td>
                                        {(transaction.cardType || transaction.lastFourDigits) ? (
                                            <CardCheck type={transaction.cardType} lastFour={transaction.lastFourDigits} />
                                        ) : null}
                                    </td>
                                    <td>{getStatusLabel(transaction.is_status)}</td>
                                    <td>{`$${Number(transaction.fee_amount).toFixed(2)}`}</td>
                                    <td>{`$${Number(transaction.Net).toFixed(2)}`}</td>
                                    <td>


                                        <div className="button-hover">
                                            {
                                            transaction?.is_status == 2 && (role_id === 133 || role_id === 280 || role_id === 281 || role_id === 282 || role_id === 283 || role_id === 308) && (                                                    <>
                                            <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                                                <img
                                                    src={View}
                                                    alt="view"
                                                    onClick={() => navigate(`/admin/transactions/${transaction?.pos_guid}`)}
                                                />
                                            </div>
                                            </>)
                                            }       
                                            {
                                                transaction?.is_status == 2 && (role_id === 133 || role_id === 280 || role_id === 282) && (
                                                    <>
                                                        <div className="tooltip-toggle" aria-label="Refund" tabIndex="0" >
                                                            <div onClick={(e) => { HandleRefund(e, transaction) }} >
                                                                <RefundIcon />
                                                            </div>
                                                        </div>
                                                        <div className="tooltip-toggle" aria-label="Void" tabIndex="0" >
                                                            <div onClick={(e) => { HandleVoid(e, transaction) }}  >
                                                                <VoidIcon />
                                                            </div>
                                                        </div>
                                                    </>)
                                            }
                                        </div>

                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} style={{ textAlign: "center" }}>
                                    No transaction available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div>
                <PaginationPg
                    totalPages={state.totalPage}
                    onPageChange={handlePageChange}
                    current={state.page}
                />
                {state.loading && <Loader loading={state.loading} />}
            </div>

        </>
    )
}

export default ClientTransaction
