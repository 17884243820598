import React, { useEffect, useState } from 'react'
import { createDataCapGateway, getDataCapGateway, updateDataCapGateway } from '../../../api/PaymentGateway';
import { useRefToken } from '../../../hooks/useToken';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

const AddPaymentGateway = ({ merchantId, state, setState, incrementLoading, decrementLoading, fetchPaymentGateWay, paymentGateways }) => {
    // token  fetch 
    const token = useRefToken();

    // Fractal payment State
    const [merchant_key, setMerchant_key] = useState("");
    const [merchant_error, setMerchant_error] = useState("");

    // DataCap payment gateway state
    const [datacap, setDatacap] = useState({});
    const [datacap_error, setDatacap_error] = useState({});


    // Default Method
    const handleChangeGateWay = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, gatewayMth: value }));
    }

    const handleClose = () => {
        setState((prev) => ({ ...prev, gateway: false }));
    }


    // Handle DataCap Payment
    const handleChange = (e) => {
        const { name, value } = e.target;
        setDatacap((prev) => ({ ...prev, [name]: value }));
    }

    const requiredValue = (value) => {
        if (!value) {
            return false;
        }
        return true;
    };

    const Validation = () => {
        let error = {};
        if (!requiredValue(datacap.merchant_application_id)) {
            error.merchant_application_id = "merchant application id is required";
        }
        if (!requiredValue(datacap.infinicept_application_id)) {
            error.infinicept_application_id = "infinicept application id is required";
        }
        if (!requiredValue(datacap.ecommerce_mId)) {
            error.ecommerce_mId = "ecommerce MId is required";
        }
        if (!requiredValue(datacap.ecommerce_token_key)) {
            error.ecommerce_token_key = "ecommerce token key is required";
        }
        if (!requiredValue(datacap.dc_direct_MID)) {
            error.dc_direct_MID = "dc direct MID is required";
        }
        if (!requiredValue(datacap.datacap_app_MID)) {
            error.datacap_app_MID = "datacap app MID name is required";
        }
        if (!requiredValue(datacap.apple_app_MID)) {
            error.apple_app_MID = "apple app MID is required";
        }
        if (!requiredValue(datacap.google_app_MID)) {
            error.google_app_MID = "google app MID is required";
        }
        if (Object.keys(error).length < 1) {
            return false;
        }
        return error;
    };

    const handleDataCapPayment = async (e) => {
        e.preventDefault();
        setDatacap_error({});
        let errors = Validation();
        if (!errors) {
            if (!isEmpty(state.gatewayEdit)) {
                incrementLoading();
                try {
                    const response = await updateDataCapGateway(token, merchantId, state.payment_id, { ...datacap, gatewayType: state.gatewayMth.toUpperCase() });
                    fetchPaymentGateWay();
                    toast.success(response?.data?.message);
                    handleClose();
                } catch (error) {
                    console.log("error", error);
                    toast.error(error?.response?.data?.message);
                } finally {
                    decrementLoading();
                }
            } else {
                incrementLoading();
                try {
                    const response = await createDataCapGateway(token, merchantId, { ...datacap, gatewayType: state.gatewayMth.toUpperCase() });
                    fetchPaymentGateWay();
                    toast.success(response?.data?.message);
                    handleClose();
                } catch (error) {
                    console.log("error", error);
                    toast.error(error?.response?.data?.message);

                } finally {
                    decrementLoading();
                }
            }
        } else {
            setDatacap_error(errors);
        }
    }


    // Handle Fractal Payment
    const handleFractalChange = (e) => {
        const { value } = e.target;
        let val = value?.trim();
        if (val != "") {
            setMerchant_key(val);
        } else {
            setMerchant_error("merchant key is required");
        }
    }
    const handleFractalPayment = () => {
        if (merchant_key != "") {
            // write fractal payment api intergration 
            toast.error("Fractal payment is not available");
        } else {
            setMerchant_error("merchant key is required");
        }
    }

    const handleDefaultDataCap = async(valueMID, dataToken) => {
        try {
            let data = {
                PayAPIPublicKey: dataToken
            }
            incrementLoading();
            const res = await getDataCapGateway(valueMID, data);
            console.log("res Datacap values", res);

        } catch (error) {
            console.log("error", error);
            toast.error(error?.response?.data?.Message);

        } finally {
            decrementLoading();
        }
    }

    useEffect(() => {
        if (!isEmpty(state.gatewayEdit)) {
            setDatacap({
                merchant_application_id: state.gatewayEdit.merchant_application_id,
                infinicept_application_id: state.gatewayEdit.infinicept_application_id,
                ecommerce_mId: state.gatewayEdit.ecommerce_mId,
                ecommerce_token_key: state.gatewayEdit.ecommerce_token_key,
                merchant_api_key: state.gatewayEdit.merchant_api_key,
                dc_direct_MID: state.gatewayEdit.dc_direct_MID,
                datacap_app_MID: state.gatewayEdit.datacap_app_MID,
                apple_app_MID: state.gatewayEdit.apple_app_MID,
                google_app_MID: state.gatewayEdit.google_app_MID,
            })
        } else if (state.gatewayMth === "infinicept" && paymentGateways.length == 1) {
            let values = paymentGateways[0].pay_method_name_address;
            let dataCapValue = {
                "merchant_application_id": "",
                "infinicept_application_id": "",
                "ecommerce_mId": "",
                "ecommerce_token_key": "",
                "merchant_api_key": "",
                "dc_direct_MID": "",
                "datacap_app_MID": "",
                "apple_app_MID": "",
                "google_app_MID": "",
            }
            let dataCap = Object.keys(dataCapValue)
            values = values?.split(":")
            for (let i = 0; i < dataCap.length; i++) {
                dataCapValue[dataCap[i]] = values[i]
            }
            handleDefaultDataCap(dataCapValue.ecommerce_mId, dataCapValue.ecommerce_token_key);
        }
    }, [state.gatewayEdit])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <label className="text-capitalize">Active Payment Gateway</label>
                        <select
                            className="custom-date-picker"
                            value={state.gatewayMth}
                            onChange={handleChangeGateWay}
                        >
                            <option value="fractal">Fractal</option>
                            <option value="infinicept">Datacap</option>
                        </select>
                    </div>
                </div>
                {
                    state.gatewayMth === "infinicept" ? (
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <label className="text-capitalize">Merchant Application Id</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Merchant Application Id"
                                        name="merchant_application_id"
                                        value={datacap.merchant_application_id}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.merchant_application_id && (
                                        <span className="text-danger fs-12">{datacap_error.merchant_application_id}</span>
                                    )}
                                </div>

                                <div className="col-6">
                                    <label className="text-capitalize">Infinicept Application Id</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        name="infinicept_application_id"
                                        value={datacap.infinicept_application_id}
                                        placeholder="Infinicept Application Id"
                                        onChange={handleChange}
                                    />
                                    {datacap_error.infinicept_application_id && (
                                        <span className="text-danger fs-12">
                                            {datacap_error.infinicept_application_id}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="text-capitalize">Ecommerce MId</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Ecommerce MId"
                                        name="ecommerce_mId"
                                        value={datacap.ecommerce_mId}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.ecommerce_mId && (
                                        <span className="text-danger fs-12">{datacap_error.ecommerce_mId}</span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label className="text-capitalize">Ecommerce Token Key</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        name="ecommerce_token_key"
                                        placeholder="Ecommerce Token Key"
                                        value={datacap.ecommerce_token_key}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.ecommerce_token_key && (
                                        <span className="text-danger fs-12">
                                            {datacap_error.ecommerce_token_key}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="text-capitalize">Merchant Api Key (Optional)</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Merchant Api Key"
                                        name="merchant_api_key"
                                        value={datacap.merchant_api_key}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.merchant_api_key && (
                                        <span className="text-danger fs-12">{datacap_error.merchant_api_key}</span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label className="text-capitalize">Dc Direct MID</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        name="dc_direct_MID"
                                        placeholder="Dc Direct MID"
                                        value={datacap.dc_direct_MID}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.dc_direct_MID && (
                                        <span className="text-danger fs-12">
                                            {datacap_error.dc_direct_MID}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="text-capitalize">Datacap App MID</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Datacap App MID"
                                        name="datacap_app_MID"
                                        value={datacap.datacap_app_MID}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.datacap_app_MID && (
                                        <span className="text-danger fs-12">{datacap_error.datacap_app_MID}</span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label className="text-capitalize">Apple App MID</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        name="apple_app_MID"
                                        placeholder="Apple App MID"
                                        value={datacap.apple_app_MID}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.apple_app_MID && (
                                        <span className="text-danger fs-12">
                                            {datacap_error.apple_app_MID}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="text-capitalize">Google App MID</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Google App MID"
                                        name="google_app_MID"
                                        value={datacap.google_app_MID}
                                        onChange={handleChange}
                                    />
                                    {datacap_error.google_app_MID && (
                                        <span className="text-danger fs-12">{datacap_error.google_app_MID}</span>
                                    )}
                                </div>
                            </div>

                            <input type="text" name="bot_key"
                                onChange={handleChange}
                                style={{ display: "none !important" }} hidden />
                            <div className="row">
                                <div className="col-6">
                                    <button className="idle-blk-btn w-100" onClick={handleClose}>Cancel</button>
                                </div>
                                <div className="col-6">
                                    <button className="idle-green-btn w-100" onClick={handleDataCapPayment}>
                                        {!isEmpty(state.gatewayEdit) ? 'Update' : 'Add'}
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-capitalize">Merchant Key</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Merchant Key"
                                        name="merchant_key"
                                        onChange={handleFractalChange}
                                    />
                                    {merchant_error && (
                                        <span className="text-danger fs-12">
                                            {merchant_error}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <input type="text" name="bot_key"
                                //   onChange={handleChange} 
                                style={{ display: "none !important" }} hidden />
                            <div className="row">
                                <div className="col-6">
                                    <button className="idle-blk-btn w-100" onClick={handleClose}>Cancel</button>
                                </div>
                                <div className="col-6">
                                    <button className="idle-green-btn w-100" onClick={handleFractalPayment}>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}

export default AddPaymentGateway
