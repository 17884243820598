import React, { useState, useEffect, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import baseUrl from '../../../../baseUrl';
import { useRefToken } from '../../../hooks/useToken';
import Loader from '../../../DefaultComponents/Loader';
import { useSelector } from 'react-redux';

const ClientLogs = () => {
    const token = useRefToken();
    const { deviceId } = useSelector(state => state.deviceId);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const loader = useRef(null);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchLogs = async (pageNumber) => {
        setLoading(true);
        try {
            const response = await fetch(`${baseUrl}/api/v1/super-admin/log/log-list?page=${pageNumber}`, {
                headers: {
                    Authorization: token,
                    'X-Device-Fingerprint': deviceId,
                },
            });
            const logs = await response.json();

            setData((prevData) =>
                pageNumber > 1 ? [...prevData, ...logs.data.logData] : logs.data.logData
            );
            setTotalPages(logs.data.totalPage);
            setHasMore(pageNumber < logs.data.totalPage);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs(page);
    }, [page]);

    const loadMoreData = () => {
        if (hasMore && !loading) {
            const nextPage = page + 1;
            setPage(nextPage);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    loadMoreData();
                }
            },
            { threshold: 1.0 }
        );

        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        };
    }, [loader, hasMore, loading]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <p className="heading">Client Logs</p>
                    </div>
                </div>
                <Accordion className="logs-box">
                    {data?.map((item, index) => {
                        const date = new Date(item.timestamp);
                        const localYear = date.getFullYear();
                        const localMonth = String(date.getMonth() + 1).padStart(2, '0');
                        const localDay = String(date.getDate()).padStart(2, '0');
                        const localHour = String(date.getHours()).padStart(2, '0');
                        const localMinute = String(date.getMinutes()).padStart(2, '0');
                        const localSecond = String(date.getSeconds()).padStart(2, '0');
                        const formattedTimestamp = `${localMonth}-${localDay}-${localYear} • ${localHour}:${localMinute}:${localSecond}`;

                        return (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row g-md-0 g-2">
                                                    <div className="col-sm-1 col-5">
                                                        <div className="post-btn text-center">{item.method}</div>
                                                    </div>
                                                    <div className="logs-url col-sm-10 text-break px-2  ">{item.url}</div>
                                                    <div className="purple col-sm-1 text-md-end">{item.response.status}</div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div>
                                                    <div className="logs-date">{formattedTimestamp}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ maxHeight: "300px", overflowY: "auto" }}>
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Body</h5>
                                                <pre>{JSON.stringify(item.body, null, 2)}</pre>
                                            </div>
                                            <div className="col-md-6">
                                                <h5>Response</h5>
                                                <pre>{JSON.stringify(item.response, null, 2)}</pre>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                    {loading && <Loader loading={loading} />}
                    {error && (
                        <div className="row alert alert-danger mt-3">
                            <small className="alert-info text-center">{error.message}</small>
                        </div>
                    )}
                    {!data.length && !loading && (
                        <div className="row alert alert-danger mt-3">
                            <small className="alert-info text-center">No logs available</small>
                        </div>
                    )}
                    <div ref={loader} style={{ height: "20px" }} />
                </Accordion>
            </div>
        </>
    );
};

export default ClientLogs;