import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DragNDrop from "../../../components/DragNDrop";
import baseUrl from "../../../baseUrl";
import { useToken } from "../../../hooks/useToken";
import { useSelector } from "react-redux";

const DownloadMerchantCSV = ({ handleState, state }) => {
  const { deviceId } = useSelector(state => state.deviceId);
  const id = state.row.id;
  const token = useToken();
  const [files, setFiles] = useState([]);
  const [bot, setBot] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setBot(value);
  };
  const handleSubmit = async () => {
    if (files.length === 0) {
      toast.error("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("bot_key", bot);
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      handleState("loading", true);
      const response = await axios.post(
        `${baseUrl}/api/v1/admin/merchant/upload-documents/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
            'X-Device-Fingerprint': deviceId,
          },
        }
      );
      handleState("download", false);
      toast.success("Files uploaded successfully.");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    } finally {
      handleState("loading", false);
    }
  };

  return (
    <div>
      <div className="row d-flex justify-content-center align-items-center my-4">
        <DragNDrop onFilesSelected={setFiles} width="auto" height="auto" />
        <input type="text" name="bot_key" onChange={handleChange} style={{ display: "none !important" }} hidden />
      </div>

      <div className="row">
        <div className="col d-flex justify-content-center">
          <button
            onClick={handleSubmit}
            className="btn btn-primary"
            disabled={state.loading}
          >
            {state.loading ? "Uploading..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadMerchantCSV;
