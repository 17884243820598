import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRefToken } from "../../../hooks/useToken";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import AddEmployee from "../Form/AddEmployee";
import EditEmployee from "../Form/EditEmployee";
import PinEmployee from "../Form/PinEmployee";
import Forgotpass from "../Form/Forgotpass";
import "../../../../pages/Merchant/Tabs/MerchantOnboard.css";
import Edit from "../../../../assets/images/Edit.svg";
import Delete from "../../../../assets/images/Delete.svg";
import { useDispatch } from "react-redux";
import { debounce, isEmpty } from "lodash";
import { deleteSuperAdminEmployee, getSuperAdminEmployee, getSuprAdminRolesAll } from "../../../api/staff";
import { tokenAction } from "../../../../redux/tokenSlice";
import Loader from "../../../DefaultComponents/Loader";
import { SearchIcon } from "../../../../DefaultComponent/Svgicons";

const Staff = () => {
  const token = useRefToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState([]);
  const [state, setState] = useState({
    page: 1,
    perPage: 10,
    totalPage: 0,
    searchParam: '',
    filterParam: null,
    message: "",
    loading: false,
    update: {},
    pass: {},
    deleteId: null,
    role: false,
    name: ""
  });

  const [form, setForm] = useState({
    show: false,
    edit: false,
    pin: false,
    pass: false,
    delete: false,
  });
  const [role, setRole] = useState([]);
  const [info, setInfo] = useState("");
  const divLoader = useRef(null);
  const [fetMoreData, setFetchMoreData] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(false);

  const handleInfo = (value) => {
    if (value == "user") {
      setInfo("user");
    } else {
      setInfo("security");
    }
  };
  const handleState = (item, value) => {
    setForm((prev) => ({
      ...prev,
      [item]: value,
    }));
    setHasMore(true);

  };
  let isScroll = true;
  const fetchEmployees = async (pageNo, perPageNo, searchValue, roleId) => {
    const params = {
      page: pageNo,
      perPage: perPageNo,
    };
    if (searchValue?.trim()) {
      params.search = searchValue;
    }
    if (roleId) {
      params.role_id = roleId;
    }
    try {
      if (isScroll) {
        setState((prev) => ({
          ...prev,
          loading: true,
        }));
        const response = await getSuperAdminEmployee(token, params);

        if (pageNo == 1) {
          setEmployee([]);
        }
        if (!response.status) {
          setHasMore(false)
          isScroll = false;
        }
        if (response?.data?.allList?.length === 0 && response?.data?.count === 0) {
          console.log("no more data as data is empty");

          setHasMore(false);
          setNoDataMessage(true);
          setFetchMoreData(0);
          setState((prev) => ({
            ...prev,
            loading: false,
          }));
          setEmployee([]);
          return false;
        }

        if (response?.data?.totalPage == pageNo) {
          setHasMore(false)
          setFetchMoreData(0);
        }
        else {
          setHasMore(true);
          setFetchMoreData(fetMoreData + 1);

        }
        if (response.status) {
          setEmployee((prev) => [...prev, ...response?.data.allList]);
          setNoDataMessage(false);
          setState((prev) => ({
            ...prev,
            page: state.page + 1,
          }));
        }
      }
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.message?.includes("Unauthorized")) {
        navigate("/super-admin-login");
        localStorage.removeItem("token");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (hasMore || !state.searchParam) {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchEmployees(state.page, state.perPage, state.searchParam, state.filterParam);
        }
      }, options);

      if (divLoader.current) {
        observer.observe(divLoader.current);
      }

      return () => {
        if (divLoader.current) {
          observer.unobserve(divLoader.current);
        }
      };
    }
  }, [state.page, fetMoreData, state.filterParam]);

  const fetchRoles = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const param = {
      isAll: true,
    };
    try {
      const response = await getSuprAdminRolesAll(token, param);
      setRole(response);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleSearchChange = (e) => {
    const searchData = e.target.value;
    if (searchData != " ") {
      setState((prev) => ({
        ...prev,
        page: 1,
        searchParam: searchData,
        filterParam: null,
      }));
      handleSearch(searchData);
    } else {
      setState((prev) => ({
        ...prev,
        page: 1,
        searchParam: null,
      }));
      setHasMore(true)
    }
  };

  const handleSearch = useCallback(
    debounce((searchQuery) => {
      fetchEmployees(1, state.perPage, searchQuery, null);
    }, 700),
    []
  );

  const handleEdit = (item, value) => {
    handleState("edit", true);
    handleInfo(value);
    setState((prev) => ({
      ...prev,
      update: item,
      pass: item,
    }));
  };

  const handleDelete = (item) => {
    handleState("delete", true);
    setState((prev) => ({
      ...prev,
      deleteId: item.id,
      name: `${item.first_name} ${item.last_name}`
    }));
  };

  const handleDeleteEmployee = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response = await deleteSuperAdminEmployee(token, state.deleteId);
      dispatch(tokenAction.showToaster({ status: true, message: `You have deleted ${state?.name} `, open: true }));

      if (response?.result == true) {
        let newData = employee?.filter((item) => {
          if (item?.id != state.deleteId) {
            return item;
          }
        });
         setEmployee(newData)
      }
      handleState("delete", false);
      setState((prev) => ({
        ...prev,
        // page:1,
        loading: false,
      }));
    } catch (error) {
      dispatch(tokenAction.showToaster({ status: false, message: error?.response?.message, open: true }));
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };
  const handlePin = (item) => {
    handleState("pin", true);
    setState((prev) => ({
      ...prev,
      pin: item,
    }));
  };

  const handleFilter = (id) => {
    setEmployee([]);
    setHasMore(true);
    setState((prev) => ({
      ...prev,
      page: 1,
      filterParam: id,

      // loading: true,
    }));
    setFetchMoreData(fetMoreData + 1);
    // fetchEmployees(1, state.perPage, state.searchParam, id);
  };
  const handleAll = () => {
    setEmployee([]);
    setHasMore(true);
    setFetchMoreData(fetMoreData + 1);
    setState((prev) => ({
      ...prev,
      page: 1,
      filterParam: null,
      // loading: true,
    }));
  };

  useEffect(() => {
  
    fetchRoles();
  }, []);

  // color gradient
  const gradientCircle = ({ color }) => {
    const style = {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      // background: `${color}`,
      background: `linear-gradient(171deg, ${color} 0%, rgba(239,239,242,1) 83%)`,
    };

    return style;
  };
  // console.log("hasMore", employee.length ,hasMore,divLoader)
  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-1">
            <h4 className="app-page-title">Staff</h4>
          </div>
          <div className="col-lg-11 d-md-flex justify-content-end">
            <div className="d-flex justify-content-start align-items-start gap-2 flex-wrap ">
              <div className="search-box">
                <SearchIcon />

                <input
                  className="searchbox"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={handleSearchChange}
                  value={state.searchParam}
                />
              </div>
              <button
                className={`${state.filterParam === null ? "sky-btn-1" : "sky-btn-2"
                  } `}
                // style={{
                //   border: `1px solid ${
                //     state.filterParam === null ? "#35254d" : "d4cddf"
                //   }`,
                // }}
                onClick={handleAll}
              >
                All
              </button>
              {role?.length > 0 && role?.map((item) => (
                <button
                  className={`${state.filterParam === item.role_id ? "sky-btn-1" : "sky-btn-2"
                    } `}
                  style={{ textTransform: 'capitalize' }}
                  key={item.role_id}
                  onClick={() => { handleFilter(item.role_id); setState((pre) => ({ ...pre, searchParam: "" })) }}
                // style={{
                //   border: `1px solid ${
                //     state.filterParam === item.role_id ? "#35254d" : "d4cddf"
                //   }`,
                // }}
                >
                  {item.role_name}
                </button>
              ))}
              <button
                className="second-blk-btn py-1"
                onClick={() => handleState("show", true)}
              >
                Add staff +
              </button>
            </div>
          </div>
        </div>
        <div className="table-responsive team-main-sec">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
           
              {employee &&
                employee?.map((item) => {
                  return (
                    <tr className="table-hover cursor-pointer" key={item.id}>
                      <td className="d-flex">
                        <div
                          className="round-gradient me-2"
                          style={gradientCircle("rgba(195, 209, 4)")}
                        ></div>
                        <div className="us-name">
                          <span className="d-block">
                            {item.first_name} {item.last_name}
                          </span>
                          <span className="fs-12 text-gray mt-0">
                            {item.email}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span style={{ textTransform: 'capitalize' }} className="truncate">{item?.role?.role_name}</span>
                      </td>
                      <td>
                        <div className="button-hover">
                          <div className="tooltip-toggle" aria-label="Security" tabIndex="0">
                            <i
                              className="fas fa-key"
                              onClick={() => handleEdit(item, "security")}
                            ></i>
                          </div>
                          <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                            <img
                              style={{ marginLeft: 5 }}
                              src={Edit}
                              alt="Edit"
                              onClick={() => handleEdit(item, "user")}
                            />
                          </div>
                          <div className="tooltip-toggle" aria-label="Delete" tabIndex="0">
                            <img
                              style={{ marginLeft: "4px" }}
                              src={Delete}
                              alt="Delete"
                              onClick={() => handleDelete(item)}
                            />
                          </div>
                          {/* <i className="fa-solid fa-trash"></i> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        {noDataMessage && <div className="text-center mt-2">No Data Available</div>}
        {<div ref={divLoader} style={{ height: "20px" }} />}
        <Modal
          className="edit-members"
          show={form.show}
          centered
          onHide={() => handleState("show", false)}
        >
          <Modal.Header className="add-member-header" closeButton>
            <Modal.Title className="mx-3">Add Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEmployee
              setEmployee={setEmployee}
              handleState={handleState}
              setState={setState}
              fetchEmployees={fetchEmployees}
              state={state}
              setHasMore={setHasMore}
              employee={employee}

            />
          </Modal.Body>
        </Modal>
        <Modal
          className="edit-members"
          style={{ width: "100%" }}
          show={form.edit}
          centered
          onHide={() => handleState("edit", false)}
        >
          <Modal.Header className="edit-member-header" closeButton>
            <Modal.Title className="mx-3">Edit {state.update.first_name} {state.update.last_name}</Modal.Title>
          </Modal.Header>
          <div className="d-flex p-2 gap-2 mx-3">
            <button
              className={`${info == "user" ? "sky-btn-1" : "sky-btn-2"
                } `}
              onClick={() => handleInfo("user")}
            >
              User info
            </button>
            <button
              className={`${info == "security" ? "sky-btn-1" : "sky-btn-2"
                } `}
              onClick={() => handleInfo("security")}
            >
              Security
            </button>
          </div>
          <Modal.Body>
            {info == "user" ? <EditEmployee
              state={state}
              handleState={handleState}
              setState={setState}
              fetchEmployees={fetchEmployees}
              setEmployee={setEmployee}
              setHasMore={setHasMore}
              employee={employee}

            />
              :
              <Forgotpass
                setEmployee={setEmployee}
                state={state}
                handleState={handleState}
                setState={setState}
                fetchEmployees={fetchEmployees}
                setHasMore={setHasMore}
                employee={employee}
              />}
          </Modal.Body>
        </Modal>
        <Modal
          className="forgot-pin"
          style={{ width: "100%" }}
          show={form.pin}
          onHide={() => handleState("pin", false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Set 4 Digit Pin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PinEmployee
              state={state}
              handleState={handleState}
              setState={setState}
              fetchEmployees={fetchEmployees}
            />
          </Modal.Body>
        </Modal>
        <Modal
          className="forgot-pass"
          style={{ width: "100%" }}
          show={form.pass}
          centered
          onHide={() => handleState("pass", false)}
        >
          <Modal.Header className="edit-member-header" closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Forgotpass
              state={state}
              handleState={handleState}
              setState={setState}
              fetchEmployees={fetchEmployees}
            />
          </Modal.Body>
        </Modal>
        <Modal
          className="delete-employee"
          style={{ width: "100%" }}
          show={form.delete}
          size="sm"
          centered
          onHide={() => handleState("delete", false)}
        >
          <Modal.Header className="delete-member-header" closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <h5 className="text-center">Are you sure you want to delete employee?</h5>
              <div className="row pt-3">
                <div className="col-6">
                  <button
                    className="cancel-btn "
                    onClick={() => {
                      handleState("delete", false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button className="add-btn" onClick={handleDeleteEmployee}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Loader loading={state.loading} />
      </div>
    </>
  );
};

export default Staff;
