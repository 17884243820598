import React from 'react'
import Select from 'react-select';

const SearchSelect = ({ placeholder, isSearchable, value, onChange, options, formatOptionLabel, isClearable = false }) => {

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: '#dee2e6',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#212529' : state.isFocused ? '#dee2e6' : 'white',
            color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'black',
        }),
    };

    return (
        <Select
            styles={customStyles}
            className='custom-react-select'
            placeholder={placeholder}
            isSearchable={isSearchable}
            isClearable={isClearable}
            value={value}
            onChange={onChange}
            options={options}
            formatOptionLabel={formatOptionLabel}
        />
    )
}

export default SearchSelect