import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useToken } from "../../../hooks/useToken";
import baseUrl from "../../../baseUrl";
import Loader from "../../../DefaultComponent/Loader";
// import { useGetSidebarQuery } from "../../../redux/apiSlice";
import { useSelector } from "react-redux";
import { useGetSidebarQuerySuperAdmin } from "../../../redux/superadminApiSlice";
import { useRefToken } from "../../hooks/useToken";

const SuperAdminPermissions = () => {
    const { deviceId } = useSelector(state => state.deviceId);
    const { data: userData, isLoading, isError } = useGetSidebarQuerySuperAdmin();
   
    const refToken = useRefToken();
    const location = useLocation();
    const { role } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        loading: true,
        check: true,
    });
    const [menuList, setMenuList] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [selectedMenuIds, setSelectedMenuIds] = useState([]);
    useEffect(() => {
        fetchMenuList();
    }, []);
    const fetchMenuList = async () => {
        try {
            const response1 = await axios.get(
                `${baseUrl}/api/v1/super-admin/menu/all-menu-list`,
                {
                    headers: {
                        Authorization: refToken,
                        'X-Device-Fingerprint': deviceId,
                    },
                }
            );
            const response2 = await axios.get(
                `${baseUrl}/api/v1/super-admin/menu/all-menu-list?role_id=${role}`,
                {
                    headers: {
                        Authorization: refToken,
                        'X-Device-Fingerprint': deviceId,
                    },
                }
            );
            const data1 = response1.data;
            const data2 = response2.data;
            const roleData = data2?.data?.roleData;
            setRoleName(roleData?.role_name);
    
            // Merge both menu lists and remove duplicates
            const allMenus = [...data1.data.menus, ...data2.data.menus].reduce((acc, menu) => {
                if (!acc.find(item => item.menu_id === menu.menu_id)) {
                    acc.push(menu);
                }
                return acc;
            }, []);
    
            // Filter menus based on user_role and menu_created_role_for
            const userRole = data2.data.roleData.user_role;
            const filteredMenus = allMenus.filter(menu => menu.menu_created_role_for === userRole || menu.menu_created_role_for === null
            );
    
            setMenuList(filteredMenus);
            setState({ ...state, loading: false });
    
            let ap = [];
            filteredMenus.forEach((item) => {
                if (item?.roleData != null) {
                    ap.push(item?.menu_id);
                }
            });
            setSelectedMenuIds(ap);
        } catch (error) {
            console.error(error);
            setState({ ...state, loading: false });
        }
    };

    const handleIsActiveChange = (item, isActive) => {
        // console.log("isActive", isActive);
        if (isActive) {
            setState((prev) => ({ ...prev, check: true }));
            setSelectedMenuIds([...selectedMenuIds, item.menu_id]);
        } else {
            setState((prev) => ({ ...prev, check: false }));
            setSelectedMenuIds(selectedMenuIds.filter((id) => id !== item.menu_id));
        }
    };
 
    const handleSubmit = async () => {
        const apiUrl = `${baseUrl}/api/v1/super-admin/role-permission/update-permission`;
        const data = {
            menu_id: selectedMenuIds,
            role_id: role,
        };
        try {
            setState((pre)=>({...pre,loading:true}));
            const response = await axios.post(apiUrl, data, {
                headers: {
                    Authorization: refToken,
                    "Content-Type": "application/json",
                    'X-Device-Fingerprint': deviceId,
                    
                },
            });
            if (response.status === 200) {
                toast.success("Role Permissions updated successfully!");
                navigate("/admin/roles");
            }
            setState((pre)=>({...pre,loading:false}));
        } catch (error) {
            setState((pre)=>({...pre,loading:false}));
            console.error("API Error:", error);
        }
    };

    return (
        <>
            <div className="container-xl">
                {/* <BreadCrumbs items={breadcrumbItems} /> */}
                <div className="row" >
                    <div className="col-md-6">
                        <h4 className="app-page-title">Permissions</h4>
                    </div>
                    <div className="col-md-6 d-sm-flex justify-content-end ">
                        <h5 className="fw-bold purple"> Role Name - {roleName}</h5>
                    </div>

                </div>
                <div className="app-card app-card-orders-table shadow-sm overflow-x-auto">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Menu Heading</th>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Icon</th>
                                {/* <th>Is Active</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.loading ? (
                                <tr>
                                    <td colSpan="6" style={{ textAlign: "center" }}>
                                        Loading...
                                    </td>
                                </tr>
                            ) : menuList?.length > 0 ? (
                                menuList?.map((item) => {
                                    return (
                                        <tr key={item.menu_id}>
                                            <td>{item.menu_heading}</td>
                                            <td>{item.name}</td>
                                            <td>{item.slug}</td>
                                            <td>
                                                <i className={item.icon} />
                                            </td>
                                          
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedMenuIds.includes(item.menu_id)}
                                                    value={state.check ? item?.menu_id : null}
                                                    onChange={(e) => {
                                                        userData?.data?.role_id == "5" &&
                                                            handleIsActiveChange(item, e.target.checked);
                                                    }}
                                                    readOnly
                                                />
                                            </td>
                                            {/* <td>
                                                        <i
                                                        className="fa-solid fa-pen-to-square text-info"
                                                        onClick={() => handleEdit(item)}
                                                        ></i>
                                                    </td> */}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="6" style={{ textAlign: "center" }}>
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <div className=" d-flex justify-content-center mt-4" >

                    {userData?.data?.role_id == "5" && <button
                        className="btn dark-purp"
                        type="submit"
                        id="submit-btn"
                        onClick={handleSubmit}

                    >
                        Submit
                    </button>}
                </div>

            </div>
            <Loader loading={state.loading || isLoading} />

        </>
    );
};
export default SuperAdminPermissions;
