import React, { useState } from 'react';
import './quickpayment.css';
import Loader from '../../DefaultComponent/Loader';
import QRScanner from './QRScanner';
import { Modal } from "react-bootstrap";
import { createWidgetOrder } from '../../api/Merchant';
import { useGetSidebarQuery } from '../../redux/apiSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import QuickPayDev from '../../DefaultComponent/QuickPayDev';
import QuickPayProd from '../../DefaultComponent/QuickPayProd';

// const socket = io(process.env.REACT_APP_SOCKET_URL)
const env = process.env.REACT_APP_ENV || 'development';
console.log("env", env);
const Quickpayment = () => {
    const navigate = useNavigate()
    const { data: userData, isLoading, isError } = useGetSidebarQuery();
    const [amount, setAmount] = useState('');
    const [discount, setDiscount] = useState('');
    const [tax, setTax] = useState('');
    const [surcharge, setSurcharge] = useState('');
    const [grandTotal, setGrandTotal] = useState('0.00');
    const [unformattedGrandTotal, setUnformattedGrandTotal] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [preauth, setPreauth] = useState(false);
    const [QRInfo, setQRInfo] = useState('');
    const fractalpayClientKey = userData?.data?.publickey || null;
    const [orderID, setOrderID] = useState('');

    // Function to format numbers with commas
    const formatWithCommas = (value) => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };


    const handleOptionChange = (value) => {
        if (amount || value === 'request') {
            setSelectedOption(value);
        }
    };

    const handledivClick = (e) => {
        const { value } = e.target;
        if (!fractalpayClientKey) {
            return;
        }
        if (amount || value === 'request') {
            handleOptionChange(value)
        } else {
            setSelectedOption('')
            toast.error("Please enter amount")
        }
    }

    const handleAmountChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value) {
            value = (parseFloat(value) / 100).toFixed(2);
            value = formatWithCommas(value);
        } else {
            value = '';
        }
        setAmount(value);
        calculateGrandTotal(value, discount, tax, surcharge);
    };

    const handleDiscountChange = (e) => {
        let value = e.target.value;

        // Only allow valid numeric input
        if (/^\d*\.?\d{0,2}$/.test(value)) { // Allow up to 2 decimals
            value = parseFloat(value) > 99.99 ? '99.99' : value; // Cap at 99.99
            setDiscount(value); // Set the value to discount
            calculateGrandTotal(amount, value, tax, surcharge);
        }
    };

    const handleTaxChange = (e) => {
        let value = e.target.value;

        // Only allow valid numeric input
        if (/^\d*\.?\d{0,2}$/.test(value)) { // Allow up to 2 decimals
            value = parseFloat(value) > 100.00 ? '100.00' : value; // Cap at 100.00
            setTax(value); // Set the value to tax
            calculateGrandTotal(amount, discount, value, surcharge);
        }
    };

    const handleSurchargeChange = (e) => {
        let value = e.target.value;

        // Only allow valid numeric input
        if (/^\d*\.?\d{0,7}$/.test(value)) {
            value = parseFloat(value) > 10.0000000 ? '10.0000000' : value;
            setSurcharge(value);
            calculateGrandTotal(amount, discount, tax, value);
        }
    };

    // Function to calculate the grand total
    const calculateGrandTotal = (amt, disc, tx, surch) => {
        const amountVal = parseFloat(amt.replace(/,/g, '')) || 0;
        const discountVal = (parseFloat(disc) || 0) / 100;
        const taxVal = (parseFloat(tx) || 0) / 100;
        const surchargeVal = parseFloat(surch) || 0;


        const discountedAmount = amountVal - (amountVal * discountVal);
        const taxedAmount = discountedAmount * (1 + taxVal);
        const finalTotal = taxedAmount + surchargeVal;

        setUnformattedGrandTotal(finalTotal); // Unformatted value for widgets
        setGrandTotal(formatWithCommas(finalTotal.toFixed(2))); // Formatted value for UI display
    };

    // Generate QR
    const sendRequestPayment = (key, amt) => {
        setLoading(true);
        let formData = {
            fractalpayPublicKey: key,
            amount: amt,
            order_id: orderID,
            action: "request",
            ...(preauth && { preauth, isQr: true }),
        };
        createWidgetOrder(formData)
            .then((data) => {
                setLoading(false);
                if (data.status === 200) {
                    setShow(true);
                    setQRInfo(data?.data?.data?.link);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message);
            });
    };


    const handleClose = () => {
        setQRInfo('');
        setAmount('');
        setDiscount('');
        setTax('');
        setSurcharge('');
        setGrandTotal('0.00');
        setUnformattedGrandTotal(0);
        setSelectedOption('');
        setOrderID('');
        setPreauth(false);
        setLoading(false);
    };

    window.addEventListener("message", (event) => {
        const response = event?.data?.other;
        if (response?.status === true) {
            setTimeout(() => {
                handleClose();
            }, 2000);
        }
    });

    const handleQR = () => {
        handleClose();
        setShow(false)
    }

    const handlePreAuth = (e) => {
        const value = e.target.checked;
        setPreauth(value);
    }

    // useEffect(() => {
    //     socket.on('message', (msg) => {
    //         console.log(msg);

    //     });

    //     return () => {
    //         socket.off('message');
    //     };
    // }, []);

    return (
        <div className='contaniner-xl esy-py'>
            <div className="col-lg-4">
                <p className="heading" >Easy Pay</p>
            </div>
            <div className='col-8 easy-pay quickpayment'>
                <div className='quickpaymentHeader'>
                    <p className='quickpaymerntlabel'>Enter Amount</p>

                    <input
                        type="text"
                        value={amount ? `$${amount}` : ''}
                        onChange={handleAmountChange}
                        className="quickpaymentinput"
                        style={{
                            width: '100%',
                            height: '100%',
                            fontSize: '62px',
                            textAlign: 'left',
                            border: 'none',
                            outline: 'none',
                            background: 'transparent',
                            padding: 0,
                        }}
                        placeholder="$0.00"
                    />
                </div>

                <div className='row quickpaymentmarginrow'>
                    <div className='col-md-4 quickpaymentnorightpadding'>
                        <label className='quickpaymerntlabel'>Discount</label>
                        <input
                            type="text"
                            value={discount}
                            onChange={handleDiscountChange}
                            placeholder='0%'
                            className='quickpaymentinput'
                        />
                    </div>
                    <div className='col-md-4 quickpaymentnorightpadding'>
                        <label className='quickpaymerntlabel'>Tax</label>
                        <input
                            type="text"
                            value={tax}
                            onChange={handleTaxChange}
                            placeholder='0.00'
                            className='quickpaymentinput'
                        />
                    </div>
                    <div className='col-md-4'>
                        <label className='quickpaymerntlabel'>Surcharge</label>
                        <input
                            type="text"
                            value={surcharge}
                            onChange={handleSurchargeChange}
                            placeholder='0.0000000'
                            className='quickpaymentinput'
                        />
                    </div>
                    <div className='col-md-12 my-3'>
                        <label className='quickpaymerntlabel'>Description/Order ID</label>
                        <input
                            className='quickpaymentinput'
                            placeholder='Description/Order ID'
                            value={orderID}
                            onChange={(e) => setOrderID(e.target.value)}
                        />
                    </div>
                </div>
                <div className='quickpaymentgrandtotal'>
                    <p><span style={{ color: 'rgba(53, 37, 77, 0.50)' }}>Grand Total: </span>${grandTotal}</p>
                </div>

                {/* Payment Option Section */}

                <div className='quickpaymerntlabel'>
                    Select payment option
                    {/* <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" style={{ cursor: 'pointer' }} checked={preauth} onClick={(val) => handlePreAuth(val)} id="flexSwitchCheckDefault" />
                        <label class="form-check-label" for="flexSwitchCheckDefault" style={{ cursor: 'pointer' }}><span className='ml-2'>PreAuth</span></label>
                    </div> */}
                </div>
                <div>
                    <div className="radiobuttons">
                        {/* Request Option */}
                        <div className='col-4 radiodiv'
                            onClick={() => { handledivClick({ target: { value: 'request' } }) }}
                            style={{ border: selectedOption === 'request' ? '1px solid #35254d ' : '1px solid #d4cddf' }} >
                            <div className="rdio">
                                <label htmlFor="request" className='custom-radio-label' >Request</label>
                                <input
                                    className='custom-radio'
                                    type="radio"
                                    name="paymentOption"
                                    value="request"
                                    id="request"
                                    checked={selectedOption === 'request'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <p>Send the payment form to customer by SMS or email.</p>
                        </div>

                        {/* Charge Option */}
                        <div className='col-4 radiodiv'
                            onClick={() => { handledivClick({ target: { value: 'charge' } }) }}
                            style={{ border: selectedOption === 'charge' ? '1px solid #35254d ' : '1px solid #d4cddf' }} >
                            <div className="rdio" >
                                <label htmlFor="charge" className='custom-radio-label' >Charge</label>
                                <input
                                    type="radio"
                                    className='custom-radio'
                                    name="paymentOption"
                                    value="charge"
                                    id="charge"
                                    checked={selectedOption === 'charge'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <p>Fill in card details and initiate transaction yourself.</p>
                        </div>

                        {/* QR Option */}
                        <div className='col-4 radiodiv'
                            onClick={() => { handledivClick({ target: { value: 'qr' } }) }}
                            style={{ border: selectedOption === 'qr' ? '1px solid #35254d ' : '1px solid #d4cddf' }}>
                            <div className="rdio">
                                <label htmlFor="qr" className='custom-radio-label' >QR</label>
                                <input
                                    className='custom-radio'
                                    type="radio"
                                    name="paymentOption"
                                    value="qr"
                                    id="qr"
                                    checked={selectedOption === 'qr'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <p>Generate a QR code and show a customer, so they're able to scan it and pay.</p>
                        </div>
                    </div>
                </div>
                {/* Conditionally render based on selected option */}
                {fractalpayClientKey && (env === 'development' ? (
                    <QuickPayDev amount={amount} selectedOption={selectedOption} fractalpayClientKey={fractalpayClientKey} unformattedGrandTotal={unformattedGrandTotal} orderID={orderID} sendRequestPayment={sendRequestPayment} />
                ) : (
                    <QuickPayProd amount={amount} selectedOption={selectedOption} fractalpayClientKey={fractalpayClientKey} unformattedGrandTotal={unformattedGrandTotal} orderID={orderID} sendRequestPayment={sendRequestPayment} />
                ))}
                {!fractalpayClientKey && (
                    <div className="row alert alert-danger mt-3 ahead-box">
                        <small className="alert-info text-center">
                            Merchant public Key is not available!! Please contact administrator
                        </small>
                    </div>
                )}
            </div>

            <Modal
                className="forgot-pass"
                style={{ width: "100%" }}
                show={show}
                centered
                onHide={handleQR}
                backdrop="static"
                keyboard={false}
            >

                {/* <Modal.Header className="edit-member-header" closeButton >

                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}  >
                    <p>
                        <PaymentSuccess />
                    </p>
                    <p className='quickpaymentsuccessheading'>
                        The payment <br></br>
                        was successful
                    </p>
                    <p className='quickpaymentsubheading'>
                        <span style={{ color: '#727272' }}>
                            We’ve processed your{" "}
                        </span>
                        $27.36 charge
                    </p>
                    <div style={{ marginTop: '4rem' }} >
                        <div className="powerd-by-part">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 26 26"
                            >
                                <path
                                    fill="currentColor"
                                    d="M23.633 5.028a1.074 1.074 0 0 0-.777-.366c-2.295-.06-5.199-2.514-7.119-3.477C14.551.592 13.768.201 13.18.098a1.225 1.225 0 0 0-.36.001c-.588.103-1.371.494-2.556 1.087c-1.92.962-4.824 3.416-7.119 3.476a1.08 1.08 0 0 0-.778.366a1.167 1.167 0 0 0-.291.834c.493 10.023 4.088 16.226 10.396 19.831c.164.093.346.141.527.141s.363-.048.528-.141c6.308-3.605 9.902-9.808 10.396-19.831a1.161 1.161 0 0 0-.29-.834zM18.617 8.97l-5.323 7.855c-.191.282-.491.469-.788.469c-.298 0-.629-.163-.838-.372l-3.752-3.753a.656.656 0 0 1 0-.926l.927-.929a.658.658 0 0 1 .926 0l2.44 2.44l4.239-6.257a.657.657 0 0 1 .91-.173l1.085.736a.657.657 0 0 1 .174.91z"
                                ></path>
                            </svg>
                            Secure payments powered by Fractal
                            <img
                                src="https://ui.fractalpay.com/favicon.ico"
                                alt="Fractal logo"
                                className="powered-logo"
                            />
                        </div>
                        <button onClick={() => { navigate('/transactions') }} className='quickpaymentbtn' >
                            Go to transactions
                        </button>
                    </div>
                </Modal.Body> */}

                <Modal.Header className="edit-member-header" closeButton>
                    <Modal.Title>QR-code payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QRScanner
                        QRInfo={QRInfo}
                    />
                    <p className='quickpaymentshowqr'>
                        Show this code to a customer to make the payment
                    </p>
                </Modal.Body>
            </Modal>
            <Loader loading={loading} />
        </div>
    );
};

export default Quickpayment;
