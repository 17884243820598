import { useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const useDeviceFingerprint = () => {
    const [fingerprint, setFingerprint] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFingerprint = async () => {
            try {
                const fp = await FingerprintJS.load();
                const result = await fp.get();

                setFingerprint(result.visitorId);
            } catch (err) {
                setError(err);
                console.error('Error fetching fingerprint:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchFingerprint();
    }, []);

    return { fingerprint, loading, error };
};

export default useDeviceFingerprint;
