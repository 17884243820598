import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const Graph = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Define the angle and calculate the gradient coordinates
    const angle = 200 * (Math.PI / -369); // Convert degrees to radians
    const x0 = 300;
    const y0 = -240;
    const x1 = chartRef.current.width;
    const y1 = chartRef.current.height * Math.tan(angle);

    // Create the gradient
    const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
    gradient.addColorStop(0.3635, "rgba(97, 198, 153, .0)");
    gradient.addColorStop(0.7474, "rgba(97, 198, 153, 0)");
    gradient.addColorStop(0.2, "rgba(97, 198, 153, .6)");
    gradient.addColorStop(0.4, "rgba(97, 198, 153, 0)");

    // Format numbers to US format (million, billion, k)
    const formatNumber = (num) => {
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M";
      } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      }
      return num;
    };

    // Set up the chart
    const myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: props?.data?.labels,
        datasets: [
          {
            data: props?.data?.salesData,
            backgroundColor: gradient,
            borderColor: "#61C699",
            borderWidth: 2,
            fill: true,
            tension: 0.4,
          },
        ],
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            beginAtZero: true,
            min: Math.abs(props?.yAxisMin) > 500 ? -500 : null,
            ticks: {
              callback: function (value) {
                return "$"+formatNumber(value);
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem) {
                return "$"+formatNumber(tooltipItem.raw);
              },
            },
          },
        },
      },
    });

    // Cleanup function to destroy chart instance when component unmounts
    return () => {
      myChart.destroy();
    };
  }, [props]);

  return <canvas ref={chartRef} style={{ display: "inline-block" }} />;
};

export default React.memo(Graph);
  