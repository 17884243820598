import React, { useEffect, useState } from "react";
import "../../Dashboard/Page/Dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import Graph from "../../../DefaultComponent/Graph";
import Loader from "../../../DefaultComponent/Loader";
import StatsFormatter from "../../../DefaultComponent/StatsFormatter";
import StatsFlexFormatter from "../../../DefaultComponent/StatsFlexFormatter";
import DatePickers from "../../../DefaultComponent/DatePickers";
import { useGetDashobardAnalyticsAdminQuery } from "../../../redux/apiSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Dashboard = () => {

  const handleDates = (val, hours, minutes) => {
    const Dates = new Date();
    Dates.setDate(Dates.getDate() - val);
    Dates.setHours(hours, minutes, 0, 0);
    return Dates;
  };
  const [startDate, setStartDate] = useState(() => {
    const start = handleDates(6, 0, 0); // 7 days ago, start of the day
    start.setHours(0, 0, 0, 0);
    return start;
  });

  const [endDate, setEndDate] = useState(() => {
    const end = new Date(); // Today
    end.setHours(23, 59, 59, 999); // Set to the end of today
    return end;
  });
  const [responseData, setResponseData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [graphData, setGraphData] = useState("");
  const [graphFilter, setGraphFilter] = useState("week");

  const { data: dashboardData, isLoading: dashboardLoading, isFetching: dashboardFetching } = useGetDashobardAnalyticsAdminQuery({
    startDate: startDate ? new Date(startDate).getTime() : undefined,
    endDate: endDate ? new Date(endDate).getTime() : undefined,
    graphFilter
  }, { skip: !startDate || !endDate || graphFilter === "allTime" });

  useEffect(() => {
    if (!dashboardData) return;
    try {
      const data = dashboardData.analytics.data;
      const labels = data?.map((item) => `${item.label}`);
      const salesData = data?.map((item) => parseFloat(item.total_amount));
      const minValue = Math.min(...salesData);
      const yAxisMin = Math.floor(minValue - (minValue * 0.1));
      setAnalyticsData(yAxisMin);
      setResponseData(dashboardData?.analytics?.analyticsData);
      setGraphData({ labels, salesData });
    } catch (error) {
      console.log("error", error);
    }
  }, [dashboardData]);
  
  return (
    <div className="container-xl">
      <div className="row">
        <div className="col-sm-4">
          <h4 className="heading">Dashboard</h4>
        </div>
      </div>


      <div className="row dash-sale p-4">
        <div className="col-lg-3 d-flex db-white-box flex-column">
          <div className="cursor-pointer position-relative">
            <DatePickers startDate={startDate} endDate={endDate} setGraphFilter={setGraphFilter} setStartDate={setStartDate} setEndDate={setEndDate} />
          </div>
          <div className="tans-main">
            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> TOTAL SALES</span>
              </div>
              <StatsFlexFormatter
                amount={responseData?.totalSalesAmount?.currentTotalTransactionAmount} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> ESTIMATED REVENUE</span>
              </div>
              <StatsFlexFormatter amount={responseData?.RangeProfitAmount?.calculatedProfitAmount} tofixed={true} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl">TOTAL TRANSACTIONS </span>
              </div>
              <StatsFlexFormatter isProfit={responseData?.totalTransactionCount?.isProfit} totalNum={responseData?.totalTransactionCount?.currentTotalTransactionCount} diffNum={responseData?.totalTransactionCount?.diffTotalTransactionCount} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> AVG TICKET</span>
              </div>
              <StatsFlexFormatter amount={responseData?.todayTransaction?.todayAmount} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> ACTIVE MERCHANTS</span>
              </div>
              <StatsFormatter totalNum={responseData?.activeMerchantCount?.activeMerchant} />
            </div>
            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> MERCHANT APPLICATIONS</span>
              </div>
              <StatsFormatter totalNum={responseData?.rangeOnboardingMerchants?.rangeOnboardingMerchants} />
            </div>
            {/* <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> TOTAL SALES VOID</span>
              </div>
              <StatsFormatter totalNum={responseData?.totalSalesVoid} />
            </div>
            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> TOTAL SALES REFUND</span>
              </div>
              <StatsFormatter totalNum={responseData?.totalSalesRefund} />
            </div> */}
          </div>
        </div>
        <div className="col-lg-9 text-end">
          <div className="app-card app-card-chart shadow-sm">
            <div className="app-card-body p-3 p-lg-4">
              <div className="chart-container">
                <Graph data={graphData} yAxisMin={analyticsData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={dashboardLoading || dashboardFetching} />
    </div>
  );
};

export default Dashboard;
