import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {  useToken } from "../../../hooks/useToken";
import baseUrl from "../../../baseUrl";
import Loader from "../../../DefaultComponent/Loader";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { useGetSidebarQuery } from "../../../redux/apiSlice";
import { useSelector } from "react-redux";

const Permissions = () => {
  const { deviceId } = useSelector(state => state.deviceId);

  const { data: userData, isLoading, isError } = useGetSidebarQuery();
  const { role_id, staff_roll_type } = userData?.data;
  const token = useToken();
  const location = useLocation();
  const { role } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: true,
    check: true,
  });
  const [menuList, setMenuList] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [selectedMenuIds, setSelectedMenuIds] = useState([]);
  useEffect(() => {
    fetchMenuList();
  }, []);
  const fetchMenuList = async () => {
    try {
      const response1 = await axios.get(
        `${baseUrl}/api/v1/admin/menu/all-menu-list`,
        {
          headers: {
            Authorization: token,
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
      const response2 = await axios.get(
        `${baseUrl}/api/v1/admin/menu/all-menu-list?role_id=${role}`,
        {
          headers: {
            Authorization: token,
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
      const data1 = response1.data;
      const data2 = response2.data;
      const roleData = data2?.data?.roleData;
      setRoleName(roleData?.role_name);
      const mergedData = data1.data.menus.map((item) => {
        const roleDataItem = data2.data.menus.find(
          (roleDataItem) => roleDataItem.menu_id === item.menu_id
        );
        return {
          ...item,
          roleData: roleDataItem ? roleDataItem.roleData : null,
        };
      });
      setMenuList(mergedData);
      setState({ ...state, loading: false });
      let ap = [];
      let roles = data2?.data?.menus?.filter((item) => {
        if (item?.roleData != null) {
          return ap.push(item?.menu_id);
        }
      });
      setSelectedMenuIds(ap);
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false });
    }
  };
  // const handleIsActiveChange = (item, isActive) => {
  //     console.log(item, isActive);
  //     if (selectedMenuIds?.indexOf(item?.menu_id) > 0) {
  //         selectedMenuIds?.splice(selectedMenuIds?.indexOf(item?.menu_id), 1);
  //     }
  //     else {
  //         setSelectedMenuIds([...selectedMenuIds, item?.menu_id]);
  //     }
  // };
  const handleIsActiveChange = (item, isActive) => {
    // console.log("isActive", isActive);
    if (isActive) {
      setState((prev) => ({ ...prev, check: true }));
      setSelectedMenuIds([...selectedMenuIds, item.menu_id]);
    } else {
      setState((prev) => ({ ...prev, check: false }));
      setSelectedMenuIds(selectedMenuIds.filter((id) => id !== item.menu_id));
    }
  };
  // const handleIsActiveChange = (item, isActive) => {
  //     if (isActive) {
  //         setSelectedMenuIds([...selectedMenuIds, item.menu_id]);
  //     } else {
  //         setSelectedMenuIds(selectedMenuIds.filter((id) => id !== item.menu_id));
  //     }
  // };
  const handleSubmit = async () => {
    const apiUrl = `${baseUrl}/api/v1/admin/role-permission/update-permission`;
    const data = {
      menu_id: selectedMenuIds,
      role_id: role,
    };
    try {
      const response = await axios.post(apiUrl, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
          'X-Device-Fingerprint': deviceId,

        },
      });
      if (response.status === 200) {
        toast.success("Role Permissions updated successfully!");
        navigate("/roles");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const breadcrumbItems = [
    { label: "Dashboard", href: "/", active: false },
    { label: "Roles", href: "/roles", active: false },
    { label: "Permissions", href: "/permissions", active: true },
  ];
  return (
    <>
   
   
      <div className="container-xl">
        {/* <BreadCrumbs items={breadcrumbItems} /> */}
        <div className="row" >
          <div className="col-md-6">
            <h4 className="app-page-title">Permissions</h4>
          </div>
          <div className="col-md-6 d-sm-flex justify-content-end ">
            <h5 className="fw-bold purple"> Role Name - {roleName}</h5>
          </div>

        </div>
            <div className="app-card app-card-orders-table shadow-sm overflow-x-auto">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Menu Heading</th>
                    <th>Name</th>
                    <th>Slug</th>
                    <th>Icon</th>
                    {/* <th>Is Active</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.loading ? (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        Loading...
                      </td>
                    </tr>
                  ) : menuList?.length > 0 ? (
                    menuList?.map((item) => {
                      return (
                        <tr key={item.menu_id}>
                          <td>{item.menu_heading}</td>
                          <td>{item.name}</td>
                          <td>{item.slug}</td>
                          <td>
                            <i className={item.icon} />
                          </td>
                          {/* <input
                                                            type="checkbox"
                                                            // checked={item.is_active === 1}
                                                            checked={item.roleData != null}
                                                            value={item?.menu_id}
                                                            onChange={(e) => { handleIsActiveChange(item, e.target.checked); }}
                                                        /> */}
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedMenuIds.includes(item.menu_id)}
                              value={state.check ? item?.menu_id : null}
                              onChange={(e) => {
                                role_id == "1" &&
                                handleIsActiveChange(item, e.target.checked);
                              }}
                              readOnly
                            />
                          </td>
                          {/* <td>
                                                        <i
                                                        className="fa-solid fa-pen-to-square text-info"
                                                        onClick={() => handleEdit(item)}
                                                        ></i>
                                                    </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
              <div className=" d-flex justify-content-center mt-4" >

          {role_id == "1" && <button
                className="btn dark-purp"
                type="submit"
                id="submit-btn"
                onClick={handleSubmit}
                
                >
                Submit
              </button>}
                </div>
         
        </div>
      <Loader loading={state.loading} />

    </>
  );
};
export default Permissions;
