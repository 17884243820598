import React, { useEffect, useState } from 'react'
import StatsFormatter from '../../DefaultComponent/StatsFormatter';
import StatsFlexFormatter from '../../DefaultComponent/StatsFlexFormatter';
import DatePickers from '../../DefaultComponent/DatePickers';
import Graph from '../../DefaultComponent/Graph';
import { useRefToken } from '../../SuperAdmin/hooks/useToken';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import Loader from '../../DefaultComponent/Loader';
import { useToken } from '../../hooks/useToken';
import { useSelector } from 'react-redux';
import { useGetMerchantDashobardAnalyticsQuery } from '../../redux/merchantApiSlice';

const MerchantDashboard = () => {
    const token = useToken();
    const { deviceId } = useSelector(state => state.deviceId);

    const [graphData, setGraphData] = useState("");
    const [graphFilter, setGraphFilter] = useState("week");
    const [responseData, setResponseData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleDates = (val, hours, minutes) => {
        const Dates = new Date();
        Dates.setDate(Dates.getDate() - val);
        Dates.setHours(hours, minutes, 0, 0);
        return Dates;
    };


    const [startDate, setStartDate] = useState(() => {
        const start = handleDates(6, 0, 0); // 7 days ago, start of the day
        start.setHours(0, 0, 0, 0);
        return start;
    });
    const [endDate, setEndDate] = useState(() => {
        const end = new Date(); // Today
        end.setHours(23, 59, 59, 999); // Set to the end of today
        return end;
    });

    const { data: MerchantdashboardData, isLoading: MerchantdashboardLoading, isFetching: MerchantdashboardFetching } = useGetMerchantDashobardAnalyticsQuery({
        startDate: startDate ? new Date(startDate).getTime() : undefined,
        endDate: endDate ? new Date(endDate).getTime() : undefined,
        graphFilter
    }, { skip: !startDate || !endDate || graphFilter === "allTime" });
    

    useEffect(() => {
        if (!MerchantdashboardData) return;
        try {
            const data = MerchantdashboardData.analytics.data;
            const labels = data?.map((item) => `${item.label}`);
            const salesData = data?.map((item) => parseFloat(item.total_amount));
            const minValue = Math.min(...salesData);
            const yAxisMin = Math.floor(minValue - (minValue * 0.1));
            setResponseData(MerchantdashboardData?.analytics?.analyticsData);
            setGraphData({ labels, salesData });
        } catch (error) {
            console.log("error", error);
        }
    }, [MerchantdashboardData]);

    // const [data, setData] = useState({
    //     labels: [],
    //     datasets: [
    //         {
    //             label: "Sales Over Time",
    //             data: [],
    //             backgroundColor: "rgba(117,193,129,0.2)",
    //             borderColor: "rgba(117,193,129, 0.8)",
    //             fill: true,
    //             tension: 0.4,
    //         },
    //     ],
    // });

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (!startDate || !endDate) {
    //             return;
    //         }
    //         try {

    //             setIsLoading(true);
    //             function convertMili(da) {
    //                 const date = new Date(da);
    //                 return date.getTime();
    //             }
    //             let params = {};
    //             if (graphFilter == "allTime") {
    //                 params = { graphFilter: graphFilter }
    //             }
    //             else {
    //                 params = {
    //                     startDate: convertMili(startDate),
    //                     // startDate:
    //                     //   convertMili(startDate) == convertMili(endDate)
    //                     //     ? convertMili(getTodayStart())
    //                     //     : convertMili(startDate),

    //                     endDate: convertMili(endDate),
    //                     graphFilter: graphFilter
    //                 }
    //             }
    //             console.log("date picker", startDate, endDate)
    //             const response = await axios.get(
    //                 `${baseUrl}/api/v1/merchant/account/dashboard/analytics`,
    //                 {
    //                     headers: {
    //                         Authorization: token,
    //                         'X-Device-Fingerprint': deviceId,
    //                     },
    //                     params,
    //                 }
    //             );
    //             if (response.status == 200) {
    //                 const data = response.data.analytics.data;
    //                 const labels = data?.map((item) => `${item.label}`);
    //                 const salesData = data?.map((item) => parseFloat(item.total_amount));
    //                 setResponseData(response?.data?.analytics?.analyticsData);
    //                 setGraphData({ labels, salesData });
    //                 setData({
    //                     labels,
    //                     datasets: [
    //                         {
    //                             label: "Sales Over Time",
    //                             data: salesData,
    //                             borderColor: "rgba(75, 192, 192, 1)",
    //                             backgroundColor: "rgba(75, 192, 192, 0.2)",
    //                             fill: true,
    //                             tension: 0.4,
    //                             pointRadius: 5,
    //                             pointHoverRadius: 10,
    //                             pointStyle: "circle",
    //                         },
    //                     ],
    //                 });
    //             } else {
    //                 console.error("Error:", response.status, response.statusText);
    //             }
    //             setIsLoading(false);
    //         } catch (error) {
    //             setIsLoading(false);
    //             console.log("error", error);
    //         }
    //     };
    //     fetchData();
    // }, [token, startDate, endDate]);

    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-sm-4">
                    <h4 className="heading">Dashboard</h4>
                </div>
            </div>
            <div className="row dash-sale p-4">
                <div className="col-lg-3 d-flex db-white-box flex-column">
                    <div className="cursor-pointer position-relative">
                        <DatePickers startDate={startDate} endDate={endDate}
                            setGraphFilter={setGraphFilter}
                            setStartDate={setStartDate} setEndDate={setEndDate} />
                    </div>
                    <div className="tans-main">
                        <div className="actv-trans">
                            <div className="mb-1">
                                <span className="fs-12 prpl"> TOTAL SALES</span>
                            </div>
                            <StatsFlexFormatter
                                amount={responseData?.totalSalesAmount?.currentTotalTransactionAmount} />
                        </div>

                        {/* <div className="actv-trans">
                            <div className="mb-1">
                                <span className="fs-12 prpl"> ESTIMATED REVENUE</span>
                            </div>
                            <StatsFlexFormatter amount={responseData?.RangeProfitAmount?.calculatedProfitAmount} tofixed={true} />
                        </div> */}

                        <div className="actv-trans">
                            <div className="mb-1">
                                <span className="fs-12 prpl">TOTAL TRANSACTIONS </span>
                            </div>
                            <StatsFlexFormatter totalNum={responseData?.totalTransactionCount?.currentTotalTransactionCount} />
                        </div>

                        <div className="actv-trans">
                            <div className="mb-1">
                                <span className="fs-12 prpl"> AVG TICKET</span>
                            </div>
                            <StatsFlexFormatter amount={responseData?.todayTransaction?.todayAmount} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 text-end">
                    <div className="app-card app-card-chart shadow-sm">
                        <div className="app-card-body p-3 p-lg-4">
                            <div className="chart-container">
                                <Graph data={graphData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader loading={MerchantdashboardFetching || MerchantdashboardLoading} />
        </div>
    )
}

export default MerchantDashboard