import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import baseUrl from '../../../../baseUrl';
import { useRefToken } from '../../../hooks/useToken';
import BackBtn from '../../../../DefaultComponent/BackBtn';
import Loader from '../../../DefaultComponents/Loader';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAddSkyFiCredentialsMutation, useGetClientProfileByClientIdQuery } from '../../../../redux/superadminApiSlice';

const ClientDetail = () => {
    const { clientId } = useParams();
    const { deviceId } = useSelector(state => state.deviceId);
    const token = useRefToken();

    // const [clientProfile, setClientProfile] = useState({});
    const [state, setState] = useState({
        loading: false,
    })

    const [clientform, setClientForm] = useState({
        client_id: '',
        client_secret_key: ''
    })
    const [error, setError] = useState({
        client_id: '',
        client_secret_key: ''
    })
    const { data: clientProfile, isError, isFetching, isLoading } = useGetClientProfileByClientIdQuery(clientId);
    const[addSkyFiTrigger, { data:addResp,isFetching:skyFetch,isLoading:skyLoading } ]= useAddSkyFiCredentialsMutation(clientId);
    console.log("clinet profile =========",clientProfile);
    
    // const ClientProfile = async () => {
    //     try {
    //         setState((prev) => ({ ...prev, loading: true }));
    //         const response = await axios.get(`${baseUrl}/api/v1/super-admin/client/get-client-profile/${clientId}`, {
    //             headers: {
    //                 Authorization: token,
    //                 'X-Device-Fingerprint': deviceId,
    //             }
    //         })
    //         setState((prev) => ({ ...prev, loading: false }));
    //         setClientProfile(response.data.data)
    //         setClientForm({
    //             client_id: response?.data?.data?.ach_client_guid,
    //             client_secret_key: response?.data?.data?.ach_client_secret_key
    //         })
    //     }
    //     catch (error) {
    //         setState((prev) => ({ ...prev, loading: false }));
    //         console.log(error);
    //     }
    // }

    useEffect(() => {
        setClientForm({
            client_id: clientProfile?.ach_client_guid,
            client_secret_key: clientProfile?.ach_client_secret_key
        })
    }, [clientProfile])


    const requiredValue = (value) => {
        if (!value) {
            return false;
        }
        return true;
    };

    const Validation = () => {
        let errors = {};
        if (!requiredValue(clientform?.client_id)) {
            errors.client_id = "Client Id is required"
        }
        if (!requiredValue(clientform?.client_secret_key)) {
            errors.client_secret_key = "Client Secret Key is required"
        }
        if (Object.keys(errors).length < 1) {
            return false;
        }
        return errors
    }

    const Handlechange = (e) => {
        const { name, value } = e.target
        console.log(name, value, "loop");
        setError({});
        setClientForm({
            ...clientform,
            [name]: value
        })
    }

    const HandleSubmit = async (e) => {
        e.preventDefault();
        setError({});
        console.log(Validation, "Validation");
        let errors = Validation();
        console.log(errors, "Error");



        if (!errors) {
           
            try {
                
                let response = await addSkyFiTrigger({ clientId, body: { client_guid: clientform?.client_id, client_secret_key: clientform?.client_secret_key }}).unwrap();
                if (response?.status == 200) {
                    setClientForm({
                        client_id: response?.data?.data?.client_guid,
                        client_secret_key: response?.data?.data?.client_secret_key
                    })
                    setError({})
                    toast.success(response?.data?.message)
                }
                console.log(response, "clientForm");
            } catch (error) {
                console.log(error, 'clientForm');
              
            } 
        } else {
            setError(errors)
        }
    }

    return (
        <>
            <div className="pb-2">
                <BackBtn />
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="border-1 border rounded-4 p-3">
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <span className="mr-2">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_6853_2910)">
                                            <path d="M13.5924 2.48839C13.5916 2.48839 13.5907 2.48828 13.5898 2.48828H9.87109V2.07812C9.87109 1.39966 9.31909 0.847656 8.64062 0.847656H5.35938C4.68091 0.847656 4.12891 1.39966 4.12891 2.07812V2.48828H0.410156C0.181793 2.48828 0 2.67542 0 2.89844V11.9219C0 12.6003 0.552002 13.1523 1.23047 13.1523H12.7695C13.448 13.1523 14 12.6003 14 11.9219V2.90709C14 2.90656 14 2.90602 14 2.90549C13.9843 2.63611 13.8186 2.48988 13.5924 2.48839ZM4.94922 2.07812C4.94922 1.85201 5.13326 1.66797 5.35938 1.66797H8.64062C8.86674 1.66797 9.05078 1.85201 9.05078 2.07812V2.48828H4.94922V2.07812ZM13.0208 3.30859L11.747 7.12967C11.6912 7.29747 11.5348 7.41016 11.358 7.41016H9.05078V7C9.05078 6.77345 8.86717 6.58984 8.64062 6.58984H5.35938C5.13283 6.58984 4.94922 6.77345 4.94922 7V7.41016H2.64198C2.46521 7.41016 2.30884 7.29747 2.25298 7.12967L0.979248 3.30859H13.0208ZM8.23047 7.41016V8.23047H5.76953V7.41016H8.23047ZM13.1797 11.9219C13.1797 12.148 12.9957 12.332 12.7695 12.332H1.23047C1.00435 12.332 0.820312 12.148 0.820312 11.9219V5.42592L1.47475 7.38911C1.64244 7.89241 2.11156 8.23047 2.64198 8.23047H4.94922V8.64062C4.94922 8.86717 5.13283 9.05078 5.35938 9.05078H8.64062C8.86717 9.05078 9.05078 8.86717 9.05078 8.64062V8.23047H11.358C11.8884 8.23047 12.3576 7.89241 12.5253 7.38911L13.1797 5.42592V11.9219Z" fill="#35254D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_6853_2910">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="">
                                    CLIENT DETAILS
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col overflow-x-auto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">GUID</th>
                                            <th scope="col">{clientProfile?.guid}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">First name</th>
                                            <td>{clientProfile?.first_name}</td>
                                        </tr>
                                        <tr>

                                            <th scope="row">Last name</th>
                                            <td>{clientProfile?.last_name}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Email</th>
                                            <td>{clientProfile?.email}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Phone</th>
                                            <td>{clientProfile?.number}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Business Name</th>
                                            <td>{clientProfile?.businessname}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Client Id</th>
                                            <td>{clientProfile?.ach_client_guid}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Client Secret Key</th>
                                            <td>{clientProfile?.ach_client_secret_key}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="border-1 border rounded-4 p-3">
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <span className="mr-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width={20}
                                        height={20}
                                        x={0}
                                        y={0}
                                        viewBox="0 0 64 64"
                                        style={{
                                            enableBackground: "new 0 0 512 512",
                                        }}
                                        xmlSpace="preserve"
                                    >
                                        <g>
                                            <g fill="#000">
                                                <path
                                                    d="M32 43a2 2 0 0 1-2-2v-7h-7a2 2 0 1 1 0-4h7v-7a2 2 0 1 1 4 0v7h7a2 2 0 1 1 0 4h-7v7a2 2 0 0 1-2 2z"
                                                    fill="#35254d"
                                                    opacity={1}
                                                    data-original="#000000"
                                                    className=""
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 2C9.163 2 2 9.163 2 18v28c0 8.837 7.163 16 16 16h28c8.837 0 16-7.163 16-16V20.607a8 8 0 0 0-2.542-5.848L48.094 4.152A8 8 0 0 0 42.635 2zM6 18c0-6.627 5.373-12 12-12h24v6a8 8 0 0 0 8 8h7.954c.03.2.046.403.046.607V46c0 6.627-5.373 12-12 12H18c-6.627 0-12-5.373-12-12zm40-6V7.67L54.926 16H50a4 4 0 0 1-4-4z"
                                                    clipRule="evenodd"
                                                    fill="#35254d"
                                                    opacity={1}
                                                    data-original="#000000"
                                                    className=""
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span className="">
                                    {clientProfile?.client_guid ? "Update" : "Add"} Sky-Fi Credentials
                                </span>
                            </div>
                            <div className='row' >

                                <form>
                                    <div>
                                        <label className="labelStyle" htmlFor="client_id">Client Id</label>
                                        <input type="text" name='client_id'
                                            placeholder='Enter Client Id' className="custom-date-picker"
                                            required
                                            onChange={Handlechange}
                                            value={clientform?.client_id}
                                        />
                                        {error?.client_id && (
                                            <span className="error text-danger fs-12">
                                                {error?.client_id}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <label className="labelStyle" htmlFor="client_secret_key">Client Secret Key</label>
                                        <input type="text" name='client_secret_key'
                                            placeholder='Enter Client Id' className="custom-date-picker"
                                            required
                                            onChange={Handlechange}
                                            value={clientform?.client_secret_key}
                                        />
                                        {error?.client_secret_key && (
                                            <span className="error text-danger fs-12">
                                                {error?.client_secret_key}
                                            </span>
                                        )}
                                    </div>
                                </form>

                                <div className='d-flex justify-end' >
                                    <button
                                        className="second-blk-btn w-auto m-3"
                                        onClick={(e) => { HandleSubmit(e) }}
                                    >
                                        {clientProfile?.client_guid ? "Update" : "Add"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Loader loading={isFetching||isLoading||skyFetch||skyLoading} />
        </>
    )
}

export default ClientDetail