import React, { useEffect, useState } from 'react'
import { formatPhoneNumberglobal } from '../../DefaultComponents/CommonFunctions';
import { useRefToken } from '../../hooks/useToken';
import { CreateTransactionRecord, GetCardtype, GetClientList, GetMerchantList, GetPaymenttype, GetPaymentvia, GetStausList, GetTypeofTerminal } from '../../api/createtransaction';
import Loader from '../../DefaultComponents/Loader';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BackBtn from '../../DefaultComponents/BackBtn';
import { useNavigate } from 'react-router-dom';

const CreateRecord = () => {
    const navigate = useNavigate()
    const phoneNum =
        /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    const numberRegex = /^\d+(\.\d+)?$/;
    const token = useRefToken()
    let initialstate = {
        client: "",
        merchant: "",
        paymenttype: "",
        paymentvia: "",
        typeofterminal: "",
        customerphone: "",
        customername: "",
        txn_id: "",
        amount: "",
        netamount: "",
        tax: "",
        discount: "",
        fees: "",
        tip_amount: "",
        table_name: "",
        lastFourDigits: "",
        cardType: "",
        txn_timestamp: "",
        is_status: ""
    }
    const [data, setData] = useState(initialstate)
    const [error, setError] = useState({});
    // const [Clientlist, setClientlist] = useState([])
    const [ClientlistOptions, setClientlistOptions] = useState([])
    // const [MerchantList, setMerchantList] = useState([])
    const [MerchantListOptions, setMerchantListOptions] = useState([])
    const [loadingCount, setLoadingCount] = useState(0);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [paymentvialist, setPaymentViaList] = useState([])
    const [typeofterminallist, setTypeofTerminallist] = useState([])
    const [paymenttypelist, setPaymentTypelist] = useState([])
    const [Cardtypelist, setCardTypelist] = useState([])
    const [statuslist, setStatuslist] = useState([])
    const isLoading = loadingCount > 0;


    const incrementLoading = () => {
        setLoadingCount((prev) => prev + 1);
    };

    const decrementLoading = () => {
        setLoadingCount((prev) => Math.max(prev - 1, 0));
    };

    const Get_Client_list = async () => {
        incrementLoading()
        try {
            const response = await GetClientList(token);
            // setClientlist(response)
            let options = []
            response?.length > 0 && response?.map((item) => {
                options.push(
                    {
                        value: item?.id,
                        label: `${item?.first_name}${" "}${item?.last_name}`
                    }
                )
            })
            setClientlistOptions(options)
        } catch (error) {
            console.log(error);
        } finally {
            decrementLoading()
        }
    }

    const Get_Merchant_list = async (id) => {
        if (id || data?.client) {
            incrementLoading()
            try {
                const response = await GetMerchantList(token, id || data?.client);
                // setMerchantList(response)
                let options = []
                response?.length > 0 && response?.map((item) => {
                    options.push(
                        {
                            value: item?.id,
                            label: `${item?.first_name}${" "}${item?.last_name}`
                        }
                    )
                })
                setMerchantListOptions(options)
            } catch (error) {
                console.log(error);
                // setMerchantList([])
                setMerchantListOptions([])
                toast.error(error?.response?.data?.message)
            } finally {
                decrementLoading()
            }
        }
    }

    const Handlechange = (e) => {
        const { name, value } = e.target
        console.log(name, value, "loop");
        setError({});
        setData({
            ...data,
            [name]: value
        })
    }

    const requiredValue = (value) => {
        if (!value) {
            return false;
        }
        return true;
    };

    const handlePhoneNumber = (num) => {
        let no = num.replace(/\D/g, "");
        return phoneNum.test(no);
    };
    const handleNumber = (num) => {
        let no = num.replace(/\D/g, "");
        return numberRegex.test(no);
    };

    const validateNumberabove = (num) => {
        if (num <= 0) {
            return true
        }
    };

    const validateNumber = (num) => {
        if (num < 0) {
            return true
        }
    };

    const validateNumberbelow = (num) => {
        if (num >= 100) {
            return true
        }
    };

    const HandleBlur = (e) => {
        const { name, value } = e.target
        console.log("BLUr", name, value);

    }

    const Validation = () => {
        let error = {};
        if (!requiredValue(selectedClient)) {
            error.client = "Client is required"
        }
        if (!requiredValue(selectedMerchant)) {
            error.merchant = "Merchant is required"
        }
        if (!requiredValue(data.paymenttype)) {
            error.paymenttype = "Payment type is required"
        }
        if (!requiredValue(data.paymentvia)) {
            error.paymentvia = "Via is required"
        }
        if (!requiredValue(data.typeofterminal)) {
            error.typeofterminal = "Type of Terminal is required "
        }
        if (!requiredValue(data.customerphone)) {
            error.customerphone = "Customer phone number is required";
        } else if (!handlePhoneNumber(data?.customerphone)) {
            error.customerphone = "Customer phone number must be a valid number";
        }
        if (!requiredValue(data.customername)) {
            error.customername = "Customer name is required";
        }
        if (!requiredValue(data.txn_id)) {
            error.txn_id = "Transaction id is required";
        }
        if (!requiredValue(data.amount)) {
            error.amount = "Amount is required";
        } else if (!handleNumber(data?.amount)) {
            error.amount = "Amount must be number";
        }
        if (!requiredValue(data.netamount)) {
            error.netamount = "Net amount is required";
        } else if (!handleNumber(data?.netamount)) {
            error.netamount = "Net amount must be number";
        }
        if (!requiredValue(data.tax)) {
            error.tax = "Tax is required";
        } else if (!handleNumber(data?.tax)) {
            error.tax = "Tax must be number";
        } else if (validateNumber(data?.tax)) {
            error.tax = "Tax must be above 0";
        } else if (validateNumberbelow(data?.tax)) {
            error.tax = "Tax must be below 100";
        }
        if (!requiredValue(data.discount)) {
            error.discount = "Discount is required";
        } else if (!handleNumber(data?.discount)) {
            error.discount = "Discount must be number";
        } else if (validateNumber(data?.discount)) {
            error.discount = "Discount must be above 0";
        } else if (validateNumberbelow(data?.discount)) {
            error.discount = "Discount must be below 100";
        }
        if (!requiredValue(data.fees)) {
            error.fees = "Fees is required";
        } else if (!handleNumber(data?.fees)) {
            error.fees = "Fees must be number";
        } else if (validateNumber(data?.fees)) {
            error.fees = "Fees must be above 0";
        } else if (validateNumberbelow(data?.fees)) {
            error.fees = "Fees must be below 100";
        }
        if (!requiredValue(data.tip_amount)) {
            error.tip_amount = "Tip amount is required";
        } else if (!handleNumber(data?.tip_amount)) {
            error.tip_amount = "Tip amount must be number";
        }
        if (!requiredValue(data.table_name)) {
            error.table_name = "Table name is required";
        }
        if (!requiredValue(data.lastFourDigits)) {
            error.lastFourDigits = "Last four digit of card is required";
        } else if (!handleNumber(data?.lastFourDigits)) {
            error.lastFourDigits = "Last four digit of card must be number";
        }
        if (!requiredValue(data.cardType)) {
            error.cardType = "Card is required";
        }
        if (!requiredValue(data.txn_timestamp)) {
            error.txn_timestamp = "Date and Time is required";
        }
        if (!requiredValue(data.is_status)) {
            error.is_status = "Transaction status is required";
        }
        if (Object.keys(error).length < 1) {
            return false;
        }
        return error
    }

    const HandleSubmit = async (e) => {
        e.preventDefault();
        setError({});
        let errors = Validation();
        if (!errors) {
            try {
                incrementLoading()
                let obj = {}
                if (data?.is_status == 5 || data?.is_status == 6) {
                    obj = {
                        ...data,
                        amount: `${-parseInt(data?.amount)}`,
                        netamount: `${-parseInt(data?.netamount)}`,
                        client: selectedClient.value,
                        merchant: selectedMerchant.value,
                        txn_timestamp: new Date(data?.txn_timestamp)
                    }
                } else {
                    obj = {
                        ...data,
                        client: selectedClient.value,
                        merchant: selectedMerchant.value,
                        txn_timestamp: new Date(data?.txn_timestamp)
                    }
                }

                const response = await CreateTransactionRecord(token, obj)
                if (response?.result) {
                    toast.success(response?.message);
                    setData(initialstate);
                    setSelectedClient(null);
                    setSelectedMerchant(null);
                    setError({});
                    navigate('/admin/transactions');
                }
            } catch (error) {
                console.log("FormSubmission", error);
                toast.error(error?.response?.data?.message || "Something went wrong !!")
            } finally {
                decrementLoading()
            }
        } else {
            setError(errors)
        }
        console.log(errors, "errors");

    }

    const handleSelectChange = (selectedOption) => {
        // console.log(selectedOption);
        if (selectedOption) {
            setSelectedClient(selectedOption);
            Get_Merchant_list(selectedOption?.value)
        } else {
            setSelectedClient(null);
            setSelectedMerchant(null)
        }
        setError({});
    };
    const handleMerchantSelectChange = (selectedOption) => {
        // console.log(selectedOption);
        setSelectedMerchant(selectedOption);
        setError({});
    };

    const Get_Payment_via = async () => {
        incrementLoading()
        try {
            const response = await GetPaymentvia(token)
            if (response) {
                setPaymentViaList(response)
            }
        } catch (error) {
            console.log(error);

        } finally {
            decrementLoading()
        }
    }

    const Get_Type_of_Terminal = async () => {
        incrementLoading()
        try {
            const response = await GetTypeofTerminal(token)
            if (response) {
                setTypeofTerminallist(response)
            }
        } catch (error) {
            console.log(error);

        } finally {
            decrementLoading()
        }
    }
    const Get_Payment_Type = async () => {
        incrementLoading()
        try {
            const response = await GetPaymenttype(token)
            if (response) {
                setPaymentTypelist(response)
            }
        } catch (error) {
            console.log(error);

        } finally {
            decrementLoading()
        }
    }
    const Get_Card_Type = async () => {
        incrementLoading()
        try {
            const response = await GetCardtype(token)
            if (response) {
                setCardTypelist(response)
            }
        } catch (error) {
            console.log(error);

        } finally {
            decrementLoading()
        }
    }
    const Get_Status_List = async () => {
        incrementLoading()
        try {
            const response = await GetStausList(token)
            if (response) {
                setStatuslist(response)
            }
        } catch (error) {
            console.log(error);
        } finally {
            decrementLoading()
        }
    }


    useEffect(() => {
        Get_Client_list();
        Get_Payment_via();
        Get_Type_of_Terminal();
        Get_Payment_Type();
        Get_Card_Type();
        Get_Status_List();
    }, [])

    // console.log(ClientlistOptions, "Client data", MerchantList, "Merchant list");



    return (
        <>
            <div className="container-xl">
                <div className="hdng-top">
                    <h1 className="app-page-title" style={{ textAlign: 'center' }} >Create Transaction</h1>
                    <div className="pb-2">
                        <BackBtn />
                    </div>
                </div>
                <div className="app-card app-card-orders-table create-trans">
                    <form id='create-record'>
                        <div className='box001'>
                            <h6 className="app-page-title">Customer Information</h6>
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <label className="labelStyle" htmlFor="client">Client List</label>
                                    <Select
                                        options={ClientlistOptions}
                                        isSearchable={true}
                                        value={selectedClient}
                                        onChange={handleSelectChange}
                                        isClearable={true}
                                        placeholder="Select Client"
                                    />
                                    {error.client && (
                                        <span className="error text-danger fs-12">
                                            {error.client}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <label className="labelStyle" htmlFor="merchant">Merchant List</label>
                                    <Select
                                        isClearable={true}
                                        options={MerchantListOptions}
                                        isSearchable={true}
                                        value={selectedMerchant}
                                        onChange={handleMerchantSelectChange}
                                        placeholder="Select Merchant"
                                    />
                                    {error.merchant && (
                                        <span className="error text-danger fs-12">
                                            {error.merchant}
                                        </span>
                                    )}
                                </div>

                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="paymenttype">Payment Type</label>
                                    <select className='custom-date-picker' name='paymenttype' style={{ color: data?.paymenttype ? "#212529" : "#999" }} value={data?.paymenttype} onChange={Handlechange} >
                                        <option style={{ color: '#999' }} value=""> Select Payment Type</option>
                                        {
                                            paymenttypelist?.length > 0 && paymenttypelist?.map((item) => {
                                                return (
                                                    <option style={{ color: '#212529' }} value={item?.value}  >
                                                        {item?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    {error.paymenttype && (
                                        <span className="error text-danger fs-12">
                                            {error.paymenttype}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="paymentvia">Via</label>
                                    <select className='custom-date-picker' name='paymentvia' style={{ color: data?.paymentvia ? "#212529" : "#999" }} value={data?.paymentvia}
                                        onChange={Handlechange} >
                                        <option style={{ color: '#999' }} value=""> Select</option>
                                        {
                                            paymentvialist?.length > 0 && paymentvialist?.map((item) => {
                                                return (
                                                    <option style={{ color: '#212529' }} value={item?.value}>{item?.name}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="PA">Payarc</option>
                            <option value="PP">Propay</option>
                            <option value="PX">Payrix</option>
                            <option value="DC">DataCap</option>
                            <option value="SA">SkyFi ACH</option>
                            <option value="OH">Other</option> */}
                                    </select>
                                    {error.paymentvia && (
                                        <span className="error text-danger fs-12">
                                            {error.paymentvia}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="typeofterminal">Type of Terminal </label>
                                    <select className='custom-date-picker' name='typeofterminal' style={{ color: data?.typeofterminal ? "#212529" : "#999" }} value={data?.typeofterminal} onChange={Handlechange} >
                                        <option style={{ color: '#999' }} value=""> Select</option>
                                        {
                                            typeofterminallist?.length > 0 && typeofterminallist?.map((item) => {
                                                return (
                                                    <option style={{ color: '#212529' }} value={item?.value}>{item?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {error.typeofterminal && (
                                        <span className="error text-danger fs-12">
                                            {error.typeofterminal}
                                        </span>
                                    )}
                                </div>

                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="customerphone">Customer Phone</label>
                                    <input type="text" name='customerphone' maxLength="10"
                                        placeholder='Enter Customer Phone' className="custom-date-picker"
                                        onChange={Handlechange}
                                        value={formatPhoneNumberglobal(data?.customerphone)}
                                        onBlur={HandleBlur}
                                    />
                                    {error.customerphone && (
                                        <span className="error text-danger fs-12">
                                            {error.customerphone}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="customername">Customer Name</label>
                                    <input type="text" name='customername' placeholder='Enter Customer Name' className="custom-date-picker"
                                        value={data?.customername}
                                        onChange={Handlechange}
                                    />
                                    {error.customername && (
                                        <span className="error text-danger fs-12">
                                            {error.customername}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='box001'>
                            <h6 className="app-page-title">Transaction Information</h6>
                            <div className="row">
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="txn_id">Transaction Id</label>
                                    <input type="text" name='txn_id' className="custom-date-picker" placeholder='Enter Transaction id'
                                        onChange={Handlechange}
                                        value={data?.txn_id}
                                    />
                                    {error.txn_id && (
                                        <span className="error text-danger fs-12">
                                            {error.txn_id}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="amount">Amount ($)</label>
                                    <input
                                        type="text" name='amount' className="custom-date-picker" placeholder='Enter Amount in $'
                                        onChange={Handlechange}
                                        value={data?.amount}
                                    />
                                    {error.amount && (
                                        <span className="error text-danger fs-12">
                                            {error.amount}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="netamount">Net Amount ($)</label>
                                    <input
                                        type="text" name='netamount' className="custom-date-picker" placeholder='Enter Net Amount in $'
                                        onChange={Handlechange}
                                        value={data?.netamount}
                                    />
                                    {error.netamount && (
                                        <span className="error text-danger fs-12">
                                            {error.netamount}
                                        </span>
                                    )}
                                </div>

                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="tax">Tax (%)</label>
                                    <input
                                        type="text" name='tax' className="custom-date-picker" placeholder='Enter Tax in %'
                                        onChange={Handlechange}
                                        value={data?.tax}
                                    />
                                    {error.tax && (
                                        <span className="error text-danger fs-12">
                                            {error.tax}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="discount">Discount (%)</label>
                                    <input
                                        type="text" name='discount' className="custom-date-picker" placeholder='Enter Discount in %'
                                        onChange={Handlechange}
                                        value={data?.discount}
                                    />
                                    {error.discount && (
                                        <span className="error text-danger fs-12">
                                            {error.discount}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="fees">Fees (%)</label>
                                    <input
                                        type="text" name='fees' className="custom-date-picker" placeholder='Enter Fees in %'
                                        onChange={Handlechange}
                                        value={data?.fees}
                                    />
                                    {error.fees && (
                                        <span className="error text-danger fs-12">
                                            {error.fees}
                                        </span>
                                    )}
                                </div>

                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="tip_amount">Tip Amount ($)</label>
                                    <input
                                        type="text" name='tip_amount' className="custom-date-picker" placeholder='Enter Tip Amount in $'
                                        onChange={Handlechange}
                                        value={data?.tip_amount}
                                    />
                                    {error.tip_amount && (
                                        <span className="error text-danger fs-12">
                                            {error.tip_amount}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="table_name">Table Name</label>
                                    <input type="text" name='table_name' className="custom-date-picker" placeholder='Enter Table Name'
                                        onChange={Handlechange}
                                        value={data?.table_name}
                                    />
                                    {error.table_name && (
                                        <span className="error text-danger fs-12">
                                            {error.table_name}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="lastFourDigits">Card Last four Digits</label>
                                    <input
                                        type="text" name='lastFourDigits' className="custom-date-picker" placeholder='Enter Card Last Four Digits'
                                        maxLength={"4"}
                                        value={data?.lastFourDigits}
                                        onChange={Handlechange}
                                    />
                                    {error.lastFourDigits && (
                                        <span className="error text-danger fs-12">
                                            {error.lastFourDigits}
                                        </span>
                                    )}
                                </div>

                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="cardType">Card Type</label>
                                    <select className='custom-date-picker' name='cardType' style={{ color: data?.cardType ? "#212529" : "#999" }} value={data?.cardType} onChange={Handlechange} >
                                        <option style={{ color: '#999' }} value="" >Select Card Type</option>
                                        {
                                            Cardtypelist?.length > 0 && Cardtypelist?.map((item) => {
                                                return (
                                                    <option style={{ color: '#212529' }} value={item?.value}  >
                                                        {item?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    {error.cardType && (
                                        <span className="error text-danger fs-12">
                                            {error.cardType}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="is_status">Transaction Status</label>
                                    <select className='custom-date-picker' name='is_status' style={{ color: data?.is_status ? "#212529" : "#999" }} value={data?.is_status} onChange={Handlechange} >
                                        <option style={{ color: '#999' }} value="" >Select Transaction Status </option>
                                        {
                                            statuslist?.length > 0 && statuslist?.map((item) => {
                                                return (
                                                    <option style={{ color: '#212529' }} value={item?.value}  >
                                                        {item?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    {error.is_status && (
                                        <span className="error text-danger fs-12">
                                            {error.is_status}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-4 mb-3">
                                    <label className="labelStyle" htmlFor="txn_timestamp">Date and Time of Transaction</label>
                                    <input type="datetime-local" name='txn_timestamp' className="custom-date-picker"
                                        onChange={Handlechange}
                                        value={data?.txn_timestamp}
                                        style={{ color: data?.txn_timestamp ? "#212529" : "#999" }}
                                    />
                                    {error.txn_timestamp && (
                                        <span className="error text-danger fs-12">
                                            {error.txn_timestamp}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='box001'>
                            <div className="d-flex justify-content-center mt-5 ">
                                <div className="w-auto mx-2">
                                    <button className="idle-green-btn" onClick={(e) => { HandleSubmit(e) }} >
                                        Create Record
                                    </button>
                                </div>
                                <div className="w-auto">
                                    <button className="idle-blk-btn" onClick={(e) => {
                                        e.preventDefault()
                                        setData(initialstate);
                                        setSelectedClient(null);
                                        setSelectedMerchant(null);
                                        setError({});
                                    }} >Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
            <Loader loading={isLoading} />
        </>
    )
}

export default CreateRecord