import axios from "axios";
import baseUrl from "../../baseUrl";
import { store } from '../../redux/store';

const getTokenFromState = () => {
    const state = store.getState();
    return state.deviceId.deviceId;
};

export const getDataCapGateway = async (tokenMID, formData) => {
    const response = await axios.post(
        `https://pay.dcap.com/v2/Admin/AcquireInitialApiKey`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: tokenMID,
            },
        }
    );
    return response.data;
};
export const createDataCapGateway = async (token, merchant_id, formData) => {
    let deviceId = getTokenFromState()
    const response = await axios.post(
        `${baseUrl}/api/v1/super-admin/merchant/create-payment-gateway/${merchant_id}`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const updateDataCapGateway = async (token, merchant_id, gateway_id, formData) => {
    let deviceId = getTokenFromState()
    const response = await axios.patch(
        `${baseUrl}/api/v1/super-admin/merchant/update-payment-gateway/${merchant_id}/${gateway_id}`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const deleteDataCapGateway = async (token, id, data) => {
    let deviceId = getTokenFromState()
    const response = await axios.delete(
        `${baseUrl}/api/v1/super-admin/merchant/delete-payment-gateway/${id}/${data?.gateway_id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const skysystemOnboarding = async (token, id) => {
    let deviceId = getTokenFromState()
    const response = await axios.post(
        `${baseUrl}/api/v1/super-admin/merchant/merchant_onboarding`,
        { merchant_id: id },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const ACHOnboarding = async (token, id) => {
    let deviceId = getTokenFromState()
    const response = await axios.post(
        `${baseUrl}/api/v1/super-admin/merchant/ach-merchant-onboarding`,
        { merchant_id: id },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};