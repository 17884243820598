import React, { useEffect, useState } from 'react';
import { useToken } from '../../hooks/useToken';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../DefaultComponent/Loader';
import { useUpdateWebhookURLMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';

const WebhookUrl = () => {
    const [updateWebhookURLTrigger, { isFetching, isLoading }] = useUpdateWebhookURLMutation();
    const location = useLocation();
    const navigate = useNavigate();
     const [loading, setLoading] = useState(false);
    const [webhookUrl, setWebhookUrl] = useState('');
    const [bot, setBot] = useState('');
    const [headObj, setHeadObj] = useState([{ key: '', value: '' }]);
    const [errors, setErrors] = useState({ webhookUrl: '', headers: [] });

    // Handle changes in the header input fields (key or value)
    const handleChange = (ind, e) => {
        const { name, value } = e.target;
        const updatedHeaders = [...headObj];
        updatedHeaders[ind][name] = value;
        setHeadObj(updatedHeaders);

        // Clear specific error for the updated field
        const updatedErrors = { ...errors };
        if (value.trim() !== '') {
            updatedErrors.headers[ind] = { ...updatedErrors.headers[ind], [name]: '' };
        }
        setErrors(updatedErrors);
    };

    // Add a new header to the list
    const handleAddHeader = () => {
        setHeadObj([...headObj, { key: '', value: '' }]);
        setErrors({ ...errors, headers: [...errors.headers, { key: '', value: '' }] });
    };

    // Delete a header from the list
    const handleDeleteHeader = (ind) => {
        const updatedHeaders = headObj.filter((_, index) => index !== ind);
        const updatedErrors = { ...errors };

        if (updatedHeaders.length === 0) {
            // Clear header errors if no headers remain
            updatedErrors.headers = [];
        } else {
            updatedErrors.headers.splice(ind, 1);
        }

        setHeadObj(updatedHeaders);
        setErrors(updatedErrors);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const sanitizedHeaders = sanitizeHeaders(headObj);

            const header = sanitizedHeaders.reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
            }, {});

            setLoading(true);
            const res = await updateWebhookURLTrigger({ webhook_url: webhookUrl, bot_key: bot, header }).unwrap();
            if (res?.result === true) {
                toast.success(`${res?.message}`);
                navigate('/api-keys', { replace: true });
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            console.log("mera wala", error?.message);
            setLoading(false);
            toast.error(error?.message || 'Error updating webhook URL');
        }
    };



    const validateFields = () => {
        const newErrors = { webhookUrl: '', headers: [] };

        // Validate webhook URL
        if (!webhookUrl.trim()) {
            newErrors.webhookUrl = 'Webhook URL is required.';
        }

        // Validate headers only if headers exist
        headObj.forEach((header, index) => {
            const headerErrors = {};

            // Validate key
            if (!header.key.trim()) {
                headerErrors.key = 'Key is required.';
            } else if (!/^[a-zA-Z0-9_-]+$/.test(header.key.trim())) {
                headerErrors.key = 'Key must be alphanumeric and can include underscores or hyphens.';
            }

            // Validate value
            if (!header.value.trim()) {
                headerErrors.value = 'Value is required.';
            } else if (!/^[a-zA-Z0-9 _-]+$/.test(header.value.trim())) {
                headerErrors.value = 'Value must be alphanumeric and can include spaces, underscores, or hyphens.';
            }

            if (Object.keys(headerErrors).length > 0) {
                newErrors.headers[index] = headerErrors;
            }
        });

        // Return errors only if they exist
        return Object.keys(newErrors).reduce((acc, key) => {
            if (newErrors[key] && newErrors[key].length !== 0) {
                acc[key] = newErrors[key];
            }
            return acc;
        }, {});
    };

    const sanitizeHeaders = (headers) => {
        return headers.map(({ key, value }) => ({
            key: key.trim(),
            value: value.trim(),
        }));
    };


    useEffect(() => {
        if (!location?.state?.flag) return navigate('/api-keys');
        setWebhookUrl(location?.state?.webhookUrl);

        if (location?.state?.header) {
            let jsonHeader = JSON.parse(location.state.header)
            const outputArray = Object.entries(jsonHeader).map(([key, value]) => ({
                key,
                value,
            }));
            setHeadObj(outputArray);
        }
    }, [location]);

    return (
        <div className="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
            <div className="app-card-body">
                <h4 className="heading">Webhook</h4>
                <div className="white-box-content pl-15">
                    <div className="row">
                        <div className="col-10 mb-3">
                            <label>URL</label>
                            <input
                                type="text"
                                className={`form-control ${errors.webhookUrl ? 'is-invalid' : ''}`}
                                placeholder="Webhook URL"
                                value={webhookUrl}
                                onChange={(e) => {
                                    setWebhookUrl(e.target.value);
                                    setErrors({ ...errors, webhookUrl: '' });
                                }}
                            />
                            {errors.webhookUrl && <div className="invalid-feedback">{errors.webhookUrl}</div>}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <label>Headers</label>
                            </div>
                        </div>
                    </div>
                    <form>
                        {headObj.length > 0 &&
                            headObj.map((item, ind) => (
                                <div className="row mb-2" key={ind}>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.headers[ind]?.key ? 'is-invalid' : ''}`}
                                            placeholder={`Key ${ind + 1}`}
                                            name="key"
                                            value={item.key}
                                            onChange={(e) => handleChange(ind, e)}
                                        />
                                        {errors.headers[ind]?.key && <div className="invalid-feedback">{errors.headers[ind]?.key}</div>}
                                    </div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.headers[ind]?.value ? 'is-invalid' : ''}`}
                                            placeholder="Value"
                                            name="value"
                                            value={item.value}
                                            onChange={(e) => handleChange(ind, e)}
                                        />
                                        {errors.headers[ind]?.value && <div className="invalid-feedback">{errors.headers[ind]?.value}</div>}
                                    </div>
                                    <div className="col-2 dlt-keys">
                                        <i
                                            className="fa-solid fa-trash cursor-pointer"
                                            onClick={() => handleDeleteHeader(ind)}
                                        ></i>
                                    </div>
                                </div>
                            ))}
                        <div className='row'>
                            <div className='col-md-12 mt-4 bottom-button'>
                                <button type="button" onClick={handleAddHeader}>
                                    Add Key
                                </button>

                                <button type="submit" onClick={handleSubmit}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Loader loading={loading} />
        </div>
    );
};

export default WebhookUrl;
