// apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import END_POINTS from '../api/endPoints';
import { toast } from 'react-toastify';

const getFingerPrint = async () => {
    try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        return result.visitorId;
    } catch (error) {
        console.log(error)
    }
}

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
        const deviceId = await getFingerPrint();
        const tkn = getState().token.token;
        const tk = localStorage.getItem('token');
        if (tkn || tk) {
            let token = JSON.parse(tk);
            if (token || tkn) {
                headers.set('Authorization', `${tkn ?? token}`);
            }
        }
        if (deviceId) {
            headers.set('X-Device-Fingerprint', deviceId);
        }
        return headers;
    },
});

const showError = (error) => {
    if (error?.data?.message &&
        ["Unauthorized", "Forbidden"].some(status => error.data.message.includes(status))) {
        toast.error(error.data.message || "Something went wrong!")
        localStorage.removeItem("token");
        setTimeout(() => {
            window.location.replace("/login");
        }, 3000)
    }
    else if (error?.data?.message) {
        toast.error(error.data.message || "Something went wrong!")
    }
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: ['Sidebar', 'Profile'],
    endpoints: (builder) => ({
        // dashboard analytics
        getDashobardAnalyticsAdmin: builder.query({
            query: ({ startDate, endDate, graphFilter }) => ({
                url: END_POINTS.DASHBOARD_ANALYTICS_ADMIN,
                method: 'GET',
                params: graphFilter === 'allTime'
                    ? { graphFilter }
                    : { startDate, endDate, graphFilter }
            }),
            transformErrorResponse: (error) => { showError(error) },
        }),
        // get profile call
        getProfileAdmin: builder.query({
            query: () => END_POINTS.GET_PROFILE_ADMIN,
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Profile'],
        }),

        // create public key
        createPublicKey: builder.mutation({
            query: (formData) => ({
                url: END_POINTS.CREATE_PUBLIC_KEY,
                method: 'POST',
                body: formData,
            }),
            transformErrorResponse: (error) => { return error?.data },
            invalidatesTags: (result, error) => {
                if (error) return []
                return ['Profile']
            }
        }),

        // UPDATE webHook url
        updateWebhookURL: builder.mutation({
            query: (formData) => ({
                url: END_POINTS.UPDATE_WEBHOOK_ADMIN,
                method: 'PATCH',
                body: formData,
            }),
            transformErrorResponse: (error) => { return error?.data },
            invalidatesTags: (result, error) => {
                if (error) return []
                return ['Profile']
            }

        }),
        // get sidebar
        getSidebar: builder.query({
            query: () => END_POINTS.GET_MENU_ADMIN,
        }),
        // merchant list
        getMerchantList: builder.query({
            query: ({ page = 1, search = '' }) => ({
                url: END_POINTS.GET_ALL_MERCHANT,
                params: { page, search },
            }),
            transformResponse: (response) => {
                return {
                    data: response?.data?.allVerifiedMerchant,
                    page: response?.data?.page,
                    totalPage: response?.data?.totalPage
                }
            },
            transformErrorResponse: (error) => { showError(error) },
        }),

    }),
});

export const { useGetDashobardAnalyticsAdminQuery,
    useGetProfileAdminQuery,
    useCreatePublicKeyMutation,
    useUpdateWebhookURLMutation,
    useLazyGetSidebarQuery,
    useGetMerchantListQuery,
    useGetSidebarQuery
} = apiSlice;
