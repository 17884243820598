import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import baseUrl from '../../../baseUrl';
import Loader from '../../DefaultComponents/Loader';
import CardType from '../../../DefaultComponent/CardType';
import BackBtn from '../../DefaultComponents/BackBtn';
import moment from 'moment';
import { GetStatusLabelGlobal } from '../../hooks/GetStatusLabelGlobal';
import { toast } from 'react-toastify';
import { useRefToken } from '../../hooks/useToken';
import { useSelector } from 'react-redux';

const Transactiondetails = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const { deviceId } = useSelector(state => state.deviceId);
    const [paymentData, setPaymentData] = useState(null);
    const [loading, setLoading] = useState(false);
    const refToken = useRefToken();
    const fetchData = async () => {
        try {
            setLoading(true);
            const url = `${baseUrl}/api/v1/super-admin/transaction/${id}`
            const response = await fetch(
                url,
                {
                    headers: {
                        Authorization: refToken,
                        'X-Device-Fingerprint': deviceId,
                    },
                }
            );
            const data = await response.json();
            setLoading(false);
            setPaymentData(data);

        } catch (error) {
            setLoading(false);
            console.error("Error fetching payment data:", error, error.response.data.message, error.response.data.message.includes("Unauthorized"));
            if (error.response.data.message.includes("Unauthorized")) {
                navigate("/super-admin-login");
                localStorage.removeItem("refreshToken");
            }
            toast.error(error?.response.data.message);
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [])


    function toStr(st) {
        if (st) {
            return String(st).slice(1);
        }
        return;
    }

    const formatTimestamp = (dateString) => {
        const date = new Date(dateString);
    
        // convert to local
        const localYear = date.getFullYear();
        const localMonth = String(date.getMonth() + 1).padStart(2, "0");
        const localDay = String(date.getDate()).padStart(2, "0");
        const localHour = String(date.getHours()).padStart(2, "0");
        const localMinute = String(date.getMinutes()).padStart(2, "0");
        const localSecond = String(date.getSeconds()).padStart(2, "0");
    
        return `${localMonth}.${localDay}.${localYear} ${localHour}:${localMinute}:${localSecond}`;
      }

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hour = String(date.getHours()).padStart(2, "0");
        const minute = String(date.getMinutes()).padStart(2, "0");
        const second = String(date.getSeconds()).padStart(2, "0");
    
        return `${month}.${day}.${year}`.replace(" ", ", ");
      };

    return (
        <>
            <div className="d-md-flex d-block gap-2 mb-4 align-items-center">
                <div className=" ">
                    <BackBtn />
                </div>
                <div className="mt-2 mt-md-0">
                    <h4 className="purple"> {paymentData?.data?.transaction[0]?.pos_guid}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex align-items-start gap-2">
                    <h3 className="">
                        {Number(paymentData?.data?.transaction[0]?.Amount) < 0 ? `-$${(Number(paymentData?.data?.transaction[0]?.Amount) * -1).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : `$${Number(paymentData?.data?.transaction[0]?.Amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    </h3>
                    {<GetStatusLabelGlobal status={paymentData?.data?.transaction[0]?.is_status} styles={{ width: 'auto' }} />}
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-sm-flex gap-3">
                    <div className="">
                        <span className="fs-6 fw-bold">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_6963_6911)">
                                    <path d="M7.00065 0.583008C5.73156 0.583008 4.49096 0.959339 3.43574 1.66441C2.38053 2.36948 1.55809 3.37163 1.07243 4.54412C0.586765 5.71661 0.459693 7.00679 0.707282 8.2515C0.95487 9.49621 1.566 10.6396 2.46339 11.5369C3.36077 12.4343 4.50411 13.0455 5.74882 13.293C6.99354 13.5406 8.28371 13.4136 9.45621 12.9279C10.6287 12.4422 11.6308 11.6198 12.3359 10.5646C13.041 9.50937 13.4173 8.26877 13.4173 6.99967C13.4153 5.29848 12.7386 3.66755 11.5357 2.46462C10.3328 1.2617 8.70184 0.585015 7.00065 0.583008ZM7.00065 12.2497C5.9623 12.2497 4.94727 11.9418 4.08391 11.3649C3.22055 10.788 2.54765 9.96807 2.15029 9.00876C1.75293 8.04945 1.64896 6.99385 1.85153 5.97545C2.0541 4.95705 2.55412 4.02159 3.28834 3.28736C4.02257 2.55314 4.95803 2.05312 5.97643 1.85055C6.99483 1.64798 8.05043 1.75195 9.00974 2.14931C9.96905 2.54667 10.789 3.21957 11.3659 4.08293C11.9427 4.94629 12.2507 5.96132 12.2507 6.99967C12.249 8.39154 11.6953 9.72591 10.7111 10.7101C9.72689 11.6943 8.39252 12.248 7.00065 12.2497Z" fill="#35254D" />
                                    <path d="M7.58268 6.75883V3.50033C7.58268 3.34562 7.52122 3.19724 7.41183 3.08785C7.30243 2.97845 7.15406 2.91699 6.99935 2.91699C6.84464 2.91699 6.69627 2.97845 6.58687 3.08785C6.47747 3.19724 6.41602 3.34562 6.41602 3.50033V7.00033C6.41605 7.15502 6.47753 7.30337 6.58693 7.41274L8.33693 9.16274C8.44695 9.269 8.5943 9.3278 8.74725 9.32647C8.9002 9.32514 9.0465 9.26379 9.15466 9.15564C9.26281 9.04748 9.32416 8.90117 9.32549 8.74823C9.32682 8.59528 9.26802 8.44793 9.16177 8.33791L7.58268 6.75883Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6963_6911">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="ms-1">Last update</span>
                        </span>
                        <div className="text" > {paymentData &&
                            formatTimestamp(paymentData?.data?.transaction[0]?.Date)}</div>
                    </div>
                    <div className="">
                        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="1" height="24" fill="#D4CDDF" fillOpacity="0.5" />
                        </svg>
                    </div>
                    <div className="">
                        <span className=" fs-6 fw-bold">
                            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 0.5H1.75C0.785062 0.5 0 1.28506 0 2.25V9.75C0 10.7149 0.785062 11.5 1.75 11.5H14.25C15.2149 11.5 16 10.7149 16 9.75V2.25C16 1.28506 15.2149 0.5 14.25 0.5ZM1.75 1.5H14.25C14.6635 1.5 15 1.83647 15 2.25V3.25H1V2.25C1 1.83647 1.33647 1.5 1.75 1.5ZM14.25 10.5H1.75C1.33647 10.5 1 10.1635 1 9.75V4.25H15V9.75C15 10.1635 14.6635 10.5 14.25 10.5Z" fill="#35254D" />
                                <path d="M3.5 9H3C2.72387 9 2.5 8.77612 2.5 8.5V8C2.5 7.72387 2.72387 7.5 3 7.5H3.5C3.77612 7.5 4 7.72387 4 8V8.5C4 8.77612 3.77612 9 3.5 9Z" fill="#35254D" />
                            </svg>
                            <span className="ms-1">Payment method </span>
                        </span>
                        <div className="text-center">
                            <CardType type={paymentData?.data?.transaction[0]?.cardType} lastFour={paymentData?.data?.transaction[0]?.lastFourDigits} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3">
                <div className="col  ">
                    <div className="border-1 border rounded-4 p-3" style={{ height: "100%" }}>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <span className="mr-2">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.169 6.32909C12.2192 6.16843 12.2467 6.00153 12.2507 5.83325V3.49992C12.2507 1.83742 9.74232 0.583252 6.41732 0.583252C3.09232 0.583252 0.583984 1.83742 0.583984 3.49992V5.83325C0.61614 6.22187 0.747902 6.59565 0.966559 6.91853C1.18522 7.24141 1.48339 7.50249 1.83232 7.67659C1.77876 7.83442 1.75118 7.99991 1.75065 8.16659V10.4999C1.75065 12.1624 4.25898 13.4166 7.58398 13.4166C10.909 13.4166 13.4173 12.1624 13.4173 10.4999V8.16659C13.3859 7.77851 13.2543 7.40523 13.0355 7.08318C12.8167 6.76112 12.5182 6.5013 12.169 6.32909ZM6.41732 8.74992C8.66315 8.74992 10.5357 8.16659 11.5157 7.29159C11.9823 7.57159 12.2507 7.87492 12.2507 8.16659C12.2507 8.87825 10.4307 9.91659 7.58398 9.91659C4.73732 9.91659 2.96982 8.90742 2.91732 8.19575C4.04347 8.58022 5.22747 8.76769 6.41732 8.74992ZM6.41732 1.74992C9.26398 1.74992 11.084 2.78825 11.084 3.49992C11.084 4.21159 9.26398 5.24992 6.41732 5.24992C3.57065 5.24992 1.75065 4.21159 1.75065 3.49992C1.75065 2.78825 3.57065 1.74992 6.41732 1.74992ZM1.75065 5.28492C3.16942 6.092 4.78645 6.48413 6.41732 6.41659C8.04819 6.48413 9.66521 6.092 11.084 5.28492V5.83325C11.084 6.54492 9.26398 7.58325 6.41732 7.58325C3.57065 7.58325 1.75065 6.54492 1.75065 5.83325V5.28492ZM7.58398 12.2499C4.73732 12.2499 2.91732 11.2116 2.91732 10.4999V9.95159C4.33609 10.7587 5.95312 11.1508 7.58398 11.0833C9.21485 11.1508 10.8319 10.7587 12.2507 9.95159V10.4999C12.2507 11.2116 10.4307 12.2499 7.58398 12.2499Z" fill="#35254D" />
                                    </svg>
                                </span>
                                <span className="fs-12">
                                    PAYMENT DETAILS
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col overflow-auto">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Merchant </th>
                                            <td> {paymentData?.data?.transaction[0]?.merchantDetails?.businessName} </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Timestamp </th>
                                            <td>{paymentData &&
                                                formatDate(paymentData?.data?.transaction[0]?.Date)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <td>{paymentData?.data?.transaction[0]?.pos_guid}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Amount</th>
                                            <td scope="col">
                                                {/* ${paymentData && paymentData?.data?.transaction[0]?.Amount} */}
                                                <td>  {Number(paymentData && paymentData?.data?.transaction[0]?.Amount) < 0 ? `-$${paymentData && paymentData?.data?.transaction[0]?.Amount.slice(1)}` : `$${paymentData && paymentData?.data?.transaction[0]?.Amount}`}</td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Net </th>
                                            <td>{Number(paymentData && paymentData?.data?.transaction[0]?.Net) < 0 ? `-$${paymentData && Number(toStr(paymentData?.data?.transaction[0]?.Net)).toFixed(2)}` : `$${Number(paymentData && paymentData?.data?.transaction[0]?.Net).toFixed(2)}`}</td>
                                        </tr>
                                        {paymentData?.data?.transaction[0]?.description && (
                                            <tr>
                                                <th scope="row">Description</th>
                                                <td>{paymentData?.data?.transaction[0]?.description}</td>
                                            </tr>
                                        )}
                                        {paymentData?.data?.transaction[0]?.order_id && (
                                            <tr>
                                                <th scope="row">External Order Id</th>
                                                <td>{paymentData?.data?.transaction[0]?.order_id}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader loading={loading} />
        </>
    )
}

export default Transactiondetails