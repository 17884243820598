import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SearchIcon } from '../../DefaultComponent/Svgicons';
import { useToken } from '../../hooks/useToken';
import { deleteStaffMerchant, getStaffMerchant, getStaffMerchantRole } from '../../api/Merchant';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Modal, Table } from "react-bootstrap";
import Edit from "../../assets/images/Edit.svg";
import Delete from "../../assets/images/Delete.svg";
import AddStaffMerchant from './Form/AddStaffMerchant';
import UpdatePasswordStaffMerchant from './Form/UpdatePasswordStaffMerchant';
import EditStaffMerchant from './Form/EditStaffMerchant';
import Loader from '../../SuperAdmin/DefaultComponents/Loader';
import { useDeleteMerchantStaffByIdMutation, useGetMerchantStaffRolesQuery, useGetStaffbyMerchantQuery } from '../../redux/merchantApiSlice';

const StaffMerchant = () => {
    const token = useToken();
    const navigate = useNavigate();
    const divLoader = useRef(null);
    const [info, setInfo] = useState("");
    const [noDataMessage, setNoDataMessage] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    // const [role, setRole] = useState([]);
    const [staffMerchant, setStaffMerchant] = useState([]);
    const [fetMoreData, setFetchMoreData] = useState(0);
    const observer = useRef();
    const [state, setState] = useState({
        page: 1,
        perPage: 10,
        totalPage: 0,
        searchParam: '',
        filterParam: '',
        message: "",
        loading: false,
        update: {},
        pass: {},
        deleteId: null,
        role: false,
        name: "",
        searchValue: '',//for debounce search
    });

    const [form, setForm] = useState({
        show: false,
        edit: false,
        pin: false,
        pass: false,
        delete: false,
    });

    const handleState = (item, value) => {
        setForm((prev) => ({
            ...prev,
            [item]: value,
        }));
        // setHasMore(true);
    };
    const { data: stafflist, isLoading: stafflistloading, isFetching: stafflistfetching, isError: stafflisterror } = useGetStaffbyMerchantQuery({ page: state.page, search: state.searchValue, role_id: state.filterParam }, {
        skip: !hasMore && state?.page !== 1
    });
    const { data, isLoading: merchantstaffroleloading, isFetching: merchantstaffrolefetching, isError: merchantstaffroleerror } = useGetMerchantStaffRolesQuery();
    const [DeleteStaffTrigger, { data: deletestaffdata, isLoading: deletestaffloading, isError: deletestafferror }] = useDeleteMerchantStaffByIdMutation()
    const { data: role } = data || {}

    // let isScroll = true;
    // const fetchStaffMerchant = async (pageNo, perPageNo, searchValue, roleId) => {
    //     const params = {
    //         page: pageNo,
    //         perPage: perPageNo,
    //     };
    //     if (searchValue?.trim()) {
    //         params.search = searchValue;
    //     }
    //     if (roleId) {
    //         params.role_id = roleId;
    //     }
    //     try {
    //         if (isScroll) {
    //             setState((prev) => ({
    //                 ...prev,
    //                 loading: true,
    //             }));
    //             const response = await getStaffMerchant(token, params);

    //             if (pageNo == 1) {
    //                 setStaffMerchant([]);
    //             }
    //             if (!response.status) {
    //                 setHasMore(false)
    //                 isScroll = false;
    //             }
    //             if (response?.data?.allList?.length === 0 && response?.data?.count === 0) {
    //                 console.log("no more data as data is empty");

    //                 setHasMore(false);
    //                 setNoDataMessage(true);
    //                 setFetchMoreData(0);
    //                 setState((prev) => ({
    //                     ...prev,
    //                     loading: false,
    //                 }));
    //                 setStaffMerchant([]);
    //                 return false;
    //             }

    //             if (response?.data?.totalPage == pageNo) {
    //                 setHasMore(false)
    //                 setFetchMoreData(0);
    //             }
    //             else {
    //                 setHasMore(true);
    //                 setFetchMoreData(fetMoreData + 1);

    //             }
    //             if (response.status) {
    //                 setStaffMerchant((prev) => [...prev, ...response?.data.allList]);
    //                 setNoDataMessage(false);
    //                 setState((prev) => ({
    //                     ...prev,
    //                     // page: response?.data?.page + 1,
    //                     page: state.page + 1,
    //                 }));
    //             }


    //         }
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     } catch (error) {
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //         toast.error(error?.response?.data?.message);
    //         if (error?.response?.data?.message?.includes("Unauthorized")) {
    //             navigate("/login");
    //             localStorage.removeItem("token");
    //         }
    //         console.log(error);
    //     }
    // };

    // const fetchRoles = async () => {
    //     setState((prev) => ({
    //         ...prev,
    //         loading: true,
    //     }));
    //     const param = {
    //         isAll: true,
    //     };
    //     try {
    //         const response = await getStaffMerchantRole(token);
    //         setRole(response?.allList);
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     } catch (error) {
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     }
    // };

    // useEffect(() => {
    //     // console.log("=====================hasmore================", !state.searchParam);
    //     if (hasMore || !state.searchParam) {

    //         // if (!state.searchParam && !state.filterParam && hasMore) {
    //         const options = {
    //             root: null,
    //             rootMargin: "20px",
    //             threshold: 1.0,
    //         };

    //         const observer = new IntersectionObserver((entries) => {
    //             if (entries[0].isIntersecting && hasMore) {
    //                 fetchStaffMerchant(state.page, state.perPage, state.searchParam, state.filterParam);
    //             }
    //         }, options);

    //         if (divLoader.current) {
    //             observer.observe(divLoader.current);
    //         }

    //         return () => {
    //             if (divLoader.current) {
    //                 observer.unobserve(divLoader.current);
    //             }
    //         };
    //     }
    // }, [state.page, fetMoreData, state.filterParam]);

    const handleSearchChange = (e) => {
        // setStaffMerchant([]);
        let searchData = e.target.value;
        searchData = searchData?.trim();
        if (searchData != " ") {
            setState((prev) => ({
                ...prev,
                page: 1,
                searchParam: searchData,
                filterParam: '',
            }));
            handleSearch(searchData);
        } else {
            // console.log("serardcj daa=================",searchData);
            setState((prev) => ({
                ...prev,
                page: 1,
                searchParam: '',
            }));
            setHasMore(true)
        }
    };

    const handleSearch = useCallback(
        debounce((searchQuery) => {
            setState((prev) => ({
                ...prev,
                searchValue: searchQuery,
            }));
        }, 700),
        []
    );

    const handleEdit = (item, value) => {
        handleState("edit", true);
        handleInfo(value);
        setState((prev) => ({
            ...prev,
            update: item,
            pass: item,
        }));
    };

    const handleDelete = (item) => {
        handleState("delete", true);
        setState((prev) => ({
            ...prev,
            deleteId: item.id,
            name: `${item.first_name} ${item.last_name}`
        }));
    };

    const handleDeleteEmployee = async () => {
        setState((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const response = await DeleteStaffTrigger(state.deleteId);
            // dispatch(tokenAction.showToaster({ status: true, message: `You have deleted ${state?.name} `, open: true }));
            toast.success(`You have deleted ${state?.name} `);

            // if (response?.result == true) {
            //     let newData = staffMerchant?.filter((item) => {
            //         if (item?.id != state.deleteId) {
            //             return item;
            //         }
            //     });
            //     // console.log("=============deleteemp========",newData);

            //     setStaffMerchant(newData)
            // }
            handleState("delete", false);
            setState((prev) => ({
                ...prev,
                // page:1,
                loading: false,
            }));
        } catch (error) {
            // dispatch(tokenAction.showToaster({ status: false, message: error?.response?.message, open: true }));
            toast.error(error?.response?.message);
            setState((prev) => ({
                ...prev,
                loading: false,
            }));
        }
    };

    const handleInfo = (value) => {
        if (value == "user") {
            setInfo("user");
        } else {
            setInfo("security");
        }
    };

    const handleAll = () => {
        // setStaffMerchant([]);
        // setHasMore(true);
        // setFetchMoreData(fetMoreData + 1);
        setState((prev) => ({
            ...prev,
            page: 1,
            filterParam: '',
        }));
    };

    const handleFilter = (id) => {
        setStaffMerchant([]);
        setHasMore(true);
        setState((prev) => ({
            ...prev,
            page: 1,
            filterParam: id,
        }));
        // setFetchMoreData(fetMoreData + 1);
    };

    const gradientCircle = ({ color }) => {
        const style = {
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            background: `linear-gradient(171deg, ${color} 0%, rgba(239,239,242,1) 83%)`,
        };
        return style;
    };

    // useEffect(() => {
    //     fetchRoles();
    // }, []);





    // Last element ref for intersection observer
    const lastCustomerRef = useCallback((node) => {
        if (stafflistloading || stafflistfetching || !hasMore) return; // Don't observe if loading or no more data
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !state.loading && !stafflistfetching && !stafflistloading) {
                setState((prev) => ({
                    ...prev,
                    page: state.page + 1,
                }));
            }
        }, {
            root: null,
            rootMargin: '20px', // Load a bit before reaching the end
            threshold: 0.1 // Trigger when even 10% of the element is visible
        });

        if (node) observer.current.observe(node);
    }, [stafflistloading, stafflistfetching, hasMore, stafflist?.page]);

    // Cleanup observer on component unmount
    useEffect(() => {
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);


    useEffect(() => {
        if (stafflist?.data) {
            if (stafflist?.page === 1) {
                setStaffMerchant(stafflist?.data);
            } else {
                setStaffMerchant(prev => {
                    // More strict duplicate checking
                    const existingIds = new Set(prev.map(item => item.id));
                    const newstafflist = stafflist?.data?.filter(
                        newItem => !existingIds.has(newItem.id)
                    );
                    return [...prev, ...newstafflist];
                });
            }
            // Only set hasMore if we received less data than expected
            setHasMore(stafflist?.totalPage > stafflist?.page);
        }
    }, [stafflist?.data, state?.page]);


    return (
        <div className='container-xl'>
            <div className="row">
                <div className="col-lg-1">
                    <h4 className="app-page-title">Staff</h4>
                </div>
                <div className="col-lg-11 d-md-flex justify-content-end">
                    <div className="d-flex justify-content-start align-items-start gap-2 flex-wrap ">
                        <div className="search-box">
                            <SearchIcon />

                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchChange}
                                value={state.searchParam}
                            />
                        </div>
                        <button
                            className={`${state.filterParam === '' ? "sky-btn-1" : "sky-btn-2"
                                } `}
                            onClick={handleAll}
                        >
                            All
                        </button>
                        {role?.map((item) => (
                            <button
                                className={`${state.filterParam === item.role_id ? "sky-btn-1" : "sky-btn-2"
                                    } `}
                                style={{ textTransform: 'capitalize' }}
                                key={item.role_id}
                                onClick={() => { handleFilter(item.role_id); setState((pre) => ({ ...pre, searchParam: "" })) }}
                            // style={{
                            //   border: `1px solid ${
                            //     state.filterParam === item.role_id ? "#35254d" : "d4cddf"
                            //   }`,
                            // }}
                            >
                                {item.role_name}
                            </button>
                        ))}
                        <button
                            className="second-blk-btn py-1"
                            onClick={() => handleState("show", true)}
                        >
                            Add staff +
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-card-body  overflow-x-auto">
                <div className="date-picker-container overflow-x-auto scroll-tbl">
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Role</th>

                                {/* <th>Location</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {!state.loading && !staffMerchant.length > 0 && (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  Records not found
                </td>
              </tr>
            )} */}
                            {staffMerchant &&
                                staffMerchant?.map((item, index) => {
                                    return (
                                        <tr className="table-hover cursor-pointer" key={item.id}
                                            ref={index === staffMerchant.length - 1 ? lastCustomerRef : null}
                                        >
                                            <td className="d-flex">
                                                <div
                                                    className="round-gradient me-2"
                                                    style={gradientCircle("rgba(195, 209, 4)")}
                                                ></div>
                                                <div className="us-name">
                                                    <span className="d-block">
                                                        {item.first_name} {item.last_name}
                                                    </span>
                                                    <span className="fs-12 text-gray mt-0">
                                                        {item.email}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <span style={{ textTransform: 'capitalize' }} className="truncate">{item?.role?.role_name}</span>
                                            </td>

                                            {/* <td>location</td> */}
                                            <td>
                                                {/* <div className="button-hover">
                      <img
                        src={Edit}
                        alt="Edit"
                        onClick={() => handleEdit(item)}
                      />
                      
                      {/* <i
                        style={{ marginLeft: "2px" }}
                        className="fa fa-map-pin"
                        onClick={() => handlePin(item)}
                      ></i>
                    </div> */}
                                                <div className="button-hover">
                                                    <div className="tooltip-toggle" aria-label="Security" tabIndex="0">
                                                        <i
                                                            className="fas fa-key"
                                                            onClick={() => handleEdit(item, "security")}
                                                        ></i>
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                        <img
                                                            style={{ marginLeft: 5 }}
                                                            src={Edit}
                                                            alt="Edit"
                                                            onClick={() => handleEdit(item, "user")}
                                                        />
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Delete" tabIndex="0">
                                                        <img
                                                            style={{ marginLeft: "4px" }}
                                                            src={Delete}
                                                            alt="Delete"
                                                            onClick={() => handleDelete(item)}
                                                        />
                                                    </div>
                                                    {/* <i className="fa-solid fa-trash"></i> */}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </div>
            </div>
            {/* <div className="table-responsive team-main-sec">

            </div> */}
            {noDataMessage && <div className="text-center mt-2">No Data Available</div>}
            {<div ref={divLoader} style={{ height: "20px" }} />}
            <Modal
                className="edit-members"
                show={form.show}
                centered
                onHide={() => handleState("show", false)}
            >
                <Modal.Header className="add-member-header" closeButton>
                    <Modal.Title className="mx-3">Add Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddStaffMerchant
                        setStaffMerchant={setStaffMerchant}
                        handleState={handleState}
                        setState={setState}
                        // fetchStaffMerchant={fetchStaffMerchant}
                        state={state}
                        setHasMore={setHasMore}
                        staffMerchant={staffMerchant}
                        role={role}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                className="edit-members"
                style={{ width: "100%" }}
                show={form.edit}
                centered
                onHide={() => handleState("edit", false)}
            >
                <Modal.Header className="edit-member-header" closeButton>
                    <Modal.Title className="mx-3">Edit {state.update.first_name} {state.update.last_name}</Modal.Title>
                </Modal.Header>
                <div className="d-flex p-2 gap-2 mx-3">
                    <button
                        className={`${info == "user" ? "sky-btn-1" : "sky-btn-2"
                            } `}
                        onClick={() => handleInfo("user")}
                    >
                        User info
                    </button>
                    <button
                        className={`${info == "security" ? "sky-btn-1" : "sky-btn-2"
                            } `}
                        onClick={() => handleInfo("security")}
                    >
                        Security
                    </button>
                </div>
                <Modal.Body>
                    {info == "user" ?
                        <EditStaffMerchant
                            state={state}
                            handleState={handleState}
                            setState={setState}
                            // fetchStaffMerchant={fetchStaffMerchant}
                            setStaffMerchant={setStaffMerchant}
                            setHasMore={setHasMore}
                            staffMerchant={staffMerchant}
                            role={role}
                        />
                        :
                        <UpdatePasswordStaffMerchant
                            handleState={handleState}
                            setState={setState}
                            state={state}
                            setHasMore={setHasMore}
                        />}
                </Modal.Body>
            </Modal>
            <Modal
                className="delete-employee"
                style={{ width: "100%" }}
                show={form.delete}
                size="sm"
                centered
                onHide={() => handleState("delete", false)}
            >
                <Modal.Header className="delete-member-header" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h5 className="text-center">Are you sure you want to delete employee?</h5>
                        <div className="row pt-3">
                            <div className="col-6">
                                <button
                                    className="cancel-btn "
                                    onClick={() => {
                                        handleState("delete", false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="add-btn" onClick={handleDeleteEmployee}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Loader loading={stafflistfetching || stafflistloading || merchantstaffrolefetching || merchantstaffroleloading || deletestaffloading} />
        </div>
    )
}

export default StaffMerchant
