import React from 'react';

const Changelog = () => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <div
                className="relative grow overflow-hidden mx-auto lg:-ml-12 lg:pl-[23.7rem]"
                id="content-area"
            >
                <header id="header" className="relative">
                    <div className="mt-0.5 space-y-2.5">
                        <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                            API Release Notes
                        </div>
                        <h1 className="text-2xl sm:text-3xl mt-2">Release 1.1.23 - 11/19/2024</h1>
                    </div>
                </header>

                <div className="relative mt-8 prose prose-gray dark:prose-invert">
                    <h2 id="hosted-solutions">Hosted Solutions and Updates</h2>
                    <p>
                        We have updated the <strong>sales_id</strong> in all API responses and webhook payloads to <strong>link_id</strong>.
                        This enhancement applies to all hosted <strong>payment links</strong>. Once a <strong>link_id</strong> is paid, 
                        it will transition to a <strong>transaction_id</strong>. Both <strong>link_id</strong> and  
                        <strong> transaction_id</strong> values are included in the webhook payload for your system to reconcile effectively.
                    </p>
                    <h3>Example Response of pay-link</h3>
                    <pre>
                        <code>
{`{
    "message": "Payment link created successfully.",
    "data": {
        "link_id": "fractal_link_id",
        "order_id": "external_order_id",
        "payment_link": "payment_link_url"
    }
}`}
                        </code>
                    </pre>

                    <h3>Example Payload of <code>payment.success</code> Event</h3>
                    <pre>
                        <code>
{`{
  "event_type": "payment.success",
  "data": {
    "transaction_id": "txn_481b9cac11",
    "merchant_id": "{{merchant_id}}",
    "amount": "2.00",
    "net_amount": 1.84,
    "payment_currency": "Credit Card",
    "created_date": "2024-11-15T21:58:18.000Z",
    "order_id": "24400",
    "last_four": "0043",
    "brand": "VISA",
    "link_id": "ord_34acdac276"
  }
}`}
                        </code>
                    </pre>

                    <h2 id="webhooks">Headers Added to Webhooks</h2>
                    <p>
                        Webhook events now support custom headers for improved security and sender validation. Users can configure header values 
                        in the platform portal to ensure your system validates incoming webhook events with a matching key-value pair.
                    </p>
                    <h3>Example Configuration</h3>
                    <ul>
                        <li>Header Name: <code>X-Sender-ID</code></li>
                        <li>Header Value: <code>your-platform-id</code></li>
                    </ul>

                    <h2 id="retry-policy">Webhook Retry Policy</h2>
                    <p>
                        We have introduced a retry policy to ensure webhook delivery reliability. This policy includes retry attempts, backoff 
                        strategy, trigger conditions, and a dead-letter queue (DLQ) for unrecoverable scenarios.
                    </p>
                    <h3>Parameter Configuration</h3>
                    <ul>
                        <li><strong>Retry Count:</strong> 5 immediate retries (Initial attempt + 4 retries).</li>
                        <li><strong>Backoff Strategy:</strong> Fixed delay for immediate retries:</li>
                        <ul className="ml-6 list-disc">
                            <li>1 second (Retry 1)</li>
                            <li>5 seconds (Retry 2, 3, 4, 5)</li>
                        </ul>
                        <li><strong>Trigger Conditions:</strong> Retry for 5xx errors, timeouts, and rate-limiting errors (429).</li>
                        <li><strong>Cron Job Schedule:</strong> Runs every 15 minutes to process webhooks that failed all immediate retries.</li>
                        <li><strong>Cron Retry Count:</strong> Each webhook is retried up to 10 times total (inclusive of immediate and cron retries).</li>
                        <li><strong>Dead Letter Queue:</strong> Use a DLQ for manual recovery after retries.</li>
                    </ul>
                    <p>
                        These changes ensure webhook events are reliably processed while giving your system a robust mechanism for handling failures.
                    </p>
                </div>

                <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                    <div className="sm:flex">
                        <a
                            className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                        >
                            Fractal Pay
                        </a>
                    </div>
                </footer>
            </div>

            {/* Sidebar for table of contents */}
            <div className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]" id="table-of-contents">
                <div className="fixed text-gray-600 text-sm leading-6 w-[16.5rem] overflow-y-auto space-y-2 h-[calc(100%-10rem)]">
                    <div className="text-gray-700 dark:text-gray-300 font-medium flex items-center space-x-2">
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-3"
                        >
                            <path d="M2.44434 12.6665H13.5554" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.44434 3.3335H13.5554" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.44434 8H7.33323" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>On this page</span>
                    </div>
                    <ul>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('hosted-solutions'); }} href="#hosted-solutions">
                                Release 1.1.23 - 11/19/2024
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('webhooks'); }} href="#webhooks">
                                Headers Added to Webhooks
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('retry-policy'); }} href="#retry-policy">
                                Webhook Retry Policy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Changelog;
