import React from 'react'
import { Link } from 'react-router-dom';

const WebHooks = ({setActiveTab}) => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };
  return (
    <>
          <>
              <div
                  className="relative grow overflow-hidden mx-auto lg:-ml-12 lg:pl-[23.7rem]"
                  id="content-area"
              >
                  <header id="header" className="relative">
                      <div className="mt-0.5 space-y-2.5">
                          <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                              API Documentation
                          </div>
                          <div className="flex items-center">
                              <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                                  Webhooks
                              </h1>
                          </div>
                      </div>
                  </header>
                  <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-6 xl:hidden" />
                  </div>
                  <div className="relative mt-8 prose prose-gray dark:prose-invert">
                      {/* <h1
                          className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8"
                          id="webhook-events"
                      >
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          {/* <span className="cursor-pointer">Webhook Events</span> */}
                      {/* </h1> */} 
                      <p>
                          This page documents the various webhook events that can be triggered by
                          the Fractal platform. Webhooks are used to notify your application when
                          specific events occur.
                      </p>
                      <h2 className="flex whitespace-pre-wrap group" id="table-of-contents">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Table of Contents</span>
                      </h2>
                      <ol>
                          <li>
                              <a >
                                  Payment Success
                              </a>
                          </li>
                          <li>
                              <a >
                                  Merchant Onboarding
                              </a>
                          </li>
                          <li>
                              <a >
                                  Merchant Approval
                              </a>
                          </li>
                      </ol>
                      <h2 className="flex whitespace-pre-wrap group" id="payment-success">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Payment Success</span>
                      </h2>
                      <h3 className="flex whitespace-pre-wrap group" id="event-payment-success">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">
                              Event: <code>payment.success</code>
                          </span>
                      </h3>
                      <h4 className="flex whitespace-pre-wrap group" id="description">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Description</span>
                      </h4>
                      <p>This event is triggered when a payment is successfully processed.</p>
                      <h4 className="flex whitespace-pre-wrap group" id="payload-example">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Payload Example</span>
                      </h4>
                      <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                          <div className="group z-10 absolute mt-0.5 top-4 right-4">
                              <button className="h-7 w-7 flex items-center justify-center rounded-md">
                                  <svg
                                      className="fill-gray-700 group-hover:fill-gray-400"
                                      width={16}
                                      height={16}
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                                  </svg>
                              </button>
                              <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                                  Copy
                              </div>
                          </div>
                          <div
                              className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                              style={{ fontVariantLigatures: "none" }}
                          >
                              <pre className="language-json">
                                  <code className="language-json">
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"event_type"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"payment.success"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"data"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"merchant_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">
                                          "example_guid"
                                      </span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"transaction_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">
                                          "txn_1234567890"
                                      </span>
                                      <span className="token punctuation">,</span>

                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"amount"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token number">1.00</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"net_amount"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token number">0.95</span>
                                      {/* <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"fee_percentage"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token number">0</span> */}
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"payment_currency"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"Credit Card"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"created_date"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"2024-06-03T06:06:22.000Z"</span>

                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"order_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"your_external_id"</span>

                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"last_four"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"4755"</span>

                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"brand"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"VISA"</span>

                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"link_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"ord_1234567890"</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token punctuation">{"}"}</span>
                                      {"\n"}
                                      <span className="token punctuation">{"}"}</span>
                                      {"\n"}
                                  </code>
                              </pre>
                          </div>
                      </div>
                      <h4 className="flex whitespace-pre-wrap group" id="explanation">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Explanation</span>
                      </h4>
                      <ul>
                          <li>
                              <code>merchant_id</code>: Unique identifier for the merchant.
                          </li>
                          <li>
                              <code>transaction_id</code>: Unique identifier for the transaction.
                          </li>
                          <li>
                              <code>amount</code>: Total amount of the payment.
                          </li>
                          <li>
                              <code>net_amount</code>: Amount received after fees.
                          </li>
                          <li>
                              <code>payment_currency</code>: Type of currency used for payment.
                          </li>
                          <li>
                              <code>created_date</code>: Date and time when the payment was created.
                          </li>
                          <li>
                            <code>order_id</code>: External identifier used in the payment request.
                          </li>
                          <li>
                            <code>last_four</code>: Last four of the card number.
                          </li>
                          <li>
                            <code>brand</code>: Brand of the card.
                          </li>
                          <li>
                            <code>link_id</code>: Unique identifier for the payment link.
                          </li>
                      </ul>
                      <h2 className="flex whitespace-pre-wrap group" id="merchant-onboarding">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Merchant Onboarding</span>
                      </h2>
                      <h3
                          className="flex whitespace-pre-wrap group"
                          id="event-merchant-onboarding"
                      >
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">
                              Event:
                              <code>merchant.onboarding</code>
                          </span>
                      </h3>
                      <h4 className="flex whitespace-pre-wrap group" id="description-2">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Description</span>
                      </h4>
                      <p>
                          This event is triggered when a merchant completes the onboarding
                          process.
                      </p>
                      <h4 className="flex whitespace-pre-wrap group" id="payload-example-2">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Payload Example</span>
                      </h4>
                      <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                          <div className="group z-10 absolute mt-0.5 top-4 right-4">
                              <button className="h-7 w-7 flex items-center justify-center rounded-md">
                                  <svg
                                      className="fill-gray-700 group-hover:fill-gray-400"
                                      width={16}
                                      height={16}
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                                  </svg>
                              </button>
                              <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                                  Copy
                              </div>
                          </div>
                          <div
                              className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                              style={{ fontVariantLigatures: "none" }}
                          >
                              <pre className="language-json">
                                  <code className="language-json">
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"event_type"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"merchant.onboarding"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"data"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"merchant_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"example-guid"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"merchant_key"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"example-key"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"business_name"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"Case Mix"</span>
                                      <span className="token punctuation">,</span>
                                      
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"email"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"merchant@example.com"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"public_key"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"example-public-key"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"company_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"external_guid"</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token punctuation">{"}"}</span>
                                      {"\n"}
                                      <span className="token punctuation">{"}"}</span>
                                      {"\n"}
                                  </code>
                              </pre>
                          </div>
                      </div>
                      <h4 className="flex whitespace-pre-wrap group" id="explanation-2">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Explanation</span>
                      </h4>
                      <ul>
                          <li>
                              <code>merchant_id</code>: Unique identifier for the merchant.
                          </li>
                          <li>
                              <code>merchant_key</code>: Unique key assigned to the merchant.
                          </li>
                          <li>
                              <code>business_name</code>: Merchant’s business name.
                          </li>
                          <li>
                              <code>email</code>: Merchant’s contact email.
                          </li>
                          <li>
                              <code>public_key</code>: Public key associated with the merchant.
                          </li>
                          <li>
                              <code>company_id</code>: External identifier used in the hosted onboarding request.
                          </li>
                      </ul>
                      <h2 className="flex whitespace-pre-wrap group" id="merchant-approval">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Merchant Approval</span>
                      </h2>
                      <h3
                          className="flex whitespace-pre-wrap group"
                          id="event-merchant-approval"
                      >
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">
                              Event:
                              <code>merchant.approval</code>
                          </span>
                      </h3>
                      <h4 className="flex whitespace-pre-wrap group" id="description-3">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Description</span>
                      </h4>
                      <p>This event is triggered when a merchant’s application is approved.</p>
                      <h4 className="flex whitespace-pre-wrap group" id="payload-example-3">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Payload Example</span>
                      </h4>
                      <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                          <div className="group z-10 absolute mt-0.5 top-4 right-4">
                              <button className="h-7 w-7 flex items-center justify-center rounded-md">
                                  <svg
                                      className="fill-gray-700 group-hover:fill-gray-400"
                                      width={16}
                                      height={16}
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                                  </svg>
                              </button>
                              <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                                  Copy
                              </div>
                          </div>
                          <div
                              className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                              style={{ fontVariantLigatures: "none" }}
                          >
                              <pre className="language-json">
                                  <code className="language-json">
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"event_type"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"merchant.approval"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"data"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"merchant_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"example-guid"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"business_name"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"Example Business"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"email"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"merchant@example.com"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"first_name"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"John"</span>
                                      {"\n"}
                                      {"        "}
                                      <span className="token property">"last_name"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"Doe"</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token punctuation">{"}"}</span>
                                      {"\n"}
                                      <span className="token punctuation">{"}"}</span>
                                      {"\n"}
                                  </code>
                              </pre>
                          </div>
                      </div>
                      <h4 className="flex whitespace-pre-wrap group" id="explanation-3">
                          <div className="absolute">
                              <a
                                  
                                  className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                  aria-label="Navigate to header"
                              >

                                  <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="gray"
                                          height="12px"
                                          viewBox="0 0 576 512"
                                      >
                                          <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                      </svg>
                                  </div>
                              </a>
                          </div>
                          <span className="cursor-pointer">Explanation</span>
                      </h4>
                      <ul>
                          <li>
                              <code>merchant_id</code>: Unique identifier for the merchant.
                          </li>
                          <li>
                              <code>business_name</code>: Name of the merchant’s business.
                          </li>
                          <li>
                              <code>email</code>: Merchant’s contact email.
                          </li>
                          <li>
                              <code>first_name</code>: Merchant's first name.
                          </li>
                          <li>
                              <code>last_name</code>: Merchant's last name.
                          </li>
                      </ul>
                  </div>
                  <div className="leading-6 mt-14">
                      <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                          <Link to={"/docs/documentation/portal-guide"}
                              className="flex items-center space-x-3 group"
                               onClick={()=>setActiveTab('/docs/documentation/portal-guide')}
                          >
                              <svg
                                  viewBox="0 0 3 6"
                                  className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                              >
                                  <path
                                      d="M3 0L0 3L3 6"
                                      fill="none"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                  />
                              </svg>
                              <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                  Portal Guide
                              </span>
                          </Link>
                          <Link to="/docs/documentation/low-code"
                              className="flex items-center ml-auto space-x-3 group"
                               onClick={()=>setActiveTab('/docs/documentation/low-code')}
                          >
                              <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                  Low Code JS
                              </span>
                              <svg
                                  viewBox="0 0 3 6"
                                  className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                              >
                                  <path
                                      d="M3 0L0 3L3 6"
                                      fill="none"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                  />
                              </svg>
                          </Link>
                      </div>
                      <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                          <div className="flex mb-6 space-x-6 sm:mb-0">
                              {/* <a
                                
                              >
                                  <span className="sr-only">linkedin</span>
                                  <svg
                                      className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                      style={{
                                          maskImage:
                                              'url("https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg")',
                                          maskRepeat: "no-repeat",
                                          maskPosition: "center center"
                                      }}
                                  />
                              </a> */}
                          </div>
                          <div className="sm:flex">
                              <a
                                  
                                 
                                  rel="noreferrer"
                                  className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                              >
                                  Fractal Pay
                              </a>
                          </div>
                      </footer>
                  </div>
              </div>
              {/* on this page right bar */}
              <div
                  className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]"
                  id="table-of-contents"
              >
                  <div className="fixed text-gray-600 text-sm leading-6 w-[16.5rem] overflow-y-auto space-y-2 h-[calc(100%-10rem)]">
                      <div className="text-gray-700 dark:text-gray-300 font-medium flex items-center space-x-2">
                          <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3"
                          >
                              <path
                                  d="M2.44434 12.6665H13.5554"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                              <path
                                  d="M2.44434 3.3335H13.5554"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                              <path
                                  d="M2.44434 8H7.33323"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                          </svg>
                          <span>On this page</span>
                      </div>
                      <ul>
                          {/* <li>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('webhook-events'); }}
                                  href="https://docs.fractalpay.com/Webhooks#webhook-events"
                                  className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                              >
                                  Webhook Events
                              </a>
                          </li> */}
                          <li>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('table-of-contents'); }}
                                  href="https://docs.fractalpay.com/Webhooks#table-of-contents"
                                  className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                              >
                                  Table of Contents
                              </a>
                          </li>
                          <li>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('payment-success'); }}
                                  href="https://docs.fractalpay.com/Webhooks#payment-success"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Payment Success
                              </a>
                          </li>
                          <li style={{ marginLeft: "1rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('event-payment-success'); }}
                                  href="https://docs.fractalpay.com/Webhooks#event-payment-success"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Event: payment.success
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('description'); }}
                                  href="https://docs.fractalpay.com/Webhooks#description"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Description
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('payload-example'); }}
                                  href="https://docs.fractalpay.com/Webhooks#payload-example"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Payload Example
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('explanation'); }}
                                  href="https://docs.fractalpay.com/Webhooks#explanation"
                                  className="group flex items-start py-1 whitespace-pre-wrap text-primary dark:text-primary-light"
                              >
                                  Explanation
                              </a>
                          </li>
                          <li>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('merchant-onboarding'); }}
                                  href="https://docs.fractalpay.com/Webhooks#merchant-onboarding"
                                  className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                              >
                                  Merchant Onboarding
                              </a>
                          </li>
                          <li style={{ marginLeft: "1rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('event-merchant-onboarding'); }}
                                  href="https://docs.fractalpay.com/Webhooks#event-merchant-onboarding"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Event: merchant.onboarding
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('description-2'); }}
                                  href="https://docs.fractalpay.com/Webhooks#description-2"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Description
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('payload-example-2'); }}
                                  href="https://docs.fractalpay.com/Webhooks#payload-example-2"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Payload Example
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('explanation-2'); }}
                                  href="https://docs.fractalpay.com/Webhooks#explanation-2"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Explanation
                              </a>
                          </li>
                          <li>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('merchant-approval'); }}
                                  href="https://docs.fractalpay.com/Webhooks#merchant-approval"
                                  className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                              >
                                  Merchant Approval
                              </a>
                          </li>
                          <li style={{ marginLeft: "1rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('event-merchant-approval'); }}
                                  href="https://docs.fractalpay.com/Webhooks#event-merchant-approval"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Event: merchant.approval
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('description-3'); }}
                                  href="https://docs.fractalpay.com/Webhooks#description-3"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Description
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('payload-example-3'); }}
                                  href="https://docs.fractalpay.com/Webhooks#payload-example-3"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Payload Example
                              </a>
                          </li>
                          <li style={{ marginLeft: "2rem" }}>
                              <a onClick={(e) => { e.preventDefault(); scrollToSection('explanation-3'); }}
                                  href="https://docs.fractalpay.com/Webhooks#explanation-3"
                                  className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                              >
                                  Explanation
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </>



    </>
  )
}

export default WebHooks