import React from 'react';
import { Link } from 'react-router-dom';

const PlatformPortalGuide = ({ setActiveTab }) => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <div
                className="relative grow overflow-hidden mx-auto lg:-ml-12 lg:pl-[23.7rem]"
                id="content-area"
            >
                <header id="header" className="relative">
                    <div className="mt-0.5 space-y-2.5">
                        <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                            Platform Portal Guide
                        </div>
                    </div>
                </header>

                <div className="relative mt-8 prose prose-gray dark:prose-invert">
                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="dashboard">
                        <div className="absolute">
                            <a
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Portal Guide</span>
                    </h1>
                    <p>This guide is designed to inform Platforms (also referred to as “Clients”) about the full range of features available in the Portal.                    </p>
                    <p>A Platform refers to an entity that leverages the Fractal system to directly manage its portfolio. Through this system, Platforms can offer Level I support, while monitoring the progress of merchants, payments, and deposits.</p>
                    <p>Below is a quick reference guide explaining the functionality of the default permission profiles.</p>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Admin</th>
                                <th>Finance</th>
                                <th>Developer</th>
                                <th>Support</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dashboard</td>
                                <td>X</td>
                                <td>X</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Transactions</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Export Transactions</td>
                                <td>X</td>
                                <td>X</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Merchants Tab</td>
                                <td>X</td>
                                <td>X</td>
                                <td></td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Merchant - Transactions</td>
                                <td>X</td>
                                <td>X</td>
                                <td></td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Merchant - View Details</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Teams</td>
                                <td>X</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Developer</td>
                                <td></td>
                                <td></td>
                                <td>X</td>
                                <td>X</td>
                            </tr>
                        </tbody>
                    </table>


                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="dashboard">
                        <div className="absolute">
                            <a
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Dashboard</span>
                    </h1>
                    <p>The Dashboard provides a high-level overview of all Gross Payment Volume (GPV) for merchants processing through Fractal. It is a dynamic interface that updates based on the selected date range and allows for quick comparisons between different time periods.</p>

                    <ul>
                        <li>Total Sales</li>
                        <li>Estimated Revenue</li>
                        <li>Total Transactions</li>
                        <li>Average Ticket Size</li>
                    </ul>
                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="transactions">
                        <div className="absolute">
                            <a className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100" aria-label="Navigate to header">
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Transactions</span>
                    </h1>
                    <p>
                        The Transactions page lists all merchant transactions processed through the platform. It includes:
                    </p>
                    <h2 className="flex whitespace-pre-wrap group" id="overview">
                        <div className="absolute">
                            <a

                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Key Metrics:
                        </span>
                    </h2>
                    <ul>
                        <li>Created Date: The date and time when the transaction was created. This helps track transaction chronology and identify processing patterns.</li>
                        <li>Merchant Name: The name of the merchant associated with the transaction. You can filter transactions by merchant to review specific account activity.</li>
                        <li>Amount:The gross amount of the transaction, before fees and other deductions. This represents the total amount charged to the customer.</li>
                        <li>Type: Identifies how the transaction was initiated, such as through a Widget or API integration. This helps understand how the merchant interacts with the platform.</li>
                        <li>Status: Indicates the outcome of the transaction. Common statuses include Success, Declined, or Pending, helping to quickly assess transaction health.</li>
                        <li>Brand: Displays the brand of the payment card used for the transaction (e.g., Visa, MasterCard). Partial card numbers may also be shown for tracking purposes.</li>

                    </ul>
                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="merchants">
                        <div className="absolute">
                            <a className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100" aria-label="Navigate to header">
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Merchants</span>
                    </h1>
                    <p>
                        The Merchants page allows you to manage merchant accounts, track their status, and access important documents such as tax records or contracts. Key fields include:
                    </p>
                    <h2 className="flex whitespace-pre-wrap group" id="overview">
                        <div className="absolute">
                            <a

                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Key Metrics:
                        </span>
                    </h2>
                    <ul>
                        <li>Merchant Name: This column displays the name of the merchant. Clicking on the name typically leads to the merchant’s detailed profile, where you can view their information and perform additional actions.</li>
                        <li>Join Date: The date when the merchant was onboarded or registered on the platform. This is useful for tracking the longevity of the relationship and any related milestones.</li>
                        <li>State: The U.S. state where the merchant is located. This provides geographic information at a glance, helping you track merchants by region.</li>
                        <li>Status: The status of the merchant's account, such as "Approved." This indicates whether the merchant is active, pending approval, or requires further actions. Merchants must be in an approved state to perform transactions.</li>
                    </ul>
                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="teams">
                        <div className="absolute">
                            <a className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100" aria-label="Navigate to header">
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Teams</span>
                    </h1>
                    <p>
                        The Teams page allows you to manage internal platform users. For each user, you can view or edit their:
                    </p>
                    <h2 className="flex whitespace-pre-wrap group" id="overview">
                        <div className="absolute">
                            <a

                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Key Metrics:
                        </span>
                    </h2>
                    <ul>
                        <li>First/Last Name: Displays the first and last name of each team member, which is helpful for identifying users quickly.</li>
                        <li>Email: The email address assigned to the user, which is used for portal login and communication. It's important to note that this email address will also be used for resetting passwords if needed.</li>
                        <li>Role: This column indicates the user's role within the platform, such as "Admin," "Developer," "Support," or "Finance." Roles determine the level of access the user has to different features in the portal.</li>
                        <li>Phone Number: Phone numbers are used for two-factor authentication (2FA) to add an extra layer of security. This information is essential when enabling 2FA for a user.</li>
                    </ul>
                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="api-keys">
                        <div className="absolute">
                            <a className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100" aria-label="Navigate to header">
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">API Keys</span>
                    </h1>
                    <p>The Credentials section provides essential keys and identifiers used for authentication and interaction with external systems. Below is a description of each field and its purpose.</p>
                    <ul>
                        <li><strong>Client ID:</strong> A unique identifier assigned to your platform. Used to authenticate your platform's access to the portal and associated services.</li>
                        <li><strong>Webhook URL:</strong> The URL where event notifications (webhooks) will be sent from the system. Make sure this URL is correctly configured to receive and process real-time updates.</li>
                        <li><strong>Public Key:</strong> Part of the key pair used to secure communication. It ensures that the data being transferred is encrypted and secure.</li>
                        <li><strong>Secret Key:</strong> A sensitive key used for authentication and must be kept confidential. If compromised, it can allow unauthorized access.</li>
                        <li><strong>Auth Key:</strong> Used for authentication in generating secure tokens for API requests. Ensures only authenticated platforms can access functionalities.</li>
                    </ul>
                    <h1 className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8" id="logs">
                        <div className="absolute">
                            <a className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100" aria-label="Navigate to header">
                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="12px" viewBox="0 0 576 512">
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Logs</span>
                    </h1>
                    <p>The Logs section displays a history of API interactions that your platform has had with the system. These logs help track activity, useful for debugging and understanding the status of operations.</p>
                    <ul>
                        <li><strong>Request Type (POST):</strong> The method of the API request. POST is typically used when new data is being submitted to the server, such as onboarding a merchant or processing a payment.</li>
                        <li><strong>API Endpoint:</strong> The endpoint (e.g., /api/v1/customers/charge/id) shows which API route was used for the request. It helps identify the purpose of the API call.</li>
                        <li><strong>Timestamp:</strong> The exact date and time when the API request was made. This helps track events and analyze timelines.</li>
                        <li><strong>Response Code (200):</strong> The response code indicates the status of the API request. A 200 response code means the request was successful.</li>
                        <li><strong>Expand Button (Arrow Icon):</strong> Clicking this arrow allows you to view more details about the request, including the request payload, headers, and the response.</li>
                    </ul>
                </div>

                <div className="leading-6 mt-14">
                    <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                        <Link to={"/docs/documentation/implementation-guide"}
                            className="flex items-center space-x-3 group"
                            onClick={()=>setActiveTab('/docs/documentation/implementation-guide')}
                        >
                            <svg
                                viewBox="0 0 3 6"
                                className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                            >
                                <path
                                    d="M3 0L0 3L3 6"
                                    fill="none"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                            Implementation Guide
                            </span>
                        </Link>
                        <Link to={"/docs/documentation/webhooks"}
                            className="flex items-center ml-auto space-x-3 group"
                           onClick={()=>setActiveTab('/docs/documentation/webhooks')}
                        >
                            <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                Webhooks
                            </span>
                            <svg
                                viewBox="0 0 3 6"
                                className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                            >
                                <path
                                    d="M3 0L0 3L3 6"
                                    fill="none"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Link>
                    </div>
                    <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                        <div className="flex mb-6 space-x-6 sm:mb-0">
                            {/* <a
                              
                           
                            >
                                <span className="sr-only">linkedin</span>
                                <svg
                                    className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                    style={{
                                        WebkitMaskImage:
                                            "url(https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg)",
                                        WebkitMaskRepeat: "no-repeat",
                                        WebkitMaskPosition: "center"
                                    }}
                                />
                            </a> */}
                        </div>
                        <div className="sm:flex">
                            <a
                               
                                rel="noreferrer"
                                className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                            >
                                Fractal Pay
                            </a>
                        </div>
                    </footer>
                </div>
            </div>
            {/* Sidebar for table of contents */}
            <div className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]" id="table-of-contents">
                <div className="fixed text-gray-600 text-sm leading-6 w-[16.5rem] overflow-y-auto space-y-2 h-[calc(100%-10rem)]">
                    <div className="text-gray-700 dark:text-gray-300 font-medium flex items-center space-x-2">
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-3"
                        >
                            <path d="M2.44434 12.6665H13.5554" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.44434 3.3335H13.5554" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.44434 8H7.33323" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>On this page</span>
                    </div>
                    <ul>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection('dashboard');
                                }}
                                href="#dashboard"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection('transactions');
                                }}
                                href="#transactions"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Transactions
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection('merchants');
                                }}
                                href="#merchants"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Merchants
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection('teams');
                                }}
                                href="#teams"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Teams
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default PlatformPortalGuide;