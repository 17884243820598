import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Tabs/MerchantOnboard.css";
import axios from "axios";
import { Modal,Table } from "react-bootstrap";
import { toast } from "react-toastify";
import baseUrl from "../../../baseUrl";
import Loader from "../../../DefaultComponent/Loader";
import { useToken } from "../../../hooks/useToken";
import DownloadMerchantCSV from "../Form/DownloadMerchantCSV";
import debounce from "lodash/debounce";
import View from "../../../assets/images/View.svg";
import Edit from "../../../assets/images/Edit.svg";
import Transaction from "../../../assets/images/Transaction.svg";
import Download from "../../../assets/images/Download.svg";
import { useGetMerchantListQuery, useGetSidebarQuery } from "../../../redux/apiSlice";
import { useSelector } from "react-redux";

const AllVerifiedMerchants = () => {
  const { data: userData, data: menu, isLoading, isError } = useGetSidebarQuery();
  const { deviceId } = useSelector(state => state.deviceId);
  const { role_id, staff_roll_type } = userData?.data;
  const token = useToken();
  const navigate = useNavigate();
  const [merchantList, setMerchantList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [state, setState] = useState({
    searchInput: "",
    totalPage: null,
    loading: false,
    download: false,
    row: {},
  });
  const observer = useRef();
  const [hasMore, setHasMore] = useState(true);

  // API call with RTK Query
  const { data: merchants, isLoading: merchantLoading, isFetching: merch_fetch } = useGetMerchantListQuery({
    page,
    search: searchValue,
  }, {
    skip: !hasMore && page !== 1
  });
  
  const handleMerchantDetail = (id) => {
    let flag = 'view';
    navigate(`/merchants/${id}/${flag}`);
  };

  const handleEdit = (row) => {
    navigate("/edit-merchant", { state: { data: row.id } });
  };

  const handleTransaction = (id) => {
    let flag = 'transaction';
    navigate(`/merchants/${id}/${flag}`);
  };

  const handleDownload = (row) => {
    handleState("download", true);
    handleState("row", row);
  };

  const verifiedMerchant = async (encodeId) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/admin/merchant/approve-merchant/${encodeId}`,
        {
          headers: {
            Authorization: token,
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
      if (response.status === 200) {
        toast.success("Merchant has been verified successfully.");
        // fetchMerchants();
        debouncedSearch('')
      } else {
        toast.error("Merchant is not valid. Please try again.");
      }
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          const errorMessages = Object.values(error.response.data.errors).join(
            ", "
          );
          toast.error(errorMessages);
        } else if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred. Please try again later.");
        }
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${month}.${day}.${year}`;
  };

  const handleState = (item, key) => {
    setState((prev) => ({
      ...prev,
      [item]: key,
    }));
  };

  const lastMerchantRef = useCallback((node) => {
    if (merchantLoading || merch_fetch || !hasMore) return; // Don't observe if loading or no more data
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !merchantLoading && !merch_fetch) {
        setPage(prevPage => prevPage + 1);
      }
    }, {
      root: null,
      rootMargin: '20px', // Load a bit before reaching the end
      threshold: 0.1 // Trigger when even 10% of the element is visible
    });

    if (node) observer.current.observe(node);
  }, [merchantLoading, merch_fetch, hasMore]);

  // Cleanup observer on component unmount
  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  // Update Merchants when new data is fetched
  useEffect(() => {
    if (merchants?.data) {
      if (page === 1) {
        setMerchantList(merchants.data);
      } else {
        setMerchantList(prev => {
          // More strict duplicate checking
          const existingIds = new Set(prev.map(item => item.id));
          const newMerchants = merchants.data.filter(
            newItem => !existingIds.has(newItem.id)
          );
          return [...prev, ...newMerchants];
        });
      }
      // Only set hasMore if we received less data than expected
      setHasMore(merchants.totalPage > page);
    }
  }, [merchants?.data, page]);

  // Create a memoized debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setPage(1);
      setMerchantList([]);
      setHasMore(true);
    }, 500),
    []
  );

  const handleSearchInput = (e) => {
    const value = e.target.value;
    setState((pre) => ({ ...pre, searchInput: value })); // Update input immediately
    debouncedSearch(value); // Debounce the actual search
  };
  let str = "";
  const handleRow = (item, value) => {
    str += value;
    if (str == "download") {
      handleDownload(item)
    } else if (str == "view") {
      handleMerchantDetail(item.id)
    } else if (str == "edit") {
      handleEdit(item)
    } else if (str == "merchant") {
      verifiedMerchant(item?.encodeId)
    } else if (str == "transaction") {
      handleTransaction(item.id)
    }
  }
  const exportCsv = async () => {
    if (!merchantList && merchantList?.length < 1) { return }
    try {
      setState((pre) => ({ ...pre, loading: true }));
      const resp = await axios.get(`${baseUrl}/api/v1/admin/merchant/get-all-merchant?isMerchantExport=true&search=${state.searchValue}`, {
        headers: {
          Authorization: token,
          'X-Device-Fingerprint': deviceId,
        },
      }
      );
      setState((pre) => ({ ...pre, loading: false }));
      if (resp?.status == 200) {
        const link = document.createElement('a');
        link.href = `${resp?.data?.data}`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      setState((pre) => ({ ...pre, loading: false }));
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  }

  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-12 col-md-4">
            <p className="heading">Merchants</p>
          </div>
          <div className="col-12  col-md-8">
            <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
              <div className="">
                {((role_id == "132" && staff_roll_type == "277") || (role_id == "223")) && <button className="btn app-btn purp border border-1 mx-1 export-csv-btn" onClick={exportCsv} >Export CSV</button>}
              </div>
              <div className="search-box">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_7372_11113)">
                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_7372_11113">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <input
                  className="searchbox"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={handleSearchInput}
                  value={state.searchInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="app-card-body">
          <div className="date-picker-container overflow-x-auto scroll-tbl">
            <Table className="table">
              <thead>
                <tr>
                  <th align="right">Merchant Name</th>
                  <th align="right">Join Date</th>
                  <th align="right">State</th>
                  <th align="right">Status</th>
                  <th align="right">Action</th>
                </tr>
              </thead>
              <tbody>
                {merchantList?.map((item, index) => (
                    <tr key={item.id} ref={index === merchantList.length - 1 ? lastMerchantRef : null} className="table-hover cursor-pointer" onClick={() => handleRow(item, "view")}>
                      <td>{item.businessname}</td>
                      <td>{formatDate(item.join_date)}</td>
                      <td>{item.state}</td>
                      <td>
                        {item.isApproved ? (
                          <div className=" d-flex"> <span className="active-btn">Approved</span></div>
                        ) : (
                          <div className="d-flex"><span className="active-btn">Not Approved</span></div>
                        )}
                      </td>
                      <td align="left" style={{ width: "14%" }}>
                        <div className="button-hover">
                          <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="" data-original-title="Disabled tooltip">
                            <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                              <img
                                src={View}

                                onClick={() => handleRow(item, "view")}
                              />
                            </div>
                          </span>
                          {role_id == '1' &&
                            <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                              <img
                                src={Edit}
                                alt="Edit"
                                style={{ marginLeft: "6px" }}
                                onClick={() => handleRow(item, "edit")}
                              /></div>
                          }
                          <div className="tooltip-toggle" aria-label="Transactions" tabIndex="0">
                            <img

                              src={Transaction}
                              alt="Transactions"
                              style={{ marginLeft: "6px" }}
                              onClick={() => handleRow(item, "transaction")}
                            /></div>
                          <div className="tooltip-toggle" aria-label="Upload" tabIndex="0">
                            <img
                              src={Download}
                              alt="Download"
                              style={{ marginLeft: "6px" }}
                              onClick={() => handleRow(item, "download")}
                            /></div>
                          {role_id == '1' &&
                            <i
                              className="fas fa-user-check"
                              style={{ marginLeft: "6px" }}
                              onClick={() => handleRow(item, "merchant")}
                            ></i>}
                        </div>
                      </td>
                    </tr>
                  ))}
                {!merchantLoading && merchantList.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No merchants available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Loader loading={state.loading || merchantLoading || merch_fetch} />
      <Modal
        className="upload-documents"
        show={state.download}
        onHide={() => handleState("download", false)}
      >
        <Modal.Header className="upload-doc" closeButton>
          <Modal.Title>Upload Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadMerchantCSV handleState={handleState} state={state} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllVerifiedMerchants;
