import React, { useState } from 'react'
import { useMenuPermissionMutation } from '../../../redux/superadminApiSlice'
import Loader from '../../DefaultComponents/Loader';

const MenuPermissions = ({ menuItem, handleClose, action }) => {
    const [rawData, setRawData] = useState('');
    const [permissionTrigger, { data, isFetching, isLoading }] = useMenuPermissionMutation();
    const handleChange = (value) => {
        setRawData(value)
    }

    // onsubmit
    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            
            let api = rawData
                ?.split(',')
                .map((item) => item.trim()) 
                .filter((item) => !item.includes('"')) 
                .map((item) => item.replace(/'/g, '').replace(/\n/g, '')); 

            let body = { apis: api, menu_id: menuItem?.menu_id }
            console.log(body)
            // const updateResponse= await permissionTrigger(body).unwrap();
            // if (updateResponse?.result) {
            //     toast.success(updateResponse?.message);
            //     handleClose()
            // }
        } catch (error) {
            console.log(error)
        }
    }
   
    return (
        <div>

            <div className="container-xl">
                <form onSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-12">
                            <label>Permissions</label>
                            <textarea
                                className="custom-date-picker"
                                type="text"
                                placeholder="Add Permissions For Menu"
                                name="name"
                                rows={5}
                                // value={menuData.name}
                                onChange={(e) => handleChange(e.target.value)}
                                required>
                            </textarea>
                            {/* {formErrors.name && <span className="text-danger fs-12">{formErrors.name}</span>} */}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button className="idle-green-btn w-100">submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <Loader loading={isLoading || isFetching} />

        </div>
    )
}

export default MenuPermissions

