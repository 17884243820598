import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Table } from "react-bootstrap";
import CardType from '../DefaultComponent/CardType';
import Loader from '../DefaultComponent/Loader';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../hooks/useToken';
import { debounce } from 'lodash';
import axios from 'axios';
import baseUrl from '../baseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { dateAction } from '../redux/dateSlice';
import DatePickers from '../DefaultComponent/DatePickers';
import View from '../assets/images/View.svg'
import { RefundIcon, VoidIcon } from '../SuperAdmin/DefaultComponents/SvgiconsSA';
import { toast } from 'react-toastify';

const MerchantTransaction = () => {
    const { deviceId } = useSelector(state => state.deviceId);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const token = useToken();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(15);
    const [transactionData, setTransactionData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [fetMoreData, setFetchMoreData] = useState(0);
    const [graphFilter, setGraphFilter] = useState("week");
    const loader = useRef(null);

    const handleDates = (val, hours, minutes) => {
        const Dates = new Date();
        Dates.setDate(Dates.getDate() - val);
        Dates.setHours(hours, minutes, 0, 0);
        return Dates;
    };

    const [startDate, setStartDate] = useState(() => {
        const start = handleDates(6, 0, 0); // 7 days ago, start of the day
        start.setHours(0, 0, 0, 0);
        return start;
    });
    const [endDate, setEndDate] = useState(() => {
        const end = new Date(); // Today
        end.setHours(23, 59, 59, 999); // Set to the end of today
        return end;
    });

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${month}.${day}.${year}`.replace(" ", ", ");
    };

    function convertMili(da) {
        const date = new Date(da);
        return date.getTime();
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px" }} className="fas">
                            &#xf110;
                        </i>
                        Pending
                    </div>
                );
            case 2:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: 'green' }} className="fas fa-check"></i>
                        Success
                    </div>
                );
            case 5:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: '#8161C6' }} className="fas fa-redo-alt"></i>
                        Refunded
                    </div>
                );
            case 3:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: 'red' }} className="far fa-times-circle"></i>
                        Declined
                    </div>
                );
            case 4:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: '#BE7D4D' }} className="fas fa-pause"></i>
                        On-hold
                    </div>
                );
            case 6:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: '#9E3636' }} className="fas fa-circle-minus"></i>
                        Voided
                    </div>
                );
            case 7:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px", color: 'green' }} className="fa-solid fa-check"></i>
                        Settled
                    </div>
                );
            case 8:
                return (
                    <div className="paymentcard-status">
                        <i style={{ fontSize: "12px" }} className="fas">
                            &#xf110;
                        </i>
                        Authorized
                    </div>
                );
            default:
                return "";
        }
    };

    const isScroll = true;
    const fetchData = async (
        newPage,
        newPerPage,
        startDate,
        endDate,
        searchData
    ) => {
        setPage(newPage);
        setPerPage(newPerPage);
        try {
            const requestBody = {
                page: newPage,
                perPage: newPerPage,
            };


            if (startDate && endDate) {
                requestBody.startDate = convertMili(startDate);
                requestBody.endDate = convertMili(endDate);
            } else if (startDate) {
                requestBody.startDate = convertMili(startDate);
            }
            if (searchData) {
                requestBody.search = searchData;
            }
            if (requestBody?.endDate) {
                let final = {};
                let localStorageDay = localStorage.getItem("trans-fltr");
                if (localStorageDay) {
                    let day = JSON.parse(localStorageDay);
                    if (day == "all") {
                        final = {
                            page: newPage,
                            perPage: newPerPage,
                            allTimeFilter: true
                        }
                    }
                    else {
                        final = { ...requestBody }
                    }
                }
                else {
                    final = { ...requestBody }
                }

                // if(filterDays==)
                setLoading(true);
                if (isScroll) {
                    const response = await axios.post(
                        `${baseUrl}/api/v1/merchant/account/transaction/list`,
                        final,
                        {
                            headers: {
                                Authorization: token,
                                'X-Device-Fingerprint': deviceId,
                            },
                        }
                    );

                    setLoading(false);
                    if (!response?.data?.status) {
                        setHasMore(false)
                        isScroll = false;
                    }
                    // if (!response?.data?.status && newPage==1) {
                    //   console.log("============================no more datea====================", newPage);

                    //   setTransactionData(null);
                    //   return false;
                    // }

                    if (newPage == 1) {
                        setTransactionData([]);
                    }
                    // if (response?.data?.status) {

                    //   if (response.data?.data?.allTransaction?.length === 0) {
                    //     setHasMore(false);
                    //   } else {
                    //     setTransactionData((prev) => [...prev, ...formattedTransactions]);
                    //     setPage((prev) => prev + 1);
                    //     setTotalPage(response.data?.data?.totalPage);
                    //   }
                    // } else {
                    //   setHasMore(response?.data?.status);
                    //   setShow(response?.data?.status);
                    //   setMessage(response?.data?.message);
                    // }
                    const formattedTransactions = response.data?.data?.allTransaction?.map(
                        (item) => ({
                            ...item,
                            Amount: `$${parseFloat(item?.Amount).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}`,
                            Date: formatDate(item.Date),
                        })
                    );
                    if (formattedTransactions?.length === 0 && response.data?.data?.totalPage) {
                        setHasMore(false);
                        setFetchMoreData(0);
                        setLoading(false);
                        setTransactionData([]);
                        return false;
                    }

                    if (response.data?.data?.totalPage <= newPage) {
                        setHasMore(false)
                        setFetchMoreData(0);
                    }
                    else {
                        setHasMore(true);
                        setFetchMoreData(fetMoreData + 1);

                    }
                    if (response?.data?.status) {
                        setTransactionData((prev) => [...prev, ...formattedTransactions]);
                        // if (custom) {
                        setPage(response.data?.data?.page + 1);
                        // }else{
                        //   setCustom(true)
                        // }
                    }
                }
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching transaction data:", error);
        }
    };

    const handleSearch = useCallback(
        debounce((start, end, searchQuery) => {
            fetchData(1, perPage, start, end, searchQuery);
            setTransactionData([]);
        }, 700),
        []
    );

    const handleSearchChange = (e) => {
        const searchData = e.target.value;
        setSearchTerm(searchData)
        setPage(1);
        handleSearch(startDate, endDate, searchData);
    };

    const handleAction = async (id, action) => {
        let url = action === 'refund' ? `${baseUrl}/api/v1/merchant/account/transaction/sales-return` : `${baseUrl}/api/v1/merchant/account/transaction/sales-void`
        let obj = {
            transaction_id: id,
        }
        try {
            setLoading(true);
            const response = await axios.post(url, obj, {
                headers: {
                    Authorization: token,
                    'X-Device-Fingerprint': deviceId,
                }
            })
            setLoading(false);
            if (response?.status == 200) {
                fetchData(1, 15, startDate, endDate, searchTerm);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || "Something went wrong!!");
        }
    }

    const HandleRefund = (e, transaction) => {
        e.stopPropagation()
        handleAction(transaction?.pos_guid, 'refund')
    }

    const HandleVoid = (e, transaction) => {
        e.stopPropagation()
        handleAction(transaction?.pos_guid, 'void')
    }


    const HandleView = (e, transaction) => {
        e.stopPropagation()
        navigate(`/transactions/${transaction?.pos_guid}`)
    }

    useEffect(() => {
        fetchData(1, 15, startDate, endDate, searchTerm);
        setPage(1)
        dispatch(dateAction.setDates({ startDate: startDate, endDate: endDate }))
    }, [token, startDate, endDate])

    useEffect(() => {
        if (hasMore || !searchTerm || !endDate) {
            const options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0,
            };
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && isScroll) {
                    console.log("calling thgis aopiiiiii");
                    fetchData(page, perPage, startDate, endDate, searchTerm);
                }
            }, options);

            if (loader.current) {
                observer.observe(loader.current);
            }
            return () => {
                if (loader.current) {
                    observer.unobserve(loader.current);
                }
            };
        }
    }, [page, fetMoreData]);

    return (
        <div className="" >

            <div className="dashboard-filter payments mb-4 row">
                <div className="col-lg-4">
                    <p className="heading">Merchant Transactions</p>
                </div>
                <div className="d-flex col-lg-8 justify-content-end search-dates" >

                    <div className="" >
                        {/* {((role_id == "132" && staff_roll_type == "269") || (role_id == "132" && staff_roll_type == "277") || (role_id == "223")) && <ExportCSV id={null} searchKey={searchTerm} dataLength={transactions.length} />} */}
                        {/* <button onClick={handleShow} className="btn app-btn purp border border-1 mx-1">Export CSV</button> */}
                    </div>

                    <div className="search-box">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_7372_11113)">
                                <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7372_11113">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <input
                            className="searchbox"
                            type="text"
                            placeholder="Search"
                            name="search"
                            disabled={loading}
                            onChange={handleSearchChange}
                        // value={state.searchValue}
                        />
                    </div>
                    {/* <div className="cursor-pointer">
              <DatePickers startDate={startDate} endDate={endDate} filterDays={filterDays} handleDateChange={handleDateChange} handleFilterChange={handleFilterChange} />
            </div> */}
                    <div className="cursor-pointer position-relative">
                        <DatePickers startDate={startDate} endDate={endDate} setGraphFilter={setGraphFilter} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </div>
                </div>
            </div>
            <div className="date-picker-container overflow-x-auto scroll-tbl">
                <Table>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>ID</th>
                            <th>Order ID</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Brand</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionData && transactionData?.length > 0 ? (
                            transactionData?.map((transaction, ind) => (
                                <tr
                                    key={ind}
                                    className="table-hover cursor-pointer"
                                    onClick={() => { navigate(`/transactions/${transaction?.pos_guid}`) }}
                                // onClick={() => handleRow(transaction.pos_guid, "view")}
                                >

                                    <td>{formatDate(transaction.Date)}</td>
                                    {/* <td>
                                        {transaction.merchantDetails?.businessName
                                            ? transaction.merchantDetails?.businessName
                                            : "Merchant Name"}
                                    </td> */}
                                    <td>{transaction.pos_guid}</td>
                                    <td>{transaction.order_id}</td>
                                    <td style={{ color: "#383838", fontWeight: 700, }}>
                                        <div className="" style={{ color: "#383838", fontWeight: 700, }}>
                                            {String(transaction.Amount).slice(1) < 0 ? (<span className="minus">{`-$${String(transaction.Amount).slice(2)}`}</span>) : <span>&nbsp;&nbsp;{transaction.Amount}</span>}
                                        </div>
                                    </td>
                                    <td>{transaction.Type}</td>
                                    <td>
                                        {(transaction.cardType || transaction.lastFourDigits) ? (
                                            <CardType type={transaction.cardType} lastFour={transaction.lastFourDigits} />
                                        ) : null}
                                    </td>
                                    <td>{getStatusLabel(transaction.is_status)}</td>
                                    {/* {(role_id == "1" || role_id == "277") && <>
                                      <td>{`$${Number(transaction.fee_amount).toFixed(2)}`}</td>
                                      <td>{`$${Number(transaction.Net).toFixed(2)}`}</td>
                                  </>} */}
                                    <td>
                                        <div className="button-hover">
                                            <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                                                <img
                                                    src={View}
                                                    alt="view"
                                                    onClick={(e) => { HandleView(e, transaction) }}
                                                />
                                            </div>
                                            {
                                                transaction?.is_status == 2 && (
                                                    <>
                                                        <div className="tooltip-toggle" aria-label="Refund" tabIndex="0" >
                                                            <div onClick={(e) => { HandleRefund(e, transaction) }} >
                                                                <RefundIcon />
                                                            </div>
                                                        </div>
                                                        <div className="tooltip-toggle" aria-label="Void" tabIndex="0" >
                                                            <div onClick={(e) => { HandleVoid(e, transaction) }}  >
                                                                <VoidIcon />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} style={{ textAlign: "center" }}>
                                    No transaction available
                                </td>
                            </tr>
                        )}
                        {page >= 2 && transactionData?.length > 12 && !loading && <div ref={loader} style={{ height: "20px" }} />}
                    </tbody>
                </Table>
                {loading && <Loader loading={loading} />}
            </div>
        </div>
    )
}

export default MerchantTransaction