import React, { useEffect, useState } from 'react'
import { useToken } from '../../../hooks/useToken';
import { isEqual } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import { createStaffMerchant, getStaffMerchantRole } from '../../../api/Merchant';
import { toast } from 'react-toastify';
import { useCreateMerchantStaffMutation } from '../../../redux/merchantApiSlice';
import Loader from '../../../SuperAdmin/DefaultComponents/Loader';

const AddStaffMerchant = ({ setStaffMerchant, handleState, setState, state, staffMerchant, role }) => {

    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const token = useToken();
    const [data, setData] = useState({});
    const [error, setError] = useState({});
    // const [role, setRole] = useState([]);
    const [showRole, setShowRole] = useState(null)
    const [CreateMerchantStaffTrigger, { data: CreateMerchantdata, isLoading, isError }] = useCreateMerchantStaffMutation()
    const handleEmail = (value) => {
        return emailFormat.test(value);
    };

    const requiredValue = (value) => {

        if (!value) {
            return false;
        }
        return true;
    };

    const checkPassword = (pass, cnf_pass) => {
        if (isEqual(pass, cnf_pass)) {
            return true;
        }
        return false;
    };

    const Validation = () => {
        let error = {};
        if (!requiredValue(data.first_name)) {
            error.first_name = "first name is required";
        }
        if (!requiredValue(data.email)) {
            error.email = "Email is required";
        } else if (!handleEmail(data.email)) {
            error.email = "Please enter a valid email";
        }
        if (!requiredValue(data.password)) {
            error.password = "Please enter a password";
        }
        if (!requiredValue(data.cnf_password)) {
            error.cnf_password = "Confirm password is required";
        }
        if (!checkPassword(data.password, data.cnf_password)) {
            error.cnf_password = "Confirm password and password must be same";
        }
        if (!requiredValue(data.role_id)) {
            error.role_id = "Please select a role";
        }
        if (!requiredValue(data.phoneNumber)) {
            error.phoneNumber = "Please enter a valid phone number";
        }
        if (Object.keys(error).length < 1) {
            return false;
        }
        return error;
    };

    const handleChange = (e) => {
        let newData = { ...data };
        const { name, value } = e.target;
        if (name === "first_name") {
            newData[name] = value;
        } else {
            newData[name] = value?.trim();
        }
        setData(newData);
    };

    const handleData = async (e) => {
        e.preventDefault();

        setError({});
        let errors = Validation();
        if (!errors) {
            setState((prev) => ({
                ...prev,
                loading: true,
            }));
            try {

                let name = data?.first_name?.split(" ");
                let firstName = name[0]
                let lastName = name?.slice(1)?.join(" ")

                let finalData = lastName ? { ...data, first_name: firstName, last_name: lastName } : { ...data, first_name: firstName }
                const { data: response, error } = await CreateMerchantStaffTrigger(finalData);

                // console.log(response, "CreateData");

                if (response?.result) {
                    handleState("show", false);
                    handleState("edit", false);
                    toast.success(`You have added ${data?.first_name}`)
                }

                // if (!state?.filterParam || state?.filterParam == response?.data?.staff_roll_type) {
                //     setStaffMerchant([{ ...response, role: { role_name: showRole } }, ...staffMerchant]);
                // }
                // else {
                //     setStaffMerchant([...staffMerchant, ...response]);
                // }
                // dispatch(tokenAction.showToaster({ status: true, message: `You have added ${data?.first_name} `, open: true }));
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
            } catch (error) {
                console.log(error, "Add Error");

                if (typeof error?.response?.data?.message == "object") {
                    // dispatch(tokenAction.showToaster({ status: false, message: Object.values(error?.response?.data?.message)[0], open: true }));
                    toast.error(Object.values(error?.response?.data?.message)[0]);
                } else {
                    // dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message, open: true }));
                    toast.error(error?.response?.data?.message)
                }
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
            }
        } else {
            setError(errors);
        }
    };

    // const fetchRoles = async () => {
    //     setState((prev) => ({
    //         ...prev,
    //         loading: true,
    //     }));
    //     const param = {
    //         isAll: true,
    //     };
    //     try {
    //         const response = await getStaffMerchantRole(token, param);
    //         setRole(response?.allList);
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     } catch (error) {
    //         setState((prev) => ({
    //             ...prev,
    //             loading: false,
    //         }));
    //     }
    // };

    const handlePermissions = () => {
        if (!data?.role_id) {
            toast.error("Select role type then you see role permission");
        } else {
            window.open(`permissions/${data.role_id}`)
        }
    }
    // useEffect(() => {
    //     fetchRoles();
    // }, []);
    const formatPhoneNumber = (numericValue) => {
        const cleaned = ("" + numericValue).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }

        return numericValue;
    };

    function handleSelectRole(roleId, roleName) {
        let newData = { ...data };
        newData["role_id"] = roleId;
        setData(newData);
        setShowRole(roleName);
    }
    return (
        <>
            <Loader loading={isLoading} />
            <div className="container-xl">
                <div className="row">
                    <div className="col-12">
                        <label className="">FIRST AND LAST NAME*</label>
                        <input
                            className="custom-date-picker"
                            type="text"
                            placeholder="Enter first and last name"
                            name="first_name"
                            onChange={handleChange}
                            required
                        />
                        {error.first_name && (
                            <span className=" text-danger fs-12">
                                {error.first_name}
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="">EMAIL ADDRESS*</label>
                        <input
                            className="custom-date-picker"
                            type="text"
                            name="email"
                            placeholder="Enter email address"
                            onChange={handleChange}
                            inputMode="numeric"
                            required
                        />
                        {error.email && (
                            <span className=" text-danger fs-12">{error.email}</span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="">PASSWORD*</label>
                        <input
                            className="custom-date-picker"
                            type="password"
                            placeholder="Enter password"
                            name="password"
                            onChange={handleChange}
                            required
                        />
                        {error.password && (
                            <span className=" text-danger fs-12">{error.password}</span>
                        )}
                    </div>

                    <div className="col-6">
                        <label className="">CONFIRM PASSWORD*</label>
                        <input
                            className="custom-date-picker"
                            type="password"
                            name="cnf_password"
                            placeholder="Confirm password"
                            onChange={handleChange}
                            required
                        />
                        {error.cnf_password && (
                            <span className=" text-danger fs-12">
                                {error.cnf_password}
                            </span>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <label className="">Role*</label>
                        <div className="d-flex add-emp">
                            {/* <select
                className="custom-date-picker"
                name="role_id"
                onChange={handleChange}
              >
                <option value="">Role </option>
                {role?.map((itm) => (
                  <option value={itm.role_id}>{itm.role_name}</option>
                ))}
              </select> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic-1">
                                    {showRole ?? "Select Role"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {role?.map((itm, ind) => (
                                        <Dropdown.Item style={{ textTransform: 'capitalize' }} href="#!" onClick={() => handleSelectRole(itm.role_id, itm?.role_name)} key={ind}>{itm.role_name}
                                            {/* <span>Placeholder text</span> */}
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>
                            <span className="p-1 cursor-pointer" onClick={() => handlePermissions()}><i className="fas fa-info-circle"></i></span>
                        </div>
                        {error.role_id && (
                            <span className=" text-danger fs-12">{error.role_id}</span>
                        )}
                    </div>
                    <div className="col-6">
                        <label className="">PHONE*</label>
                        <input
                            className="custom-date-picker"
                            type="text"
                            maxLength="10"
                            value={formatPhoneNumber(data?.phoneNumber)}

                            name="phoneNumber"
                            placeholder="Phone"
                            onChange={handleChange}
                            required
                        />

                        {error.phoneNumber && (
                            <span className=" text-danger fs-12">
                                {error.phoneNumber}
                            </span>
                        )}
                    </div>
                </div>
                <input type="text" name="bot_key" onChange={handleChange} style={{ display: "none !important" }} hidden />
                <div className="row">
                    <div className="col-6">
                        <button className="idle-blk-btn w-100" onClick={() => handleState("show", false)}>Cancel</button>
                    </div>
                    <div className="col-6">
                        <button className="idle-green-btn w-100" onClick={handleData}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddStaffMerchant
