import React, { useState } from 'react'
import { Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useGetRolesListQuery, useGetRolesQuery } from '../../../redux/superadminApiSlice';
import AddRole from './AddRole';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../DefaultComponent/Loader';
import Edit from "../../../assets/images/Edit.svg";
import PaginationPg from '../../../DefaultComponent/PaginationPg';

const SuperAdminRoles = () => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [roleItem, setRoleItem] = useState('')
    const [show, setShow] = useState({ edit: false, delete: false, modalName: "" });
    const { data: rolesList, isLoading, isFetching } = useGetRolesListQuery(page)
    const { data: roles, isLoading: rolesLoading } = useGetRolesQuery();


    const handleClose = () => setShow({ edit: false, delete: false, modalName: "" });
    const handleShow = (payload) => setShow({ ...payload })
    // handle permissions menu
    const handleGetAllMenuList = (role_id) => {
        navigate(`/admin/permissions/${role_id}`);
    };
    // handle page
    const handlePageChange = (pageNo) => {
        setPage(pageNo);

    }
    // user label 
    const checkUserLabel = (id) => {
        let findLabel=  roles?.map((item) => {
            if (item?.value === id) return item?.label?.replaceAll("_"," ")
        })
        
        console.log("page,", findLabel);
        return findLabel
    }

    return (
        <>
            <div className="container-xl">
                <div className="row" >
                    <div className="app-card-body d-flex justify-content-between px-5" >
                        <div className="payment-heading">
                            <h5>Roles</h5>
                        </div>
                        <button className="btn btn-primary purp border border-1 text-black"
                            onClick={() => handleShow({ edit: true, delete: false, modalName: "Add" })}
                        >
                            <i className="fa-solid fa-plus"></i> Add Role
                        </button>
                    </div>
                </div>
                <div className="date-picker-container overflow-x-auto scroll-tbl">
                    <Table>
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>User Label</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rolesList?.allList?.length > 0 &&
                                rolesList?.allList?.map((item) => {
                                    return (
                                        <tr key={item?.role_id} className="table-hover cursor-pointer">
                                            <td>{item?.role_name}</td>
                                            <td>{checkUserLabel(item?.user_role)}</td>

                                            <td>
                                                <div className='button-hover'>
                                                    <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                        <img
                                                            src={Edit}
                                                            alt="Edit"
                                                            onClick={() => { handleShow({ edit: true, delete: false, modalName: "Edit" }); setRoleItem(item) }}
                                                        />
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Permissions" tabIndex="0">
                                                        <i className="fa-solid fa-lock cursor-pointer mx-2 " onClick={() => handleGetAllMenuList(item?.role_id)}></i>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <PaginationPg
                        totalPages={rolesList?.totalPage}
                        onPageChange={handlePageChange}
                        current={page}
                    />
                </div>
                {/* edit Role */}
                <Modal show={show.edit} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{show.modalName} Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <AddRole roleItem={roleItem} action={show.modalName} handleClose={handleClose} />
                    </Modal.Body>
                </Modal>
                <Loader loading={isLoading || isFetching || rolesLoading} />
            </div>
        </>
    )
}

export default SuperAdminRoles