import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import MerchantDashoard from "../merchant/MerchantDashoard";
import Footer from "./Footer";
import MerchantTransaction from "../merchant/MerchantTransaction";
import DigitalPos from "../merchant/DigitalPos";
import Quickpayment from "../merchant/quickpayment/Quickpayment";
import MerchantDashboard from "../merchant/MerchantDashboard/MerchantDashboard";
import PaymentDetails from "../pages/Payments/Detail/PaymentDetails";
import SubmerchantList from "../merchant/SubMerchant/SubmerchantList";
import AddSubMerchant from "../merchant/SubMerchant/AddSubMerchant";
import StaffMerchant from "../merchant/MerchantStaff/StaffMerchant";
import EditSubMerchant from "../merchant/SubMerchant/EditSubMerchant";
import ViewSubmerchant from "../merchant/SubMerchant/ViewSubmerchant";
import TransactionsSubmerchant from "../merchant/SubMerchant/TransactionsSubmerchant";
import CustomersList from "../merchant/Customer/CustomersList";

const MerchantRoutes = () => {
    return (
        <div className=" ">
            <Sidebar />
            <div className="app-wrapper">
                <div className="app-content ">
                    <div className="container-xl">
                        <div className="white-box-main">
                            <div className="" id="style-4">
                                <Routes>
                                    <Route path="/transactions" element={<MerchantTransaction />} />
                                    <Route path="/transactions/:id" element={<PaymentDetails />} />
                                    <Route path="/profile" element={<DigitalPos />} />
                                    <Route path="/EasyPay" element={<Quickpayment />} />
                                    <Route path="/" element={<MerchantDashboard />} />
                                    <Route path="/sub-merchant" element={<SubmerchantList />} />
                                    <Route path="/sub-merchant/add" element={<AddSubMerchant />} />
                                    <Route path="/sub-merchant/edit/:id" element={<EditSubMerchant />} />
                                    <Route path="/sub-merchant/view/:id" element={<ViewSubmerchant />} />
                                    <Route path="/sub-merchant/transactions/:id" element={<TransactionsSubmerchant />} />
                                    <Route path="/merchant-staff" element={<StaffMerchant />} />
                                    <Route path="/merchant-customer" element={<CustomersList />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default MerchantRoutes