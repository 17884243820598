import React from 'react'
import { Link } from 'react-router-dom';

const LowCode = ({setActiveTab}) => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };
  return (
    <>
    
          <div
              className="relative grow overflow-hidden mx-auto  lg:-ml-12 lg:pl-[23.7rem]"
              id="content-area"
          >
              <header id="header" className="relative">
                  <div className="mt-0.5 space-y-2.5">
                      <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                          API Documentation
                      </div>
                      <div className="flex items-center">
                          <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                              Low Code JS
                          </h1>
                      </div>
                  </div>
                  <div className="mt-2 text-lg prose prose-gray dark:prose-invert">
                      <p>Fractal JS in depth</p>
                  </div>
              </header>
              <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-6 xl:hidden" />
              </div>
              <div className="relative mt-8 prose prose-gray dark:prose-invert">
                  <p>
                      Welcome to the Widgets API documentation. This API allows you to integrate
                      and interact with various payment widgets seamlessly. You can embed
                      buttons for payments, request payments, and display transaction tables.
                  </p>
                  {/* <h3 className="flex whitespace-pre-wrap group" id="widget-usage">
                      <div className="absolute">
                          <a
                              
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Widget Usage</span>
                  </h3> */}
                  <h3 className="flex whitespace-pre-wrap group" id="pay-link">
                      <div className="absolute">
                          <a
                              
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Pay Link</span>
                  </h3>
                  <p>
                      <strong>Pay Button</strong>
                  </p>
                  <p>HTML implementation</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>a
                                      </span>{" "}
                                      <span className="token attr-name">href</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>javascript:void(0);
                                          <span className="token punctuation">"</span>
                                      </span>{" "}
                                      <span className="token special-attr">
                                          <span className="token attr-name">onclick</span>
                                          <span className="token attr-value">
                                              <span className="token punctuation attr-equals">=</span>
                                              <span className="token punctuation">"</span>
                                              <span className="token value javascript language-javascript">
                                                  <span className="token function">getPaymentPage</span>
                                                  <span className="token punctuation">(</span>
                                                  <span className="token number">8</span>
                                                  <span className="token punctuation">)</span>
                                              </span>
                                              <span className="token punctuation">"</span>
                                          </span>
                                      </span>{" "}
                                      <span className="token attr-name">class</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>btn btn-primary
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  Pay
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>a
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>React/Vue js implementation</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-java">
                              <code className="language-java">
                                  <span className="token keyword">import</span>{" "}
                                  <span className="token punctuation">{"{"}</span>{" "}
                                  <span className="token class-name">GetPaymentPage</span>{" "}
                                  <span className="token punctuation">{"}"}</span> from 'fractalpay-test'
                                  <span className="token punctuation">;</span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>
                      <strong>Description</strong>: This widget generates a button that, when
                      clicked, opens a payment page for the amount <code>8.00</code>.
                  </p>
                  <p>
                      <strong>Parameters</strong>:
                  </p>
                  <ul>
                      <li>
                          <code>amount</code> (number): The amount of the widget to open the
                          payment page for.
                      </li>
                  </ul>
                  <p>
                      <strong>Example</strong>:
                  </p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>a
                                      </span>{" "}
                                      <span className="token attr-name">href</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>javascript:void(0);
                                          <span className="token punctuation">"</span>
                                      </span>{" "}
                                      <span className="token special-attr">
                                          <span className="token attr-name">onclick</span>
                                          <span className="token attr-value">
                                              <span className="token punctuation attr-equals">=</span>
                                              <span className="token punctuation">"</span>
                                              <span className="token value javascript language-javascript">
                                                  <span className="token function">getPaymentPage</span>
                                                  <span className="token punctuation">(</span>
                                                  <span className="token number">8.00</span>
                                                  <span className="token punctuation">)</span>
                                              </span>
                                              <span className="token punctuation">"</span>
                                          </span>
                                      </span>{" "}
                                      <span className="token attr-name">class</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>btn btn-primary
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  Pay
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>a
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  {"  "}
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>GetPaymentPage
                                      </span>{" "}
                                      <span className="token attr-name">fractalpayClientKey</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          {"{"}fractalpayClientKey{"}"}
                                      </span>{" "}
                                      <span className="token attr-name">amount</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          {"{"}8.55{"}"}
                                      </span>{" "}
                                      <span className="token punctuation">/&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <h3 className="flex whitespace-pre-wrap group" id="request-link">
                      <div className="absolute">
                          <a
                            
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Request Link</span>
                  </h3>
                  <p>
                      <strong>Request Payment Button</strong>
                  </p>
                  <p>HTML implementation</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>a
                                      </span>{" "}
                                      <span className="token attr-name">href</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>javascript:void(0);
                                          <span className="token punctuation">"</span>
                                      </span>{" "}
                                      <span className="token special-attr">
                                          <span className="token attr-name">onclick</span>
                                          <span className="token attr-value">
                                              <span className="token punctuation attr-equals">=</span>
                                              <span className="token punctuation">"</span>
                                              <span className="token value javascript language-javascript">
                                                  <span className="token function">sendRequestPayment</span>
                                                  <span className="token punctuation">(</span>
                                                  <span className="token number">8.00</span>
                                                  <span className="token punctuation">,</span>{" "}
                                                  <span className="token string">'9695969889'</span>
                                                  <span className="token punctuation">)</span>
                                              </span>
                                              <span className="token punctuation">"</span>
                                          </span>
                                      </span>{" "}
                                      <span className="token attr-name">class</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>btn btn-primary
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  Request payment
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>a
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>React/Vue js implementation</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-java">
                              <code className="language-java">
                                  <span className="token keyword">import</span>{" "}
                                  <span className="token punctuation">{"{"}</span>{" "}
                                  <span className="token class-name">RequestPaymentonClick</span>{" "}
                                  <span className="token punctuation">{"}"}</span> from 'fractalpay-test'
                                  <span className="token punctuation">;</span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>
                      <strong>Description</strong>: This widget generates a button that, when
                      clicked, sends a payment request to the specified phone number for the
                      amount of <code>8.00</code>.
                  </p>
                  <p>
                      <strong>Parameters</strong>:
                  </p>
                  <ul>
                      <li>
                          <code>amount</code> (number): The value of the widget to send the
                          payment request for.
                      </li>
                      <li>
                          <code>recipient_phone</code> (string): The phone number of the
                          recipient.
                      </li>
                  </ul>
                  <p>
                      <strong>Example</strong>:
                  </p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>a
                                      </span>{" "}
                                      <span className="token attr-name">href</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>javascript:void(0);
                                          <span className="token punctuation">"</span>
                                      </span>{" "}
                                      <span className="token special-attr">
                                          <span className="token attr-name">onclick</span>
                                          <span className="token attr-value">
                                              <span className="token punctuation attr-equals">=</span>
                                              <span className="token punctuation">"</span>
                                              <span className="token value javascript language-javascript">
                                                  <span className="token function">sendRequestPayment</span>
                                                  <span className="token punctuation">(</span>
                                                  <span className="token number">8.00</span>
                                                  <span className="token punctuation">,</span>{" "}
                                                  <span className="token string">'9695969889'</span>
                                                  <span className="token punctuation">)</span>
                                              </span>
                                              <span className="token punctuation">"</span>
                                          </span>
                                      </span>{" "}
                                      <span className="token attr-name">class</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>btn btn-primary
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  Request payment
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>a
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>React/Vue js</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  {"  "}
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>
                                          RequestPaymentonClick
                                      </span>{" "}
                                      <span className="token attr-name">fractalpayClientKey</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          {"{"}fractalpayClientKey{"}"}
                                      </span>{" "}
                                      <span className="token attr-name">amount</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          {"{"}invoice.amount{"}"}
                                      </span>{" "}
                                      <span className="token attr-name">phone_number</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          {"{"}phoneNumbers[invoice.id]
                                      </span>{" "}
                                      <span className="token attr-name">||</span>{" "}
                                      <span className="token attr-name">""{"}"}</span>
                                      <span className="token punctuation">/&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <h3 className="flex whitespace-pre-wrap group" id="transactions-table">
                      <div className="absolute">
                          <a
                            
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Transactions Table</span>
                  </h3>
                  <p>
                      <strong>Transactions Table</strong>
                  </p>
                  <p>HTML implementation</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>table
                                      </span>{" "}
                                      <span className="token attr-name">id</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>
                                          fractal_complete_transactions
                                          <span className="token punctuation">"</span>
                                      </span>{" "}
                                      <span className="token attr-name">class</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>table
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>table
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>React/Vue js implementation</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-java">
                              <code className="language-java">
                                  <span className="token keyword">import</span>{" "}
                                  <span className="token punctuation">{"{"}</span>{" "}
                                  <span className="token class-name">CompletedTransactions</span>{" "}
                                  <span className="token punctuation">{"}"}</span> from 'fractalpay-test'
                                  <span className="token punctuation">;</span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>
                      <strong>Description</strong>: This widget generates a table to display
                      complete transactions.
                  </p>
                  <p>
                      <strong>Parameters</strong>:
                  </p>
                  <ul>
                      <li>
                          <code>id</code> (string): The ID of the table element. In this case, it
                          is
                          <code>fractal_complete_transactions</code>.
                      </li>
                  </ul>
                  <p>
                      <strong>Example</strong>:
                  </p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>table
                                      </span>{" "}
                                      <span className="token attr-name">id</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>
                                          fractal_complete_transactions
                                          <span className="token punctuation">"</span>
                                      </span>{" "}
                                      <span className="token attr-name">class</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>table
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>table
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>React/Vue.js</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>
                                          CompletedTransactions
                                      </span>{" "}
                                      <span className="token attr-name">fractalpayClientKey</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          {"{"}fractalpayClientKey{"}"}/
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <h3
                      className="flex whitespace-pre-wrap group"
                      id="installing-js-and-npm-package"
                  >
                      <div className="absolute">
                          <a
                            
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Installing JS &amp; NPM Package</span>
                  </h3>
                  <p>To use these widgets, include the following script tag in your HTML:</p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-html">
                              <code className="language-html">
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;</span>script
                                      </span>{" "}
                                      <span className="token attr-name">src</span>
                                      <span className="token attr-value">
                                          <span className="token punctuation attr-equals">=</span>
                                          <span className="token punctuation">"</span>
                                          https://testapi.fractalpay.com/js/dynamic-launch.js?fractalpay_merchant_public_key=
                                          {"{"}
                                          {"{"}merchant_public_key{"}"}
                                          {"}"}
                                          <span className="token punctuation">"</span>
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  <span className="token script" />
                                  <span className="token tag">
                                      <span className="token tag">
                                          <span className="token punctuation">&lt;/</span>script
                                      </span>
                                      <span className="token punctuation">&gt;</span>
                                  </span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>
                      Replace{" "}
                      <code>
                          {"{"}
                          {"{"}merchant_public_key{"}"}
                          {"}"}
                      </code>{" "}
                      with your actual merchant public key.
                  </p>
                  <p>
                      To use these widgets in a react/Vue.js app, install the following npm
                      package:
                  </p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-java">
                              <code className="language-java">
                                  npm i fractal<span className="token operator">-</span>pay
                                  <span className="token operator">-</span>now{"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <p>
                      To use the components in a react/Vue.js app, use the below line to import
                      the proper npm function:
                  </p>
                  <div className="mt-5 mb-8 not-prose bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50 relative">
                      <div className="group z-10 absolute mt-0.5 top-4 right-4">
                          <button className="h-7 w-7 flex items-center justify-center rounded-md">
                              <svg
                                  className="fill-gray-700 group-hover:fill-gray-400"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                              </svg>
                          </button>
                          <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                              Copy
                          </div>
                      </div>
                      <div
                          className="text-gray-50 p-5 min-w-full text-sm leading-6 overflow-x-auto children:!my-0 children:!shadow-none children:!bg-transparent"
                          style={{ fontVariantLigatures: "none" }}
                      >
                          <pre className="language-java">
                              <code className="language-java">
                                  <span className="token keyword">import</span>{" "}
                                  <span className="token punctuation">{"{"}</span>{" "}
                                  <span className="token operator">--</span>flag
                                  <span className="token operator">--</span>{" "}
                                  <span className="token punctuation">{"}"}</span> from 'fractalpay-test'
                                  <span className="token punctuation">;</span>
                                  {"\n"}
                              </code>
                          </pre>
                      </div>
                  </div>
                  <h3 className="flex whitespace-pre-wrap group" id="error-handling-and-faqs">
                      <div className="absolute">
                          <a
                           
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Error Handling and FAQs</span>
                  </h3>
                  <h3 className="flex whitespace-pre-wrap group" id="error-handling">
                      <div className="absolute">
                          <a
                             
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">Error Handling</span>
                  </h3>
                  <p>
                      <strong>Error Codes:</strong>
                  </p>
                  <ul>
                      <li>
                          <strong>400 Bad Request</strong>: The request was invalid or cannot be
                          otherwise served.
                      </li>
                      <li>
                          <strong>401 Unauthorized</strong>: Authentication is required and has
                          failed or has not yet been provided.
                      </li>
                      <li>
                          <strong>404 Not Found</strong>: The requested resource could not be
                          found.
                      </li>
                      <li>
                          <strong>500 Internal Server Error</strong>: An error occurred on the
                          server.
                      </li>
                  </ul>
                  <h3
                      className="flex whitespace-pre-wrap group"
                      id="faqs-and-troubleshooting"
                  >
                      <div className="absolute">
                          <a
                              
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                      </div>
                      <span className="cursor-pointer">FAQs and Troubleshooting</span>
                  </h3>
                  <ul>
                      <li>
                          <p>
                              <strong>
                                  Q: What should I do if I receive a 401 Unauthorized error?
                              </strong>
                              <strong>A:</strong> Ensure that your API key is correct and included
                              in the request header.
                          </p>
                      </li>
                      <li>
                          <p>
                              <strong>Q: How do I update the settings of a widget?</strong>
                              <strong>A:</strong> Use the PUT /widgets/ endpoint with the new
                              settings in the request body.
                          </p>
                      </li>
                  </ul>
              </div>
              <div className="leading-6 mt-14">
                  <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                      <Link to={"/docs/documentation/webhooks"}
                          className="flex items-center space-x-3 group"
                          onClick={()=>setActiveTab('/docs/documentation/webhooks')}
                      >
                          <svg
                              viewBox="0 0 3 6"
                              className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                          >
                              <path
                                  d="M3 0L0 3L3 6"
                                  fill="none"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                          </svg>
                          <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                              Webhooks
                          </span>
                      </Link>
                  </div>
                  <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                      <div className="flex mb-6 space-x-6 sm:mb-0">
                          {/* <a
                              
                              target="_blank"
                          >
                              <span className="sr-only">linkedin</span>
                              <svg
                                  className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                  style={{
                                      maskImage:
                                          'url("https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg")',
                                      maskRepeat: "no-repeat",
                                      maskPosition: "center center"
                                  }}
                              />
                          </a> */}
                      </div>
                      <div className="sm:flex">
                          <a
                              
                              target="_blank"
                              rel="noreferrer"
                              className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                          >
                              Fractal Pay
                          </a>
                      </div>
                  </footer>
              </div>
          </div>
          {/* on this page right bar */}
          <div
              className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]"
              id="table-of-contents"
          >
              <div className="fixed text-gray-600 text-sm leading-6 w-[16.5rem] overflow-y-auto space-y-2 h-[calc(100%-10rem)]">
                  <div className="text-gray-700 dark:text-gray-300 font-medium flex items-center space-x-2">
                      <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3"
                      >
                          <path
                              d="M2.44434 12.6665H13.5554"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                          <path
                              d="M2.44434 3.3335H13.5554"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                          <path
                              d="M2.44434 8H7.33323"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                      </svg>
                      <span>On this page</span>
                  </div>
                  <ul>
                      {/* <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('widget-usage'); }}
                              
                              className="cursor-pointer py-1 block -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                          >
                              Widget Usage
                          </a>
                      </li> */}
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('pay-link'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              Pay Link
                          </a>
                      </li>
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('request-link'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              Request Link
                          </a>
                      </li>
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('transactions-table'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              Transactions Table
                          </a>
                      </li>
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('installing-js-and-npm-package'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              Installing JS &amp; NPM Package
                          </a>
                      </li>
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('error-handling-and-faqs'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              Error Handling and FAQs
                          </a>
                      </li>
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('error-handling'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              Error Handling
                          </a>
                      </li>
                      <li>
                          <a onClick={(e) => { e.preventDefault(); scrollToSection('faqs-and-troubleshooting'); }}
                              
                              className="cursor-pointer py-1 block hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                              FAQs and Troubleshooting
                          </a>
                      </li>
                  </ul>
              </div>
          </div>


    </>
  )
}

export default LowCode