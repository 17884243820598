import axios from "axios";
import baseUrl from "../baseUrl";
import { store } from "../redux/store";
import END_POINTS from "./endPoints";

const getTokenFromState = () => {
    const state = store.getState();
    return state.deviceId.deviceId;
};

export const GetSubmerchantList = async (token, params) => {
    let deviceId = getTokenFromState()
    const { data } = await axios.get(
        `${baseUrl}${END_POINTS.GET_SUB_MERCHANT_LIST}`,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
            params: params,
        }
    );

    if (!data?.result) {
        throw new Error(data?.message)
    }

    return data
}

export const GetSubmerchantBusinessDetails = async (token, id) => {
    let deviceId = getTokenFromState()
    const { data } = await axios.get(
        `${baseUrl}${END_POINTS?.GET_VIEW_SUB_MERCHANT_DETAILS}${id}`,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );

    if (!data?.result) {
        throw new Error(data?.message)
    }

    return data?.data
}

export const CreateSubmerchant = async (token, data) => {
    let deviceId = getTokenFromState()
    const response = await axios.post(
        `${baseUrl}${END_POINTS.CREATE_SUB_MERCHANT}`,
        data,
        {
            headers: {
                Authorization: token,
                "Content-Type": "application/JSON",
                'X-Device-Fingerprint': deviceId,
            },
        }
    );

    console.log("view", response);


    if (!response?.data?.result) {
        throw new Error(response?.data?.message)
    }

    return response
}

export const UpdateSubmerchantBusiness = async (token, data, id) => {
    let deviceId = getTokenFromState()
    const response = await axios.patch(
        `${baseUrl}${END_POINTS?.UPDATE_SUB_MERCHANT_BUSINESS}${id}`,
        data,
        {
            headers: {
                Authorization: token,
                "Content-Type": "application/JSON",
                'X-Device-Fingerprint': deviceId,
            },
        }
    );

    if (!response?.data?.result) {
        throw new Error(response?.data?.message)
    }

    return response
}

export const GetSubmerchantOwnerDetails = async (token, id) => {
    let deviceId = getTokenFromState()
    const { data } = await axios.get(
         `${baseUrl}${END_POINTS?.GET_SUB_MERCHANT_OWNER}${id}`,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );

    if (!data?.result) {
        throw new Error(data?.message)
    }

    return data?.data
}

export const UpdateSubMerchnatOwnerDetails = async (token, data, id) => {
    let deviceId = getTokenFromState()
    const response = await axios.patch(
        `${baseUrl}${END_POINTS?.UPDATE_SUB_MERCHANT_OWNER}${id}`,
        data,
        {
            headers: {
                Authorization: token,
                'X-Device-Fingerprint': deviceId,
            },
        }
    );

    if (!response?.data?.result) {
        throw new Error(response?.data?.message)
    }

    return response?.data
}