import React from 'react'

const CardType = (props) => {

    const cards = {
        "visa": <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7115_989)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0807 4.92536L9.0724 11.1218H10.6855L11.6939 4.92536H10.0807ZM7.71515 4.92536L6.17712 9.18721L5.9952 8.26948L5.99532 8.26978L5.95523 8.06409C5.76909 7.66294 5.33799 6.9005 4.52395 6.24982C4.28328 6.0575 4.04068 5.89336 3.80615 5.75315L5.20397 11.1218H6.88473L9.45139 4.92536H7.71515ZM14.011 6.64561C14.011 5.94505 15.582 6.03507 16.2724 6.41547L16.5026 5.08464C16.5026 5.08464 15.7921 4.81445 15.0516 4.81445C14.251 4.81445 12.3499 5.16456 12.3499 6.86574C12.3499 8.46675 14.5812 8.48665 14.5812 9.3272C14.5812 10.1678 12.58 10.0175 11.9196 9.48731L11.6795 10.8783C11.6795 10.8783 12.3998 11.2284 13.5006 11.2284C14.6013 11.2284 16.2623 10.6582 16.2623 9.10707C16.2623 7.49605 14.011 7.346 14.011 6.64561ZM20.5938 4.92536H19.2968C18.6979 4.92536 18.552 5.38714 18.552 5.38714L16.1463 11.1218H17.8277L18.1641 10.2014H20.2149L20.4041 11.1218H21.8853L20.5938 4.92536ZM18.629 8.92987L19.4766 6.61102L19.9535 8.92987H18.629Z" fill="#005BAC" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.47452 5.5087C5.47452 5.5087 5.40775 4.94946 4.6949 4.94946H2.10504L2.07471 5.05456C2.07471 5.05456 3.3196 5.30834 4.51391 6.25911C5.65525 7.16781 6.02757 8.30055 6.02757 8.30055L5.47452 5.5087Z" fill="#F6AC1D" />
            </g>
            <rect x="0.5" y="0.5" width="23" height="15" rx="3.5" stroke="#D4CDDF" />
            <defs>
                <clipPath id="clip0_7115_989">
                    <rect width="24" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>,
        "m/c": <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="16" rx="4" fill="#12253E" />
            <g clipPath="url(#clip0_7115_1038)">
                <path d="M9.375 12C11.7912 12 13.75 9.98528 13.75 7.5C13.75 5.01472 11.7912 3 9.375 3C6.95875 3 5 5.01472 5 7.5C5 9.98528 6.95875 12 9.375 12Z" fill="#F93232" />
                <path d="M14.625 3C13.6424 3 12.7385 3.3375 12.0088 3.9H12V3.9063C11.8268 4.0404 11.6675 4.1916 11.5157 4.35H12.4843C12.6191 4.49085 12.7433 4.6416 12.8584 4.8H11.1381C11.0322 4.94355 10.9351 5.0934 10.8463 5.25H13.1493C13.2316 5.3958 13.309 5.5443 13.3759 5.7H10.6236C10.5611 5.84625 10.5059 5.9961 10.4587 6.15H13.5404C13.5859 6.2967 13.6218 6.44745 13.6533 6.6H10.3445C10.3152 6.7476 10.2907 6.897 10.2758 7.05H13.7238C13.7408 7.1976 13.75 7.34745 13.75 7.5H10.25C10.25 7.65255 10.2618 7.8015 10.2763 7.95H13.7246C13.708 8.10255 13.6839 8.2524 13.6538 8.4H10.3449C10.3751 8.55345 10.4141 8.70285 10.4591 8.85H13.5409C13.4936 9.0039 13.4385 9.15375 13.3759 9.3H10.6236C10.6897 9.4548 10.7641 9.6051 10.8463 9.75H13.1493C13.0609 9.9066 12.9625 10.0564 12.8566 10.2H11.1381C11.2545 10.3584 11.3801 10.5092 11.5153 10.65H12.4843C12.3321 10.8088 12.1732 10.9596 12 11.0937C12.7315 11.6602 13.6384 12 14.625 12C17.0413 12 19 9.9849 19 7.5C19 5.0151 17.0413 3 14.625 3Z" fill="#FED049" />
            </g>
            <defs>
                <clipPath id="clip0_7115_1038">
                    <rect width="14" height="9" fill="white" transform="translate(5 3)" />
                </clipPath>
            </defs>
        </svg>,
        "amex": <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7115_1047)">
                <rect width="24" height="16" rx="4" fill="#006FCF" />
                <path d="M26.3948 8.46412H22.1677L20.4843 10.2775L18.8546 8.46412H13.5342V14.0001H18.7752L20.4689 12.1672L22.0986 14.0001H24.6701V12.1434H26.3205C27.4768 12.1434 28.6238 11.8257 28.6238 10.2878C28.6238 8.755 27.4448 8.46206 26.3948 8.46412ZM18.1057 12.8479H14.8545V11.7463H17.7581V10.6158H14.8545V9.60907H18.1707L19.6179 11.2213L18.1057 12.8479ZM23.3519 13.4968L21.3199 11.2399L23.3519 9.05723V13.4968ZM26.3783 11.0171H24.6701V9.60701H26.3948C26.8734 9.60701 27.2035 9.80196 27.2035 10.2868C27.2035 10.7664 26.8878 11.0191 26.3783 11.0171Z" fill="white" />
                <path d="M21.2421 2L19.6526 5.75565L17.9207 2H15.7608V7.24202L13.5091 2H11.5451L9.17578 7.536H10.6126L11.1263 6.30131H13.8804L14.3889 7.536H17.0811V3.19653L19.0028 7.53497L20.1673 7.536L22.0859 3.20065L22.091 7.536H23.4475V2H21.2421ZM11.5977 5.1481L12.4972 2.94175L13.4018 5.1481H11.5977Z" fill="white" />
            </g>
            <rect x="0.5" y="0.5" width="23" height="15" rx="3.5" stroke="#006FCF" />
            <defs>
                <clipPath id="clip0_7115_1047">
                    <rect width="24" height="16" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>,
        "dcvr": <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7115_1055)">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.58846e-06 8.47976C-0.000193012 8.42774 0.00751371 8.40883 0.0664937 8.4097C0.369022 8.41398 0.671864 8.40553 0.974196 8.41493C1.41898 8.42877 1.82315 8.55836 2.15281 8.87253C2.37973 9.08871 2.52234 9.35342 2.57849 9.6605C2.69032 10.2723 2.51782 10.7915 2.05176 11.2065C1.79732 11.4331 1.49243 11.547 1.15562 11.5742C0.784398 11.6041 0.412117 11.5803 0.0404246 11.5884C-0.00404637 11.5894 0.000907949 11.5654 0.000947269 11.5374C0.00126183 11.2243 0.00193027 8.98621 3.58846e-06 8.47976ZM0.618861 10.991C0.618625 11.034 0.62763 11.0478 0.673162 11.0472C0.797099 11.0456 0.921271 11.0468 1.04446 11.033C1.26941 11.0078 1.4804 10.9433 1.64507 10.7785C1.92189 10.5016 2.02361 10.1648 1.94674 9.78373C1.86932 9.39993 1.64842 9.12949 1.26536 9.01078C1.07879 8.95298 0.884782 8.9566 0.691682 8.95416C0.636673 8.95345 0.617603 8.96434 0.618232 9.02474C0.621653 9.35031 0.620709 10.6612 0.618861 10.991Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.7765 10.2558C19.1092 10.7008 19.4367 11.1387 19.7721 11.5873C19.7199 11.5873 19.6791 11.5873 19.6383 11.5873C19.4512 11.5873 19.264 11.5844 19.077 11.589C19.0203 11.5904 18.9918 11.5693 18.9624 11.5253C18.7031 11.1366 18.4415 10.7493 18.1805 10.3616C18.177 10.3563 18.1728 10.3514 18.1704 10.3456C18.1546 10.3069 18.1207 10.3095 18.0907 10.3156C18.0576 10.3225 18.0734 10.3536 18.0733 10.3731C18.0723 10.7536 18.0709 11.1341 18.0742 11.5146C18.0748 11.5779 18.056 11.5902 17.9972 11.5887C17.8333 11.5845 17.6693 11.586 17.5053 11.5881C17.464 11.5886 17.4472 11.5811 17.4473 11.5342C17.4486 10.5104 17.4484 9.48662 17.4478 8.46289C17.4478 8.42817 17.4515 8.40941 17.4944 8.41036C17.8473 8.41771 18.2007 8.39628 18.5532 8.42333C18.798 8.44213 19.0283 8.50834 19.2165 8.67522C19.4423 8.87544 19.5059 9.13813 19.4905 9.42745C19.4679 9.85301 19.236 10.134 18.8228 10.2387C18.8089 10.2422 18.7957 10.2486 18.7765 10.2558ZM18.0726 9.39501C18.0726 9.53558 18.0751 9.67623 18.0712 9.81668C18.0699 9.86595 18.0855 9.87562 18.1307 9.87377C18.2229 9.87 18.315 9.872 18.4071 9.86229C18.6769 9.83382 18.8303 9.67792 18.8503 9.40771C18.8664 9.19015 18.7552 9.0256 18.5483 8.95773C18.4122 8.9131 18.2718 8.91798 18.1316 8.91523C18.0857 8.91432 18.0701 8.92431 18.0714 8.9733C18.075 9.11379 18.0726 9.2544 18.0726 9.39501Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.2871 8.47319C15.287 8.42553 15.2966 8.40996 15.348 8.41016C15.8945 8.41224 16.441 8.41193 16.9874 8.41051C17.03 8.41039 17.0453 8.41873 17.0444 8.46481C17.0413 8.61187 17.0413 8.75908 17.0444 8.90614C17.0453 8.95049 17.0288 8.95643 16.9905 8.95627C16.6521 8.95498 16.3137 8.9571 15.9754 8.95399C15.9178 8.95348 15.9064 8.97023 15.9073 9.02422C15.9107 9.21543 15.9103 9.40676 15.9075 9.59798C15.9068 9.64575 15.9202 9.65731 15.9671 9.65704C16.2928 9.65495 16.6186 9.65727 16.9444 9.65456C16.9959 9.65413 17.0057 9.66997 17.0047 9.71723C17.0017 9.858 17.0017 9.99888 17.0048 10.1396C17.0057 10.1842 16.993 10.1947 16.9493 10.1945C16.6256 10.1926 16.302 10.1952 15.9783 10.1919C15.918 10.1912 15.9068 10.2103 15.9074 10.2656C15.9103 10.5052 15.9099 10.7448 15.9076 10.9844C15.9071 11.0321 15.917 11.0478 15.9684 11.0474C16.3068 11.0448 16.6452 11.0473 16.9836 11.0448C17.0341 11.0444 17.0455 11.0588 17.0444 11.1069C17.0412 11.2518 17.0422 11.3969 17.044 11.542C17.0444 11.5767 17.0359 11.5885 16.999 11.5884C16.4441 11.5873 15.8893 11.5868 15.3344 11.5889C15.2838 11.5891 15.2879 11.5644 15.2879 11.5314C15.2883 11.0185 15.2888 8.97971 15.2871 8.47319Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M4.14563 10.6698C4.22923 10.8262 4.33952 10.9535 4.50187 11.0248C4.67637 11.1015 4.85603 11.1283 5.03717 11.0473C5.20177 10.9737 5.29067 10.8425 5.29775 10.6638C5.30479 10.4867 5.21006 10.3682 5.05672 10.2916C4.88044 10.2036 4.68982 10.1513 4.5097 10.0731C4.33366 9.99662 4.16596 9.90815 4.04442 9.75154C3.97015 9.65588 3.92894 9.54649 3.91455 9.42935C3.85038 8.90773 4.13604 8.53467 4.56934 8.41207C5.02192 8.284 5.42951 8.39653 5.7946 8.68345C5.82157 8.70465 5.83081 8.71959 5.80498 8.75195C5.71202 8.86837 5.62116 8.98653 5.53331 9.10681C5.50662 9.14342 5.49498 9.13304 5.47064 9.10709C5.37132 9.00108 5.25733 8.91524 5.1096 8.89413C4.92315 8.86747 4.74676 8.89134 4.61696 9.0459C4.50093 9.18408 4.52668 9.35811 4.67327 9.46222C4.80448 9.55541 4.95739 9.60236 5.10536 9.65953C5.24408 9.71316 5.37957 9.77309 5.50925 9.84662C5.77383 9.99662 5.91924 10.2167 5.93819 10.525C5.97016 11.0456 5.69645 11.4853 5.18667 11.6163C4.64276 11.756 4.11131 11.6012 3.76238 11.0862C3.74799 11.0649 3.74642 11.0525 3.76623 11.0337C3.89257 10.9138 4.01772 10.7927 4.14563 10.6698Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9595 8.41135C12.1751 8.41135 12.381 8.41272 12.5868 8.41017C12.628 8.40966 12.6441 8.42444 12.6583 8.4605C12.8478 8.9402 13.3939 10.3139 13.4777 10.5259C13.516 10.4836 14.0616 9.09992 14.3188 8.45834C14.3291 8.43274 14.3364 8.41017 14.3738 8.41048C14.5838 8.41233 14.7939 8.41139 15.0129 8.41139C14.9629 8.53139 14.0632 10.6661 13.6628 11.6193C13.6469 11.6571 13.6294 11.6694 13.59 11.6679C13.5103 11.6649 13.4303 11.6651 13.3505 11.6679C13.315 11.6692 13.299 11.6562 13.2858 11.6243C13.039 11.0257 12.1733 8.93407 11.9883 8.48633C11.9787 8.46345 11.9704 8.44009 11.9595 8.41135Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.58175 9.27029C8.46461 9.15823 8.35231 9.0641 8.21819 9.00205C7.69048 8.75803 7.05519 9.02014 6.85178 9.57982C6.69513 10.0109 6.74727 10.4208 7.06203 10.7709C7.27251 11.005 7.55058 11.0904 7.8607 11.0871C8.10672 11.0846 8.31028 10.9845 8.49021 10.8255C8.51671 10.8021 8.54231 10.7777 8.56806 10.754C8.59044 10.7663 8.5812 10.7852 8.58124 10.8C8.58194 11.008 8.58131 11.2162 8.58214 11.4243C8.58226 11.4559 8.57758 11.4751 8.54329 11.4916C7.90603 11.7985 7.09989 11.677 6.60132 11.1667C6.29277 10.8508 6.14084 10.4696 6.13125 10.0282C6.11501 9.28138 6.6126 8.63012 7.30483 8.42094C7.72956 8.2926 8.14427 8.31718 8.54431 8.51794C8.56582 8.52872 8.58257 8.53804 8.58242 8.5678C8.5812 8.79684 8.58175 9.0258 8.58175 9.27029Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.51622 11.5182C3.51642 11.5703 3.50867 11.5909 3.44899 11.5892C3.28097 11.5842 3.11268 11.5862 2.94455 11.5883C2.90374 11.5889 2.89119 11.5784 2.89123 11.5363C2.89237 10.5126 2.89245 9.48882 2.89111 8.46512C2.89103 8.419 2.90629 8.40992 2.94856 8.41047C3.11461 8.41271 3.28074 8.41385 3.44671 8.41C3.50167 8.4087 3.51685 8.42191 3.51658 8.47857C3.5141 8.98725 3.51441 11.0136 3.51622 11.5182Z" fill="#231F20" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.9105 8.65987C19.9405 8.70001 19.9701 8.73957 20.0004 8.78007C19.9957 8.78007 19.992 8.78007 19.9883 8.78007C19.9714 8.78007 19.9545 8.77979 19.9376 8.78023C19.9326 8.78038 19.93 8.77846 19.9273 8.77449C19.9039 8.73937 19.8803 8.70442 19.8567 8.66942C19.8564 8.66895 19.856 8.66852 19.8558 8.66801C19.8544 8.66447 19.8513 8.6647 19.8486 8.66526C19.8456 8.66588 19.847 8.66868 19.847 8.67045C19.847 8.70477 19.8468 8.73914 19.8471 8.7735C19.8472 8.7792 19.8455 8.78034 19.8401 8.78019C19.8254 8.77983 19.8105 8.77995 19.7958 8.78015C19.792 8.78019 19.7905 8.77952 19.7905 8.77527C19.7906 8.68287 19.7906 8.59043 19.7906 8.49803C19.7906 8.49488 19.7909 8.49323 19.7948 8.49327C19.8267 8.49394 19.8586 8.49201 19.8904 8.49441C19.9125 8.4961 19.9333 8.50208 19.9503 8.51718C19.9707 8.53522 19.9764 8.55897 19.975 8.58508C19.973 8.6235 19.9521 8.64886 19.9147 8.65833C19.9134 8.65861 19.9122 8.6592 19.9105 8.65987ZM19.8469 8.58213C19.8469 8.59483 19.8472 8.60753 19.8468 8.62019C19.8467 8.62464 19.8481 8.62554 19.8522 8.62538C19.8605 8.62503 19.8688 8.62523 19.8771 8.62432C19.9015 8.62177 19.9153 8.60769 19.9171 8.58331C19.9186 8.56369 19.9085 8.54879 19.8898 8.5427C19.8776 8.53868 19.8649 8.53912 19.8522 8.53884C19.8481 8.5388 19.8466 8.53967 19.8468 8.54407C19.8471 8.55673 19.8469 8.56943 19.8469 8.58213Z" fill="#231F20" />
                <path d="M10.4574 11.6846C11.3845 11.6846 12.136 10.9304 12.136 10C12.136 9.06965 11.3845 8.31543 10.4574 8.31543C9.53035 8.31543 8.77881 9.06965 8.77881 10C8.77881 10.9304 9.53035 11.6846 10.4574 11.6846Z" fill="url(#paint0_linear_7115_1055)" />
                <path d="M10.4574 11.6846C11.3845 11.6846 12.136 10.9304 12.136 10C12.136 9.06965 11.3845 8.31543 10.4574 8.31543C9.53035 8.31543 8.77881 9.06965 8.77881 10C8.77881 10.9304 9.53035 11.6846 10.4574 11.6846Z" fill="url(#paint1_linear_7115_1055)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_7115_1055" x1="9.74576" y1="8.47384" x2="11.1691" y2="11.5262" gradientUnits="userSpaceOnUse">
                    <stop offset="0.3831" stop-color="#E45E26" />
                    <stop offset="1" stop-color="#F8A020" />
                </linearGradient>
                <linearGradient id="paint1_linear_7115_1055" x1="9.61573" y1="8.54214" x2="11.2991" y2="11.4579" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#80391B" />
                    <stop offset="0.1105" stop-color="#843B1B" stop-opacity="0.8161" />
                    <stop offset="0.2346" stop-color="#92401D" stop-opacity="0.6096" />
                    <stop offset="0.3653" stop-color="#A8481F" stop-opacity="0.3922" />
                    <stop offset="0.4996" stop-color="#C75323" stop-opacity="0.1687" />
                    <stop offset="0.601" stop-color="#E45E26" stop-opacity="0" />
                    <stop offset="1" stop-color="#F8A020" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_7115_1055">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    }


    return (
        <>
            {props?.type ? cards[props?.type?.toLowerCase()] : ""}{props?.lastFour ? `*${props?.lastFour}` : ""}
        </>
    )
}

export default CardType