import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../../Merchant/Tabs/MerchantOnboard.css";
import Modal from "react-bootstrap/Modal";
import KeyConfirm from "../Froms/KeyConfirm";
import Reload from "../../../assets/images/Reload.svg";
import Copy from "../../../assets/images/Copy.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useGetSidebarQuery, useGetProfileAdminQuery } from "../../../redux/apiSlice";
import Loader from "../../../DefaultComponent/Loader";

const ProfileView = (props) => {
  const { data: userData, isLoading, isError } = useGetSidebarQuery();
  const { data: profileData, isFetching, isLoading: load } = useGetProfileAdminQuery();

  const personalInfo = profileData?.data;
  const publicData = personalInfo?.public_data;

  const { role_id, staff_roll_type } = userData?.data;
  const { title, state, setState } = props;

  const navigate = useNavigate();
  const [showPasswords, setShowPasswords] = useState(false);

  const handleKeys = (flag) => {
    setState((prev) => ({ ...prev, key: true, keys: flag }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, key: false }));
  };

  const togglePasswordsVisibility = () => {
    setShowPasswords(!showPasswords);
  };

  const handleCopy = (coptText) => {
    toast.success(coptText);
  };

  return (
    <div
      className="tab-pane fade show active"
      id="orders-all"
      role="tabpanel"
      aria-labelledby="orders-all-tab"
    >
      <div className="app-card-body">
        <h4 className="heading">{title}</h4>
        <p className="sub-heading">Profile</p>
        <div className="white-box-content">
          <div className="row">
            <div className="col-3 Apikey">Email</div>
            <div className="col-9 Apikey"><span style={{ wordBreak: 'break-all' }}>{personalInfo?.email}</span></div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-3 Apikey">Name</div>
            <div className="col-9 Apikey">{personalInfo?.first_name?.concat(" ", personalInfo.last_name)}</div>
          </div>
          <hr />
          <p className="sub-heading" style={{ marginBottom: "1.5em", marginTop: '1em' }}>
            Credentials
          </p>
          <div className="row">
            <div className="col-md-3 col-12 Apikey" >Client ID</div>
            <div className="col-md-9 col-12 Apikey"> {publicData?.h_guid ?
              publicData?.h_guid : publicData?.guid}</div>
          </div>
          <hr />
          <div className="row">

            <div className="col-md-3 col-12 Apikey" >Webhook URL</div>
            <div className="col-md-9 col-12 Apikey d-flex align-items-center">
              <div className="col-md-9 col-12 Apikey"> {personalInfo?.webhook_url ? personalInfo?.webhook_url : personalInfo?.webhook_url}</div>
              {(role_id == 223 || (role_id == 132 && staff_roll_type == 269)) && <i className="fa fa-pencil ms-2" aria-hidden="true"
                onClick={() => { navigate('/api-keys/webhook-url', { state: { header: personalInfo?.header, webhookUrl: personalInfo?.webhook_url, flag: true } }) }}></i>}

            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 col-12 Apikey" >Public Key</div>
            <div className="col-md-6 col-12 Apikey d-flex justify-content-between">
              <span style={{ wordBreak: 'break-all' }}>{publicData?.public_key ? publicData?.public_key : "N/A"}</span>
              <div>  {!publicData?.public_key && (
                <div className="copy-code">
                  {!publicData?.public_key && <img
                    src={Reload}
                    alt="Reload"
                    onClick={() => handleKeys(false)}
                  />}
                  <CopyToClipboard text={publicData?.public_key} onCopy={() => handleCopy("Public Key copied")}>
                    <img
                      src={Copy}
                      alt="Copy"
                      style={{ marginLeft: 2 }}
                    />
                  </CopyToClipboard>
                </div>
              )}</div></div>

          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 col-12 Apikey">Secret Key</div>
            <div className="col-md-7 col-12 Apikey d-flex">
              <div className="d-flex align-items-center">
                <span style={{ wordBreak: 'break-all' }}>

                  {publicData?.api_key ? (
                    <PasswordCell
                      password={publicData?.api_key}
                      showPassword={showPasswords}
                    />
                  ) : (
                    "N/A"
                  )}
                </span>
                {publicData?.api_key &&
                  (showPasswords ? (
                    <i className="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordsVisibility}></i>
                  ) : (
                    <i className="fa fa-eye ms-2" aria-hidden="true" onClick={togglePasswordsVisibility}></i>

                  ))}  <div className="sml-line">

                </div>
              </div>
              <div>
                <div className="copy-code">
                  {!publicData?.api_key && <img
                    src={Reload}
                    alt="Reload"
                    onClick={() => handleKeys(true)}
                  />}
                  {publicData?.api_key && (
                    <>
                      <CopyToClipboard text={publicData?.api_key} onCopy={() => handleCopy("Secret Key copied")}>
                        <img
                          src={Copy}
                          alt="Copy"
                          style={{ marginLeft: 2 }}
                        />
                      </CopyToClipboard>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 col-12 Apikey" >Auth Key</div>
            <div className="col-md-9 col-12 Apikey d-flex align-items-center"> <div className="authkey">{publicData?.auth_key ? publicData?.auth_key : "N/A"}</div>
              <div className="d-flex">
                <div className="sml-line"></div>
                {publicData?.auth_key && (
                  <>
                    <div className="copy-code">
                      <CopyToClipboard text={publicData?.auth_key} onCopy={() => handleCopy("Auth Key copied")}>
                        <img
                          src={Copy}
                          alt="Copy"
                          style={{ marginLeft: 2 }}
                        />
                      </CopyToClipboard>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr />
          <Loader loading={load || isFetching} />
          <Modal
            className="confirm-key"
            style={{ width: "100%" }}
            show={state.key}
            onHide={handleClose}
          >
            <Modal.Header className="edit-member-header" closeButton>
            </Modal.Header>
            <Modal.Body>
              <KeyConfirm
                publicData={publicData}
                state={props.state}
                handleClose={handleClose}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const PasswordCell = ({ password, showPassword }) => {
  return <>{showPassword ? password : "*".repeat(password?.length)}</>;
};

export default ProfileView;