import axios from "axios";
import baseUrl from "../baseUrl";
import { store } from '../redux/store';
import END_POINTS from "./endPoints";

const getTokenFromState = () => {
  const state = store.getState();
  return state.deviceId.deviceId;
};
export const getFeeMerchant = async (token, id) => {
  let deviceId = getTokenFromState()

  const response = await axios.get(
    `${baseUrl}/api/v1/admin/fee/get-by-merchantId/${id}`,
    {
      headers: {
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const createFeeMerchant = async (token, body, id) => {
  let deviceId = getTokenFromState()

  const response = await axios.post(
    `${baseUrl}/api/v1/admin/fee/create`,
    { ...body, merchantId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const createWidgetOrder = async (formData) => {
  let deviceId = getTokenFromState()
  const response = await axios.post(
    `${process.env.REACT_APP_WIDGET_BASE_URL}${END_POINTS.CREATE_WIDGET_ORDER}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  return response;
};

export const getStaffMerchant = async (token, params) => {
  let deviceId = getTokenFromState()

  const response = await axios.get(
    `${baseUrl}/api/v1/merchant/account/employee/get-all`,
    {
      headers: {
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
      params,
    }
  );
  
  return response.data;
};

export const getStaffMerchantRole = async (token) => {
  let deviceId = getTokenFromState()

  const response = await axios.get(
    `${baseUrl}/api/v1/merchant/account/role/get-all`,
    {
      headers: {
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const createStaffMerchant = async (token, body) => {
  let deviceId = getTokenFromState();
  const response = await axios.post(
    `${baseUrl}/api/v1/merchant/account/employee/create`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const updateStaffMerchant = async (token, body, id) => {
  let deviceId = getTokenFromState()

  const response = await axios.patch(
    `${baseUrl}/api/v1/merchant/account/employee/update`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const fetchStaffMerchantDetail = async (token, id) => {
  let deviceId = getTokenFromState()

  const response = await axios.get(
    `${baseUrl}/api/v1/merchant/account/employee/get-by-id/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};
export const forgotPasswordStaffMerchant = async (token, body, id) => {
  let deviceId = getTokenFromState()

  const response = await axios.patch(
    `${baseUrl}/api/v1/merchant/account/employee/update-password`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const deleteStaffMerchant = async (token, id) => {
  let deviceId = getTokenFromState()

  const response = await axios.delete(
    `${baseUrl}/api/v1/merchant/account/employee/delete`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        'X-Device-Fingerprint': deviceId,

      },
      params: {
        employeeId: id,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};
