import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import baseUrl from "../../../baseUrl";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";

const EditManager = () => {
  const { deviceId } = useSelector(state => state.deviceId);

  const navigate = useNavigate();
  const location = useLocation().state.item;

  const [userData, setUserData] = useState({
    first_name: location.first_name,
    last_name: location.last_name,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = { ...userData, managerId: location.id };
      const userd = localStorage.getItem("token");
      const token = JSON.parse(userd);
      const { data } = await axios.patch(
        `${baseUrl}/api/v1/admin/admin-manager/edit`,
        user,
        {
          headers: {
            Authorization: token,
            'X-Device-Fingerprint': deviceId,

          },
        }
      );
      if (data?.result == true) {
        toast.success(`${data.message}`);
        navigate("/admin-manager");
      }
    } catch (error) {
      toast.error(error?.response.data.message);

      console.log(error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const breadcrumbItems = [
    { label: "Dashboard", href: "/", active: false },
    { label: "Account-Manager", href: "/admin-manager", active: false },
    { label: "Edit-Manager", href: "/edit-manager", active: true },
  ];
  return (
    <div className="container-xl">
      <BreadCrumbs items={breadcrumbItems} />
      <h1 className="app-page-title">Edit Manager</h1>

      <div className="">
        <form>
          <div className="row">
            <div className=" mb-3 col-md-6">
              <label className="sr-only" htmlFor="signin-email">
                First Name
              </label>
              <input
                id="first_name"
                name="first_name"
                type="text"
                className="form-control border border-2"
                placeholder="First name "
                value={userData.first_name}
                onChange={handleChange}
              />
            </div>
            <div className=" mb-3 col-md-6">
              <label className="sr-only" htmlFor="signin-email">
                Last Name
              </label>
              <input
                id="last_name"
                name="last_name"
                type="text"
                className="form-control border border-2"
                placeholder="Last Name "
                value={userData.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="btn app-btn-primary" onClick={handleSubmit}>
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditManager;
