import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import END_POINTS from '../api/endPoints';
import { toast } from 'react-toastify';
import { method } from 'lodash';

const getFingerPrint = async () => {
    try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        return result.visitorId;
    } catch (error) {
        console.log(error)
    }
}


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
        const deviceId = await getFingerPrint();
        // const tkn = getState().token.token;
        const tk = localStorage.getItem('token');
        if (tk) {
            let token = JSON.parse(tk);
            if (token) {
                headers.set('Authorization', token);
            }
        }
        if (deviceId) {
            headers.set('X-Device-Fingerprint', deviceId);
        }
        return headers;
    },
});

const showError = (error) => {
    if (error?.data?.message &&
        ["Unauthorized", "Forbidden"].some(status => error.data.message.includes(status))) {
        toast.error(error.data.message || "Something went wrong!")
        localStorage.removeItem("token");
        setTimeout(() => {
            window.location.replace("/login");
        }, 3000)
    }
    else if (error?.data?.message) {
        toast.error(error.data.message || "Something went wrong!")

    }
}

export const merchantApiSlice = createApi({
    reducerPath: 'merchantApi',
    baseQuery,
    tagTypes: ['merchant', 'Merchant-staff', 'Merchant-staff-edit'],
    endpoints: (builder) => ({
        //Merchant DashBoard Analytics
        getMerchantDashobardAnalytics: builder.query({
            query: ({ startDate, endDate, graphFilter }) => ({
                url: END_POINTS.MERCHNAT_DASHBOARD_ANALYTICS,
                method: 'GET',
                params: graphFilter === 'allTime'
                    ? { graphFilter }
                    : { startDate, endDate, graphFilter }
            }),
            transformErrorResponse: (error) => { showError(error) },
        }),
        getMerchantTransaction: builder.query({
            query: () => '/api/v1/merchant/account/transaction/list',
        }),
        getTransactionDetailById: builder.query({
            query: (id) => `/api/v1/merchant/account/transaction/get-by-id/${id}`,
        }),
        //GET MERCHANT STAFF
        getStaffbyMerchant: builder.query({
            query: ({ page = 1, search = '', perPage = 10, role_id }) => ({
                url: `${END_POINTS.GET_MERCHANT_STAFF}`,
                params: { page, search, perPage, role_id },
            }),
            transformResponse: (response) => {
                return {
                    data: response?.data?.allList,
                    page: response?.data?.page,
                    totalPage: response?.data?.totalPage
                }
            },
            providesTags: ['Merchant-staff'],
            transformErrorResponse: (error) => { showError(error) },
        }),
        //Get Merchant Roles
        getMerchantStaffRoles: builder.query({
            query: () => `${END_POINTS.GET_MERCHANT_STAFF_ROLES}`,
            transformResponse: (response) => {
                return {
                    data: response?.data?.allList
                }
            },
            transformErrorResponse: (error) => { showError(error) }
        }),
        //Delete Merchnat staff
        deleteMerchantStaffById: builder.mutation({
            query: (employeeId) => ({
                url: END_POINTS.DELETE_MERCHANT_STAFF_BY_ID,
                method: "DELETE",
                params: { employeeId }
            }),
            invalidatesTags: ['Merchant-staff'],
            transformErrorResponse: (error) => { showError(error) },
        }),
        //Create Merchant Staff
        createMerchantStaff: builder.mutation({
            query: (body) => ({
                url: END_POINTS.CREATE_MERCHANT_STAFF,
                method: "POST",
                body
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Merchant-staff']
        }),
        //Get Merchant staff data by ID
        getMerchantStaffById: builder.query({
            query: ({ id }) => `${END_POINTS.GET_MERCHANT_STAFF_ID}/${id}`,
            transformResponse: (response) => {
                return {
                    data: response?.data
                }
            },
            transformErrorResponse: (error) => { showError(error) },
            providesTags: ['Merchant-staff-edit']
        }),
        //Update Merchant Staff
        updateMerchantStaff: builder.mutation({
            query: (body) => ({
                url: END_POINTS.UPDATE_MERCHANT_STAFF,
                method: "PATCH",
                body
            }),
            transformErrorResponse: (error) => { showError(error) },
            invalidatesTags: ['Merchant-staff-edit', 'Merchant-staff']
        })

    }),
});

export const {
    useGetMerchantTransactionQuery,
    useGetTransactionDetailByIdQuery,
    useGetStaffbyMerchantQuery,
    useGetMerchantStaffRolesQuery,
    useDeleteMerchantStaffByIdMutation,
    useCreateMerchantStaffMutation,
    useGetMerchantStaffByIdQuery,
    useUpdateMerchantStaffMutation,
    useGetMerchantDashobardAnalyticsQuery,
} = merchantApiSlice;
