import React from "react";
import { useCreatePublicKeyMutation } from "../../../redux/apiSlice";
import Loader from "../../../DefaultComponent/Loader";

const KeyConfirm = (props) => {
  const { publicData, state, handleClose } = props;
  const [createPublicKeyTrigger, { isFetching, isLoading }] = useCreatePublicKeyMutation();

  const handleSubmit = async () => {
    const body = {
      guid: publicData?.h_guid ? publicData?.h_guid : publicData?.guid,
      clientId: "",
      isUpdateApikeys: state.keys,
    };
    try {
      await createPublicKeyTrigger(body).unwrap();
      handleClose();
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };
  return (
    <div className="">
      <h5 className="text-center">Are you sure ?</h5>
      <div className="row pt-3">
        <div className="col-6">
          <button
            className="cancel-btn "
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button className="add-btn" onClick={handleSubmit}>
            Yes
          </button>
        </div>
      </div>
      <Loader  loading={isFetching || isLoading} />
    </div>
  );
};

export default KeyConfirm;
