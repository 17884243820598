import React, { useEffect, useState } from 'react';
import { useAddRoleMutation, useGetRolesQuery, useLazyGetRoleByIdQuery, useUpdateRoleMutation } from '../../../redux/superadminApiSlice';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Loader from '../../../DefaultComponent/Loader';


const AddRole = ({ roleItem, action, handleClose }) => {
    const { data: roles, isLoading: rolesLoading } = useGetRolesQuery();
    let [addRoleTrigger, {  isLoading: addRoleLoad }] = useAddRoleMutation();
    let [updateRoleTrigger, { isLoading: updateRoleLoad }] = useUpdateRoleMutation();
    let [getRoleDataByIDTrigger, { isLoading: getRoleLoad }] = useLazyGetRoleByIdQuery();
    const [roleData, setRoleData] = useState({
        name: '',
        user_role: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const handleChange = (name, value) => {
        setRoleData((prev) => ({ ...prev, [name]: value }));
        setFormErrors((prev) => ({ ...prev, [name]: '' }));
    };
    //close modal
   

    // filed validations
    const validateFields = () => {
        const errors = {};
        if (!roleData.name.trim()) errors.name = 'Name is required';
        if (!roleData.user_role) errors.user_role = 'User role is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors); 
            return;
        }
        try {
            let body = { role_name: roleData?.name, user_role: roleData?.user_role?.value }
            if (action === "Add") {
                let updateResponse=  await addRoleTrigger(body).unwrap();
                
                if (updateResponse?.result) {
                    console.log("role added",updateResponse);
                    toast.success(updateResponse?.message);
                    handleClose()
                }
            }
            else {
                body = { ...body, roleId: roleItem?.role_id }
                let updateResponse = await updateRoleTrigger(body).unwrap();
                if (updateResponse?.result) {
                    toast.success(updateResponse?.message);
                    handleClose()
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    console.log("roeleee data",action);
    
    useEffect(() => {
        (async () => {

            if (roleItem && action === 'Edit') {
                let repData = await getRoleDataByIDTrigger({ id: roleItem?.role_id }).unwrap();
                if (repData) {
                    let findRole = roles?.find(item => item.value === repData?.data?.user_role)
                    setRoleData((prev) => ({
                        ...prev,
                        name: roleItem?.role_name || '',
                        user_role: findRole || '',
                    }));
                    // get menu by role id
                }
            }
        })()
    }, [roles]);

    return (
        <div className="container-xl">
            <form>

                <div className="row">
                    <div className="col-12">
                        <label>Name</label>
                        <input
                            className="custom-date-picker"
                            type="text"
                            name="Name"
                            value={roleData.name}
                            placeholder="Name"
                            onChange={(e) =>  handleChange('name', e.target.value) }
                            required
                        />
                        {formErrors.name && <span className="text-danger fs-12">{formErrors.name}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label>Role</label>
                        <Select className='' placeholder="role" options={roles}
                            value={roleData.user_role}
                            isLoading={rolesLoading || getRoleLoad}

                            onChange={(e) => {

                                handleChange('user_role', e)
                            }} 
                            menuPlacement="auto" 
                            menuShouldScrollIntoView={false}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        {formErrors.user_role && <span className="text-danger fs-12">{formErrors.user_role}</span>}

                    </div>


                </div>

                <div className="col-6">
                    <button className="idle-green-btn w-100" onClick={handleSubmit}>
                        {action === 'Add' ? 'Add' : 'Update'} Role
                    </button>
                </div>
            </form>
            <Loader loading={updateRoleLoad || addRoleLoad} />

        </div>
    );
};

export default AddRole;
