import Loader from '../../../DefaultComponents/Loader';
import { useGetProfileQuery } from '../../../../redux/superadminApiSlice';

const Profile = () => {
    const { data: profileData, isFetching ,isLoading } = useGetProfileQuery();
    
    return (
        <div className="container-xl">
            <div className="d-flex justify-content-between align-items-start mb-3"></div>
            <div className="tab-content" id="orders-table-tab-content">
                <div
                    className="tab-pane fade show active"
                    id="orders-all"
                    role="tabpanel"
                    aria-labelledby="orders-all-tab"
                >
                    <div className="app-card-body">
                        <h4 className="heading">Developer</h4>
                        <p className="sub-heading">Profile</p>
                        <div className="white-box-content">
                            <div className="row">
                                <div className="col-3 Apikey"  >Email</div>
                                <div className="col-9 Apikey"><span style={{ wordBreak: 'break-all' }}>{profileData?.data?.email}</span></div>
                            </div>
                            <hr />
                            <div className="row ">
                                <div className="col-3 Apikey">Name</div>
                                <div className="col-9 Apikey">{profileData?.data?.first_name} {profileData?.data?.last_name}</div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
            <Loader loading={isFetching || isLoading} />
        </div>
    )
}

export default Profile
