import { createSlice } from "@reduxjs/toolkit";


let deviceIdSlice = createSlice({
    name: "device-id",
    initialState: { deviceId:"" },
    reducers: {
        getDeviceId: (state, action) => {
            state.deviceId = action.payload;
        },
      
    }
})

export const deviceIdAction = deviceIdSlice.actions;
const deviceIdReducer = deviceIdSlice.reducer;
export default deviceIdReducer;