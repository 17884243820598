import React from 'react'
import { QRCodeCanvas } from 'qrcode.react';

const QRScanner = ({ QRInfo }) => {
    console.log("QRInfo", QRInfo);
    
    return (
        <div className='text-center'>
            <QRCodeCanvas value={QRInfo} size={256} level="H" />
        </div>
    )
}

export default QRScanner
