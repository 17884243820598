import React from 'react'
import { AddCustomer, CustomerDatable } from '@fractalpay/fractalpay-test';

const CustomerDev = ({ componentType, fractalpayClientKey }) => {
  return (
    <>
      {componentType === 'addCustomer' && (
        <AddCustomer fractalpayClientKey={fractalpayClientKey} />
      )}
      {componentType === 'customerList' && (
        <CustomerDatable fractalpayClientKey={fractalpayClientKey} />
      )}
    </>
  )
}

export default CustomerDev
