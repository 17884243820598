import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar.jsx";
// import Dashboard from "../Dashboard/Page/Dashboard.jsx";
import Footer from "../../components/Footer.jsx";
import Dashboard from "../../SuperAdmin/Pages/Dashboard/Page/Dashboard.jsx";
import AllClientsList from "../../SuperAdmin/Pages/Clients/Page/AllClientsList.jsx";
import ClientMerchant from "../../SuperAdmin/Pages/Clients/Tabs/ClientMerchant.jsx";
import Staff from "../Pages/Staff/Page/Staff.jsx";
import ClientTransaction from "../Pages/Clients/Tabs/ClientTransaction.jsx";
import Profile from "../Pages/Profile/Page/Profile.jsx";
import ClientLogs from "../Pages/Clients/Page/ClientLogs.jsx";
import ClientDetail from "../Pages/Clients/Detail/ClientDetail.jsx";
import Customers from "../Pages/Customer/Page/Customers.jsx";
import ClientMerchantDetail from "../Pages/Clients/Detail/ClientMerchantDetail.jsx";
import EditClientMerchant from "../Pages/Clients/Form/EditClientMerchant.js";
import PdfViewer from "../DefaultComponents/PdfViewer.jsx";
import Transactiondetails from "../Pages/TransactionDeatils/Transactiondetails.jsx";
import TransactionList from "../Pages/TransactionDeatils/TransactionList.jsx";
import CreateRecord from "../Pages/TransactionDeatils/CreateRecord.jsx";
import SuperAdminPermissions from "../Pages/Permissions/Permissions.jsx";

import SuperAdminRoles from "../Pages/Roles/SuperAdminRoles.jsx";
import MenuList from "../Pages/Menu/MenuList.jsx";



const SuperAdminLayout = () => {

    return (
        <div className=" ">
            {/* <header className="app-header fixed-top"> */}
            {/* <Header /> */}
            <Sidebar />
            {/* </header> */}
            <div className="app-wrapper">
                <div className="app-content ">
                    <div className="container-xl">
                        <div className="white-box-main">
                            <div className="" id="style-4">
                                <Routes>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="/clients" element={<AllClientsList />} />
                                    <Route path="/clients/:clientId" element={<ClientDetail />} />
                                    <Route path="/clients/:clientId/merchants" element={<ClientMerchant />} />
                                    <Route path="/clients/:clientId/transactions" element={<ClientTransaction />} />
                                    <Route path="/clients/:clientId/:merchantId/transactions" element={<ClientTransaction />} />
                                    <Route path="/clients/:clientId/:merchantId" element={<ClientMerchantDetail />} />
                                    <Route path="/clients/:clientId/:merchantId/edit" element={<EditClientMerchant />} />
                                    <Route path="/active-log" element={<ClientLogs />} />
                                    <Route path="/customer" element={<Customers />} />
                                    <Route path="/staffs" element={<Staff />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="/viewdocument" element={<PdfViewer />} />
                                    <Route path="/transactions/:id" element={<Transactiondetails />} />
                                    <Route path="/transactions" element={<TransactionList />} />
                                    <Route path="/create-transaction" element={<CreateRecord />} />
                                    <Route path="/permissions/:role" element={<SuperAdminPermissions />} />
                                    <Route path="/menu" element={<MenuList />} />
                                    <Route path="/roles" element={<SuperAdminRoles />} />

                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default SuperAdminLayout;