import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DocsjsonData } from "./content/DocsjsonData";

const DocsSideBar = ({ show, activeTab, setActiveTab, method, setMethod, handleActiveTab }) => {

  useEffect(() => {
    if (show == "documentation") {
      setActiveTab("/docs/documentation/introduction");
    } else {
      setActiveTab("/docs/api/full-merchant-submit");
      setMethod("/docs/api/full-merchant-submit");
    }
  }, [show]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeTab, method]);



  const Activestyles = (apimethod) => {

    if (apimethod === "GET") {
      return { color: '#fff', backgroundColor: '#2AB673' }
    } else if (apimethod === "PUT") {
      return { color: '#fff', backgroundColor: '#a16207' }
    } else if (apimethod === "DELETE") {
      return { color: '#fff', backgroundColor: '#c52626' }
    } else if (apimethod === "PATCH") {
      return { color: '#fff', backgroundColor: '#750080 ' }
    } else {
      return { color: '#fff', backgroundColor: '#3064e3' }
    }
  }

  const Normalstyles = (apimethod) => {
    if (apimethod === "GET") {
        return { color: '#2AB673', backgroundColor: '#2ab67336' }
    } else if (apimethod === "DELETE") {
        return { color: '#c52626', backgroundColor: '#c5262636' }
    } else if (apimethod === "PUT") {
        return { color: '#a16207', backgroundColor: '#a1620736' }
    } else if (apimethod === "PATCH") {
        return { color: '#750080', backgroundColor: '#75008036' }
    } else {
        return { color: '#3064E3', backgroundColor: '#3064e336' }
    }
}



  return (
    <>
      {/* documentation sidebar */}
      {show == "documentation" && (
        <div
          className="z-20 hidden lg:block fixed bottom-0 right-auto w-[18rem] top-[7.1rem]"
          id="sidebar"
        >
          <div
            className="absolute inset-0 z-10 stable-scrollbar-gutter overflow-auto pr-8 pb-10"
            id="sidebar-content"
          >
            <div className="relative lg:text-sm lg:leading-6">
              <div className="sticky top-0 h-8 bg-gradient-to-b from-background-light dark:from-background-dark" />
              <ul id="navigation-items">
                <li className="paymt">
                  {/* <Link
                    target="_blank"
                    rel="noreferrer"
                    className="pl-4 group flex items-center lg:text-sm lg:leading-6 mb-5 sm:mb-4 font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    <div
                      className="mr-4 rounded-md p-1 zinc-box group-hover:brightness-100 group-hover:ring-0 ring-1 ring-gray-950/5 dark:ring-gray-700/40"
                      style={{}}
                    >
                      <svg
                        className="h-4 w-4 secondary-opacity group-hover:fill-primary-dark group-hover:bg-white bg-gray-400 dark:bg-gray-500"
                        style={{
                          maskImage:
                            'url("https://mintlify.b-cdn.net/v6.5.1/duotone/book-open-cover.svg")',
                          maskRepeat: "no-repeat",
                          maskPosition: "center center",
                        }}
                      />
                    </div>
                    Embedded Payments
                  </Link> */}
                </li>
                <div className="mt-12 lg:mt-8">
                  <h5 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                    API Documentation
                  </h5>
                  <li>
                    <Link
                      to={"/docs/documentation/introduction"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/documentation/introduction"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        setActiveTab("/docs/documentation/introduction")
                      }
                    >
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Introduction</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/documentation/implementation-guide"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/documentation/implementation-guide"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        setActiveTab("/docs/documentation/implementation-guide")
                      }
                    >
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Implementation Guide</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/documentation/portal-guide"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/documentation/portal-guide"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        setActiveTab("/docs/documentation/portal-guide")
                      }
                    >
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Portal Guide</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/documentation/webhooks"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/documentation/webhooks"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        setActiveTab("/docs/documentation/webhooks")
                      }
                    >
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Webhooks</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/documentation/low-code"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/documentation/low-code"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        setActiveTab("/docs/documentation/low-code")
                      }
                    >
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Low Code JS</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/documentation/Changelog"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/documentation/Changelog"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        setActiveTab("/docs/documentation/Changelog")
                      }
                    >
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Changelog</div>
                      </div>
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* API reference sidebar */}
      {show == "api" && (
        <div
          className="z-20 hidden lg:block fixed bottom-0 right-auto w-[18rem] top-[7.1rem]"
          id="sidebar"
        >
          <div
            className="absolute inset-0 z-10 stable-scrollbar-gutter overflow-auto pr-8 pb-10 slide-overflo"
            id="sidebar-content"
          >
            <div className="relative lg:text-sm lg:leading-6">
              <div className="sticky top-0 h-8 bg-gradient-to-b from-background-light dark:from-background-dark" />
              <ul id="navigation-items">
                {/* <li>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    className="pl-4 group flex items-center lg:text-sm lg:leading-6 mb-5 sm:mb-4 font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    <div
                      className="mr-4 rounded-md p-1 zinc-box group-hover:brightness-100 group-hover:ring-0 ring-1 ring-gray-950/5 dark:ring-gray-700/40"
                      style={{}}
                    >
                      <svg
                        className="h-4 w-4 secondary-opacity group-hover:fill-primary-dark group-hover:bg-white bg-gray-400 dark:bg-gray-500"
                        style={{
                          maskImage:
                            'url("https://mintlify.b-cdn.net/v6.5.1/duotone/book-open-cover.svg")',
                          maskRepeat: "no-repeat",
                          maskPosition: "center center",
                        }}
                      />
                    </div>
                    Embedded Payments
                  </Link>
                </li> */}



                {/* <div className="mt-12 lg:mt-8">
                  <h5 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                    Merchant Onboarding
                  </h5>
                  <li>
                    <Link
                      to={"/docs/api/full-merchant-submit"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/full-merchant-submit"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/full-merchant-submit")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/full-merchant-submit"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Full Merchant Submit</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/hosted-onboarding"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/hosted-onboarding"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/hosted-onboarding")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/hosted-onboarding"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Hosted Onboarding</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/get-merchant-by-client"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/get-merchant-by-client"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      style={{ paddingLeft: "1rem" }}
                      onClick={() =>
                        handleActiveTab("/docs/api/get-merchant-by-client")
                      }
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/get-merchant-by-client"
                            ? "bg-[#2AB673] text-white"
                            : "bg-green-400/20 text-green-700 dark:bg-green-400/20 dark:text-green-400"
                            }`}
                        >
                          GET
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Get Merchants by Client</div>
                      </div>
                    </Link>
                  </li>
                </div>
                <div className="mt-12 lg:mt-8">
                  <h5 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                    Payment Collection Methods
                  </h5>
                  <li>
                    <Link
                      to={"/docs/api/create-link"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/create-link"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/create-link")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/create-link"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Create Payment Link</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/payment-link"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/payment-link"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/payment-link")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/payment-link"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>QR Payment Link</div>
                      </div>
                    </Link>
                  </li>
                  <br></br>
                  <h8 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                    Requests
                  </h8>

                  <li>
                    <Link
                      to={"/docs/api/payment-request"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/payment-request"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/payment-request")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/payment-request"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Payment Request</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/PaymentRequestCustomer"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/PaymentRequestCustomer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/PaymentRequestCustomer")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/PaymentRequestCustomer"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Payment Request Customer</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/AuthorizationRequestCustomer"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/AuthorizationRequestCustomer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/AuthorizationRequestCustomer")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/AuthorizationRequestCustomer"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Authorization Request Customer</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/RequestCardCustomer"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/RequestCardCustomer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/RequestCardCustomer")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/RequestCardCustomer"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Request Card With Customer</div>
                      </div>
                    </Link>
                  </li>

                  <br></br>
                  <h8 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-700 dark:text-gray-200">
                    Preauthorizations
                  </h8>

                  <li>
                    <Link
                      to={"/docs/api/PreauthWithToken"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/PreauthWithToken"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/PreauthWithToken")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/PreauthWithToken"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Pre-Authorization with Token</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/IncrementPreAuth"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/IncrementPreAuth"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/IncrementPreAuth")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/IncrementPreAuth"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Increment Pre-Authorization</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/CapturePreAuthorization"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/CapturePreAuthorization"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/CapturePreAuthorization")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/CapturePreAuthorization"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Capture Pre-Authorization</div>
                      </div>
                    </Link>
                  </li>
                </div>



                <div className="mt-12 lg:mt-8">
                  <h5 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                    Customers
                  </h5>
                  <li>
                    <Link
                      to={"/docs/api/create-customer"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/create-customer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/create-customer")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/create-customer"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Create Customer</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/get-customer"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/get-customer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/get-customer")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/get-customer"
                            ? "bg-[#2AB673] text-white"
                            : "bg-green-400/20 text-green-700 dark:bg-green-400/20 dark:text-green-400"
                            }`}
                        >
                          GET
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Get Customer</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/get-customers"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/get-customers"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/get-customers")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/get-customers"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Get Customers</div>
                      </div>
                    </Link>
                  </li>
                  <br></br>
                  <h8 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-700 dark:text-gray-200">
                    Charging Customer
                  </h8>

                  <li>
                    <Link
                      to={"/docs/api/charge-customer"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/charge-customer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/charge-customer")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/charge-customer"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Charge Customer</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/charge-customer-preauth"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/create-customer"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/charge-customer-preauth")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/charge-customer-preauth"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Charge Customer PreAuth</div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/docs/api/request-card"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/request-card"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/request-card")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/request-card"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Request Card</div>
                      </div>
                    </Link>
                  </li>

                </div>
                <div className="mt-12 lg:mt-8">
                  <h5 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                    Transactions
                  </h5>
                  <li>
                    <Link
                      to={"/docs/api/get-transactions"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/get-transactions"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/get-transactions")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/get-transactions"
                            ? "bg-[#2AB673] text-white"
                            : "bg-green-400/20 text-green-700 dark:bg-green-400/20 dark:text-green-400"
                            }`}
                        >
                          GET
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Get Transactions</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/get-transaction"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/get-transaction"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() =>
                        handleActiveTab("/docs/api/get-transaction")
                      }
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/get-transaction"
                            ? "bg-[#2AB673] text-white"
                            : "bg-green-400/20 text-green-700 dark:bg-green-400/20 dark:text-green-400"
                            }`}
                        >
                          GET
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Get Transaction</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/return-sale"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/return-sale"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/return-sale")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/return-sale"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Return Sale</div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/docs/api/void-sale"}
                      className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === "/docs/api/void-sale"
                        ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                        : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                        }`}
                      onClick={() => handleActiveTab("/docs/api/void-sale")}
                      style={{ paddingLeft: "1rem" }}
                    >
                      <span className="w-8 flex items-center">
                        <span
                          className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold ${method === "/docs/api/void-sale"
                            ? "bg-[#3064E3] text-white"
                            : "bg-blue-400/20 text-blue-700 dark:bg-blue-400/20 dark:text-blue-400"
                            }`}
                        >
                          POST
                        </span>
                      </span>
                      <div className="flex-1 flex items-center space-x-2.5">
                        <div>Void Sale</div>
                      </div>
                    </Link>
                  </li>
                </div> */}

                {
                  DocsjsonData?.map((data) => {
                    return (
                      <div className="mt-12 lg:mt-8">
                        <h5 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                          {data?.title}
                        </h5>
                        {
                          data?.api?.map((item) => {
                            return (
                              <li>
                                <Link
                                  to={item?.url}
                                  className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === item?.url
                                    ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                                    : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    }`}
                                  onClick={() => handleActiveTab(item?.url)}
                                  style={{ paddingLeft: "1rem" }}
                                >
                                  <span className="w-8 flex items-center">
                                    <span
                                      className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold`}
                                      style={activeTab === item?.url ? Activestyles(item?.method) : Normalstyles(item?.method)}
                                    >
                                      {item?.method}
                                    </span>
                                  </span>
                                  <div className="flex-1 flex items-center space-x-2.5">
                                    <div>{item?.label}</div>
                                  </div>
                                </Link>
                              </li>
                            )
                          })
                        }
                        {
                          data?.sub && (
                            data?.sub?.map((subitem) => {
                              return (
                                <>
                                  <br></br>
                                  <h8 className="pl-4 mb-3.5 lg:mb-2.5 font-semibold text-gray-900 dark:text-gray-200">
                                    {subitem?.subtitle}
                                  </h8>
                                  {
                                    subitem?.subapi?.map((subapiitem) => {
                                      return (
                                        <li>
                                          <Link
                                            to={subapiitem?.url}
                                            className={`group mt-2 lg:mt-0 flex items-center pr-3 py-1.5 cursor-pointer focus:outline-primary dark:focus:outline-primary-light space-x-3 rounded-lg ${activeTab === subapiitem?.url
                                              ? "bg-primary/10 text-primary font-semibold dark:text-primary-light dark:bg-primary-light/10"
                                              : "hover:bg-gray-600/5 dark:hover:bg-gray-200/5 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                              }`}
                                            onClick={() => handleActiveTab(subapiitem?.url)}
                                            style={{ paddingLeft: "1rem" }}
                                          >
                                            <span className="w-8 flex items-center">
                                              <span
                                                className={`px-1 py-0.5 rounded-md text-[0.55rem] leading-tight font-bold `}
                                                style={method === subapiitem?.url ? Activestyles(subapiitem?.method) : Normalstyles(subapiitem?.method)}
                                              >
                                                {subapiitem?.method}
                                              </span>
                                            </span>
                                            <div className="flex-1 flex items-center space-x-2.5">
                                              <div>{subapiitem?.label}</div>
                                            </div>
                                          </Link>
                                        </li>
                                      )
                                    })
                                  }
                                </>
                              )
                            })
                          )
                        }
                      </div>
                    )
                  })
                }


              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocsSideBar;
